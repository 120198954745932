import { useMemo } from 'preact/hooks';
import { I18n } from 'react-redux-i18n';
import { SetValueConfig } from 'react-hook-form';
import cls from 'classnames';

import Button from 'Components/buttons';
import Checkbox from 'Components/fields/checkbox';
import DropDownMenu from 'Components/DropDownMenu';
import Tooltip from 'Components/Tooltip';
import InputField from '../inputField';

import { isOptionWithExtraFieldsData, Option } from 'Types/commonTypes';
import arrowDown from 'Assets/icons/arrowDown.svg';
import closeIcon from 'Assets/icons/cross.svg';
import { useDropdown } from '../../../helpers/hooks/useDropdown';

import styles from './dropdownCheckbox.scss';

export type DropdownCheckboxProps = {
  formLabel?: string;
  placeholder?: string;
  options: Option[];
  selectedOptions: Option[];
  allowUnknownOption?: boolean;
  async?: boolean;
  id?: string;
  name?: string;
  renderWithTooltip?: boolean;
  entityName?: string;
  enableFieldStyling?: boolean;
  noOptionsMessage?: string;
  totalOptionsCount?: number;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  showSelectedOptions?: boolean;
  onChange: (option: Option[]) => void;
  onStyleBtnClick?: () => void;
  onLoadAllOptions?: () => void;
  setValue?: (name: string, value: unknown, config?: SetValueConfig) => void;
  onInputChange?: (e: InputEvent) => void;
  onChipClick?: (value: string) => void;
};

const DropdownCheckbox = ({
  formLabel,
  placeholder,
  options,
  selectedOptions,
  allowUnknownOption,
  async,
  id,
  name = 'dropdown-checkbox',
  renderWithTooltip = false,
  entityName,
  enableFieldStyling,
  noOptionsMessage,
  totalOptionsCount,
  hasError,
  errorMessage,
  disabled,
  showSelectedOptions,
  onChange,
  onStyleBtnClick,
  onInputChange,
  onLoadAllOptions,
  setValue,
  onChipClick,
}: DropdownCheckboxProps) => {
  const selectedOptionMemo = useMemo(() => {
    return selectedOptions ? selectedOptions : [];
  }, [selectedOptions]);

  const {
    getRootProps,
    getInputProps,
    getDropdownProps,
    getOptionProps,
    highlightedOptionIndex,
    isOpen,
    availableOptions,
    selectedOption,
    areAllOptionsSelected,
  } = useDropdown({
    options,
    selected: selectedOptionMemo,
    name,
    allowUnknownOption,
    async,
    totalOptionsCount,
    isMultiSelect: true,
    disabled,
    setValue,
    onChange,
    onInputChange,
    onLoadAllOptions,
  });

  const getTooltipTitle = () => {
    return selectedOption.map(option => {
      if (!isOptionWithExtraFieldsData(option)) return;

      return (
        <div className={styles.tooltip}>
          <div className={styles.title}>{option.label}</div>
          <div className={styles.body}>
            {!!option.extraFieldsData.length ? (
              option.extraFieldsData.map(field => {
                return (
                  <div className={styles.row}>
                    <div>{field.key}</div>
                    <div>{field.value}</div>
                  </div>
                );
              })
            ) : (
              <div>No additional info</div>
            )}
          </div>
        </div>
      );
    });
  };

  const getTooltipOptionTitle = (option: Option) => {
    if (!isOptionWithExtraFieldsData(option)) return;

    return (
      <div className={styles.tooltip}>
        <div className={styles.title}>{option.label}</div>
        <div className={styles.body}>
          {!!option.extraFieldsData.length ? (
            option.extraFieldsData.map(field => {
              return (
                <div className={styles.row}>
                  <div>{field.key}</div>
                  <div>{field.value}</div>
                </div>
              );
            })
          ) : (
            <div>No additional info</div>
          )}
        </div>
      </div>
    );
  };

  const renderOptions = () => {
    if (!availableOptions.length) {
      return (
        <label class={[styles.dropdownMenuLabel, styles.checkboxSelectLabel].join(' ')}>
          {noOptionsMessage}
        </label>
      );
    }
    return availableOptions.map((option, index) => (
      <Tooltip
        title={getTooltipOptionTitle(option)}
        delay={100}
        variant="white"
        placement="left"
        shouldRenderTitle={renderWithTooltip}
      >
        <div
          class={cls(styles.dropdownMenuItem, {
            [styles.highlighted]: highlightedOptionIndex === index,
          })}
        >
          <Checkbox
            checked={selectedOption.some(selectedOption => selectedOption.value === option.value)}
            id={option.value}
            data-testid="dropdown-item-checkbox"
            onChange={() => getOptionProps().onOptionSelect(option)}
          />
          <label
            class={[styles.dropdownMenuLabel, styles.checkboxSelectLabel].join(' ')}
            for={option.value}
            data-testid="dropdown-item-label"
          >
            {option.label}
          </label>
        </div>
      </Tooltip>
    ));
  };

  const renderSelectedOptions = () => {
    if (!showSelectedOptions) return null;

    return (
      <div className={styles.selectedOptionsSection}>
        {selectedOption.map(option => {
          const isOptionExist = availableOptions.some(o => option.value === o.value);
          return (
            <div className={styles.chip}>
              <div>
                <div className={styles.label}>{option.label}</div>
                {isOptionExist && (
                  <div className={styles.description} onClick={() => onChipClick?.(option.value)}>
                    View {entityName}
                  </div>
                )}
              </div>
              <Button
                variant="text"
                icon={closeIcon}
                className={styles.removeItemBtn}
                iconClassName={styles.removeItemIcon}
                onClick={() => getDropdownProps().selectDeselectAllProps.onDeselectOption(option)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const classnames = isOpen ? [styles['chevron-flip']].join(' ') : '';

  return (
    <div class={styles.dropdownWidthFix}>
      <div id="dropdown-checkbox" aria-labelledby="dropdown-checkbox-label">
        <div className={classnames} data-testid="dropdown-checkbox-input-field" {...getRootProps()}>
          <Tooltip
            title={getTooltipTitle()}
            delay={100}
            variant="white"
            shouldRenderTitle={renderWithTooltip}
          >
            <InputField
              formLabel={formLabel}
              placeholder={placeholder}
              name={name}
              id={id}
              hasError={hasError}
              errorMessage={!isOpen ? errorMessage : null}
              endAdornment={
                <img
                  src={arrowDown}
                  className={cls(styles.arrowIcon, {
                    [styles.arrowIconRotated]: isOpen,
                  })}
                />
              }
              {...getInputProps()}
            />
          </Tooltip>
        </div>
        <DropDownMenu
          isOpen={isOpen}
          highlightedOptionIndex={highlightedOptionIndex}
          dropdownMenuProps={getDropdownProps()}
          noOptionsMessage={noOptionsMessage}
        >
          {!!availableOptions.length && (
            <div className={styles.dropdownHeader}>
              <Checkbox
                onChange={() => getDropdownProps().selectDeselectAllProps.onSelectAll()}
                checked={areAllOptionsSelected}
                formLabel={I18n.t(
                  areAllOptionsSelected ? 'checkbox.deselectAll' : 'checkbox.selectAll',
                )}
                margin="none"
                id="select-all"
                data-testid="dropdown-item-checkbox"
              />
              {enableFieldStyling && (
                <Button variant="text" label="Style" onClick={onStyleBtnClick} />
              )}
            </div>
          )}
          {renderOptions()}
        </DropDownMenu>
      </div>
      {renderSelectedOptions()}
    </div>
  );
};

export default DropdownCheckbox;
