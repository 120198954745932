import { h, ComponentChildren } from 'preact';
import classnames from 'classnames';

import closeIcon from '../../assets/icons/close.svg';
import closeWhiteIcon from '../../assets/icons/close-white.svg';
import styles from './alerts.scss';

export enum AlertType {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}
export interface Alert {
  type: AlertType;
  message: string | ComponentChildren;
  action?: ComponentChildren;
  onActionClick?: () => void;
  onClose?: () => void;
}

const SingleAlert = ({ type, message, action, onActionClick, onClose }: Alert) => {
  return (
    <div className={classnames(styles.alert__post, styles[`alert${type}`])}>
      <div className={styles.alert__body}>
        {message}
        {!!action && (
          <div className={styles.alertAction} onClick={onActionClick}>
            {action}
          </div>
        )}
      </div>
      <button type="button" className={styles.close} onClick={onClose}>
        <img src={type === AlertType.Error ? closeWhiteIcon : closeIcon} />
      </button>
    </div>
  );
};

export default SingleAlert;
