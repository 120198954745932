import {
  CreateOrderRequestProps,
  LoadOrderRequestsParams,
  LoadProducedMaterialsParams,
  ProducedMaterialType,
  RequestOrderDatesPaginationType,
} from 'Types/orderRequestTypes';
import { CreateOrderResponse } from 'Types/orderTypes';
import { PlantId } from 'Types/plantTypes';
import { Action } from '../helper';

export enum OrderRequestsActionTypes {
  LOAD_ORDER_REQUESTS_REQUESTED = '@orderRequests/LOAD_ORDER_REQUESTS_REQUESTED',
  LOAD_ORDER_REQUESTS_SUCCEEDED = '@orderRequests/LOAD_ORDER_REQUESTS_SUCCEEDED',
  LOAD_ORDER_REQUESTS_FAILED = '@orderRequests/LOAD_ORDER_REQUESTS_FAILED',

  LOAD_PRODUCED_MATERIALS_REQUESTED = '@orderRequests/LOAD_PRODUCED_MATERIALS_REQUESTED',
  LOAD_PRODUCED_MATERIALS_SUCCEEDED = '@orderRequests/LOAD_PRODUCED_MATERIALS_SUCCEEDED',
  LOAD_PRODUCED_MATERIALS_FAILED = '@orderRequests/LOAD_PRODUCED_MATERIALS_FAILED',

  CREATE_ORDER_REQUEST_REQUESTED = '@orderRequests/CREATE_ORDER_REQUEST_REQUESTED',
  CREATE_ORDER_REQUEST_SUCCEEDED = '@orderRequests/CREATE_ORDER_REQUEST_SUCCEEDED',
  CREATE_ORDER_REQUEST_FAILED = '@orderRequests/CREATE_ORDER_REQUEST_FAILED',

  FETCH_ORDER_REQUEST_COUNT_REQUESTED = '@orderRequests/FETCH_ORDER_REQUEST_COUNT_REQUESTED',
  FETCH_ORDER_REQUEST_COUNT_SUCCEEDED = '@orderRequests/FETCH_ORDER_REQUEST_COUNT_SUCCEEDED',
  FETCH_ORDER_REQUEST_COUNT_FAILED = '@orderRequests/FETCH_ORDER_REQUEST_COUNT_FAILED',
}

export type OrderRequestActions =
  | CreateOrderRequestActionRequested
  | CreateOrderRequestActionSucceeded
  | CreateOrderRequestActionFailed
  | LoadOrderRequestActionRequested
  | LoadOrderRequestActionSucceeded
  | LoadOrderRequestActionFailed
  | LoadProducedMaterialsActionRequested
  | LoadProducedMaterialsActionSucceeded
  | LoadProducedMaterialsActionFailed
  | FetchOrderRequestCountActionRequested
  | FetchOrderRequestCountActionSucceeded
  | FetchOrderRequestCountActionFailed;

export type LoadOrderRequestActionRequested = Action<
  OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_REQUESTED,
  LoadOrderRequestsParams
>;

export type LoadOrderRequestActionSucceeded = Action<
  OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_SUCCEEDED,
  RequestOrderDatesPaginationType
>;

export type LoadOrderRequestActionFailed = Action<
  OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_FAILED,
  unknown
>;

export type LoadProducedMaterialsActionRequested = Action<
  OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_REQUESTED,
  LoadProducedMaterialsParams
>;

export type LoadProducedMaterialsActionSucceeded = Action<
  OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_SUCCEEDED,
  ProducedMaterialType[]
>;

export type LoadProducedMaterialsActionFailed = Action<
  OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_FAILED,
  unknown
>;

export type CreateOrderRequestActionRequested = Action<
  OrderRequestsActionTypes.CREATE_ORDER_REQUEST_REQUESTED,
  CreateOrderRequestProps
>;

export type CreateOrderRequestActionSucceeded = Action<
  OrderRequestsActionTypes.CREATE_ORDER_REQUEST_SUCCEEDED,
  CreateOrderResponse
>;

export type CreateOrderRequestActionFailed = Action<
  OrderRequestsActionTypes.CREATE_ORDER_REQUEST_FAILED,
  unknown
>;

export type FetchOrderRequestCountActionRequested = Action<
  OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_REQUESTED,
  PlantId
>;

export type FetchOrderRequestCountActionSucceeded = Action<
  OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_SUCCEEDED,
  number
>;

export type FetchOrderRequestCountActionFailed = Action<
  OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_FAILED,
  unknown
>;
