import { Ref } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { SetValueConfig } from 'react-hook-form';
import { Option, Optional } from 'Types/commonTypes';

const OPTIONS_LIMIT = 50;
const SEPARATOR = ', ';

type UseDropdownProps<T> = {
  options: Option[];
  selected: Option[];
  open?: boolean;
  name: string;
  disableListWrap?: boolean;
  allowUnknownOption?: boolean;
  async?: boolean;
  totalOptionsCount?: number;
  disabled?: boolean;
  isMultiSelect?: T;
  onChange?: (option: any) => void;
  setValue?: (name: string, value: any, config?: SetValueConfig) => void;
  onInputChange?: (e: InputEvent) => void;
  onLoadAllOptions?: (searchTerm?: string) => void;
};

type BaseReturnType<T> = {
  highlightedOptionIndex: number;
  isOpen: boolean;
  availableOptions: Option[];
  areAllOptionsSelected: boolean;
  selectedOption: T extends true ? Option[] : Optional<Option>;
  hoveredOption: Optional<Option>;
};

type GetRootPropsReturnType = () => {
  onKeyDown: (event: KeyboardEvent) => void;
  onClick: () => void;
};

type GetInputPropsReturnType = () => {
  value: string;
  onChange: (e) => void;
  onBlur: (e) => void;
  ref: Ref<HTMLInputElement>;
};

type GetDropdownPropsReturnType = () => {
  root: {
    ref: (node: any) => void;
  };
  selectDeselectAllProps: {
    onSelectAll: () => void;
    onDeselectOption: (option: Option) => void;
  };
  showAllOptionsProps: {
    totalCount: number;
    onClick: () => void;
    showAllOptions: boolean;
  };
};

type GetOptionPropsReturnType = () => {
  hoveredOption: Optional<Option>;
  onOptionSelect: (option: Option) => void;
  onOptionHover: (option: Option, type: 'enter' | 'leave') => void;
};

type UseDropdownReturnType<T> = BaseReturnType<T> & {
  getRootProps: GetRootPropsReturnType;
  getInputProps: GetInputPropsReturnType;
  getDropdownProps: GetDropdownPropsReturnType;
  getOptionProps: GetOptionPropsReturnType;
};

export const useDropdown = <T extends boolean>({
  options,
  selected = [],
  open,
  name,
  disableListWrap,
  allowUnknownOption,
  async = false,
  isMultiSelect,
  totalOptionsCount,
  disabled,
  onChange,
  onInputChange,
  onLoadAllOptions,
  setValue,
}: UseDropdownProps<T>): UseDropdownReturnType<T> => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [hoveredOption, setHoveredOption] = useState<Optional<Option>>();
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState<number>(-1);
  const [isOpen, setIsOpen] = useState<boolean>(!!open);
  const [inputValue, setInputValue] = useState<string>('');
  const [availableOptions, setAvailableOptions] = useState<Option[]>(options);
  const [showAllOptions, setShowAllOptions] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const showAllItemsBtnClicked = useRef<boolean>(false);
  const optionsCount = useRef<number>(totalOptionsCount || options.length);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHighlightedOptionIndex(-1);
        setIsOpen(false);
        setShowAllOptions(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    optionsCount.current = totalOptionsCount || options.length;
  }, [totalOptionsCount, options.length]);

  useEffect(() => {
    setIsOpen(open => !!open);
  }, [open]);

  useEffect(() => {
    setAvailableOptions(limitOptionsIfNeeded(options));
  }, [options]);

  useEffect(() => {
    const optionsToSelect: Option[] = options.filter(option => {
      return selected.some(
        sel => String(sel.value).toLowerCase() === String(option.value).toLowerCase(),
      );
    });

    setSelectedOptions(optionsToSelect.length ? optionsToSelect : selected);
  }, [options, selected]);

  useEffect(() => {
    setInputValue(selected?.map(o => o.label).join(SEPARATOR) || '');
  }, [selected]);

  const selectOption = (option: Option | null) => {
    if (isMultiSelect) {
      if (!option) return;

      const fieldsToSelect = selectedOptions.some(o => o.value === option.value)
        ? selectedOptions.filter(selectedOption => selectedOption.value !== option.value)
        : [...selectedOptions, option];

      setInputValue(fieldsToSelect.map(o => o.label).join(SEPARATOR));
      setValue?.(name, fieldsToSelect, { shouldValidate: true });
      onChange?.(fieldsToSelect);
    } else {
      setInputValue(option?.label || '');
      setIsOpen(false);
      setValue?.(name, option, { shouldValidate: true });
      onChange?.(option);
    }

    setAvailableOptions(limitOptionsIfNeeded(options));
    setHoveredOption(null);
  };

  const limitOptionsIfNeeded = (options: Option[] = []) => {
    if (
      !options.length ||
      optionsCount.current <= OPTIONS_LIMIT ||
      showAllItemsBtnClicked.current
    ) {
      setShowAllOptions(true);
      return options;
    }

    if (!!options.length && optionsCount.current > OPTIONS_LIMIT) {
      setShowAllOptions(false);
      return options.slice(0, OPTIONS_LIMIT);
    }

    return options;
  };

  const handleInputChange = e => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();
    showAllItemsBtnClicked.current = false;

    setInputValue(value);

    if (!isOpen) setIsOpen(true);

    if (!async) {
      const filteredOptions = options.filter(({ label }) => {
        return label.toLowerCase().includes(lowerCaseValue);
      });

      optionsCount.current = filteredOptions.length;
      setAvailableOptions(limitOptionsIfNeeded(filteredOptions));
      const matchedOptionIndex = filteredOptions.findIndex(option => option.value === value);
      if (filteredOptions.length === 1) {
        setHighlightedOptionIndex(0);
      } else if (matchedOptionIndex !== -1) {
        setHighlightedOptionIndex(matchedOptionIndex);
      } else {
        setHighlightedOptionIndex(-1);
      }
    } else {
      onInputChange?.(e);
    }
  };

  const handleBlur = e => {
    // When a user selects an item from the drop-down menu, this event is also triggered
    // This checks if the user clicked on drop-down menu
    const relatedTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
    if (relatedTarget && relatedTarget.id === 'dropdownMenu') {
      return;
    }

    const { value }: { value: string } = e.target;
    const valueArr = value.split(SEPARATOR);

    if (selectedOptions.some(o => valueArr.some(val => val === o.label))) {
      setIsOpen(false);
      return;
    }

    const valueExists = availableOptions.find(option =>
      valueArr.some(val => val.toLowerCase() === option.label.toLowerCase()),
    );

    if (valueExists) {
      selectOption(valueExists);
    } else {
      if (allowUnknownOption) {
        if (!value) {
          selectOption(null);
          onChange?.(null);
          return;
        }
        const option = { value, label: value };
        selectOption(option);
      } else {
        selectOption(null);
        onChange?.(null);
        setAvailableOptions(limitOptionsIfNeeded(options));
      }
    }
  };

  const handleOpen = () => {
    if (disabled) return;
    if (!isOpen) {
      inputRef.current?.focus();
    }
    setIsOpen(open => !open);
  };

  const handleOptionSelect = (option: Option) => {
    const isAlreadySelected = selectedOptions.some(o => o.value === option.value);

    if (!isMultiSelect && isAlreadySelected) {
      setIsOpen(false);
      return;
    }

    selectOption(option);
  };

  const handleOptionHover = (option: Option, type: 'enter' | 'leave') => {
    if (type === 'enter') {
      setHoveredOption(option);
      return;
    }

    setHoveredOption(null);
  };

  const changeHighlightedIndex = ({ diff }) => {
    if (!isOpen) {
      setIsOpen(true);
    }
    const getNextIndex = () => {
      const maxIndex = availableOptions.length - 1;

      const newIndex = highlightedOptionIndex + diff;

      if (newIndex < 0) {
        return disableListWrap ? 0 : maxIndex;
      }

      if (newIndex > maxIndex) {
        return disableListWrap ? maxIndex : 0;
      }

      return newIndex;
    };

    const nextIndex = getNextIndex();

    setHighlightedIndex(nextIndex);
  };

  const setHighlightedIndex = (index: number) => {
    if (!dropdownRef.current) return -1;
    setHighlightedOptionIndex(index);
    const option = dropdownRef.current.querySelector(`[data-highlighted-option-index="${index}"]`);
    if (!option) return;

    const element = option as HTMLElement;
    const dropdownMenu = dropdownRef.current.querySelector('#dropdownMenu');
    if (!dropdownMenu) return;
    const scrollBottom = dropdownMenu.clientHeight + dropdownMenu.scrollTop;
    const elementBottom = element.offsetTop + element.offsetHeight;

    if (elementBottom > scrollBottom) {
      dropdownMenu.scrollTop = elementBottom - dropdownMenu.clientHeight;
    } else if (element.offsetTop < dropdownMenu.scrollTop) {
      dropdownMenu.scrollTop = element.offsetTop;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      changeHighlightedIndex({ diff: 1 });
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      changeHighlightedIndex({ diff: -1 });
    }

    if (event.key === 'Enter') {
      const optionToSelect = availableOptions.find((option, i) => i === highlightedOptionIndex);
      if (!optionToSelect) {
        if (allowUnknownOption && inputValue) {
          const option = { value: inputValue, label: inputValue };
          selectOption(option);
          setIsOpen(false);
          return;
        }
        return;
      }
      selectOption(optionToSelect);
    }
  };

  const handleShowAllOptionsChange = () => {
    setShowAllOptions(true);
    inputRef.current?.focus();
    showAllItemsBtnClicked.current = true;

    // if there is an option selected then skip the selectedOption.value and show all options when the button is clicked
    const value = selectedOptions.length ? '' : inputValue;

    if (async) {
      onLoadAllOptions?.(value);
    } else {
      const filteredOptions = options.filter(({ label }) => {
        return label.toLowerCase().includes(value.toLowerCase());
      });
      setAvailableOptions(filteredOptions);
    }
  };

  const handleDropdownRef = node => {
    dropdownRef.current = node;
  };

  const checkIfAllOptionsAreSelected = () => {
    return options.every(option =>
      selectedOptions.some(selected => selected.value === option.value),
    );
  };

  const handleSelectAll = () => {
    if (!isMultiSelect) return;

    const optionsToSelect = checkIfAllOptionsAreSelected() ? [] : [...options, ...selectedOptions];
    setInputValue(optionsToSelect.map(o => o.label).join(SEPARATOR));
    onChange?.(optionsToSelect);
  };

  const handleDeselectOption = (option: Option) => {
    if (!isMultiSelect) return;
    const options = selectedOptions.filter(selectedOption => selectedOption.value !== option.value);
    setInputValue(options.map(o => o.label).join(SEPARATOR));
    onChange?.(options);
  };
  return {
    getRootProps: () => ({
      onKeyDown: handleKeyDown,
      onClick: handleOpen,
    }),
    getInputProps: () => ({
      value: inputValue,
      onChange: handleInputChange,
      onBlur: handleBlur,
      ref: inputRef,
    }),
    getDropdownProps: () => ({
      root: {
        ref: handleDropdownRef,
      },
      selectDeselectAllProps: {
        onSelectAll: handleSelectAll,
        onDeselectOption: handleDeselectOption,
      },
      showAllOptionsProps: {
        totalCount: optionsCount.current,
        onClick: handleShowAllOptionsChange,
        showAllOptions,
      },
    }),
    getOptionProps: () => ({
      onOptionSelect: handleOptionSelect,
      onOptionHover: handleOptionHover,
      hoveredOption,
    }),
    highlightedOptionIndex,
    isOpen,
    availableOptions,
    selectedOption: isMultiSelect ? selectedOptions : selectedOptions[0],
    hoveredOption,
    areAllOptionsSelected: checkIfAllOptionsAreSelected(),
  } as UseDropdownReturnType<T>;
};
