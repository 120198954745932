import Dashboard from 'Routes/dashboard/Dashboard';
import Account from 'Routes/account';
import Reports from 'Routes/reports';
import OrdersRoute from 'Routes/orders';
import OrdersListRoute from 'Routes/ordersList';
import ActivityRoute from 'Routes/activity';
import MoveOrder from 'Routes/MoveOrder';
import Settings from 'Routes/Settings';
import onBoarding from 'Routes/onBoarding';
import RequestsRoute from 'Routes/requests';
import Logout from 'Routes/Logout';
import routeMap from './route-map';
import SpaceSchedule from 'Routes/SpaceSchedule';
import SpaceSettigns from 'Routes/SpaceSettigns';
import PrimaryFieldValuesConfiguration from 'Routes/PrimaryFieldValuesConfiguration';

const authOnlyRoutes = [
  {
    path: routeMap.dashboard,
    comp: Dashboard,
    exact: false,
  },
  {
    path: routeMap.account,
    comp: Account,
    exact: false,
  },
  {
    path: routeMap.reports,
    comp: Reports,
    exact: false,
  },
  {
    path: routeMap.plantCalendar,
    comp: OrdersRoute,
    exact: true,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.plantOrders,
    comp: OrdersListRoute,
    exact: true,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.plantActivity,
    comp: ActivityRoute,
    exact: true,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.plantRequests,
    comp: RequestsRoute,
    exact: true,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.moveOrder,
    comp: MoveOrder,
    exact: true,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.settings,
    comp: Settings,
    exact: false,
    shouldValidatePlantId: true,
  },
  {
    path: routeMap.onBoarding,
    comp: onBoarding,
    exact: false,
  },
  {
    path: routeMap.plantRequests,
    comp: RequestsRoute,
    exact: true,
  },
  {
    path: routeMap.space,
    comp: SpaceSchedule,
    exact: true,
  },
  {
    path: routeMap.spaceSettings,
    comp: SpaceSettigns,
    exact: false,
    // shouldValidatePlantId: true,
  },
  {
    path: routeMap.primaryField2,
    comp: PrimaryFieldValuesConfiguration,
    exact: false,
  },
  {
    path: routeMap.logout,
    comp: Logout,
    exact: true,
  },
];

export default authOnlyRoutes;
