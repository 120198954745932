export const getHostName = (): string => {
  if (window.location.host.includes('localhost')) {
    // check if the backend is running locally
    if (process.env.DEV_PROXY?.startsWith('http')) {
      return window.location.host;
    }

    return 'gamma.plantdemand.com';
  }

  return window.location.host;
};
