import axios from '../plantDemandAxios';
import { makeGraphQLRequest } from '../request';
import apiMap from '../utils/apiMap/apiMap';

export interface SupportMessageParams {
  message: string;
  url: string;
  supporting_data: any;
  email?: string | any;
}

export const sendMessage = async (params: SupportMessageParams) => {
  let queryParams = '';

  if (params.message) {
    queryParams = queryParams.concat(`message: ${JSON.stringify(params.message)}, `);
  }

  if (params.supporting_data) {
    queryParams = queryParams.concat(
      `supportingData: ${JSON.stringify(JSON.stringify(params.supporting_data))}, `,
    );
  }

  if (params.url) {
    queryParams = queryParams.concat(`url: "${params.url}", `);
  }

  if (params.email) {
    queryParams = queryParams.concat(`email: ${JSON.stringify(params.email)}, `);
  }

  const query = {
    query: `mutation {
      requestHelp(${queryParams}) {
        __typename
        ... on RequestHelpResponse {
          message
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }`,
  };

  const formData = new FormData();
  formData.append('message', params.message);
  formData.append('url', params.url);
  formData.append('email', params.email);
  formData.append('supporting_data', JSON.stringify(params.supporting_data));

  return !params.email
    ? makeGraphQLRequest(query, 'requestHelp')
    : axios.post(apiMap.publicHelpRequest, formData);
};
