import * as Sentry from '@sentry/react';

export const initSentry = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN;
  if (SENTRY_DSN) {
    const options: Sentry.BrowserOptions = {
      dsn: SENTRY_DSN,
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'xhr' && hint) {
          const data = {
            requestBody: hint.xhr.__sentry_xhr__.body,
            response: hint.xhr.response,
            responseUrl: hint.xhr.responseURL,
          };
          return { ...breadcrumb, data };
        }
        return breadcrumb;
      },
    };

    Sentry.init(options);
  } else {
    console.log('Unable to init Sentry. SENTRY_DSN is not provided');
  }
};

export const addCurrentUserToSentry = (username: string) => {
  Sentry.setUser({ username });
};

export const removeCurrentUserFromSentry = () => {
  Sentry.configureScope(scope => scope.setUser(null));
};
