import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import reducers from '../reducers';
import rootSagas from '../sagas';
import { syncTranslationWithStore, loadTranslations, setLocale } from 'react-redux-i18n';
import { translations } from '../../i18n';

export const history = createBrowserHistory();

const createAppStore = (initialState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [thunk, sagaMiddleware];

  const store: any = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    ),
  );

  sagaMiddleware.run(rootSagas);

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));

  const language = navigator.language;
  if (translations.hasOwnProperty(language)) {
    store.dispatch(setLocale(language));
  } else {
    store.dispatch(setLocale('en'));
  }

  return store;
};

export const store = createAppStore();
export default createAppStore;
