import { useState } from 'preact/hooks';

interface UseSelectionProps<T, I> {
  fields: T[];
  initialSelectedFields?: I[];
}

export const useSelection = <T, I extends number = number>({
  fields,
  initialSelectedFields,
}: UseSelectionProps<T, I>) => {
  const [selectedDates, setSelectedDates] = useState(new Set<number>(initialSelectedFields));

  const selectItem = (index: number) => {
    setSelectedDates(prevState => {
      const next = new Set(prevState);
      next.add(index);

      return next;
    });
  };

  const deselectItem = (index: number) => {
    setSelectedDates(prev => {
      const next = new Set(prev);
      next.delete(index);

      return next;
    });
  };

  const selectAllItems = () => {
    setSelectedDates(prev => {
      const next = new Set(prev);
      fields.forEach((date, i) => next.add(i));

      return next;
    });
  };

  const clearSelectedItems = () => setSelectedDates(new Set());
  const isItemSelected = (index: number) => selectedDates.has(index);
  const areAllItemsSelected = () => fields.length === selectedDates.size;
  const isAnyItemSelected = () => !!selectedDates.size;

  const getItemsIndexesToRemove = () => {
    const selectedDatesAsArray = Array.from(selectedDates);

    if (areAllItemsSelected()) {
      const [, ...rest] = selectedDatesAsArray;
      return rest;
    } else {
      return selectedDatesAsArray;
    }
  };

  const getSelectedItems = () => {
    return fields.filter((date, i: number) => {
      if (selectedDates.has(i)) {
        return date;
      }
    });
  };

  return {
    selectItem,
    deselectItem,
    selectAllItems,
    clearSelectedItems,
    isItemSelected,
    areAllItemsSelected: areAllItemsSelected(),
    isAnyItemSelected: isAnyItemSelected(),
    getSelectedItems,
    getItemsIndexesToRemove,
  };
};
