import { put, delay, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/pageLevelAlertActions';
import { addPageLevelAlert } from '../actions/pageLevelAlertActions';
import { removePageLevelAlert } from '../actions/pageLevelAlertActions';
import { Alert } from '../../components/alerts/singleAlert';
const ALERT_TIMEOUT_MS = 60000;

export function* pageLevelAlert({ payload }: { type: string; payload: Alert }) {
  yield put(addPageLevelAlert(payload));
  yield delay(ALERT_TIMEOUT_MS);
  yield put(removePageLevelAlert(payload));
}

export default function* watchPageLevelAlerts() {
  yield takeEvery(actions.TRIGGER_PAGE_LEVEL_ALERT, pageLevelAlert);
}
