import { call, put, takeLatest } from 'redux-saga/effects';
import { exportPlantCustomers } from 'Services/plantService';
import {
  PlantSettingsActionTypes,
  ExportPlantCustomersActionRequested,
  exportPlantCustomersSucceeded,
  exportPlantCustomersFailed,
} from 'Redux/actions/plantSettingsActions';
import { CustomerId } from 'Types/plantTypes';
import { history } from 'Redux/store/index';
import routeMap from '../../route-maps/route-map';

export function* exportPlantCustomersSaga({ payload }: ExportPlantCustomersActionRequested) {
  try {
    const response: CustomerId[] = yield call(exportPlantCustomers, payload);
    yield put(exportPlantCustomersSucceeded(response));
    history.push(routeMap.customersList.replace(/:plantId/, payload.sourcePlant));
  } catch (error) {
    yield put(exportPlantCustomersFailed(error));
  }
}

export default function* watchPlantSettings() {
  yield takeLatest(
    PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_REQUESTED,
    exportPlantCustomersSaga,
  );
}
