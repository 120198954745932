import { h } from 'preact';
import { Switch, Route } from 'react-router-dom';
import AuthOnlyRoute from './AuthOnlyRoute';
import NoAuthOnlyRoute from './NoAuthOnlyRoute';
import PermissionDenied from 'Components/PermissionDenied';
import PlantNotExist from 'Components/PlantNotExist';
import PageNotFound from 'Routes/pageNotFound';
import AuthRouteWithPlantValidation from './AuthRouteWithPlantValidation';
import { history } from 'Redux/store';
import routeMap, { authOnlyRoutes, noAuthOnlyRoutes } from '../../route-maps';
import { Redirect } from 'react-router';

const RoutesRenderer = () => (
  <div className="appLayout">
    <Switch>
      <Redirect exact from={routeMap.root} to={routeMap.dashboard} />
      {authOnlyRoutes.map(({ path, comp: Comp, shouldValidatePlantId, ...rest }, i) =>
        !shouldValidatePlantId ? (
          <AuthOnlyRoute
            key={'auth' + i}
            history={history}
            path={path}
            component={Comp}
            {...rest}
          />
        ) : (
          <AuthRouteWithPlantValidation path={path} history={history} {...rest}>
            <Comp />
          </AuthRouteWithPlantValidation>
        ),
      )}

      {noAuthOnlyRoutes.map(({ path, comp, ...rest }, i) => (
        <NoAuthOnlyRoute
          key={'noauth' + i}
          history={history}
          path={path}
          component={comp}
          {...rest}
        />
      ))}
      <Route path={routeMap.permissionDenied} component={PermissionDenied} />
      <Route path={routeMap.plantNotExist} component={PlantNotExist} />
      <Route component={PageNotFound} />
    </Switch>
  </div>
);

export default RoutesRenderer;
