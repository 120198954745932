import { useState } from 'preact/hooks';
import { UseFormSetError } from 'react-hook-form';
import { getErrorMessage } from './getErrorMessage';
import { AsyncReturnType } from 'src/types/commonTypes';
import { GraphQLError, GraphQLValidationError } from '../../errors/GraphQLError';
import { isAxiosError } from 'Errors/utils';
import { AxiosError } from 'axios';

type SetFormError<T> = UseFormSetError<T>;

type DefaultOptions<TData, TArgs, ErrorFn> = {
  setFormError?: SetFormError<ErrorFn>;
  onSuccess?: (data: TData, args: TArgs) => void;
  onError?: (errorMsg: string, rawError: any) => void;
};

type RunMutationOptions = {
  setFormError?: SetFormError<any>;
};

const handleServerSideValidationError = <T = any>(error: any, setFormError?: SetFormError<T>) => {
  if (!setFormError) return;

  if (error.response.data !== null && typeof error.response.data === 'object') {
    const { data } = error.response;
    Object.keys(data).forEach((key: any) => {
      const errors = data[key];

      if (Array.isArray(errors)) {
        errors.forEach((error, errorIndex) => {
          if (typeof error === 'string') {
            setFormError(key as any, { type: 'ServerError', message: error });
          } else if (typeof error === 'object') {
            Object.keys(error).forEach(errorKey => {
              const fieldName: any = `${key}.${errorIndex}.${errorKey}`;

              setFormError(fieldName, { type: 'ServerError', message: error[errorKey] });
            });
          }
        });
      }

      if (typeof errors === 'string') {
        setFormError(key as any, { type: 'ServerError', message: error });
      }
    });
  }
};

const handleGraphQLValidationError = <T = any>(
  err: GraphQLValidationError,
  setFormError?: SetFormError<T>,
) => {
  const errorData = err.error;
  errorData.forEach(err => {
    setFormError?.(err.fieldName as any, { type: 'ServerError', message: err.errorDescription });
  });
};

export const useMutation = <T extends (...args: any[]) => Promise<AsyncReturnType<T>>>(
  asyncFn: T,
  options?: DefaultOptions<AsyncReturnType<T>, Parameters<typeof asyncFn>, SetFormError<T>>,
) => {
  const [data, setData] = useState<AsyncReturnType<T> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const runMutation = (
    args: Parameters<typeof asyncFn>,
    runMutationOptions?: DefaultOptions<
      AsyncReturnType<T>,
      Parameters<typeof asyncFn>,
      SetFormError<T>
    >,
  ) => {
    const mergedOptions = { ...options, ...runMutationOptions };

    setLoading(true);
    asyncFn(...args)
      .then(response => {
        setData(response);
        mergedOptions?.onSuccess?.(response, args);
        // options?.onSuccess?.(response);
      })
      .catch(err => {
        if (err instanceof GraphQLValidationError) {
          // handleGraphQLValidationError(err, setErrorFn);
          handleGraphQLValidationError(err, mergedOptions.setFormError);
          const errorMessage = getErrorMessage(err);
          setError(errorMessage);
          // options?.onError?.(errorMessage, err);
          mergedOptions?.onError?.(errorMessage, err);
          return;
        }
        if (err instanceof GraphQLError) {
          const errorMessage = getErrorMessage(err);
          setError(errorMessage);
          // options?.onError?.(errorMessage, err);
          mergedOptions?.onError?.(errorMessage, err);
          return;
        }
        if (isAxiosError(err)) {
          const axisError: AxiosError = err;
          const rawError = axisError.response?.data || axisError;
          const errorMessage = getErrorMessage(axisError);

          // handleServerSideValidationError(err, setErrorFn);
          handleServerSideValidationError(err, mergedOptions.setFormError);
          setError(errorMessage);
          // options?.onError?.(errorMessage, rawError);
          mergedOptions?.onError?.(errorMessage, rawError);
          return;
        }

        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runMutationAsync = async (
    args: Parameters<typeof asyncFn>,
    runMutationOptions: RunMutationOptions = {},
  ) => {
    const setErrorFn = options?.setFormError || runMutationOptions.setFormError;

    try {
      setLoading(true);
      const response = await asyncFn(...args);
      setData(response);
      return response;
    } catch (err) {
      if (err instanceof GraphQLValidationError) {
        handleGraphQLValidationError(err, setErrorFn);
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
        throw new Error(errorMessage);
      }
      if (err instanceof GraphQLError) {
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
        throw new Error(errorMessage);
      }
      if (isAxiosError(err)) {
        handleServerSideValidationError(err, setErrorFn);
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
        throw new Error(errorMessage);
      }

      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, runMutation, runMutationAsync };
};
