import axios from '../plantDemandAxios';
import * as qs from 'query-string';
import groupBy from 'lodash/groupBy';
import { differenceInDays } from 'date-fns';
import { AxiosResponse } from 'axios';

import { get, set } from './storageService';
import {
  getDateLocalTimeZone,
  getNowISODateString,
  isISODate,
  toDefaultDateFormat,
} from './timeService';
import { addToDate, getNow } from './dateService';
import { RequestConfig } from 'Types/commonTypes';
import { Plant, PlantId } from 'Types/plantTypes';
import apiMap from '../utils/apiMap/apiMap';

export const DATE_URL_KEY = 'date';
export const DATE_LOCAL_STPRAGE_KEY = 'dashboardDate';

export type DashboardDataParams = {
  plantIds: PlantId[];
  date: string;
};

export type DashboardInfo = {
  day_materials_count: number;
  night_materials_count: number;
  total_materials_count: number;
  day_order_count: number;
  night_order_count: number;
  total_order_count: number;
  day_quantity: number;
  night_quantity: number;
  total_quantity: number;
};

export type DashboardPlantInfo = {
  [plantId: string]: DashboardInfo;
};

export const getDashboardInfo = async (
  params: DashboardDataParams,
  config?: RequestConfig,
): Promise<DashboardPlantInfo> => {
  const date = toDefaultDateFormat(params.date);
  const plantIds = params.plantIds.join('+');

  const response: AxiosResponse<DashboardPlantInfo> = await axios.get(
    apiMap.getDashboardInfo(date, plantIds),
    { signal: config?.signal },
  );
  return response.data;
};
export const getCustomerPortalDashboardInfo = async (
  params: DashboardDataParams,
  config?: RequestConfig,
): Promise<DashboardPlantInfo> => {
  const date = toDefaultDateFormat(params.date);
  const plantIds = params.plantIds.join('+');

  const response: AxiosResponse<DashboardPlantInfo> = await axios.get(
    apiMap.getCustomerPortalsDashboardInfo(date, plantIds),
    { signal: config?.signal },
  );
  return response.data;
};

const isDateInfo = (dateInfo: string) => {
  const dateInfoRegExp = new RegExp(/P-?[0-2]D/);
  return dateInfoRegExp.test(dateInfo);
};

export const parseDateInfo = (dateInfo: string): number => {
  if (isISODate(dateInfo)) {
    const urlDate = getDateLocalTimeZone(dateInfo);
    const currentDateTime = getNow().setHours(0, 0, 0, 0);
    const currentDateMidnight = new Date(currentDateTime);
    const difference = differenceInDays(urlDate, currentDateMidnight);

    return difference;
  } else if (isDateInfo(dateInfo)) {
    const offset = dateInfo.replace(/-?[^-0-2]/g, '');
    return parseInt(offset);
  }

  return 0;
};

export const getDateOffsetInitialState = (queryString: string): number => {
  const { date: urlDate } = qs.parse(queryString);
  const localStorageDateOffset = get<string>(DATE_LOCAL_STPRAGE_KEY);
  if (urlDate && typeof urlDate === 'string') {
    const parsedUrlDateInfo = parseDateInfo(urlDate);
    return parsedUrlDateInfo;
  } else if (!urlDate && localStorageDateOffset) {
    const parseLocalStorageDateInfo = parseDateInfo(localStorageDateOffset);
    return parseLocalStorageDateInfo;
  }

  return 0;
};

export const setDateOffsetToLocalStorage = (dateInfo: string) => {
  set(DATE_LOCAL_STPRAGE_KEY, dateInfo);
};

export const getDateValue = (offset: number) => {
  const currentDate = getNowISODateString();
  const newDate = addToDate(currentDate, { days: offset });
  return newDate;
};

export const ORGANIZATION_COLLAPSE_STATE = 'organizationCollapseState';

type OrganizationPlant = {
  organizationId: string;
  organizationName: string;
  plants: Plant[];
  expanded: boolean;
};

export type OrganizationPlants = Record<string, OrganizationPlant>;

export const getOrganizationPlantsState = (plants: Plant[]): OrganizationPlants => {
  const organizationCollapseState =
    get<Record<PlantId, boolean>>(ORGANIZATION_COLLAPSE_STATE) || {};
  const groupedPlantsByOrganization = groupBy(plants, plant => plant.business);
  const organizations = Object.keys(groupedPlantsByOrganization).reduce((acc, organization) => {
    const organizationPlants = groupedPlantsByOrganization[organization];
    return {
      ...acc,
      [organization]: {
        organizationId: organization,
        organizationName: organizationPlants[0].business_name,
        plants: organizationPlants,
        expanded: organizationCollapseState[organization],
      },
    };
  }, {} as OrganizationPlants);

  return organizations;
};

export const saveOrganizationStateToLocalStorage = (organizations: OrganizationPlants) => {
  set(
    ORGANIZATION_COLLAPSE_STATE,
    Object.keys(organizations).reduce((acc, organizationId) => {
      return { ...acc, [organizationId]: organizations[organizationId].expanded };
    }, {}),
  );
};
