import { ComponentChildren, h } from 'preact';
import { I18n } from 'react-redux-i18n';
import Button, { ButtonBaseProps } from 'Components/buttons';
import ModalWindow from 'Components/modal';
import styles from './confirmModalStyles.scss';

type ConfirmModalTypes = {
  title: string | ComponentChildren;
  bodyContent: string | ComponentChildren;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  confirmBtnType?: ButtonBaseProps['color'];
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmModal = ({
  title,
  bodyContent,
  confirmBtnLabel = I18n.t('confirm'),
  confirmBtnType = 'warning',
  cancelBtnLabel = I18n.t('cancel'),
  onConfirm,
  onCancel,
}: ConfirmModalTypes) => {
  const footerContent = (
    <div className={styles.confirmModalWrapper}>
      <Button color="accent" label={cancelBtnLabel} className="mr-md" onClick={onCancel} />
      <Button color={confirmBtnType} label={confirmBtnLabel} onClick={onConfirm} />
    </div>
  );

  return (
    <ModalWindow
      title={title}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onClose={onCancel}
      data-testid="confirm-modal"
    />
  );
};

export default ConfirmModal;
