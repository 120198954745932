import { h } from 'preact';
import { useState } from 'preact/hooks';
import { I18n } from 'react-redux-i18n';
import AddComment from '../AddComment';
import Comment from '../Comment';
import { toDefaultDateTimeFormat } from 'Services/timeService';
import { OrderFeed as OrderFeedType } from 'Types/orderTypes';
import styles from './orderFeed.scss';

interface OrderFeedProps {
  orderFeed: OrderFeedType[];
  disabled?: boolean;
  onCommentChange: (e) => void;
}

const OrderFeed = ({ orderFeed = [], disabled, onCommentChange }: OrderFeedProps) => {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showAllFeed, setShowAllFeed] = useState(false);
  const feedLimit = 3;

  const toggleCommentInput = () => {
    setShowCommentInput(prevState => !prevState);
  };

  const toggleAllFeed = () => {
    setShowAllFeed(!showAllFeed);
  };

  const orderFeedLimited = showAllFeed ? orderFeed.slice(0) : orderFeed.slice(0, feedLimit);
  return (
    <div className={styles.orderFeed} data-testid="order-feed-wrapper">
      <div className={!showCommentInput ? styles.orderFeedHeader : 'mb-md'}>
        <div className={styles.header} role="heading">
          {I18n.t('updates')}
        </div>
        <AddComment
          btnLabel={I18n.t('addComment')}
          name="comment"
          placeholder={I18n.t('commentPlaceholder')}
          showInput={showCommentInput}
          disabled={disabled}
          onClick={toggleCommentInput}
          onChange={onCommentChange}
        />
      </div>

      {orderFeedLimited.map(feed => (
        <div className={styles.feed} key={feed.id} data-testid="feed-row">
          <div className={styles.author}>
            <span className={styles.createdBy}>
              {`${feed.createdBy.firstName} ${feed.createdBy.lastName}`}
            </span>
            <span className={styles.createdAt}>
              {` - ${toDefaultDateTimeFormat(feed.createdAt)}`}
            </span>
          </div>

          {/* List all comments */}
          <Comment show={!!feed.comment} value={feed.comment} />

          {/* List all messages */}
          {feed.messages?.map(message => (
            <div className={styles.message}>{message.message}</div>
          ))}

          {/* List all records */}
          {feed.records?.map(record => (
            <div className={styles.record}>{record}</div>
          ))}
        </div>
      ))}

      {orderFeed.length > feedLimit && (
        <span className={styles.toggleFeedBtn} role="button" onClick={toggleAllFeed}>
          {!showAllFeed ? I18n.t('showAll') : I18n.t('showLatest')}
        </span>
      )}
    </div>
  );
};

export default OrderFeed;
