import { SetCalendarFiltersParams } from 'Services/calendarFilterService';
import {
  SetCalendarFiltersActions,
  SetCalendarFiltersActionTypes,
} from 'Redux/actions/calendarFilterActions';
import { CalendarFiltersType } from 'Types/commonTypes';

const initialState: CalendarFiltersType = {} as CalendarFiltersType;

const calendarFilterReducer = (state = initialState, action: SetCalendarFiltersActions) => {
  switch (action.type) {
    case SetCalendarFiltersActionTypes.SET_CALENDAR_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export default calendarFilterReducer;
