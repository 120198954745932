import { h } from 'preact';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/reducers';

const ModalContainer = () => {
  const modals = useSelector((state: RootState) => state.modals);

  return <div>{modals.map(modal => modal.modal)}</div>;
};

export default ModalContainer;
