import { h } from 'preact';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';
import Tooltip from 'Components/Tooltip';

import styles from './plantName.scss';

export interface PlantNameProps {
  plantName: string;
  className?: string;
  hideChangePlantAction?: boolean;
  disableChangePlantAction?: boolean;
  disabled?: boolean;
  onChangePlant: () => void;
}

const PlantName = ({
  plantName,
  className,
  hideChangePlantAction,
  disableChangePlantAction,
  disabled,
  onChangePlant,
}: PlantNameProps) => {
  const handlePlantChange = () => {
    if (disableChangePlantAction || disabled) return;
    onChangePlant();
  };

  const changePlantBtn = (
    <div className="change-plant-btn" data-testid="change-plant-btn">
      {disableChangePlantAction ? (
        <Tooltip title={I18n.t('orderModal.disabledChangePlantTooltipTitle')} name="change-plant">
          <span className={cls(styles.changePlantBtn, 'phone-hidden disabled')} role="button">
            {I18n.t('orderForm.changePlant')}
          </span>
        </Tooltip>
      ) : (
        <span
          className={cls(styles.changePlantBtn, 'phone-hidden')}
          role="button"
          onClick={handlePlantChange}
        >
          {I18n.t('orderForm.changePlant')}
        </span>
      )}
    </div>
  );

  return (
    <div className={cls(styles.plantNameField, className, { disabled })}>
      <span role="heading">{plantName}</span>
      {!hideChangePlantAction && changePlantBtn}
    </div>
  );
};

export default PlantName;
