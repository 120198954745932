import { h } from 'preact';
import { Preset } from 'src/types/reportTypes';
import styles from './simpleList.scss';

export interface SimpleList {
  listData?: Preset[];
  onSelect?: (selected: Preset) => void;
}

const SimpleList = ({ listData, onSelect = () => {} }: SimpleList) => {
  return (
    <div className={styles.simpleListWrapper}>
      <ul className={styles.presetList}>
        {listData?.map(row => (
          <li className={styles.presetListItem} onClick={() => onSelect(row)}>
            {row.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SimpleList;
