import { AxiosResponse } from 'axios';
import { PendingInvitation } from 'Types/invitationTypes';
import { Business, BusinessesWithMembers } from 'Types/businessType';
import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';

export const getBusinesses = async (): Promise<Business[]> => {
  const response: AxiosResponse<Business[]> = await axios.get(apiMap.businesses);
  return response.data;
};

export const newEmployee = data => {
  return axios.post(apiMap.newEmployee, data);
};

export const getRoles = () => {
  return axios.get(apiMap.getRoles);
};

export const getPendingInvitations = async (): Promise<PendingInvitation[]> => {
  const response: AxiosResponse<PendingInvitation[]> = await axios.get(apiMap.pendingInvitations);
  return response.data;
};

export const deletePendingInvitations = (plantId, invitationId) => {
  return axios.delete(apiMap.deletePendingInvitation(plantId, invitationId));
};

export const getPlantMembership = async (): Promise<BusinessesWithMembers[]> => {
  const response: AxiosResponse<BusinessesWithMembers[]> = await axios.get(apiMap.membership);
  const sortedOrganizations = response.data.sort((a, b) => a.name.localeCompare(b.name));

  const sortedOrganizationsMembers = sortedOrganizations.map(organization => {
    return {
      ...organization,
      members: organization.members.sort(
        (a, b) =>
          a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name),
      ),
    };
  });

  return sortedOrganizationsMembers;
};
