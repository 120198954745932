import { OrderActions, OrdersActionTypes } from 'Redux/actions/actionTypes/orderActionTypes';
import { OrderState } from '../../types/orderTypes';

const initialState: OrderState = {
  data: [],
};

const orderReducer = (state = initialState, action: OrderActions) => {
  switch (action.type) {
    case OrdersActionTypes.ORDERS_FETCH_REQUESTED:
      return { ...state, fetchingOrdersStatus: 'IN_PROGRESS' };
    case OrdersActionTypes.ORDERS_FETCH_SUCCEEDED:
      const orders = action.payload;
      return {
        ...state,
        data: orders.items,
        nextPage: orders.nextPage,
        totalCount: orders.totalCount,
        totalPages: orders.totalPages,
        fetchingOrdersStatus: 'COMPLETED',
        fetchingOrdersError: null,
      };
    case OrdersActionTypes.ORDERS_FETCH_FAILED:
      return { ...state, fetchingOrdersStatus: 'COMPLETED', fetchingOrdersError: action.payload };
    case OrdersActionTypes.LOAD_MORE_ORDERS_REQUESTED:
      return { ...state, loadingMoreOrdersStatus: 'IN_PROGRESS' };
    case OrdersActionTypes.LOAD_MORE_ORDERS_SUCCEEDED:
      const ordersData = action.payload;
      return {
        ...state,
        data: [...state.data, ...ordersData.items],
        nextPage: ordersData.nextPage,
        totalCount: ordersData.totalCount,
        totalPages: ordersData.totalPages,
        loadingMoreOrdersStatus: 'COMPLETED',
        loadingMoreOrdersError: null,
      };
    case OrdersActionTypes.LOAD_MORE_ORDERS_FAILED:
      return {
        ...state,
        loadingMoreOrdersStatus: 'COMPLETED',
        loadingMoreOrdersError: action.payload,
      };
    case OrdersActionTypes.ORDERS_INPUT_MATERIALS_REQUEST:
      return { ...state, fetchingInputMaterialsStatus: 'IN_PROGRESS' };
    case OrdersActionTypes.ORDERS_INPUT_MATERIALS_SUCCEEDED:
      return {
        ...state,
        inputMaterials: action.payload,
        fetchingInputMaterialsStatus: 'COMPLETED',
        fetchingInputMaterialsErrors: null,
      };
    case OrdersActionTypes.ORDERS_INPUT_MATERIALS_FAILED:
      return {
        ...state,
        fetchingInputMaterialsStatus: 'COMPLETED',
        fetchingInputMaterialsErrors: action.payload,
      };
    case OrdersActionTypes.ORDERS_CLEAR_REQUESTED:
      return { ...state, data: [] };
    default:
      return state;
  }
};

export default orderReducer;
