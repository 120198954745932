import { Action } from './helper';
import { AxiosError } from 'axios';
import { Supplier } from 'src/types/supplierTypes';

export enum SupplierActionTypes {
  FETCH_SUPPLIERS_REQUESTED = 'FETCH_SUPPLIERS_REQUESTED',
  FETCH_SUPPLIERS_SUCCEEDED = 'FETCH_SUPPLIERS_SUCCEEDED',
  FETCH_SUPPLIERS_FAILED = 'FETCH_SUPPLIERS_FAILED',
  SET_SUPPLIERS = '@suppliers/SET_SUPPLIERS',
}

export interface AddSupplierParams {
  email: string;
  name: string;
  address: string;
}

export type FetchSuppliersRequested = Action<SupplierActionTypes.FETCH_SUPPLIERS_REQUESTED, null>;
export type FetchSuppliersSucceeded = Action<
  SupplierActionTypes.FETCH_SUPPLIERS_SUCCEEDED,
  Supplier[]
>;
export type FetchSuppliersFailed = Action<SupplierActionTypes.FETCH_SUPPLIERS_FAILED, unknown>;
export type SetSuppliersAction = Action<SupplierActionTypes.SET_SUPPLIERS, Supplier[]>;

export type SuppliersActions =
  | FetchSuppliersRequested
  | FetchSuppliersSucceeded
  | FetchSuppliersFailed
  | SetSuppliersAction;

export const fetchSuppliers = (): FetchSuppliersRequested => ({
  type: SupplierActionTypes.FETCH_SUPPLIERS_REQUESTED,
  payload: null,
});

export const fetchSuppliersSucceeded = (suppliers: Supplier[]): FetchSuppliersSucceeded => ({
  type: SupplierActionTypes.FETCH_SUPPLIERS_SUCCEEDED,
  payload: suppliers,
});

export const fetchSuppliersFailed = (error: unknown): FetchSuppliersFailed => ({
  type: SupplierActionTypes.FETCH_SUPPLIERS_FAILED,
  payload: error,
});

export const setSuppliers = (suppliers: Supplier[]): SetSuppliersAction => ({
  type: SupplierActionTypes.SET_SUPPLIERS,
  payload: suppliers,
});
