import { h } from 'preact';
import { useContext, useEffect, useRef } from 'preact/hooks';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import camelCase from 'lodash/camelCase';

import PlantDropdownField, { DropdownEntity } from 'Components/fields/plantDropdownField';
import MenuTabs, { MenuTab } from 'Components/header/menu-tabs';
import Button from 'Components/buttons';
import DatePicker from 'Components/fields/datePicker';
import plusIcon from 'Components/buttons/icons/plus.svg';
import NavBar from 'Components/NavBar';

import { DateStringISO } from 'Services/timeService';

import { fetchOrderRequestsCount } from 'Redux/actions/orderRequestActions';
import {
  getCurrentPlantPermissionsSelector,
  getCurrentPlantSelector,
} from 'Redux/selectors/plantsSelector';
import { RootState } from 'Redux/reducers';

import { PlantId, PlantType } from 'Types/plantTypes';

import { SpaceScheduleContext } from 'Contexts/SpaceScheduleContext';
import settings from 'Assets/icons/settings.svg';
import {
  replacePlantIdInUrl,
  replaceSpaceIdFieldNameAndFieldIdInUrl,
  replaceSpaceIdInUrl,
} from '../../../helpers/url';
import routeMap from '../../../route-maps';
import { useRevealOnScroll } from '../../../helpers/hooks/useRevealOnScroll';
import styles from './plantheader.scss';

type PlantHeaderProps = {
  plantId: PlantId;
  entities: DropdownEntity[];
  calendarDate?: DateStringISO;
  primaryFields?: any[];
  ordersRoute: string;
  shouldHideNewOrderBtn?: boolean;
  isUsingNavLinks: boolean;
  createEntityBtnLabel?: string;
  onDateFilterChange?: (date: Date) => void;
  onCreateNewOrder?: () => void;
};

const PlantHeader = ({
  plantId,
  entities,
  calendarDate,
  ordersRoute,
  shouldHideNewOrderBtn = false,
  isUsingNavLinks,
  primaryFields = [],
  createEntityBtnLabel,
  onDateFilterChange,
  onCreateNewOrder,
}: PlantHeaderProps) => {
  const dispatch = useDispatch();
  const { spaceConfiguration, isEntryType } = useContext(SpaceScheduleContext);
  const currentPlant = useSelector((state: RootState) => getCurrentPlantSelector(state, plantId));
  const currentPlantPermissions = useSelector((state: RootState) =>
    getCurrentPlantPermissionsSelector(state, plantId),
  );

  const tabsBarElementRef = useRef<HTMLDivElement>(null);
  const plantsBarElementRef = useRef<HTMLDivElement>(null);
  const showDatePicker = useRevealOnScroll(tabsBarElementRef);

  useEffect(() => {
    if (!isEntryType) {
      dispatch(fetchOrderRequestsCount(plantId));
    }
  }, [plantId, dispatch]);

  const isCustomerPortal = entities.some(
    entity => entity.entryType === 'customerPortal' && entity.fields.some(f => f.value === plantId),
  );
  const settingsUrl = isEntryType
    ? replaceSpaceIdInUrl(routeMap.spaceSettings, plantId)
    : replacePlantIdInUrl(routeMap.settings, plantId);

  const getMenuItems = () => {
    const menuItems: MenuTab[] = [
      {
        link: ordersRoute,
        title: isEntryType ? spaceConfiguration.entry_name_plural : I18n.t('plantHeader.orders'),
        type: isUsingNavLinks ? 'navlink' : 'hyperlink',
        visible: true,
      },
      {
        link: replacePlantIdInUrl(routeMap.forecasts, plantId),
        title: I18n.t('plantHeader.planner'),
        type: 'hyperlink',
        visible: !isCustomerPortal && currentPlantPermissions?.permissions.view_order_plan,
      },
      {
        link: replacePlantIdInUrl(routeMap.plantActivity, plantId),
        title: I18n.t('plantHeader.activity'),
        type: isUsingNavLinks ? 'navlink' : 'hyperlink',
        visible: true,
      },
      {
        link: replacePlantIdInUrl(routeMap.plantRequests, plantId),
        title: I18n.t('plantHeader.requests'),
        type: isUsingNavLinks ? 'navlink' : 'hyperlink',
        visible:
          !isCustomerPortal &&
          (currentPlantPermissions?.permissions.view_order_request ||
            currentPlantPermissions?.permissions.approve_order_request),
      },
      {
        link: replacePlantIdInUrl(routeMap.prodSchedule, plantId),
        title: I18n.t('plantHeader.productionSchedule'),
        type: 'hyperlink',
        visible: currentPlant?.plant_type === PlantType.Aggregate,
      },
    ];

    return menuItems.filter(item => item.visible);
  };

  const getPrimaryFieldMenuItems = () => {
    if (!isEntryType) return [];

    const primaryFieldMenuItems: MenuTab[] = primaryFields.map(primaryField => ({
      link: replaceSpaceIdFieldNameAndFieldIdInUrl(
        routeMap.primaryField2,
        plantId,
        camelCase(primaryField.name),
        primaryField.id,
      ),
      title: primaryField.name,
    }));

    return primaryFieldMenuItems;
  };

  return (
    <div className={styles.plantHeader} id="header">
      <div className="bgWhite">
        <div className="layoutContainer">
          <div className={styles.plantsBar} id="plantsBar" ref={plantsBarElementRef}>
            <PlantDropdownField
              entities={entities}
              selected={plantId}
              isUsingNavLinks={isUsingNavLinks}
            />
            <Link to={settingsUrl} className={styles.plantSettings}>
              <strong>
                {isEntryType
                  ? `${spaceConfiguration.space_name} Settings`
                  : I18n.t('plantHeader.plantSettings')}
              </strong>
              <img src={settings} alt={I18n.t('plantHeader.cogIcon')} />
            </Link>
          </div>
        </div>
      </div>

      <NavBar ref={tabsBarElementRef}>
        {!showDatePicker && <MenuTabs items={[...getMenuItems(), ...getPrimaryFieldMenuItems()]} />}

        {calendarDate && !!showDatePicker && (
          <DatePicker
            calendarDatepicker
            date={calendarDate}
            selectRange={false}
            allowEmptyValue={false}
            className={styles.calendarWrapper}
            onChange={onDateFilterChange}
          />
        )}

        <div className={styles.colActions}>
          {!!showDatePicker && (
            <PlantDropdownField
              entities={entities}
              selected={plantId}
              shouldTruncate
              isUsingNavLinks={isUsingNavLinks}
              className={styles.stickyPlantSelector}
            />
          )}
          {!shouldHideNewOrderBtn && (
            <Button
              icon={plusIcon}
              label={
                createEntityBtnLabel
                  ? createEntityBtnLabel
                  : isEntryType
                  ? `Add ${spaceConfiguration.entry_name_single}`
                  : I18n.t(isCustomerPortal ? 'newOrderRequest' : 'plantHeader.newOrder')
              }
              onClick={onCreateNewOrder}
            />
          )}
        </div>
      </NavBar>
    </div>
  );
};

export default PlantHeader;
