import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { loadingBarReducer } from 'react-redux-loading-bar';

import userReducer from './userReducer';
import supplierReducer from './supplierReducer';
import plantReducer from './plantReducer';
import spaceReducer from './spaceReducer';
import businessReducer from './businessReducer';
import roleReducer from './roleReducer';
import orderReducer from './orderReducer';
import noteReducer from './noteReducer';
import calendarFilterReducer from './calendarFilterReducer';
import modalReducer from './modalReducer';
import menuReducer from './menuReducer';
import pageLevelAlertsReducer from './pageLevelAlertsReducer';
import sendSupportMessageReducer from './sendSupportMessageReducer';
import plantSettingsReducer from './plantSettingsReducer';
import sidebarReducer from './sidebarReducer';
import orderRequestReducer from './orderRequestReducer';
import customersReducer from './customerReducer';

export type RootState = ReturnType<typeof appReducer>;

const appReducer = combineReducers({
  i18n: i18nReducer as any,
  user: userReducer,
  suppliers: supplierReducer,
  plants: plantReducer,
  spaces: spaceReducer,
  businesses: businessReducer,
  roles: roleReducer,
  loadingBar: loadingBarReducer,
  orders: orderReducer,
  orderRequests: orderRequestReducer,
  notes: noteReducer,
  customers: customersReducer,
  calendarFilters: calendarFilterReducer,
  modals: modalReducer,
  menu: menuReducer,
  alerts: pageLevelAlertsReducer,
  sendSupportMessage: sendSupportMessageReducer,
  plantSettings: plantSettingsReducer,
  sidebar: sidebarReducer,
});

const rootReducer: any = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
