import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'preact/devtools';

import { h, render } from 'preact';

import App from './components/Provider';

const rootComponent = <App />;
const appRoot = document.getElementById('react-app') as HTMLElement;

render(rootComponent, appRoot);
