import cls from 'classnames';
type FutureQuantityProps = {
  totalFuture: number;
  totalOrdered?: number;
  className?: string;
};
import styles from './futureQUantityStyles.scss';

const FutureQuantity = ({ totalFuture, totalOrdered, className }: FutureQuantityProps) => {
  if (!totalOrdered) return null;

  const totalToBeScheduled = totalOrdered - totalFuture;
  return (
    <div className={cls(styles.futureQuantityWrapper, className)}>
      <div className={styles.row}>
        <span className={styles.label}>Total ordered:</span>
        <span className={styles.value}>{totalOrdered}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Total in Future:</span>
        <span className={styles.value}>{totalFuture}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Total to be scheduled:</span>
        <span className={styles.value}>
          {totalToBeScheduled > 0 ? totalToBeScheduled : `Over by ${Math.abs(totalToBeScheduled)}`}
        </span>
      </div>
    </div>
  );
};

export default FutureQuantity;
