import axios from '../plantDemandAxios';
import { AxiosResponse } from 'axios';

import apiMap from '../utils/apiMap/apiMap';

import { Material, PlantId } from '../types/plantTypes';
import { makeGraphQLRequest } from '../request';
import {
  DeleteInvitationParams,
  SetPlantMemberPermissionsResponse,
  SetPlantPermissions,
  ProductionMode,
  ProductionModeWithMaterials,
} from 'src/types/plantSettingsTypes';

export interface PlantMembership {
  id: number;
  obj: number;
  removed: Date | null;
  role: number;
  user: number;
  user_email: string;
  user_name: string;
}

export interface PlantMembershipWithRole extends Omit<PlantMembership, 'role'> {
  role: Roles;
}

export enum RolesIds {
  Customer = '1',
  PlantManager = '2',
  Dispatch = '3',
  QA = '4',
  ReadOnly = '5',
  CustomerContact = '6',
  SpaceManager = '101',
}

export enum Roles {
  Customer = 'Customer',
  Dispatch = 'Dispatch',
  PlantManager = 'Plant Manager',
  SpaceManager = 'Space Manager',
  QA = 'QA',
  ReadOnly = 'Read Only',
  CustomerContact = 'Customer Contact',
}

export interface Role {
  id: number;
  name: Roles;
}

export interface PlantPermissions {
  plantId: PlantId;
  plantName: string;
  plantOrganization: number;
  plantOrganizationName: string;
  roleId?: RolesIds;
  roleName: Roles;
  userId: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  invitationStatus: InvitationStatus;
}

export interface GetPlantMemberPermissionsProps {
  plantId?: PlantId;
  userId?: number;
  email?: string;
}

export enum InvitationStatus {
  REGISTERED = 'REGISTERED',
  INVITED = 'INVITED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
}

export const roleOptions = [
  { label: Roles.PlantManager, value: '2' },
  { label: Roles.Dispatch, value: '3' },
  { label: Roles.QA, value: '4' },
  { label: Roles.ReadOnly, value: '5' },
  { label: Roles.SpaceManager, value: '101' },
];

export const getPlantMembership = async (plantId: PlantId): Promise<PlantMembership[]> => {
  const response: AxiosResponse<PlantMembership[]> = await axios.get(
    apiMap.plantMemberships(plantId),
  );
  return response.data;
};

export const getPlantPermissions = async ({
  plantId,
  userId,
  email,
}: GetPlantMemberPermissionsProps): Promise<PlantPermissions[]> => {
  let queryParams = '';

  if (plantId) {
    queryParams = queryParams.concat(`plantId: ${Number(plantId)}, `);
  }
  if (userId) {
    queryParams = queryParams.concat(`userId: ${Number(userId)}, `);
  }
  if (email) {
    queryParams = queryParams.concat(`email: "${email}"`);
  }

  const queryName = queryParams ? `getPlantPermissions(${queryParams})` : 'getPlantPermissions';

  const query = `
    {
      ${queryName} {
        plantId
        plantName
        roleId
        roleName
        plantOrganization
        plantOrganizationName
        userFirstName
        userLastName
        userEmail
        invitationStatus
      }
    }
  `;

  const { getPlantPermissions }: { getPlantPermissions: PlantPermissions[] } =
    await makeGraphQLRequest({ query });
  const sortedPermissions = getPlantPermissions.sort(
    (a, b) =>
      a.userFirstName.localeCompare(b.userFirstName) ||
      a.userLastName.localeCompare(b.userLastName),
  );

  return sortedPermissions.map(permission => ({
    ...permission,
    plantId: permission.plantId.toString(),
  }));
};

export const setPlantPermissions = async ({
  email,
  firstName,
  lastName,
  roles,
}: SetPlantPermissions): Promise<SetPlantMemberPermissionsResponse> => {
  const variables = {
    firstName,
    lastName,
    email,
    roles: roles.map(role => ({
      plantId: parseInt(role.plantId),
      roleId: role.roleId ? parseInt(role.roleId) : undefined,
    })),
  };

  const query = {
    query: `mutation setPlantPermissions($email: String, $firstName: String, $lastName: String, $roles: [RoleInputType]) {
      setPlantPermissions(email: $email, firstName: $firstName, lastName: $lastName roles: $roles) {
        __typename
        ... on SetPlantPermissionsResponse {
          firstName
          lastName
          email
          outcomes {
            plantId
            outcome
          }
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'setPlantPermissions');
  return response.setPlantPermissions;
};

export const deleteInvitation = async ({ email, plantIds }: DeleteInvitationParams) => {
  const variables = {
    email,
    plantIds: plantIds.map(plantId => parseInt(plantId)),
  };

  const query = {
    query: `mutation deleteInvitation($email: String, $plantIds: [Int]) {
      deleteInvitation(email: $email, plantIds: $plantIds) {
        __typename
        ... on DeleteInvitationResponse {
          success
        }

        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }    
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'deleteInvitation');
  return response.deleteInvitation;
};

export const getRoles = async () => {
  const response = await axios.get(apiMap.getRoles);
  return response.data;
};

export const getProductionModes = async (plantId: PlantId): Promise<ProductionMode[]> => {
  const response = await axios.get(apiMap.plantProductionModes(plantId));

  return response.data;
};

export const getProductionModeById = async (plantId, productionModeId) => {
  const productionMode = await axios.get(apiMap.editProductionMode(plantId, productionModeId));
  return productionMode.data;
};

export const createProductionMode = async productionModeData => {
  const productionMode = await axios.post(
    apiMap.createProductionMode(productionModeData.plant),
    productionModeData,
  );
  return productionMode.data;
};

export const updateProductionMode = async (productionModeData: ProductionMode) => {
  const productionMode = await axios.put(
    apiMap.editProductionMode(productionModeData.plant, productionModeData.id),
    productionModeData,
  );
  return productionMode.data;
};

export const deleteProductionMode = async (plantId: PlantId, productionModeId: string) => {
  const productionMode = await axios.delete(apiMap.deleteProductionMode(plantId, productionModeId));
  return productionMode.data;
};

export const mapProductionModeWithMaterials = (
  productionModes: ProductionMode[],
  materials: Material[],
): ProductionModeWithMaterials[] => {
  const productionModesWithMaterials = productionModes.map(productionMode => {
    return {
      ...productionMode,
      components: productionMode.components.map(component => {
        const material = materials.find(
          material => material.id.toString() === component.material.toString(),
        );

        return {
          ...component,
          material,
        };
      }),
    };
  });

  return productionModesWithMaterials;
};
