import { LabelPosition } from 'Components/fields/InputFieldWrapper';
import { Option } from './commonTypes';
import { OrderFieldsType } from './plantTypes';

export enum OrderModalCustomFieldsType {
  DateQuantityPicker = 'DateQuantityPicker',
  InputDropdown = 'InputDropdown',
  InputDropdownWithTooltip = 'InputDropdownWithTooltip',
  MultiSelect = 'MultiSelect',
  PlantName = 'PlantName',
  SpaceName = 'SpaceName',
}

export type BaseSchemaFormProps = {
  key: string;
  title: string;
  type: OrderFieldsType | OrderModalCustomFieldsType;
  disabled?: boolean;
  cols?: number;
};

export type StaticFieldProps = BaseSchemaFormProps & {
  static: true;
  cols: number;
  [key: string]: any;
};

export type RequiredFieldProps = BaseSchemaFormProps & {
  cols: number;
  [key: string]: any;
};

export type CustomFieldProps = BaseSchemaFormProps & {
  cols: number;
  deleted: boolean;
  isCustomField: true;
  isSupported: boolean;
  visibleToCustomer: boolean;
  [key: string]: any;
};

export type RemoteFieldProps = BaseSchemaFormProps & {
  remote: true;
  [key: string]: any;
};

export type OrderModalSchema = {
  title: string;
  required: string[];
  properties: (StaticFieldProps | RequiredFieldProps | CustomFieldProps | RemoteFieldProps)[];
};

export type AsyncInputDropDownTypeProps = BaseSchemaFormProps & {
  type: OrderModalCustomFieldsType.InputDropdown;
  options: Option[];
  placeholder: string;
  allowUnknownOption: boolean;
  noOptionsMessage: string;
  async: true;
  renderWithTooltip?: boolean;
  loadingOptions?: boolean;
  totalOptionsCount?: number;
  cols: number;
  labelPosition: LabelPosition;
  customValidation: (option: Option) => string | undefined;
};
export type BaseInputDropDownTypeProps = BaseSchemaFormProps & {
  type: OrderModalCustomFieldsType.InputDropdown;
  options: Option[];
  placeholder: string;
  allowUnknownOption: boolean;
  noOptionsMessage: string;
  async?: never;
  renderWithTooltip?: boolean;
  loadingOptions?: never;
  totalOptionsCount?: never;
  cols: number;
  labelPosition: LabelPosition;
  customValidation: (option: Option) => string | undefined;
};

export type InputDropDownTypeProps = BaseInputDropDownTypeProps | AsyncInputDropDownTypeProps;
