import { ComponentChild, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { Message } from 'react-hook-form';
import cls from 'classnames';
import clearIcon from 'Assets/icons/cancel.svg';
import OptionalFormLabel from '../OptionalFormLabel';
import styles from './inputField.scss';

export type InputFieldProps = {
  type?: string;
  name?: string;
  value?: string | number | null;
  formLabel?: ComponentChild;
  placeholder?: string;
  maxLength?: number;
  min?: number;
  max?: number;
  id?: string;
  input?: any;
  className?: string;
  inputClassName?: string;
  clearButton?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  startAdornment?: ComponentChild;
  endAdornment?: ComponentChild;
  hasError?: boolean;
  errorMessage?: Message | null;
  'data-testid'?: string;
  onChange?: (e) => void;
  onFocus?: () => void;
  onBlur?: (e) => void;
  onKeyUp?: (e) => void;
  onKeyDown?: (e) => void;
  onClearButtonClick?: () => void;
  other?: any;
  defaultValue?: string | number;
};

const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    id,
    input,
    value,
    type = 'text',
    formLabel,
    placeholder,
    maxLength,
    min,
    max,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    onKeyDown,
    clearButton,
    className,
    inputClassName,
    onClearButtonClick,
    readOnly,
    disabled,
    startAdornment,
    endAdornment,
    hasError,
    errorMessage,
    'data-testid': dataTestId = undefined,
    name,
    other,
    defaultValue,
  } = props;
  const classes = cls(styles.formField, className, {
    disabled: disabled || readOnly,
    [styles.hasError]: hasError,
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (disabled || readOnly) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    onKeyDown?.(e);
  };

  return (
    <div className={styles.inputFieldWrapper}>
      {formLabel && <OptionalFormLabel forComponentId={id}>{formLabel}</OptionalFormLabel>}
      <div className={classes} data-testid={dataTestId}>
        {startAdornment && <span className={styles.startAdornment}>{startAdornment}</span>}
        <input
          {...input}
          type={type}
          value={value}
          placeholder={placeholder}
          maxlength={maxLength}
          min={min}
          max={max}
          id={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
          name={name}
          defaultValue={defaultValue}
          readOnly={readOnly}
          disabled={disabled}
          onKeyUp={onKeyUp}
          onKeyDown={handleKeyDown}
          className={cls(styles.input, inputClassName)}
          aria-label={name ? `input-field-${name}` : 'input-field'}
          autocomplete="off"
          {...other}
        />

        {endAdornment && <span className={styles.endAdornment}>{endAdornment}</span>}

        {clearButton && value ? (
          <button
            type="button"
            className={styles.cancelBtn}
            onClick={onClearButtonClick}
            data-testid="input-clear-button"
          >
            <img src={clearIcon} />
          </button>
        ) : null}
      </div>

      {hasError && (
        <span
          className={styles.errorStyle}
          data-testid="input-error-message"
          role="alert"
          aria-label={errorMessage}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
});

InputField.displayName = 'InputField';

export default InputField;
