import { h } from 'preact';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { store } from '../redux/store';
import App from './App';

if (window.navigator.language === 'de') {
  // TODO: If we decide to have a userLocale service we should move this there
  // Currently we check the Browser locale and if it's DE we use it otherwise we use EN-US=en
  // moment.locale('de');
} else {
  // moment.locale('en');
}

const ProviderWrapper = () => (
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </Provider>
);

export default ProviderWrapper;
