import { AxiosResponse } from 'axios';
import { spaceApiMap } from '../utils/apiMap';
import axios from '../plantDemandAxios';
import { Configuration, SpaceNote, SpaceNoteDate, SpacePermissions } from 'Types/spaceTypes';
import { DateStringISO } from './timeService';

export const getAllConfigurations = async (businessId: string): Promise<Configuration[]> => {
  const url = spaceApiMap.listConfigurations(businessId);
  const response: AxiosResponse<Configuration[]> = await axios.get(url);

  return response.data;
};
export const getConfigurationById = async ({
  businessId,
  configurationId,
}: {
  businessId: string;
  configurationId: string;
}): Promise<Configuration> => {
  const url = spaceApiMap.getConfigurationById(businessId, configurationId);
  const response: AxiosResponse<Configuration> = await axios.get(url);

  return response.data;
};
export const createConfiguration = async (payload: Configuration) => {
  const response: AxiosResponse = await axios.post(
    spaceApiMap.createConfiguration(payload.business.toString()),
    payload,
  );
  return response.data;
};

export const updateConfiguration = async ({
  businessId,
  configurationId,
  data,
}): Promise<Configuration> => {
  const response: AxiosResponse = await axios.patch(
    spaceApiMap.updateConfiguration(businessId, configurationId),
    data,
  );
  return response.data;
};

export const deleteConfiguration = async ({
  businessId,
  spaceId,
}: {
  businessId: string;
  spaceId: string;
}): Promise<null> => {
  const url = spaceApiMap.deleteConfiguration(businessId, spaceId);
  const response: AxiosResponse<null> = await axios.delete(url);
  return response.data;
};
export const restoreConfiguration = async () => {};

export const getSpacePermissions = async (): Promise<SpacePermissions[]> => {
  const response: AxiosResponse<SpacePermissions[]> = await axios.get(
    spaceApiMap.getUserSpacePermissions(),
  );
  const mappedSpacesPermissions: SpacePermissions[] = response.data.map(
    ({ space_id, ...rest }) => ({
      space_id: space_id.toString(),
      ...rest,
    }),
  );

  return mappedSpacesPermissions;
};

export const updateUserSpacePermissions = async (payload: {
  email: string;
  first_name: string;
  last_name: string;
  roles: { role_id: string | null; space_id: string }[];
}): Promise<{ space_id: string; outcome: string }> => {
  const url = spaceApiMap.updateSpacesPermissions();
  const response: AxiosResponse<any> = await axios.post(url, payload);
  return response.data;
};

export const deleteUserSpacePermissions = async (payload: {
  email: string;
  space_ids: string[];
}) => {
  const url = spaceApiMap.deleteSpacesPermissions();
  const response: AxiosResponse<any> = await axios.post(url, payload);
  return response.data;
};

export const getSpaceNoteDates = async (spaceId: string): Promise<SpaceNoteDate[]> => {
  const url = spaceApiMap.getNoteDates(spaceId);
  const response: AxiosResponse<SpaceNoteDate[]> = await axios.get(url);
  return response.data;
};

export const getSpaceNotes = async ({
  spaceId,
  dateFrom,
  dateUntil,
}: {
  spaceId: string;
  dateFrom?: DateStringISO;
  dateUntil?: DateStringISO;
}): Promise<SpaceNote[]> => {
  const url = spaceApiMap.getNotes({ spaceId, dateFrom, dateUntil });
  const response: AxiosResponse<SpaceNote[]> = await axios.get(url);
  return response.data;
};

export const createSpaceNote = async ({ spaceId, payload }: { spaceId: string; payload: any }) => {
  const url = spaceApiMap.createNote(spaceId);
  const response: AxiosResponse<SpaceNote[]> = await axios.post(url, payload);

  return response.data;
};

export const updateSpaceNote = async ({
  spaceId,
  noteId,
  payload,
}: {
  spaceId: string;
  noteId: string;
  payload: any;
}) => {
  const url = spaceApiMap.updateNote(spaceId, noteId);
  const response: AxiosResponse<SpaceNote[]> = await axios.patch(url, payload);

  return response.data;
};

export const deleteSpaceNote = async ({
  spaceId,
  noteId,
}: {
  spaceId: string;
  noteId: string;
}): Promise<null> => {
  const url = spaceApiMap.deleteNote(spaceId, noteId);
  const response: AxiosResponse<null> = await axios.delete(url);

  return response.data;
};

export const getNoteTypes = async (): Promise<string[]> => {
  const url = spaceApiMap.getNoteTypes();
  const response: AxiosResponse<string[]> = await axios.get(url);

  return response.data;
};
