import { h } from 'preact';
import cls from 'classnames';
import styles from './comment.scss';

export type CommentProps = {
  value: string | null;
  show?: boolean;
  className?: string;
  type?: 'note' | 'warning';
  'data-testid'?: string;
};

const Comment = ({
  value,
  show,
  className,
  type = 'note',
  'data-testid': dataTestId = undefined,
}: CommentProps) => {
  if (!show) return null;

  return (
    <div
      className={cls(className, {
        [styles.commentNote]: type === 'note',
        [styles.commentWarning]: type === 'warning',
      })}
      data-testid={dataTestId}
    >
      {value}
    </div>
  );
};

export default Comment;
