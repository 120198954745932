import { DebouncedFunc, DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';

const debounceEvent = (
  eventHandler: any,
  wait: number,
  options?: DebounceSettings,
): DebouncedFunc<any> => {
  const debouncedEvent = debounce(eventHandler, wait, options);
  return debouncedEvent;
};

export default debounceEvent;
