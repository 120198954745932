export const SET_ROLES = '@suppliers/SET_ROLES';

export interface SetRolesProps {
  roles: {
    id: number;
    name: string;
  }[];
}

export const setRoles = (roles: SetRolesProps) => ({
  type: SET_ROLES,
  roles,
});
