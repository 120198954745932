import { Fragment, h } from 'preact';
import { useCallback, useRef, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';

import SchemaForm from 'Components/schemaForm';
import ModalWindow from 'Components/modal';
import Button from 'Components/buttons';
import Checkbox from 'Components/fields/checkbox';
import OrderFeed from 'Components/OrderFeed';
import OrderModalLoader from 'Components/loaders/OrderModalLoader';
import Textarea from 'Components/fields/Textarea';
import ConfirmModal from '../ConfirmModal';
import ErrorIndicator from 'Components/ErrorIndicator';

import {
  buildSchemaFromOrderFields,
  mapFormDataToApproveRejectOrderRequest,
  mapFormDataToOrderRequest,
  mapOrderToFormData,
} from 'Services/orderModalService';
import {
  approveRejectOrderRequest,
  createOrderRequest,
  orderRequestById,
  updateOrderRequest,
} from 'Services/orderRequestService';
import { getOrderById } from 'Services/orderService';
import { DateStringISO } from 'Services/timeService';
import { getPlantMaterials, listOrderDateFields, listOrderFields } from 'Services/plantService';

import { RootState } from 'Redux/reducers';
import { closeModal, openModal } from 'Redux/actions/modalActions';
import { triggerErrorAlert } from 'Redux/actions/pageLevelAlertActions';
import { approvingOrderRequestPermissions } from 'Redux/selectors/approvingOrderRequestPermissions';

import { formModeType, Order } from 'Types/orderTypes';
import { PlantId } from 'Types/plantTypes';

import { useQuery } from '../../../../helpers/hooks/useQuery';
import { useMutation } from '../../../../helpers/hooks/useMutation';
import { publish } from '../../../../helpers/hooks/useRemoteFormControl';
import { smoothScrollTo } from '../../../../utils/smoothScrollTo';

import styles from './orderRequestModalStyles.scss';
import { getCurrentPlantSelector } from 'Redux/selectors/plantsSelector';

interface OrderRequestModalProps {
  requestId?: string;
  confirmedOrderId?: string;
  producedMaterialId?: string;
  plantId: PlantId;
  materialId: string;
  nightshift?: boolean;
  date?: DateStringISO;
  hasApprovalPermission?: boolean;
  onClose: (shouldReloadCalendarData?: boolean, shouldClearCalendarFilters?: boolean) => void;
}

export enum OrderRequestModalEntityType {
  OrderRequest,
  ConfirmedOrder,
}

export enum ApprovedRejectedType {
  Approved = 'approved',
  Rejected = 'rejected',
}

const OrderRequestModal = ({
  requestId,
  confirmedOrderId,
  plantId,
  materialId,
  nightshift,
  date,
  onClose,
}: OrderRequestModalProps) => {
  const dispatch = useDispatch();

  const hasApprovalPermission = useSelector((state: RootState) =>
    approvingOrderRequestPermissions(state, plantId),
  );
  const authUserId = useSelector((state: RootState) => state.user.id);
  const selectedPlant = useSelector((state: RootState) => getCurrentPlantSelector(state, plantId));

  const [selectedMaterial, setSelectedMaterial] = useState<{ label: string; value: string }>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const approvedRejectedClickedButtonStatus = useRef<ApprovedRejectedType | undefined>();

  const rejectReasonRef = useRef<HTMLTextAreaElement | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const formModeRef = useRef<formModeType>(
    requestId || confirmedOrderId ? formModeType.EDIT : formModeType.CREATE,
  );

  const entityTypeRef = useRef<OrderRequestModalEntityType>(
    !!confirmedOrderId && !requestId
      ? OrderRequestModalEntityType.ConfirmedOrder
      : OrderRequestModalEntityType.OrderRequest,
  );

  const isEditMode = formModeRef.current === formModeType.EDIT;
  const isCreationMode = formModeRef.current === formModeType.CREATE;
  const isOrderRequest = entityTypeRef.current === OrderRequestModalEntityType.OrderRequest;

  const { data: plantOrderFields, loading: plantOrderFieldsLoading } = useQuery(
    listOrderFields,
    [plantId],
    {
      initialData: [],
    },
  );

  const { data: plantOrderDateFields, loading: plantOrderDateFieldsLoading } = useQuery(
    listOrderDateFields,
    [plantId],
    {
      initialData: [],
    },
  );

  const { data: plantMaterials, loading: plantMaterialsLoading } = useQuery(
    getPlantMaterials,
    [plantId],
    {
      initialData: [],
      onSuccess: data => {
        const material = data.find(material => material.id == materialId);
        if (!material) return;

        setSelectedMaterial({ label: material.name, value: material.id });
      },
    },
  );

  const plantOutputMaterials = plantMaterials.filter(material => material.is_output_material);

  const { data: orderRequestData, loading: orderRequestDataLoading } = useQuery(
    orderRequestById,
    [plantId, requestId as string],
    {
      enabled: !!requestId && formModeRef.current !== formModeType.CREATE,
    },
  );

  const { data: confirmedOrder, loading: confirmedOrderLoading } = useQuery(
    getOrderById,
    [{ plantId, orderId: confirmedOrderId as string }],
    {
      enabled: !!confirmedOrderId && formModeRef.current !== formModeType.CREATE,
      initialData: {} as Order,
    },
  );

  const onCreateUpdateSuccess = () => {
    onClose(true);
  };

  const onCreateUpdateError = (errorMessage: string) => {
    dispatch(triggerErrorAlert(errorMessage));
  };

  const updateOrderRequestMutation = useMutation(updateOrderRequest, {
    onSuccess: onCreateUpdateSuccess,
    onError: onCreateUpdateError,
  });
  const createOrderRequestMutation = useMutation(createOrderRequest, {
    onSuccess: onCreateUpdateSuccess,
    onError: onCreateUpdateError,
  });

  const approveRejectOrderRequestMutation = useMutation(approveRejectOrderRequest);

  const isDataLoading = () => {
    if (plantOrderFieldsLoading) return true;
    if (plantOrderDateFieldsLoading) return true;
    if (plantMaterialsLoading) return true;
    if (isCreationMode && materialId && !selectedMaterial) return true;
    if (!isEditMode) return false;
    return isOrderRequest ? orderRequestDataLoading : confirmedOrderLoading;
  };

  const triggerSubmit = (buttonStatus?: ApprovedRejectedType) => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    approvedRejectedClickedButtonStatus.current = buttonStatus;
  };

  const handleFormSubmit = formData => {
    if (!selectedPlant) return;

    const entityId = requestId || confirmedOrderId;
    const serverData = mapFormDataToOrderRequest({
      formData,
      entityId,
      customer: selectedPlant.customer_name,
      plantOrderFields,
      userId: authUserId,
      formMode: formModeRef.current,
      formType: entityTypeRef.current,
    });
    if (isEditMode) {
      if (isOrderRequest) {
        // update order request
        const orderRequestId = requestId as string;
        updateOrderRequestMutation.runMutation([{ plantId, orderRequestId, data: serverData }]);
      } else {
        const pendingRequest = confirmedOrder.requests.find(r => r.status === 'pending');

        if (!pendingRequest) {
          // should create new order request if there is no pending request
          createOrderRequestMutation.runMutation([{ plantId, data: serverData }]);
        } else {
          // if there are already a pending request then update it
          updateOrderRequestMutation.runMutation([
            { plantId, orderRequestId: pendingRequest.id, data: serverData },
          ]);
        }
      }
    } else {
      if (isOrderRequest) {
        createOrderRequestMutation.runMutation([{ plantId, data: serverData }]);
      }
    }
  };

  const openConfirmModal = () => {
    const confirmRejectModal = (
      <ConfirmModal
        title={I18n.t('orderRequestModal.rejectRequest')}
        bodyContent={
          <div>
            <div>{I18n.t('orderRequestModal.rejectReason')}</div>
            <Textarea name="rejectReason" inputRef={rejectReasonRef} />
          </div>
        }
        onConfirm={handleRejectRequest}
        onCancel={() => dispatch(closeModal())}
      />
    );

    dispatch(openModal({ modal: confirmRejectModal }));
  };

  const handleRejectRequest = async () => {
    if (!rejectReasonRef.current?.value) {
      dispatch(triggerErrorAlert(I18n.t('orderRequestModal.noRejectReason')));
      return;
    }

    const data = { reason: rejectReasonRef.current.value };

    try {
      await approveRejectOrderRequestMutation.runMutationAsync([
        plantId,
        requestId as string,
        data,
        ApprovedRejectedType.Rejected,
      ]);

      onClose(true); // close Confirm modal
      onClose(); // close OrderRequest modal
    } catch (error: any) {
      dispatch(triggerErrorAlert(error.message));
    }
  };

  const handleApproveRequest = async formData => {
    const entityId = requestId || confirmedOrderId;
    const serverData = mapFormDataToApproveRejectOrderRequest({
      formData,
      entityId,
      customer: formData.customer.label,
      plantOrderFields,
      userId: authUserId,
      formMode: formModeRef.current,
      formType: entityTypeRef.current,
    });

    try {
      await approveRejectOrderRequestMutation.runMutationAsync([
        plantId,
        requestId as string,
        serverData,
        ApprovedRejectedType.Approved,
      ]);

      onClose(true);
    } catch (error: any) {
      dispatch(triggerErrorAlert(error.message));
    }
  };

  const scrollToErrors = () => {
    smoothScrollTo(100, modalRef);
  };

  const handleValidationErrors = useCallback(errors => {
    setValidationErrors(errors);

    if (!!errors.length) {
      scrollToErrors();
    }
  }, []);

  const getBodyContent = () => {
    if (!selectedPlant) return null;

    const entity: any = orderRequestData || confirmedOrder;
    // If the formData is not ready on the initial render the default values are not injected into inputs
    // https://github.com/react-hook-form/react-hook-form/issues/1150
    if (isDataLoading()) return <OrderModalLoader />;

    const formData = mapOrderToFormData({
      order: entity,
      selectedDate: date as string,
      selectedMaterial: selectedMaterial,
      nightshift: nightshift,
      formMode: formModeRef.current,
      orderDateFields: plantOrderDateFields,
    });

    const orderExtraFields = entity?.extraFields || [];

    const schema = buildSchemaFromOrderFields({
      plantOrderFields,
      orderExtraFields,
      plantMaterials: plantOutputMaterials.map(({ name, id }) => ({ label: name, value: id })),
      plantCustomers: [],
      plantOrderDateFields: plantOrderDateFields,
      selectedDate: !isEditMode ? date : undefined,
      formName: 'OrderRequest',
      formMode: formModeRef.current,
      plantName: selectedPlant.name,
      isCustomerPlant: true,
    });

    return (
      <Fragment>
        <SchemaForm
          schema={schema}
          formData={formData}
          formRef={formRef}
          onSubmit={data => {
            if (approvedRejectedClickedButtonStatus.current === ApprovedRejectedType.Approved) {
              handleApproveRequest({ ...data, customer: formData.customer });
            } else {
              handleFormSubmit(data);
            }
          }}
          onValidationErrors={handleValidationErrors}
        />
        {isEditMode && (
          <OrderFeed
            orderFeed={entity.feed || []}
            onCommentChange={({ target }) => publish(target.name, target.value)}
          />
        )}
      </Fragment>
    );
  };

  const getFooterContent = () => {
    const isSaving = updateOrderRequestMutation.loading || createOrderRequestMutation.loading;

    const footer = (
      <div className="grid-row" data-testid="footer-content">
        <div className={cls('grid-col grid-col-12', styles.saveBtnContainer)}>
          <Checkbox
            checked={false}
            formLabel={I18n.t('orderRequestModal.urgentRequest')}
            id="urgentRequest"
            margin="none"
            name="urgentRequest"
            onChange={({ target }) => publish(target.name, target.checked)}
          />
          {hasApprovalPermission ? (
            <Fragment>
              <Button
                color="warning"
                label={I18n.t('orderForm.reject')}
                className="ml-md"
                disabled={isSaving}
                onClick={openConfirmModal}
                data-testid="rejectButton"
              />
              <Button
                type="button"
                label={I18n.t('orderForm.approve')}
                className={cls('ml-md', styles.btnApprove)}
                disabled={isSaving}
                onClick={() => triggerSubmit(ApprovedRejectedType.Approved)}
              />
            </Fragment>
          ) : (
            <>
              <ErrorIndicator
                show={!!validationErrors.length}
                errors={validationErrors}
                className="ml-md"
                onClick={scrollToErrors}
              />
              <Button
                label={isEditMode ? I18n.t('orderForm.save') : I18n.t('orderForm.create')}
                className="ml-md"
                disabled={isSaving}
                onClick={triggerSubmit}
              />
            </>
          )}
        </div>
      </div>
    );
    return !isDataLoading() && footer;
  };

  return (
    <ModalWindow
      contentClassName={cls({
        [styles.pendingRequestModal]: isOrderRequest,
      })}
      title={
        isOrderRequest
          ? I18n.t('orderRequestModal.orderRequest')
          : I18n.t('orderRequestModal.confirmedOrder')
      }
      headerClassName={isOrderRequest && styles.title}
      bodyContent={getBodyContent()}
      footerContent={getFooterContent()}
      onClose={() => onClose()}
      data-testid="order-request-modal"
      ref={modalRef}
    />
  );
};

export default OrderRequestModal;
