import cls from 'classnames';
import { Link } from 'react-router-dom';
import routeMap from '../../route-maps';
import styles from './plantNotExistStyles.scss';

const PlantNotExist = () => {
  return (
    <div className={cls(styles.plantNotExistWrapper, 'pageBody')}>
      <h3 className={styles.plantNotExistHeader}>Plant not exist</h3>
      <p className={styles.plantNotExistMsg}>
        This plant may be deleted or you may not be authorized to view it.
      </p>
      <div className={styles.plantNotExistMsg}>
        <Link to={routeMap.dashboard} className={styles.dashboardBtn}>
          Go to Dashboard
        </Link>{' '}
        and select a plant from the plant list
      </div>
    </div>
  );
};

export default PlantNotExist;
