import { h } from 'preact';
import classnames from 'classnames';
import Tooltip from 'Components/Tooltip';
import { isHighlightedOption, isOptionWithExtraFieldsData, Option } from 'Types/commonTypes';
import styles from './dropDownMenuItemStyles.scss';
import ColorCircle from 'Components/ColorCircle';

export interface DropDownItemProps {
  option: Option;
  isHighlighted?: boolean;
  isActive?: boolean;
  index: number;
  showTooltip?: boolean;
  optionProps: Record<string, any>;
}

const DropDownItem = ({
  option,
  isHighlighted,
  isActive = false,
  index,
  showTooltip = false,
  optionProps,
}: DropDownItemProps) => {
  const handleOptionSelect = () => {
    optionProps.onOptionSelect(option);
  };

  const getTooltipTitle = () => {
    if (!isOptionWithExtraFieldsData(option)) return;

    return (
      <div className={styles.tooltip}>
        <div className={styles.title}>{option.label}</div>
        <div className={styles.body}>
          {option.extraFieldsData.length ? (
            option.extraFieldsData.map(field => {
              return (
                <div className={styles.row}>
                  <div>{field.key}</div>
                  <div>{field.value?.toString()}</div>
                </div>
              );
            })
          ) : (
            <div>No additional info</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Tooltip
      title={getTooltipTitle()}
      delay={100}
      variant="white"
      placement="left"
      shouldRenderTitle={showTooltip}
      active={!!optionProps.hoveredOption}
      onShow={() => optionProps.onOptionHover(option, 'enter')}
      onHide={() => optionProps.onOptionHover(option, 'leave')}
    >
      <div
        className={classnames(styles.dropdownMenuItem, {
          [styles.active]: isActive,
          [styles.highlighted]: isHighlighted,
        })}
        data-highlighted-option-index={index}
        onClick={handleOptionSelect}
        data-testid="dropdown-menu-option"
      >
        {!!option.icon && (
          <img
            className={styles.dropdownIcon}
            src={isActive ? option.iconActive : option.icon}
            alt="option-icon"
          />
        )}
        <label className={styles.dropdownMenuLabel} data-label={option.value}>
          {option.label}
          <ColorCircle color={isHighlightedOption(option) ? option.highlightColor : undefined} />
        </label>
      </div>
    </Tooltip>
  );
};

export default DropDownItem;
