export const uk = {
  copyright: {
    systemStatus: 'Статус системи',
  },
  mixPlant: 'Мікс завод',
  aggregatePlant: 'Агрегатний завод',
  pavingSchedule: 'Графік укладання бруківки',
  home: 'Додому',
  mesch: 'MeSch Software UG',
  allRights: '(Haftungsbeschränkt). Всі права захищені.',
  privacyPolicy: 'Конфіденційність',
  termsOfService: 'Умови обслуговування',
  whatsTheName: 'Як звуть нового співробітника?',
  employeeEmail: 'Електронна адреса співробітника:',
  dashboard: 'Панель приладів',
  paving: 'Мощення',
  plant: 'Завод',
  role: 'Роль',
  cancel: 'Скасувати',
  ok: 'Ок',
  save: 'зберегти',
  add: 'Додати',
  type: 'Тип',
  required: 'Вимагається',
  visibleToCustomers: 'Видно клієнтам?',
  export: 'Експорт',
  newField: 'Нове поле',
  oldPassword: 'Старий пароль',
  newPassword: 'Новий пароль',
  confirmNewPassword: 'Підтвердити новий пароль',
  oldPasswordRequired: 'Потрібен старий пароль!',
  newPasswordRequired: 'Необхідно ввести новий пароль!',
  newPasswordConfirmation: 'Введіть ще раз свій новий пароль.',
  passwordsDontMatch: 'Два поля пароля не збігаються.',
  passwordMinLength: 'Пароль повинен містити не менше 8 символів',
  passwordMustBeDifferent: 'Новий пароль має відрізнятися від старого.',
  passwordChangeDone: 'Ваш пароль змінено. Натисніть тут, щоб вийти.',
  nameChangeDone: "Ваше ім'я змінено.",
  changePassword: 'Змінити пароль',
  password: 'Пароль',
  passwordPlaceholder: 'Пароль...',
  repeatNewPassword: 'Повторіть новий пароль!',
  repeatPassword: 'Повторіть пароль',
  repeatPasswordPlaceholder: 'Повторити пароль...',
  termsAndConditions: 'Розпочніть безкоштовну пробну версію та прийміть Правила та умови',
  termsAndConditionsRequiredMsg: 'Будь ласка, прийміть Загальні положення та умови',
  email: 'Електронна пошта',
  emailAddress: 'Адреса електронної пошти',
  emailPlaceholder: 'Електронна пошта...',
  emailAddressPlaceholder: 'Адреса електронної пошти',
  subscriberNamePlaceholder: "Ім'я абонента",
  code: 'Код',
  phone: 'Телефон',
  phoneNumber: 'Телефонний номер',
  fieldIsRequired: 'Поле %{fieldName} обов’язкове',
  fieldIsMissing: '%{fieldName} не існує',
  company: 'Компанія',
  message: 'Повідомлення',
  send: 'Надіслати',
  sending: 'Відправка',
  sendInvite: 'Надіслати запрошення',
  sendToMe: 'Надішли мені',
  sendToOrg: 'Надіслати моїй команді',
  print: 'Роздрукувати',
  download: 'Завантажити',
  printReport: 'Роздрукувати звіт',
  minimumAmountIsRequired: 'Потрібна мінімальна сума!',
  maximumAmountIsRequired: 'Потрібна максимальна сума!',
  currentAmountIsRequired: 'Необхідна поточна сума!',
  nameIsRequired: "Ім'я обов'язкове!",
  selectCountryCode: 'Виберіть код країни!',
  firstNamePlaceholder: "Ім'я",
  lastNamePlaceholder: 'Прізвище',
  reports: 'Звіти',
  account: 'Обліковий запис',
  referAFriend: 'Запропонуйте другу',
  logOut: 'Вийти',
  organizationMembership: 'Членство в організації',
  employeeCount: '%{count} співробітників',
  members: 'Члени',
  addAnEmployee: 'Додайте співробітника',
  addANewPlant: 'Додайте новий завод',
  onlyAddEmployees: 'Ви повинні додавати лише працівників %{name}.',
  onlyAddCurrent: 'Додайте лише поточних співробітників',
  inviteNewEmployee: 'Запросіть нового співробітника до %{name}',
  plants: 'Заводи',
  schedule: 'Розклад',
  edit: 'Редагувати',
  create: 'Створити',
  createPlant: 'Створити завод',
  editPlant: 'Редагувати завод',
  chooseSupplier: 'Виберіть постачальника',
  supplier: 'Постачальник',
  suppliers: 'Постачальники',
  materials: 'Матеріали',
  productionMode: 'Режим виробництва',
  mixDesignLabel: 'Змішаний дизайн',
  activate: 'Активувати',
  deactivate: 'Дезактивувати',
  actions: 'Дії',
  notifications: 'Сповіщення',
  address: 'Адреса',
  name: "Ім'я",
  firstName: "Ім'я",
  lastName: 'Прізвище',
  saveName: "Зберегти ім'я",
  date: 'Дата',
  expired: 'Термін дії минув',
  picture: 'Картина',
  maxCapacity: 'Максимальна місткість',
  plantName: 'Назва заводу',
  plantType: 'Тип заводу',
  plantTypePlaceholder: 'Виберіть тип заводу',
  search: 'Пошук',
  searchPlaceholder: 'Пошук...',
  nightShift: 'Нічна зміна',
  nightShiftCaps: 'НІЧНА ЗМІНА',
  nightShiftFrom: 'Нічна зміна від',
  nightShiftTo: 'Нічна зміна До',
  inactivePlants: 'Неактивні заводи',
  activePlants: 'Активні заводи',
  addPlant: 'Додати завод',
  addInputMaterial: 'Додати вхідний матеріал',
  addNewSupplier: 'Додати нового постачальника',
  addSupplier: 'Додати постачальника',
  editSupplier: 'Редагувати постачальника',
  deleteSupplier: 'Видалити постачальника',
  deleteSupplierMessage: 'Ви впевнені, що хочете видалити постачальника?',
  deleteOrderField: 'Видалити поле форми замовлення',
  deleteOrderFieldMessage: 'Ви впевнені, що хочете видалити поле замовлення?',
  yesDelete: 'Так, видалити',
  yesDeactivate: 'Так, деактивувати',
  yesActivate: 'Так, активувати',
  yes: 'Так',
  description: 'Опис',
  addEmpoyee: 'Додати співробітника',
  reactivatePlant: 'Повторно активувати завод',
  deactivatePlant: 'Деактивувати завод',
  configureSupplier: 'Налаштувати постачальника',
  configurePlant: 'Налаштувати установку',
  billing: 'Виставлення рахунків',
  changeCard: 'Змінити картку',
  confirm: 'Підтвердити',
  aysReactivate: 'Ви впевнені, що бажаєте повторно активувати %{name}?',
  aysDeactivate: 'Ви впевнені, що бажаєте деактивувати %{name}?',
  toggleNavigation: 'Увімкнути навігацію',
  login: 'Вхід',
  signUp: 'Зареєструватися',
  tryItOut: 'Спробуйте!',
  learnMore: 'Вивчайте більше',
  contact: 'Контакти',
  services: 'Послуги',
  help: 'Довідка',
  remove: 'Видалити',
  deletedPrefix: '[ВИДАЛЕНО]',
  copy: 'Копія',
  verified: 'Перевірено',
  newOrderRequest: 'Новий запит на замовлення',
  helpButtonText: 'ПОТРІБНА ДОПОМОГА?',
  supportModalTitle: 'Як ми можемо допомогти?',
  supportModalInputPlaceholder: 'Напишіть своє запитання чи проблему з програмним забезпеченням...',
  supportModalSent: 'Повідомлення успішно надіслано.',
  supportModalError: "Будь ласка, зв'яжіться з нами за адресою support@plantdemand.com",
  loading: 'Завантаження...',
  submit: 'Надіслати',
  academy: 'Академія',
  support: 'Підтримка',
  walkthrough: 'Проходження',
  reportBug: 'Повідомити про помилку',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: 'Забули пароль?',
  staySignedIn: "Запам'ятати мене",
  logInAgain: 'Увійдіть знову',
  logInPlantDemand: 'Увійдіть до PlantDemand',
  passwordRequiredFieldMsg: 'Необхідно вказати пароль',
  passwordMinLengthErrorMsg: 'Пароль повинен містити не менше 8 символів',
  emailRequiredFieldMsg: 'Необхідно вказати адресу електронної пошти',
  newToPD: 'Вперше користуєтеся PlantDemand?',
  newToPDText:
    'Ми раді вас тут бачити! Ви можете створити обліковий запис і почати планувати за 4 хвилини.',
  externalSupplierNotification:
    'Якщо ви намагаєтеся надати зовнішньому постачальнику або клієнту доступ до своїх установок, скористайтеся натомість функціями керування користувачами на вкладці конфігурації установки.',
  inviteFriend: 'Запросіть друга',
  thankYou: 'Дякую тобі',
  referAFriendMessage:
    'Ми раді, що вам подобається PlantDemand, і хочемо поділитися ним із друзями та колегами.',
  thanks: 'Спасибі',
  reminder: 'Нагадування:',
  warningCardTitleOnlyCurrentEmployee: 'Додайте лише поточних співробітників',
  warningCardTextOnlyAddEmployee: 'Ви повинні додавати лише співробітників',
  warningCardTextUseManagmentFeatures:
    'Якщо ви намагаєтеся надати зовнішньому постачальнику або клієнту доступ до своїх установок, скористайтеся натомість функціями керування користувачами на вкладці конфігурації установки.',
  errorCardText: 'У цьому бізнесі немає діючих заводів. Будь ласка, додайте новий завод.',
  pendingInvitations: 'Запрошення в очікуванні',
  deleteInvitation: 'Видалити запрошення?',
  aysDeleteInvitation: 'Ви впевнені, що бажаєте видалити запрошення для %{user}?',
  inputForecast: 'Вхідний прогноз',
  outputForecast: 'Прогноз виходу',
  production: 'виробництво',
  inventoryForecast: 'Прогноз запасів',
  dayView: 'Перегляд дня',
  using: 'Використання',
  by: 'за',
  from: 'Від',
  until: 'Поки',
  weekdays: 'Будні',
  filters: 'Фільтри',
  shortcuts: 'Ярлики',
  for: 'ЗА',
  selectedPlants: 'Вибрано %{count} заводів',
  plantsCaps: 'ЗАВОДИ',
  noPlants: 'Жодних Заводів',
  forAllPlants: 'ДЛЯ ВСІХ ЗАВОДІВ',
  forOnePlant: 'НА ОДИН ЗАВОД',
  thisWeek: 'Цього тижня',
  nextWeek: 'Наступного тижня',
  nextTwoWeeks: 'Два тижні',
  threeWeeks: 'Три тижні',
  thisMonth: 'Цього місяця',
  nextMonth: 'Наступного місяця',
  thisQuarter: 'Цей квартал',
  thisYear: 'Цього року',
  day: 'День',
  night: 'Ніч',
  week: 'Тиждень',
  month: 'Місяць',
  refresh: 'Оновити',
  selectMaterial: 'Виберіть матеріал',
  xlsx: 'XLSX',
  youDontHavePlants: 'У вас ще немає заводів.',
  youCanConfigurePlants: 'Ви можете налаштувати свої заводи на',
  accountDownCase: 'рахунок',
  screen: 'екран.',
  youDontHaveOrganisation:
    'У вас ще немає організації. Якщо ваша компанія вже використовує PlantDemand, попросіть свого менеджера запросити вас. Ви можете налаштувати заводи вашого бізнесу на',
  page: 'сторінка.',
  registerOrganisation: 'Зареєструйте нову організацію',
  askCompanyToConfigureBusiness:
    'Якщо ваша компанія вже використовує PlantDemand, попросіть свого менеджера запросити вас. Ви можете налаштувати заводи вашого бізнесу на',
  availableFields: 'Доступні поля:',
  updatedMaterialMsg: 'Матеріал успішно оновлено',
  updatedMaterialFailedMsg: 'Неможливо оновити матеріал',
  deleteMaterialMsg: 'Матеріал успішно видалено',
  deleteMaterialFailedMsg: 'Неможливо видалити матеріал',
  addNewSupplierMsg: 'Постачальника успішно додано',
  groupBy: 'Групувати за:',
  pivotReport: 'Зведений звіт',
  productionReport: 'Звіт про виробництво',
  noReportData: 'Немає даних для вибраних дат.',
  materialName: 'Назва матеріалу',
  total: 'Всього',
  customer: 'Замовник',
  dates: 'Дати',
  quantity: 'Кількість',
  loadTime: 'Час завантаження',
  material: 'Матеріал',
  compact: 'Компактний',
  classic: 'Класичний',
  requestPending: 'Запит очікує на розгляд',
  list: 'Список',
  endDailyReport: 'ЗАВЕРШИТИ ЩОДЕННИЙ ЗВІТ ЗА',
  addTime: 'Додати час',
  to: 'До',
  orderBy: 'Сортувати за',
  me: 'Я',
  myOrganization: 'Моя організація',
  teamNotifications: 'Сповіщення команди',
  dailyReport: 'Щоденний звіт',
  plantSettings: 'Налаштування сповіщень',
  myProfile: 'Мій профіль',
  all: 'Все',
  pleaseSelect: 'Виберіть будь ласка',
  selectAll: 'Вибрати все',
  inventory: 'Інвентар',
  desiredRange: 'Бажаний діапазон:',
  minimumInventory: 'Мінімальний запас:',
  maximumInventory: 'Максимальний запас:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Д',
  inventoryForecastLegend: 'Δ - Зміна ↑ - Виробництво ↓ - Збут',
  goToPlant: 'Ідіть заводити',
  noOrdersOn: 'Без замовлень',
  emailSentToMe: 'Мені надіслано електронний лист',
  emailSentToMyOrganization: 'Електронну пошту надіслано до Моєї організації',
  removeNoteConfirmMessage: 'Ви впевнені, що хочете видалити цю нотатку?',
  comment: 'Коментар',
  updates: 'Оновлення',
  addComment: 'Додати коментар',
  commentPlaceholder: 'Введіть свій коментар',
  dropdownChoosePlaceholder: '- Виберіть -',
  showAll: 'Показати все',
  showLatest: 'Показати останні',
  hide: 'Сховати',
  show: 'Показати',
  errorTitle: 'Помилка',
  noOptions: 'Без варіантів',
  components: 'компоненти',
  errorMessage: 'Ой! Щось пішло не так',
  requestErrorMessage: 'Сталася помилка. Якщо ви застрягли, натисніть кнопку довідки.',
  tryNewAppVersionMessage:
    'Ми раді, що ви пробуєте нову версію PlantDemand 🎉 Будь ласка, надішліть нам свій відгук.',
  switchInterfaceMessage: 'Перейти до оригінального інтерфейсу',
  overProductionTitle: 'Надвиробництво',
  overProductionMessage: 'Ви перевищили потужність на %{overProductionCount}',
  none: 'Жодного',
  showAllOptions: 'Показати всі %{count} елементів',
  visibleToCustomersLabel: 'Видно клієнтам?',
  requestMaterial: 'Запит на матеріал',
  calendar: {
    addOrderText: 'Додати замовлення',
    tentative: 'Орієнтовний',
  },
  checkbox: {
    selectAll: 'Вибрати все',
    deselectAll: 'Зняти вибір із усіх',
  },
  calendarWeek: {
    newOrder: 'Нове замовлення',
    addNote: 'Додати коментар',
    viewInputMaterial: 'Переглянути вхідний матеріал',
    viewDayReport: 'Переглянути звіт за день',
    viewEndOfDayReport: 'Переглянути звіт на кінець дня',
  },
  reportsPage: {
    calendarData: 'Календарні дані',
    groupBy: 'Групувати за',
    pivotBy: 'Pivot by',
    pivotByPlaceholder: 'Pivot by...',
    presets: 'Попередні налаштування...',
    timeFrame: 'Період часу',
    sendReportFailedMsg: 'Неможливо надіслати звіт',
  },
  calendarFilterBar: {
    fields: 'Поля',
    fieldsPlaceholder: 'Вибрані поля',
    countFieldsPlaceholder: '%{count} полів',
    startDate: 'Дата початку',
    endDate: 'Дата закінчення',
    view: 'Переглянути замовлення',
    move: 'Рухатися',
    plants: 'Заводи',
    dates: 'Дати',
    dateRange: 'Проміжок часу',
    selectRange: 'Виберіть діапазон',
    quantityRange: 'Діапазон кількості',
    min: 'Мін',
    max: 'Макс',
    goToToday: 'Перейти до сьогоднішнього дня',
  },
  plantDropdown: {
    plantImageAlt: 'Зображення заводу',
  },
  modal: {
    close: 'Закрити',
  },
  orderModal: {
    deleteOrder: 'Видалити замовлення',
    deleteOrderConfirmationMsg: 'Ця дія видалить %{count} замовлення(ів). Ти впевнений?',
    notifyTeam: 'Повідомити команду',
    disabledChangePlantTooltipTitle:
      'Будь ласка, збережіть це замовлення перед тим, як перемістити його на інший завод',
  },
  orderRequestModal: {
    orderRequest: 'Запит на замовлення',
    confirmedOrder: 'Підтверджене замовлення',
    urgentRequest: 'Терміновий запит',
  },
  noteModal: {
    addNoteDate: 'Додати дату примітки',
    noteDate: 'Примітка дати',
    plant_closure: 'Закриття заводу',
    custom: 'Примітка',
    unknown: 'Невідомий',
    crew: 'Екіпаж / Перерва',
    maintenance: 'Технічне обслуговування',
    alert: 'Попередження',
    inspection: 'Огляд',
    other: 'Інший',
    fieldError: "Це поле є обов'язковим",
    descriptionLabel: 'опис*',
    descriptionPlaceholder: 'Опис примітки...',
    titleLabel: 'Назва',
    titlePlaceholder: 'Замовник',
    typeLabel: 'Тип примітки',
    typePlaceholder: 'Закриття заводу',
    serverErrorText1:
      'На жаль, нам не вдалося зберегти вашу нотатку. Наш сервер видав таку помилку',
    serverErrorText2:
      'Якщо ця проблема не зникне, <a>натисніть тут</a> , щоб зв’язатися з PlantDemand',
    notifyTeam: 'Повідомити команду',
  },
  orderForm: {
    order: 'порядок',
    addDates: 'Додайте дати',
    deliveryDates: 'Терміни доставки',
    quantity: 'Кількість *',
    deliveryDate: 'Дата доставки',
    deliveryTime: 'Час доставки',
    nightShift: 'Нічна зміна',
    create: 'створити',
    save: 'зберегти',
    saving: 'Збереження...',
    remove: 'видалити',
    copy: 'Копія',
    copyOrderMsg:
      'Ми створили копію замовлення №%{copyOrderId}. Виберіть нову дату доставки та натисніть «Зберегти»',
    moveDates: 'Перемістити дати',
    movePlant: 'Перемістити завод',
    changePlant: 'Змінити завод',
    customerVisibleField: 'Це поле видно клієнтам',
    fieldIsRemoved: 'Це поле видалено з вашої форми',
    errorMessage: 'Щось пішло не так',
    unknownFieldType: 'Невідомий тип поля',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Вхідний матеріал',
    quantity: 'Кількість',
    supplier: 'Постачальник',
    noOrdersMessage: 'Немає вихідних матеріалів, оскільки на цю дату немає запланованих замовлень',
    addOrderLink: 'Натисніть, щоб додати замовлення.',
    noInputMaterials:
      '<span>Цей %{name} не має вхідних матеріалів.</span> <a>Натисніть тут</a> <span>, щоб додати вхідний матеріал до %{name}</span>',
    noInputMaterialsForDay: 'Вхідних матеріалів на цей день немає.',
    clickToAddOrder: 'Натисніть тут, щоб додати нове замовлення',
    clickToAddMixDesign: 'Клацніть тут, щоб керувати своїми дизайнами сумішей.',
  },
  monthNames: {
    January: 'січня',
    February: 'Лютий',
    March: 'березень',
    April: 'квітень',
    May: 'Може',
    June: 'червень',
    July: 'липень',
    August: 'серпень',
    September: 'Вересень',
    October: 'жовтень',
    November: 'Листопад',
    December: 'Грудень',
  },
  activityList: {
    order: 'порядок',
    time: 'час',
    change: 'Зміна',
    user: 'Користувач',
    noData: 'Нічого не знайдено.',
  },
  ordersList: {
    edit: 'Редагувати',
    material: 'матеріал',
    quantity: 'Кількість',
    customer: 'Замовник',
    deliveryDate: 'Дата доставки',
    createdDate: 'Дата створення',
    noData: 'Нічого не знайдено.',
    checkAll: '#',
    actions: 'Дії',
  },
  plantHeader: {
    orders: 'Замовлення',
    planner: 'Планувальник',
    activity: 'Діяльність',
    requests: 'Запити',
    productionSchedule: 'Графік виробництва',
    plantSettings: 'Налаштування заводу',
    cogIcon: 'Cog значок',
    newOrder: 'Нове замовлення',
  },
  accountHeader: {
    account: 'Обліковий запис',
    membership: 'Членство',
    plants: 'Заводи',
    suppliers: 'Постачальники',
    billing: 'Виставлення рахунків',
    notifications: 'Сповіщення',
    myProfile: 'Мій профіль',
  },
  moveOrder: {
    selectOrdersHeader: 'Вибрані вами замовлення',
    selectPlant: 'Виберіть «Завод».',
    selectMaterial: 'Виберіть матеріал',
    moveSelectedOrdersBtn: 'Перемістити вибрані замовлення',
    copySelectedOrdersBtn: 'Копіювати вибрані замовлення',
    disableBtnMessage: 'Будь ласка, виберіть цільовий завод і матеріал',
    disableMoveOrderBtnMessage: 'Виберіть принаймні одне поле',
    keepOriginalOrderLabel: 'Зберігайте оригінальний порядок',
    selectMatchingField: 'Видалити поле %{fieldName}',
    confirmPlantAndMaterial: 'Підтвердити завод і матеріал',
    moveOrderFrom: 'Перемістити замовлення з',
    moveOrderTo: 'Перемістити замовлення до',
    deselectOrderMessage: 'Дата замовлення скасована.',
    undo: 'Скасувати',
    bubbleMsgHeader: 'Перемістіть поля введення',
    bubbleMsgSubHeader:
      'Зіставте поля введення, щоб жодна інформація не була втрачена під час переміщення вибраних замовлень. Виберіть принаймні одне поле введення, щоб перемістити цей порядок.',
  },
  OrderFormConfigInfoPanel: {
    header: 'Тут ви можете налаштувати поля у формах замовлення заводів:',
    requiredFields: "обов'язкові поля",
    optionalFields: "Необов'язкові поля",
    optionsHeaderExampleText: 'Наприклад, ви можете:',
    addTextFieldExampleText: 'Додайте текстове поле для вантажів вантажівок',
    addTextAreaExampleText: 'Додайте текстову область з інструкціями щодо отримання',
    addCheckboxExampleText: 'Додайте прапорець, щоб показати нічні зміни',
    visibleToCustomersText:
      'Позначте поля як видимі для клієнта, щоб клієнти могли переглядати та редагувати ці поля під час запитів на замовлення.',
    fieldOrder: 'Польовий порядок',
    fieldName: 'Назва поля',
  },
  addInputMaterialPage: {
    materialProperties: 'Властивості матеріалу',
    materialName: 'Назва матеріалу',
    supplierProductName: 'Код/назва товару постачальника',
    supplierProductNamePlaceholder: 'Введіть код/назву продукту постачальника Ім’я',
    inventoryAmount: 'Сума запасів',
    minimum: 'Мінімум',
    maximum: 'Максимум',
    current: 'поточний',
    systemColor: 'Системний колір',
    materialColor: 'Колір матеріалу',
    isOutputMaterial: 'вихідний матеріал',
    addInputMaterial: 'Додайте вихідний матеріал',
    materialNamePlaceholder: "Назва матеріалу (наприклад, 1234 - Ім'я)",
    deleteMaterial: 'Видалити матеріал',
    deleteMaterialConfirmationMsg: 'Ви впевнені, що хочете видалити цей матеріал?',
  },
  addSupplierModal: {
    supplierName: "Ім'я постачальника",
    supplierAddress: 'Адреса постачальника',
  },
  mixDesign: {
    deleteMixDesign: 'Видалити дизайн суміші',
    deleteMixDesignderConfirmationMsg: 'Ви впевнені, що хочете це видалити?',
    updatedMixDesignSucceededMsg: 'Дизайн суміші успішно оновлено',
    updatedMixDesignFailedMsg: 'Неможливо оновити дизайн суміші',
    createdMixDesignSucceededMsg: 'Дизайн суміші створено успішно',
    createdMixDesignFailedMsg: 'Неможливо створити змішаний дизайн',
    deleteMixDesignSucceededMsg: 'Дизайн суміші успішно видалено',
    deleteMixDesignFailedMsg: 'Неможливо видалити змішаний дизайн',
    addMixDesign: 'Додати Mix Design',
    addNewMaterial: 'Додайте новий матеріал',
    addInputMaterial: 'Додайте вихідний матеріал',
    addOutputMaterial: 'Додати вихідний матеріал',
    selectOutputMaterial: 'Виберіть вихідний матеріал',
    expirationDate: 'Термін придатності',
    specificationBasis: 'Основа специфікації',
    addComponent: 'Додати компонент',
    percent: 'Відсоток',
    components: 'компоненти',
    noComponentsMsg: 'Компонентів немає',
    noMixDesignsMsg: 'Змішаних дизайнів не знайдено',
    mixDesignErrorMsg: 'Не вдається завантажити дизайн суміші',
  },
  materialsPage: {
    noMaterialsMsg: 'Матеріали не знайдено',
    outputMaterial: 'Вихідний матеріал',
  },
  informativePanel: {
    productionMode: 'Режим виробництва',
    productionModeDescription:
      'Виробничі режими виробляють «сировину», яку можна замовити безпосередньо або поєднати в нові матеріали за допомогою «змішаних конструкцій».',
    rawMaterial: 'Сирий матеріал %',
    mixDesign: 'Змішаний дизайн',
    mixDesignDescription: 'Комбінуйте «сировину», щоб створити «вихідні матеріали».',
  },
  subscribers: {
    subscribers: 'Підписники',
    newSubscriber: 'Новий абонент',
    addNewSubscriber: 'Додати нового абонента',
    infoSection:
      'Ви можете додати адреси електронної пошти людей, які отримуватимуть звіти від вашої організації.',
  },
  customers: {
    infoSection: 'Експортуйте цей список на інший завод. Створіть нового клієнта',
    viewOrders: 'Переглянути замовлення',
    noCustomersMsg: 'Клієнтів не знайдено',
    targetPlant: 'Цільовий завод',
    selectTargetPlant: 'Виберіть цільовий завод',
    exportCustomers: 'Експорт клієнтів',
    customerName: "Ім'я клієнта",
    customerList: 'Список клієнтів',
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'Інформацію про завод успішно оновлено',
    changePhoto: 'Змінити фотографію',
    toLabel: 'до',
    exportSectionText1: 'Ваші дані PlantDemand належать вам!',
    exportSectionText2:
      'Експортуйте дані замовлення як файл CSV, щоб створити резервні копії, або використайте їх для створення власних звітів Excel. Не забудьте перевірити тут, щоб отримати найновішу версію даних.',
    exportSectionText3:
      'Клацніть правою кнопкою миші посилання нижче та виберіть «Зберегти ціль як» або «Зберегти посилання як»;',
    exportCSVFileLabel: 'Експорт файлу CSV',
    backToPlant: 'Назад до заводу',
  },
  plantMembers: {
    editMemberInfo: 'Редагувати інформацію про учасника',
    addNewMember: 'Додати нового учасника',
    name: "Ім'я",
    namePlaceholder: "Ім'я члена",
    email: 'Адреса електронної пошти',
    emailPlaceholder: 'Адреса електронної пошти',
    close: 'Закрити',
    remove: 'видалити',
    save: 'зберегти',
    userList: 'Список користувачів',
    newEmployeeBtn: 'Новий працівник',
    employee: 'Співробітник',
    role: 'роль',
    plant: 'Завод',
    plantAccessUserRoles: 'Доступ до заводу та ролі користувачів',
    access: 'Доступ',
    noAccessPlaceholder: 'Немає доступу',
    deleteMembershipTitle: 'Видалити членство',
    deleteMembershipMessage: 'Ви впевнені, що хочете видалити членство?',
    noRoleSelectedMessage: 'Виберіть принаймні одну роль',
    noPermissionsMessage: 'У вас немає прав на зміну доступу',
    cannotUpdateYourPermissionsMsg: 'Неможливо оновити ваші дозволи',
    cannotDeleteInvitationMessage: 'Неможливо видалити запрошення',
    updatePermissionsMessage: 'Дозволи успішно оновлено',
    inviteNewMemberMessage: 'Учасника успішно запрошено',
    deleteInvitationMessage: 'Запрошення успішно видалено',
  },
  plantPermissionsTable: {
    permissionsText: 'Дозволи',
    needCustomPermissionText: 'Потрібен спеціальний дозвіл?',
    contactUs: "Зв'яжіться з нами",
    task: 'завдання',
    plantManager: 'Директор заводу',
    dispatch: 'Відправка',
    QA: 'QA',
    customer: 'Замовник',
    viewCalendar: 'Переглянути календар',
    enterOrders: 'Введіть замовлення',
    viewPlantSettings: 'Переглянути параметри заводу',
    changePlantSettings: 'Змінити налаштування заводу',
    viewReports: 'Переглянути звіти',
    inviteUsers: 'Запросіть користувачів',
    manageOrderPlanner: 'Керуйте планувальником замовлень',
    limited: 'Обмежений',
  },
  suppliersPage: {
    noSuppliersMsg: 'Постачальники не знайдені',
    cannotCreateSupplier: 'Не вдалося створити постачальника',
  },
  onBoarding: {
    createCompany: 'Створіть свою організацію',
    companyName: 'Назва компанії',
    addFirstPlant: 'Додайте свій перший завод',
    uploadPlantImage: 'Додайте фото заводу',
    addFirstOutputMaterial: 'Додайте свій перший вихідний матеріал',
    outputMaterialName: 'Назва вихідного матеріалу',
    outputMaterialInfo: 'Це назва матеріалу, який ви продаєте',
    materialSetup: 'Налаштування матеріалу',
    fileSizeBig: 'Завеликий розмір зображення. Змініть розмір або спробуйте інший файл.',
    error: 'Сталася помилка',
  },
  productionModePage: {
    composition: 'Композиція',
    hourlyRate: 'Погодинна ставка',
    defaultHourlyRate: 'Погодинна ставка за замовчуванням',
    addProductionMode: 'Додайте режим виробництва',
    updatedProductionModeMsg: 'Режим виробництва успішно оновлено',
    cannotUpdateProductionModeMsg: 'Неможливо оновити робочий режим',
    deleteProductionMode: 'Видалити режим виробництва',
    deleteProductionModeConfirmMsg: 'Ви впевнені, що хочете видалити цей режим виробництва?',
    deleteProductionModeSucceededMsg: 'Виробничий режим успішно видалено',
    deleteProductionModeFailedMsg: 'Неможливо видалити робочий режим',
    createProductionModeSucceededMsg: 'Виробничий режим успішно створено',
    createProductionModeFailedMsg: 'Неможливо створити робочий режим',
  },
  orderRequests: {
    reviewRequest: 'перегляд запиту',
    noRequests: 'На даний момент немає запитів на цей завод.',
  },
};
