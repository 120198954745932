import { Action } from './helper';
import { ComponentChild } from 'preact';

export enum ModalActionTypes {
  MODAL_OPEN = '@modals/MODAL_OPEN',
  MODAL_CLOSE = '@modals/MODAL_CLOSE',
}

export interface ModalProps {
  modal: ComponentChild;
}

export type OpenModalAction = Action<ModalActionTypes.MODAL_OPEN, ModalProps>;

export type CloseModalAction = Action<ModalActionTypes.MODAL_CLOSE, null>;

export type ModalActions = OpenModalAction | CloseModalAction;

export const openModal = (modal: ModalProps): OpenModalAction => ({
  type: ModalActionTypes.MODAL_OPEN,
  payload: modal,
});

export const closeModal = (): CloseModalAction => ({
  type: ModalActionTypes.MODAL_CLOSE,
  payload: null,
});
