import { h } from 'preact';
import styles from './alerts.scss';
import SingleAlert, { Alert } from './singleAlert';
import { removePageLevelAlert } from '../../redux/actions/pageLevelAlertActions';
import { useDispatch } from 'react-redux';

export interface AlertListProps {
  alerts: Alert[];
  onClick?: () => void;
}

const AlertList = ({ alerts }: AlertListProps) => {
  const dispatch = useDispatch();

  const handleRemovePageLevelAlert = (alert: Alert) => {
    dispatch(removePageLevelAlert(alert));
  };

  if (!alerts || !alerts?.length) return null;
  return (
    <div className={styles.alert__position}>
      {alerts.map((alert: Alert) => {
        return (
          <SingleAlert
            type={alert.type}
            message={alert.message}
            action={alert.action}
            onActionClick={() => {
              handleRemovePageLevelAlert(alert);
            }}
            onClose={() => {
              handleRemovePageLevelAlert(alert);
            }}
          />
        );
      })}
    </div>
  );
};

export default AlertList;
