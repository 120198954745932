import { matchPath } from 'react-router';
import { PlantId } from 'Types/plantTypes';

export function isMatchingRoute(route, exact = false, includeHash = false) {
  const path = includeHash
    ? window.location.pathname + window.location.hash
    : window.location.pathname;
  const routeMatch = route.includes(window.location.pathname + '?') ? path : route;
  return matchPath(path, { path: routeMatch, exact: exact }) !== null;
}

export function replacePlantIdInUrl(url, plantId) {
  return url.replace(':plantId', plantId);
}
export function replaceSpaceIdInUrl(url: string, spaceId: string) {
  return url.replace(':spaceId', spaceId);
}

export function replaceSpaceIdAndFieldIdInUrl(url: string, spaceId: string, fieldId: string) {
  const props = {
    ':spaceId': spaceId,
    ':fieldId': fieldId,
  };
  return url.replace(/:spaceId|:fieldId/gi, matched => props[`${matched}`]);
}

export function replaceSpaceIdAndConfigurationIdInUrl(
  url: string,
  spaceId: string,
  configurationId: string,
) {
  const props = {
    ':spaceId': spaceId,
    ':configurationId': configurationId,
  };
  return url.replace(/:spaceId|:configurationId/gi, matched => props[`${matched}`]);
}
export function replaceSpaceIdFieldNameAndFieldIdInUrl(
  url: string,
  spaceId: string,
  fieldName: string,
  primaryFieldId: string,
) {
  const props = {
    ':spaceId': spaceId,
    ':fieldName': fieldName,
    ':primaryFieldId': primaryFieldId,
  };
  return url.replace(/:spaceId|:fieldName|:primaryFieldId/gi, matched => props[`${matched}`]);
}

export function replacePlantIdAndOrderIdInUrl(url, plantId, orderId) {
  const props = {
    ':plantId': plantId,
    ':orderId': orderId,
  };

  return url.replace(/:plantId|:orderId/gi, matched => props[`${matched}`]);
}
export function replacePlantIdAndCustomerIdInUrl(
  url: string,
  plantId: PlantId,
  customerId: string,
) {
  const props = {
    ':plantId': plantId,
    ':customerId': customerId,
  };

  return url.replace(/:plantId|:customerId/gi, matched => props[`${matched}`]);
}

export function replacePlantIdAndMaterialIdInUrl(url: string, plantId: string, materialId: string) {
  const props = {
    ':plantId': plantId,
    ':materialId': materialId,
  };

  return url.replace(/:plantId|:materialId/gi, matched => props[`${matched}`]);
}

export function replacePlantIdAndMixDesignIdInUrl(
  url: string,
  plantId: string,
  mixDesignId: string,
) {
  const props = {
    ':plantId': plantId,
    ':mixDesignId': mixDesignId,
  };

  return url.replace(/:plantId|:mixDesignId/gi, matched => props[`${matched}`]);
}

export function replacePlantIdAndProductionModeIdInUrl(
  url: string,
  plantId: string,
  productionModeId: string,
) {
  const props = {
    ':plantId': plantId,
    ':productionModeId': productionModeId,
  };

  return url.replace(/:plantId|:productionModeId/gi, matched => props[`${matched}`]);
}
