export const subscribe = (eventName: string, listener: (...params: any | undefined) => void) => {
  document.addEventListener(eventName, listener);
};

export const unsubscribe = (eventName: string, listener: () => void) => {
  document.removeEventListener(eventName, listener);
};

export const publish = (eventName: string, data?: any) => {
  const event = new CustomEvent(eventName, { detail: data, cancelable: true, bubbles: true });
  document.dispatchEvent(event);
};
