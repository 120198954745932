import { h } from 'preact';
import moment, { Moment } from 'moment';
import parseISO from 'date-fns/parseISO';
import intlFormat from 'date-fns/intlFormat';
import { getDateFromIsoString, localizeDate } from './dateService';

/**
 * The preferred date format for internally representing dates.
 *
 *  YYYY-MM-DD
 */
export type DateStringISO = string; // format
export type DateStringISOWithoutTimeZone = string;

export interface DateInUTC extends Date {
  _utcDate: any;
}
export interface DateInLocalTimeZone extends Date {
  _localDateTime: any;
}

export interface DateInUnknownTimeZone extends Date {
  _unknownTimeZone: any;
}

/**
 * The old server format in us-date style
 *
 *  mm/dd/yyyy
 */
export type DateStringLegacy = string;

/**
 * ISO time string
 * For example '03:45'
 */
export type ISOTimeString = string;

export const DEFAULT_NIGHT_SHIFT_FROM_VALUE = '18:00';
export const DEFAULT_NIGHT_SHIFT_TO_VALUE = '03:45';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const LEGACY_DATE_FORMAT = 'MM/DD/YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const convertLegacyToIsoDate = date => {
  return moment(date, LEGACY_DATE_FORMAT).format(SERVER_DATE_FORMAT);
};

export const convertToDefaultTime = date => {
  return moment(date, SERVER_DATE_FORMAT).format(LEGACY_DATE_FORMAT);
};

export const toDefaultDateFormat = date => moment(date).format(LEGACY_DATE_FORMAT);

export const toDefaultTimeFormat = date => moment(date).format(DEFAULT_TIME_FORMAT);

export const toDefaultDateTimeFormat = (date: DateStringISO) => {
  const dateObj = getDateFromIsoString(date);
  const localizedDate = localizeDate(dateObj);
  const localizedTime = convertToLocalTimeZone(dateObj);

  return `${localizedDate} ${getLocalDateTime(localizedTime)}`;
};

export const roundUpTimeToNumber = (number: number, date?: Date) => {
  const momentDate = moment(date);
  const currentMinutes = moment(date).minutes();
  const minutesToAdd = number - (currentMinutes % number);

  if (currentMinutes % 5 !== 0) {
    momentDate.add(minutesToAdd, 'minutes');
  }

  return momentDate;
};

export const getDateFromISOTime = (time: ISOTimeString) => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(+hours, +minutes);
  return date;
};

export const getLocaleTimeFromDate = (date: Date) => {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const getLocaleStringFromISOTime = (time?: ISOTimeString | null) => {
  return time && getLocaleTimeFromDate(getDateFromISOTime(time));
};

export const getDateLocalTimeZone = (date: DateStringISOWithoutTimeZone): DateInUTC => {
  return new Date(date + 'T00:00:00') as DateInUTC;
};

export const getNowMoment = () => {
  return moment();
};

export const isISODate = (dateString: string): boolean => {
  const isoDateRegExp = new RegExp(/^\d{4}-([0][1-9]|1[0-2])-([0-2][1-9]|[1-3]0|3[01])$/);
  return isoDateRegExp.test(dateString);
};

export const isLegacyDate = (dateString: string): boolean => {
  const legacyDateRegExp = new RegExp(/^([0][1-9]|1[0-2])\/([0-2][1-9]|[1-3]0|3[01])\/(\d{4})$/);
  return legacyDateRegExp.test(dateString);
};

export const isValidDateISODate = (date: unknown) => {
  if (date && typeof date === 'string' && isISODate(date)) return true;
  return false;
};

export const getNowLegacyDateString = (): DateStringLegacy => {
  return moment().format(LEGACY_DATE_FORMAT);
};

export const getNowISODateString = (): DateStringLegacy => {
  return moment().format(SERVER_DATE_FORMAT);
};

export const getLocalMomentFromUTCMoment = (value: Moment | undefined) => {
  return moment(value, `${SERVER_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);
};

export const getMomentFromISOTime = (time: ISOTimeString) => {
  const [hours, minutes] = time.split(':');

  return moment().utc().hour(+hours).minute(+minutes);
};

export const buildLoadTime = (loadTime: ISOTimeString) => {
  return getMomentFromISOTime(loadTime).toISOString();
};

export function getUTCHourAndMinutes(date) {
  const hour = parseISO(date).getUTCHours();
  const minutes = parseISO(date).getMinutes();
  const minuteString = minutes > 9 ? minutes : '0' + minutes;
  return hour + ':' + minuteString;
}

export const getUTCDateTime = (date: DateInUTC): string => {
  return intlFormat(
    date,
    {
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'UTC',
    },
    {
      locale: window.navigator.language,
    },
  );
};

export const getLocalDateTime = (date: DateInLocalTimeZone) => {
  return intlFormat(
    date,
    {
      hour: 'numeric',
      minute: '2-digit',
    },
    {
      locale: window.navigator.language,
    },
  );
};

export const convertToUTCDateTime = (date: DateInUnknownTimeZone): DateInUTC => {
  const dateInUTC = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
  const convertedDate: DateInUTC = new Date(dateInUTC) as DateInUTC;
  return convertedDate;
};

export const convertToLocalTimeZone = (date: DateInUnknownTimeZone): DateInLocalTimeZone => {
  return new Date(date.getTime()) as DateInLocalTimeZone;
};
