import { ComponentChildren, h } from 'preact';
import CircularLoadingSpinner from 'Components/CircularLoadingSpinner';
import cls from 'classnames';
import styles from './buttons.scss';

export type ButtonBaseProps = {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary';
  color?: 'primary' | 'secondary' | 'warning' | 'accent' | 'alternative' | 'outline';
  size?: 'small' | 'medium' | 'large';
  label: string | ComponentChildren;
  disabled?: boolean;
  loading?: boolean;
  loadingSpinnerColor?: string;
  icon?: string;
  fullWidth?: boolean;
  className?: string;
  iconClassName?: string;
  'data-testid'?: string;
  'aria-label'?: string;
  accessibilityLabel?: string;
  onClick?: () => void;
};

type TextButtonProps = Omit<
  ButtonBaseProps,
  'label' | 'variant' | 'color' | 'size' | 'fullWidth'
> & {
  label?: string | ComponentChildren;
  variant: 'text';
  color?: never;
  size?: 'small';
  disabled?: boolean;
  fullWidth?: never;
};

const Button = ({
  type = 'button',
  variant = 'primary',
  color = 'primary',
  size = 'medium',
  label,
  className,
  iconClassName,
  icon,
  disabled = false,
  loading = false,
  loadingSpinnerColor,
  fullWidth,
  'data-testid': dataTestId = undefined,
  'aria-label': ariaLabel = undefined,
  accessibilityLabel,
  onClick,
  ...other
}: ButtonBaseProps | TextButtonProps) => {
  const internalDisabled = disabled || loading;

  const handleClick = () => {
    if (internalDisabled) return;

    onClick?.();
  };

  const classes = cls(className, {
    [styles.btn]: variant === 'primary',
    [styles.btnText]: variant === 'text',
    [styles['btn-primary']]: color === 'primary',
    [styles['btn-secondary']]: color === 'secondary',
    [styles['btn-accent']]: color === 'accent',
    [styles['btn-alternative']]: color === 'alternative',
    [styles['btn-warning']]: color === 'warning',
    [styles['btn-outline']]: color === 'outline',
    [styles['btn-fullWidth']]: fullWidth,
    [styles['btn-small']]: size === 'small',
    [styles['btn-medium']]: size === 'medium',
    [styles['btn-large']]: size === 'large',
    [styles['btn-hasIcon']]: !!icon,
  });

  return (
    <button
      type={type}
      className={classes}
      disabled={internalDisabled}
      onClick={handleClick}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      {...other}
    >
      {!!loading && (
        <span className={styles.loadingIconWrapper} data-testid="loading-spinner-wrapper">
          <CircularLoadingSpinner color={loadingSpinnerColor} />
        </span>
      )}
      {!!icon && (
        <img
          className={cls({ [styles.btnIcon]: label }, iconClassName)}
          src={icon}
          alt={accessibilityLabel}
        />
      )}
      {label && <span className={styles['btn-label']}>{label}</span>}
    </button>
  );
};

export default Button;
