import { SpaceActions, SpaceActionTypes } from 'Redux/actions/spaceActions';
import { Optional, RequestStatusType } from 'Types/commonTypes';
import { Space, SpacePermissions } from 'Types/spaceTypes';
import { sortList } from '../../helpers/array';

type SpaceState = {
  spaces: Space[];
  spacesPermissions: SpacePermissions[];
  lastActiveSpace: Optional<string>;
  fetchingSpacesStatus?: RequestStatusType;
  fetchingSpacesPermissionsStatus?: RequestStatusType;
};
const initialState: SpaceState = {
  spaces: [],
  lastActiveSpace: null,
  spacesPermissions: [],
};

const spaceReducer = (state = initialState, action: SpaceActions) => {
  switch (action.type) {
    case SpaceActionTypes.SET_SPACES:
      return {
        ...state,
        spaces: action.payload,
        fetchingSpacesStatus: RequestStatusType.Completed,
      };
    case SpaceActionTypes.SET_LAST_ACTIVE_SPACE:
      return {
        ...state,
        lastActiveSpace: action.payload,
      };

    case SpaceActionTypes.SET_SPACE_PERMISSIONS_REQUESTED:
      return { ...state, fetchingSpacesPermissionsStatus: RequestStatusType.InProgress };
    case SpaceActionTypes.SET_SPACE_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        spacesPermissions: sortList(action.payload, 'space_name'),
        fetchingSpacesPermissionsStatus: RequestStatusType.Completed,
      };
    case SpaceActionTypes.SET_SPACE_PERMISSIONS_FAILED:
      return {
        ...state,
        fetchingSpacesPermissionsStatus: RequestStatusType.Completed,
        fetchingSpacesPermissionsError: action.payload,
      };

    default:
      return state;
  }
};

export default spaceReducer;
