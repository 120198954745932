import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CustomerActionTypes,
  FetchMorePlantCustomersRequested,
  FetchPlantCustomersRequestedAction,
  listMorePlantCustomersFailed,
  listMorePlantCustomersSucceeded,
  listPlantCustomersFailed,
  listPlantCustomersSucceeded,
} from 'Redux/actions/customerActions';

import { getPlantCustomers } from 'Services/plantService';

export function* fetchPlantCustomers({ payload }: FetchPlantCustomersRequestedAction) {
  try {
    const customersData = yield call(getPlantCustomers, payload);
    yield put(listPlantCustomersSucceeded(customersData));
  } catch (error) {
    yield put(listPlantCustomersFailed(error));
  }
}

export function* fetchMorePlantCustomersSaga({ payload }: FetchMorePlantCustomersRequested) {
  try {
    const data = yield call(getPlantCustomers, payload);
    yield put(listMorePlantCustomersSucceeded(data));
  } catch (error) {
    yield put(listMorePlantCustomersFailed(error));
  }
}

export default function* watchCustomers() {
  yield takeLatest(CustomerActionTypes.FETCH_CUSTOMERS_REQUESTED, fetchPlantCustomers);
  yield takeLatest(CustomerActionTypes.FETCH_MORE_CUSTOMERS_REQUESTED, fetchMorePlantCustomersSaga);
}
