import { ComponentChild } from 'preact';
import { forwardRef } from 'preact/compat';
import { useDispatch } from 'react-redux';
import cls from 'classnames';

import Button from 'Components/buttons';
import StickyBar from 'Components/sticky-bar';

import { openMenu } from 'Redux/actions/menuActions';
import menuIcon from 'Assets/icons/mobile-menu.svg';
import styles from './navBarStyles.scss';

type NavBarProps = {
  children: ComponentChild;
  className?: string;
  onMobileMenuToggle?: () => void;
};

const NavBar = forwardRef<HTMLDivElement, NavBarProps>((props, ref) => {
  const { className, children, onMobileMenuToggle } = props;

  const dispatch = useDispatch();

  const toggleMobileMenu = () => {
    dispatch(openMenu());
    onMobileMenuToggle?.();
  };
  return (
    <StickyBar
      className={cls(styles.navBar, className)}
      stickyClassName={styles.stickyNavBar}
      options={{
        threshold: 1,
      }}
    >
      <div className="layoutContainer">
        <div className={styles.plantTabs} ref={ref}>
          <div className={styles['mobile-menu']}>
            <Button variant="text" icon={menuIcon} onClick={toggleMobileMenu} />
          </div>
          {children}
        </div>
      </div>
    </StickyBar>
  );
});

NavBar.displayName = 'NavBar';

export default NavBar;
