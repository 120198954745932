import { Business } from 'Types/businessType';
import { BusinessActions, BusinessActionTypes } from '../actions/businessActions';
import orderBy from 'lodash/orderBy';

const initialState = [] as Business[];

const businessReducer = (state = initialState, action: BusinessActions) => {
  switch (action.type) {
    case BusinessActionTypes.SET_BUSINESSES:
      const businesses = orderBy(action.payload, ['name']);

      const sortedBusinesses = businesses.map(business => ({
        ...business,
        members: orderBy(business.members, ['first_name', 'last_name']),
      }));

      return sortedBusinesses;
    default:
      return state;
  }
};

export default businessReducer;
