import { ComponentChildren } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import cls from 'classnames';
import styles from './tooltip.scss';
import Portal from 'Components/Portal';

const calculatePosition = ({
  elementRef,
  tooltipRef,
  placement,
  space = 15,
}: {
  elementRef: any;
  tooltipRef: any;
  placement: 'left' | 'right' | 'top' | 'bottom';
  space: number;
}) => {
  const elRect = elementRef.getBoundingClientRect();
  const position = { x: 0, y: 0 };

  switch (placement) {
    case 'left':
      position.x = elRect.left - (tooltipRef?.offsetWidth + space);
      position.y =
        elRect.top + (elementRef.offsetHeight - tooltipRef?.offsetHeight) / 2 + window.scrollY;
      break;
    case 'right':
      position.x = elRect.right + space;
      position.y = elRect.top + (elementRef.offsetHeight - tooltipRef?.offsetHeight) / 2;
      break;
    case 'top':
      position.x = elRect.left + (elementRef.offsetWidth - tooltipRef?.offsetWidth) / 2;
      position.y = elRect.top - (tooltipRef?.offsetHeight + space) + window.scrollY;
      break;
    case 'bottom':
      position.x = elRect.left + (elementRef.offsetWidth - tooltipRef?.offsetWidth) / 2;
      position.y = elRect.bottom + space;
      break;
    default:
      position.x = 0;
      position.y = 0;
  }

  return position;
};
export interface TooltipProps {
  open?: boolean;
  title: string | ComponentChildren;
  children: ComponentChildren;
  shouldRenderTitle?: boolean;
  className?: string;
  variant?: 'dark' | 'white' | 'warning';
  placement?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number;
  name?: string;
  active?: boolean;
  onShow?: () => void;
  onHide?: () => void;
}

const Tooltip = ({
  open = false,
  title,
  children,
  shouldRenderTitle = true,
  className,
  variant = 'dark',
  placement = 'top',
  delay,
  name,
  active = false,
  onShow,
  onHide,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(open);
  const [isActive, setIsActive] = useState(active);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const delayTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const deactivateTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const tooltipRef = useRef<HTMLDivElement>();
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldRenderTitle) {
      setIsVisible(false);
      clearTimeout(delayTimeoutRef.current as unknown as number);
    }
  }, [shouldRenderTitle]);

  useEffect(() => {
    if (!isActive) return;
    const newPosition = calculatePosition({
      elementRef: childRef.current,
      tooltipRef: tooltipRef.current,
      placement,
      space: 15,
    });

    setTooltipPosition(newPosition);
  }, [isActive, placement]);

  useEffect(() => {
    if (isActive) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isActive]);

  useEffect(() => {
    return () => {
      clearTimeout(delayTimeoutRef.current as unknown as number);
      clearTimeout(deactivateTimeoutRef.current as unknown as number);
    };
  }, []);

  const handleMouseEnter = e => {
    if (!shouldRenderTitle) return;

    if (delay) {
      delayTimeoutRef.current = setTimeout(function delay() {
        setIsActive(true);
        onShow?.();
      }, delay);
    } else {
      setIsActive(true);
      onShow?.();
    }
  };

  const handleMouseLeave = () => {
    if (!shouldRenderTitle) return;

    clearTimeout(delayTimeoutRef.current as unknown as number);
    setIsVisible(false);

    deactivateTimeoutRef.current = setTimeout(() => {
      setIsActive(false);
    }, 400);
    onHide?.();
  };

  const visibleState = isVisible ? styles.isVisible : styles.isHidden;
  const classes = cls(
    styles.tooltip,
    { [styles.tooltipWhite]: variant === 'white' },
    { [styles.tooltipWarning]: variant === 'warning' },
    visibleState,
    className,
  );

  const tooltipTitleClasses = cls(
    styles.tooltipTitle,
    { [styles.tooltipTitleTop]: placement === 'top' },
    { [styles.tooltipTitleBottom]: placement === 'bottom' },
    { [styles.tooltipTitleLeft]: placement === 'left' },
    { [styles.tooltipTitleRight]: placement === 'right' },
  );

  const testIdWrapperValue = name ? `${name}-tooltip-wrapper` : 'tooltip-wrapper';

  if (!shouldRenderTitle) return <>{children}</>;

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid={testIdWrapperValue}
        ref={childRef}
      >
        {children}
      </div>

      <Portal show={isActive}>
        <div className={classes}>
          <div
            className={tooltipTitleClasses}
            role="tooltip"
            style={{
              position: 'absolute',
              top: `${tooltipPosition.y}px`,
              left: `${tooltipPosition.x}px`,
              zIndex: 100000,
            }}
            ref={el => {
              if (el) tooltipRef.current = el;
            }}
          >
            {title}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default Tooltip;
