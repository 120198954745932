import { h } from 'preact';
import styles from './badge.scss';

type BadgeProps = {
  count: number;
};

const Badge = ({ count }: BadgeProps) => {
  if (!count) return null;
  return <div className={styles.badge}>{count}</div>;
};

export default Badge;
