import { en } from './en';
import merge from 'lodash/merge';

export const pavingLocale = {
  plant: 'Schedule',
  paving: 'Paving',
  customer: 'Project',
  project: 'Project',
  material: 'Crew/Equipment',
  entry: 'Entry',
  teamMember: 'Team Member',
  equipment: 'Equipment',
  space: 'Space',
  noProjectMsg: 'No projects found',
  noTeamMemberMsg: 'No team members found',
  noEquipmentMsg: 'No equipments found',
  teamMemberSelectFieldPlaceholder: 'Select Team Member',
  equipmentSelectFieldPlaceholder: 'Select Equipment',
  calendar: {
    addOrderText: 'Add Entry',
  },
  calendarWeek: {
    newOrder: 'New Entry',
    viewInputMaterial: 'View Input Crew/Equipment',
  },
  calendarFilterBar: {
    view: 'Entry View',
  },
  orderModal: {
    deleteOrder: 'Delete Entry',
    deleteOrderConfirmationMsg: 'This action will delete %{count} entry/entries. Are you sure?',
  },
  noteModal: {
    titlePlaceholder: 'Project',
  },
  entryModal: {
    createEntryErrorMsg: 'Could not create the entry',
    updateEntryErrorMsg: 'Could not update the entry',
  },
  orderForm: {
    order: 'Entry',
    quantity: 'Value',
    deliveryDates: 'Entry Days',
    deliveryDate: 'Entry Day',
    deliveryTime: 'Entry Time',
    copyOrderMsg:
      'We created a copy of entry #%{copyOrderId}. Select the new delivery date and press "Save"',
    changePlant: 'Change Space',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Input Crew/Equipment',
    noOrdersMessage:
      'There are no input materials because there are no entries scheduled on this date',
    addOrderLink: 'Click to add an entry.',
    noInputMaterials:
      '<span>This %{name} has no input Crew/Equipment. </span><a>Click here </a><span>to add input Crew/Equipment to %{name}</span>',
    noInputMaterialsForDay: 'There are no input Crew/Equipment available for this day.',
    clickToAddOrder: 'Click here to add a new entry',
  },
  activityList: {
    order: 'Entry',
  },
  ordersList: {
    material: 'Crew/Equipment',
    customer: 'Project',
  },
  plantHeader: {
    ...en.plantHeader,
    orders: 'Entries',
    newOrder: 'New Entry',
    plantSettings: 'Space Settings',
  },
  moveOrder: {
    selectOrdersHeader: 'Your selected entries',
    selectMaterial: 'Select Crew/Equipment',
    moveSelectedOrdersBtn: 'Move selected entries',
    copySelectedOrdersBtn: 'Copy selected entries',
    disableBtnMessage: 'Please select target Plant and Crew/Equipment',
    keepOriginalOrderLabel: 'Keep original entry',
    confirmPlantAndMaterial: 'Confirm Plant & Crew/Equipment',
    moveOrderFrom: 'Move entry from',
    moveOrderTo: 'Move entry to',
    deselectOrderMessage: 'The entry date has been deselected.',
    bubbleMsgSubHeader:
      'Match the input fields to ensure no information gets lost when you move your selected entries. Select at least one input field to move this order.',
  },
};
export const enPaving = merge({}, en, pavingLocale);
