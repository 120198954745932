import { I18n } from 'react-redux-i18n';
import camelCase from 'lodash/camelCase';
import orderBy from 'lodash/orderBy';

import {
  BuildSchemaFromOrderFieldsProps,
  QuantityDate,
  formModeType,
  MapFormDataToOrderProps,
  OrderExtraField,
  RequiredFieldsProps,
  ServerData,
  ServerDate,
} from 'Types/orderTypes';
import { OrderFieldsType, PlantId, PlantOrderField } from 'Types/plantTypes';
import {
  buildLoadTime,
  convertLegacyToIsoDate,
  DateStringISO,
  toDefaultDateFormat,
} from './timeService';
import { OrderRequestServerData } from 'Types/orderRequestTypes';
import { OrderRequestModalEntityType } from 'Components/modal/customModals/OrderRequestModal/OrderRequestModal';
import {
  CustomFieldProps,
  InputDropDownTypeProps,
  OrderModalCustomFieldsType,
  OrderModalSchema,
  RemoteFieldProps,
  StaticFieldProps,
} from 'Types/OrderModalTypes';
import { LabelPosition } from 'Components/fields/InputFieldWrapper';
import { Option } from 'Types/commonTypes';

export const getFieldName = (fieldName: string) => camelCase(fieldName);

const getDeletedFields = (
  plantOrderFields: PlantOrderField[] = [],
  orderExtraFields: OrderExtraField[] = [],
  disabled: boolean,
): CustomFieldProps[] => {
  const deletedOrderFields = orderExtraFields
    .filter(field => field.type !== OrderFieldsType.Hidden)
    .filter(orderField => {
      const plantOrderFieldIndex = plantOrderFields.findIndex(i => i.name === orderField.key);
      return plantOrderFieldIndex === -1;
    });

  const mappedFields: CustomFieldProps[] = deletedOrderFields.map(field => {
    const fieldName: string = getFieldName(field.key);

    const customField: CustomFieldProps = {
      key: fieldName,
      title: field.key,
      name: fieldName,
      type: field.type as OrderFieldsType,
      visibleToCustomer: !!field.visibleToCustomer,
      deleted: true,
      cols: 12,
      isSupported: !!field.type,
      isCustomField: true,
      disabled,
    };

    return customField;
  });

  return mappedFields;
};

export const buildOrderFields = (
  plantOrderFields: PlantOrderField[],
  orderExtraFields: OrderExtraField[],
  disabled: boolean,
): CustomFieldProps[] => {
  const customFields: CustomFieldProps[] = plantOrderFields
    .filter(field => field.name)
    .filter(field => field.field_type !== OrderFieldsType.Hidden)
    .filter(field => !field.is_required)
    .map(field => {
      const fieldName: string = getFieldName(field.name);

      const customField: CustomFieldProps = {
        key: fieldName,
        title: field.name,
        name: fieldName,
        type: field.field_type as OrderFieldsType,
        labelPosition: LabelPosition.left,
        visibleToCustomer: field.visible_to_customer,
        deleted: false,
        cols: 12,
        isSupported: !!field.field_type,
        isCustomField: true,
        disabled: disabled,
        readOnly: field.field_type === OrderFieldsType.ReadOnly,
      };

      return customField;
    });

  const deletedCustomFields = getDeletedFields(plantOrderFields, orderExtraFields, disabled);

  return [...customFields, ...deletedCustomFields];
};

const buildRequiredFields = ({
  plantMaterials,
  plantCustomers,
  plantOrderDateFields,
  desiredFutureQuantity,
  customersLoading,
  totalCustomersCount,
  selectedDate,
  formMode,
  isCustomerPlant,
  disabled,
}: RequiredFieldsProps) => {
  const customer: InputDropDownTypeProps = {
    key: 'customer',
    title: I18n.t('customer'),
    type: OrderModalCustomFieldsType.InputDropdown,
    labelPosition: LabelPosition.top,
    options: plantCustomers.map(customer => ({
      label: customer.name,
      value: customer.id,
      highlightColor: customer.highlight_color,
      extraFieldsData: customer.extra_fields_data.map(data => ({
        key: data.key,
        value: data.value,
        type: data.type,
      })),
      labelPrefix: customer.removed ? I18n.t('inactivePrefix') : null,
    })),
    placeholder: I18n.t('customer'),
    allowUnknownOption: true,
    renderWithTooltip: true,
    noOptionsMessage: I18n.t('customersPage.noCustomersMsg'),
    async: true,
    loadingOptions: customersLoading,
    totalOptionsCount: totalCustomersCount,
    cols: 6,
    disabled,
    customValidation: option => {
      if (!option?.value) return I18n.t('fieldIsRequired', { fieldName: 'Customer' });
    },
  };

  const material: InputDropDownTypeProps = {
    key: 'material',
    title: I18n.t('material'),
    type: OrderModalCustomFieldsType.InputDropdown,
    labelPosition: LabelPosition.top,
    options: plantMaterials,
    placeholder: I18n.t('dropdownChoosePlaceholder'),
    allowUnknownOption: false,
    noOptionsMessage: I18n.t('materialsPage.noMaterialsMsg'),
    cols: isCustomerPlant ? 12 : 6,
    disabled,
    customValidation: option => {
      if (!option?.value) return I18n.t('fieldIsRequired', { fieldName: 'Material' });

      const isOptionExist = plantMaterials.find(material => material.value == option.value);
      if (!isOptionExist) return I18n.t('fieldIsMissing', { fieldName: 'Material' });
    },
  };

  const dateQuantityPicker = {
    key: 'dates',
    title: I18n.t('dates'),
    type: OrderModalCustomFieldsType.DateQuantityPicker,
    selectedDate: selectedDate,
    extraFields: plantOrderDateFields,
    shouldHideDailyOrderFields:
      isCustomerPlant && !plantOrderDateFields.filter(field => field.visible_to_customer).length,
    formMode,
    calendarOpen: formMode === formModeType.COPY,
    showMoveFormBtn: formMode !== formModeType.CREATE,
    desiredFutureQuantity,
    cols: 12,
    disabled,
  };

  return {
    dateQuantityPicker: [dateQuantityPicker],
    requiredFields: isCustomerPlant ? [material] : [customer, material],
  };
};

export const buildCustomRequiredFields = (
  plantOrderFields: PlantOrderField[],
  disabled?: boolean,
) => {
  const customRequiredFields = plantOrderFields.filter(field => field.is_required);

  return customRequiredFields.map(field => {
    const fieldName: string = getFieldName(field.name);

    return {
      key: fieldName,
      title: field.name,
      name: fieldName,
      type: field.field_type,
      labelPosition: LabelPosition.top,
      visibleToCustomer: field.visible_to_customer,
      cols: 12,
      isSupported: !!field.field_type,
      isCustomField: true,
      disabled,
      deleted: false,
    } as CustomFieldProps;
  });
};

const buildRemoteFields = (isCustomerPlant?: boolean, disabled?: boolean): RemoteFieldProps[] => {
  const tentative: RemoteFieldProps = {
    key: 'tentative',
    title: I18n.t('calendar.tentative'),
    type: OrderFieldsType.Checkbox,
    remote: true,
    disabled,
  };
  const comment: RemoteFieldProps = {
    key: 'comment',
    title: I18n.t('comment'),
    type: OrderFieldsType.Text,
    remote: true,
    disabled,
  };
  const notifyTeam: RemoteFieldProps = {
    key: 'notifyTeam',
    title: I18n.t('calendar.notifyTeam'),
    type: OrderFieldsType.Checkbox,
    remote: true,
    disabled,
  };
  const urgentRequest: RemoteFieldProps = {
    key: 'urgentRequest',
    title: I18n.t('orderRequestModal.urgentRequest'),
    type: OrderFieldsType.Checkbox,
    remote: true,
    disabled,
  };

  const remoteFields = [tentative, comment, notifyTeam];
  const orderRequestRemoteFields = [urgentRequest, comment];

  return isCustomerPlant ? orderRequestRemoteFields : remoteFields;
};

export const buildStaticFields = ({
  plantName,
  formMode,
  isCustomerPlant,
  disabled,
}): StaticFieldProps[] => {
  const plantField: StaticFieldProps = {
    key: 'plant',
    title: I18n.t('plant'),
    type: OrderModalCustomFieldsType.PlantName,
    labelPosition: LabelPosition.top,
    cols: 12,
    hideChangePlantAction: formMode === formModeType.CREATE || isCustomerPlant,
    plantName,
    static: true,
    disabled,
  };

  return [plantField];
};

export const buildSchemaFromOrderFields = ({
  plantOrderFields = [],
  orderExtraFields = [],
  plantOrderDateFields = [],
  plantMaterials = [],
  plantCustomers = [],
  customersLoading,
  totalCustomersCount,
  selectedDate,
  formMode,
  formName,
  plantName,
  isCustomerPlant,
  disabled = false,
}: BuildSchemaFromOrderFieldsProps) => {
  const desiredFutureQuantityOrderField = orderExtraFields.find(
    field => field.key === 'DESIRED_FUTURE_QUANTITY',
  );
  const customFields = buildOrderFields(plantOrderFields, orderExtraFields, disabled);

  const { dateQuantityPicker, requiredFields: defaultRequiredFields } = buildRequiredFields({
    plantMaterials,
    plantCustomers,
    plantOrderDateFields,
    desiredFutureQuantity:
      desiredFutureQuantityOrderField && typeof desiredFutureQuantityOrderField.value === 'string'
        ? parseInt(desiredFutureQuantityOrderField.value)
        : undefined,
    customersLoading,
    totalCustomersCount,
    selectedDate,
    formMode,
    isCustomerPlant,
    disabled,
  });

  const customRequiredFields = buildCustomRequiredFields(plantOrderFields, disabled);
  const remoteFields = buildRemoteFields(isCustomerPlant, disabled);
  const staticFields = buildStaticFields({ plantName, formMode, isCustomerPlant, disabled });
  const requiredFields = [
    ...defaultRequiredFields,
    ...dateQuantityPicker,
    ...customRequiredFields,
  ].map(f => f.key);

  const schema: OrderModalSchema = {
    title: formName,
    required: requiredFields,
    properties: [
      ...staticFields,
      ...defaultRequiredFields,
      ...customRequiredFields,
      ...dateQuantityPicker,
      ...customFields,
      ...remoteFields,
    ],
  };

  return schema;
};

export const mapOrderToFormData = ({
  order,
  selectedDate,
  selectedMaterial,
  nightshift,
  formMode,
  orderDateFields,
}: {
  order: any;
  selectedDate?: DateStringISO;
  selectedMaterial?: { label: string; value: string };
  nightshift?: boolean;
  formMode: formModeType;
  orderDateFields: PlantOrderField[];
}) => {
  if (formMode === formModeType.CREATE) {
    const initialState = [
      {
        quantity: 0,
        deliveryOn: selectedDate,
        loadTime: undefined,
        selected: false,
        nightshift,
        extraFields: orderDateFields.map(orderDateField => {
          return {
            key: orderDateField.name,
            value: null,
            type: orderDateField.field_type,
            visibleToCustomer: orderDateField.visible_to_customer,
          };
        }),
      },
    ];
    return { material: selectedMaterial, dates: initialState };
  }

  const { customer, material, dates, extraFields, ...rest } = order;

  const mapOrderDateFieldsToExtraFieldsData = (
    orderDateFields: PlantOrderField[],
    extraFieldsData: OrderExtraField[] = [],
  ): OrderExtraField[] => {
    const result = orderDateFields.map(dateField => {
      const field = extraFieldsData.find(fieldData => fieldData.key === dateField.name);
      let value: string | boolean | null = null;

      if (field) {
        value = field.type === OrderFieldsType.Checkbox ? Boolean(field.value) : field.value;
      }

      return {
        key: dateField.name,
        value,
        type: dateField.field_type,
        visibleToCustomer: dateField.visible_to_customer,
      };
    });

    return result;
  };

  const orderDates = dates.map(date => ({
    ...date,
    quantity: date.quantity + '',
    extraFields: mapOrderDateFieldsToExtraFieldsData(orderDateFields, date.extraFields),
  }));

  const sortedDates = orderBy(orderDates, ['deliveryOn', 'loadTime']);

  const orderExtraFields = extraFields.reduce((acc, el) => {
    const fieldName = getFieldName(el.key);
    if (el.type === OrderFieldsType.Date && el.value) {
      acc[fieldName] = convertLegacyToIsoDate(el.value);
    } else {
      acc[fieldName] = el.value ? el.value : null;
    }

    return acc;
  }, {});

  return {
    ...rest,
    customer: {
      value: customer.id,
      label: customer.name,
      labelPrefix: customer.removed ? I18n.t('inactivePrefix') : null,
      extraFieldsData: customer.extraFields,
    },
    material: {
      value: +material.id,
      label: material.removed
        ? `${I18n.t('deletedPrefix')} ${material.name}`
        : !material.active
        ? `${I18n.t('inactivePrefix')} ${material.name}`
        : material.name,
    },
    dates: sortedDates,
    ...orderExtraFields,
  };
};

export const mapFormDataToOrder = ({
  formData,
  orderId,
  orderFields = [],
  formMode,
}: MapFormDataToOrderProps) => {
  const orderExtraFielsdData = orderFields.map(field => {
    const key: string = getFieldName(field.name);
    const fieldValue: string =
      field.field_type === OrderFieldsType.Date && formData[key]
        ? toDefaultDateFormat(formData[key])
        : formData[key];
    return {
      key: field.name,
      value: fieldValue,
      type: field.field_type,
      visible_to_customer: field.visible_to_customer,
    };
  });

  const dates = formData.dates.map((date: QuantityDate) => {
    const dailyOrderFields = date.extraFields || [];
    const newDate: ServerDate = {
      quantity: date.quantity,
      delivery_on: toDefaultDateFormat(date.deliveryOn),
      load_time: date.loadTime ? buildLoadTime(date.loadTime) : null,
      nightshift: date.nightshift,
      extra_fields_data: dailyOrderFields.map(dateExtraField => ({
        key: dateExtraField.key,
        value:
          dateExtraField.type === OrderFieldsType.Date && dateExtraField.value
            ? toDefaultDateFormat(dateExtraField.value)
            : dateExtraField.value,
        type: dateExtraField.type,
        visible_to_customer: dateExtraField.visibleToCustomer,
      })),
    };

    if (formMode === formModeType.EDIT) {
      newDate.id = date.id;
    }
    return newDate;
  });

  const result: ServerData = {
    customer: formData.customer.label,
    dates,
    extra_fields_data: orderExtraFielsdData,
    material: formData.material.value,
    tentative: formData.tentative || false,
    notify_team: formData.notifyTeam || false,
  };

  if (formMode === formModeType.EDIT) {
    result.id = orderId;
    result.comment = formData.comment;
  }

  return result;
};

export const mapFormDataToOrderRequest = ({
  formData,
  entityId,
  customer,
  plantOrderFields = [] as PlantOrderField[],
  userId,
  formMode,
  formType,
}) => {
  const extraFielsdData = plantOrderFields.map(field => {
    const key: string = getFieldName(field.name);
    const fieldValue: string = formData[key];
    return {
      key: field.name,
      value: fieldValue,
      type: field.field_type,
      visible_to_customer: field.visible_to_customer,
    };
  });

  const dates = formData.dates.map((date: QuantityDate) => {
    const dailyOrderFields = date.extraFields || [];
    const newDate: ServerDate = {
      quantity: date.quantity,
      delivery_on: toDefaultDateFormat(date.deliveryOn),
      load_time: date.loadTime ? buildLoadTime(date.loadTime) : null,
      nightshift: date.nightshift,
      extra_fields_data: dailyOrderFields
        .filter(field => field.value)
        .map(dateExtraField => ({
          key: dateExtraField.key,
          value: dateExtraField.value,
          type: dateExtraField.type,
          visible_to_customer: dateExtraField.visibleToCustomer,
        })),
    };

    if (formMode === formModeType.EDIT && formType === OrderRequestModalEntityType.OrderRequest) {
      newDate.id = date.id;
    }
    return newDate;
  });

  const result: OrderRequestServerData = {
    customer,
    dates,
    extra_fields_data: extraFielsdData,
    material: formData.material.value,
    created_by: userId,
    urgent: formData.urgentRequest || false,
  };

  if (formMode === formModeType.EDIT) {
    if (formType === OrderRequestModalEntityType.ConfirmedOrder) {
      result.order = entityId;
    }

    result.comment = formData.comment;
  }

  return result;
};

export const mapFormDataToApproveRejectOrderRequest = ({
  formData,
  entityId,
  customer,
  plantOrderFields = [] as PlantOrderField[],
  userId,
  formMode,
  formType,
}) => {
  const extraFielsdData = plantOrderFields.map(field => {
    const key: string = getFieldName(field.name);
    const fieldValue: string = formData[key];
    return {
      key: field.name,
      value: fieldValue,
      type: field.field_type,
      visible_to_customer: field.visible_to_customer,
    };
  });

  const dates = formData.dates.map((date: QuantityDate) => {
    const orderRequestDateExtraFields = date.extraFields || [];
    const newDate: ServerDate = {
      id: date.id,
      quantity: date.quantity,
      delivery_on: toDefaultDateFormat(date.deliveryOn),
      load_time: date.loadTime ? buildLoadTime(date.loadTime) : null,
      nightshift: date.nightshift,
      extra_fields_data: orderRequestDateExtraFields
        .filter(field => field.value)
        .map(dateExtraField => ({
          key: dateExtraField.key,
          value: dateExtraField.value,
          type: dateExtraField.type,
          visible_to_customer: dateExtraField.visibleToCustomer,
        })),
    };

    if (formMode === formModeType.EDIT && formType === OrderRequestModalEntityType.OrderRequest) {
      newDate.id = date.id;
    }
    return newDate;
  });

  const result: OrderRequestServerData = {
    customer,
    dates,
    extra_fields_data: extraFielsdData,
    material: formData.material.value,
    created_by: userId,
    urgent: formData.urgentRequest || false,
  };

  if (formMode === formModeType.EDIT) {
    if (formType === OrderRequestModalEntityType.ConfirmedOrder) {
      result.order = entityId;
    }

    result.comment = formData.comment;
  }

  return result;
};
