import {
  h,
  cloneElement,
  toChildArray,
  isValidElement,
  ComponentChildren,
  ComponentChild,
} from 'preact';
import styles from './dropDownMenuStyles.scss';
import { I18n } from 'react-redux-i18n';
import { Option } from 'Types/commonTypes';
import DropDownMenuLoader from 'Components/loaders/DropDownMenuLoader';

export interface DropDownMenuProps {
  isOpen: boolean;
  selectedOption?: Option | null;
  highlightedOptionIndex?: number;
  footerContent?: ComponentChildren;
  dropdownMenuProps?: {
    root: Record<string, any>;
    showAllOptionsProps: Record<string, any>;
  };
  onOptionSelect?: (option: any) => void;
  setShowAllOptions?: (option: any) => void;
  showAllOptions?: boolean;
  loading?: boolean;
  noOptionsMessage?: string;
  children: ComponentChild;
}

const DropDownMenu = ({
  isOpen,
  selectedOption,
  highlightedOptionIndex,
  footerContent,
  dropdownMenuProps,
  children,
  loading,
  noOptionsMessage = I18n.t('noOptions'),
}: DropDownMenuProps) => {
  const noOptionsText = <p className={styles.noOptionsMessage}>{noOptionsMessage}</p>;
  const menuFooter = <div class={styles.dropdownMenuButton}>{footerContent}</div>;

  const isOptionHighlighted = (optionIndex: number): boolean => {
    return highlightedOptionIndex != undefined && highlightedOptionIndex >= 0
      ? highlightedOptionIndex === optionIndex
      : false;
  };

  if (!isOpen) return null;

  const renderChildren = () => {
    return toChildArray(children).map(child => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          isHighlighted: isOptionHighlighted((child.props as any).index),
          isActive: selectedOption?.value === (child.props as any).option?.value,
        });
      }
      return child;
    });
  };

  return (
    <div className={styles.dropdownContainer} {...dropdownMenuProps?.root}>
      <div
        id="dropdownMenu"
        className={!!footerContent ? styles.dropdownMenuWithFooter : styles.dropdownMenu}
        tabIndex={-1}
        data-testid="dropdown-menu"
      >
        {loading ? <DropDownMenuLoader /> : renderChildren()}
        {!dropdownMenuProps?.showAllOptionsProps.showAllOptions && !loading && (
          <label
            className={styles.dropdownShowAllLink}
            onClick={dropdownMenuProps?.showAllOptionsProps.onClick}
          >
            {I18n.t('showAllOptions', { count: dropdownMenuProps?.showAllOptionsProps.totalCount })}
          </label>
        )}
        {!loading && !renderChildren().length && noOptionsText}
      </div>
      {!!footerContent && menuFooter}
    </div>
  );
};

export default DropDownMenu;
