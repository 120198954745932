import { h } from 'preact';
import { useState } from 'preact/hooks';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import SideMenuLink from './sideMenuLink';
import PlantDropdownField from '../fields/plantDropdownField';
import { ProfileButton } from './profileButton';

import { isMatchingRoute, replacePlantIdInUrl } from '../../helpers/url';

import { Plant, PlantId } from 'Types/plantTypes';
import routeMap from '../../route-maps/route-map';

import logo from 'Assets/img/logo.png';
import homeIcon from 'Assets/icons/home/default.svg';
import activeHomeIcon from 'Assets/icons/home/active.svg';
import hoverHomeIcon from 'Assets/icons/home/hover.svg';
import plantsIcon from 'Assets/icons/plant/default.svg';
import activePlantsIcon from 'Assets/icons/plant/active.svg';
import hoverPlantsIcon from 'Assets/icons/plant/hover.svg';
import reportsIcon from 'Assets/icons/daily-report/default.svg';
import activeReportsIcon from 'Assets/icons/daily-report/active.svg';
import hoverReportsIcon from 'Assets/icons/daily-report/hover.svg';
import accountIcon from 'Assets/icons/account/default.svg';
import activeAccountIcon from 'Assets/icons/account/active.svg';
import hoverAccountIcon from 'Assets/icons/account/hover.svg';
import calendarIcon from 'Assets/icons/calendar/default.svg';
import calendarActiveIcon from 'Assets/icons/calendar/active.svg';
import calendarHoverIcon from 'Assets/icons/calendar/hover.svg';
import closeIcon from 'Assets/icons/close.svg';
import settingsIcon from 'Assets/icons/settings.svg';

import styles from './side-menu.scss';
import { Optional } from 'Types/commonTypes';

export interface SideMenuProps {
  plants: Plant[];
  customerPortals: Plant[];
  selectedPlant: Optional<PlantId>;
  selectedSpace: Optional<PlantId>;
  opened: boolean;
  plantRoute?: string;
  isUsingNavLinks?: boolean;
  isSidebarVisible?: boolean;
  isProfileOpen?: boolean;
  shouldRenderSpaceScheduleTab: boolean;
  onClose: () => void;
}

const SideMenu = ({
  opened,
  plants,
  customerPortals,
  selectedPlant,
  selectedSpace,
  plantRoute,
  isUsingNavLinks,
  shouldRenderSpaceScheduleTab,
  onClose,
  isSidebarVisible,
}: SideMenuProps) => {
  const [, forceRerender] = useState<number>(0);
  const plantSettingsUrl = replacePlantIdInUrl(routeMap.settings, selectedPlant);

  const classnames = opened ? styles.sideMenuExpanded : styles.sideMenu;

  const onSideMenuClick = () => {
    //this will force the component to rerender and update the active item in the sidebar
    forceRerender(prevState => prevState + 1);

    // only close side menu if using react router, do not close menu if using hrefs
    if (isUsingNavLinks) {
      onClose();
    }
  };

  const selectedEntity = selectedPlant || selectedSpace;

  if (!isSidebarVisible || !selectedEntity) return null;

  return (
    <div className={classnames} data-testid="component-side-menu">
      {opened && (
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.close} onClick={onClose}>
              <img src={closeIcon} alt={I18n.t('sideMenu.close')} />
            </div>
            <div className={styles.logoWrapper}>
              <img src={logo} className={styles.logo} alt={I18n.t('sideMenu.plantdemandLogo')} />
            </div>
            <div className={styles.plantSettings} onClick={onClose}>
              <Link to={plantSettingsUrl}>
                <strong>{I18n.t('plantHeader.plantSettings')}</strong>
                <img
                  src={settingsIcon}
                  className={styles.settingsIcon}
                  alt={I18n.t('plantHeader.cogIcon')}
                />
              </Link>
            </div>
          </div>
          <div className={styles.plant}>
            <PlantDropdownField
              entities={[
                {
                  entryType: 'plant',
                  fields: plants.map(option => ({
                    label: option.name,
                    value: option.id,
                    icon: option.picture,
                    link: plantRoute,
                  })),
                },
                {
                  entryType: 'customerPortal',
                  fields: customerPortals.map(cp => ({
                    label: cp.name,
                    value: cp.id,
                    icon: cp.picture,
                    link: plantRoute,
                  })),
                },
              ]}
              selected={selectedEntity}
              isUsingNavLinks={isUsingNavLinks}
              onChange={onClose}
            />
          </div>
        </div>
      )}

      <ul className={styles.navLinks}>
        <li className={styles.navLinkItem} onClick={onSideMenuClick}>
          <SideMenuLink
            testId="menu-home"
            path={routeMap.dashboardPlants}
            exact
            icon={homeIcon}
            activeIcon={activeHomeIcon}
            hoverIcon={hoverHomeIcon}
            label={I18n.t('dashboard')}
            active={
              isMatchingRoute(routeMap.dashboardPlants) || isMatchingRoute(routeMap.dashboard)
            }
            isUsingNavLinks={isUsingNavLinks}
          />
        </li>
        <li className={styles.navLinkItem} onClick={onSideMenuClick}>
          <SideMenuLink
            testId="menu-plants"
            path={selectedPlant ? replacePlantIdInUrl(routeMap.plantCalendar, selectedPlant) : '#'}
            icon={plantsIcon}
            activeIcon={activePlantsIcon}
            hoverIcon={hoverPlantsIcon}
            label={I18n.t('schedule')}
            active={
              isMatchingRoute(routeMap.plantCalendar) || isMatchingRoute(routeMap.singlePlant)
            }
            isUsingNavLinks={isUsingNavLinks}
          />
        </li>
        <li className={styles.navLinkItem} onClick={onSideMenuClick}>
          <SideMenuLink
            testId="menu-reports"
            path={routeMap.reports}
            icon={reportsIcon}
            activeIcon={activeReportsIcon}
            hoverIcon={hoverReportsIcon}
            label={I18n.t('reports')}
            active={isMatchingRoute(routeMap.reports)}
            isUsingNavLinks={isUsingNavLinks}
          />
        </li>
        <li className={styles.navLinkItem} onClick={onSideMenuClick}>
          <SideMenuLink
            testId="menu-account"
            path={routeMap.account}
            icon={accountIcon}
            activeIcon={activeAccountIcon}
            hoverIcon={hoverAccountIcon}
            label={I18n.t('account')}
            active={isMatchingRoute(routeMap.account)}
            isUsingNavLinks={isUsingNavLinks}
          />
        </li>
        {shouldRenderSpaceScheduleTab && selectedSpace && (
          <li className={styles.navLinkItem} onClick={onSideMenuClick}>
            <SideMenuLink
              testId="menu-team/equipment"
              path={routeMap.space.replace(':spaceId', selectedSpace)}
              icon={calendarIcon}
              activeIcon={calendarActiveIcon}
              hoverIcon={calendarHoverIcon}
              label="Space Schedule"
              active={isMatchingRoute(routeMap.space)}
              isUsingNavLinks={isUsingNavLinks}
            />
          </li>
        )}
      </ul>
      <ProfileButton opened={opened} onClick={onSideMenuClick} />
    </div>
  );
};

export default SideMenu;
