import { forwardRef } from 'preact/compat';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';
import RoundPlantIcon, { IconSize } from 'Components/plantPicture/roundPlantIcon';
import { DashboardInfo } from 'Services/dashboardService';
import { Plant } from 'Types/plantTypes';
import { Space } from 'Types/spaceTypes';
import plantImgPlaceholder from 'Assets/img/plant-placeholder.png';
import routeMap from '../../../route-maps';
import styles from './dashboardCardStyles.scss';

type DashboardCardProps = {
  entity: Plant | Space;
  plantData?: DashboardInfo;
  linkTo?: string;
  loading?: boolean;
  onClick?: (spaceId: string) => void;
};

const DashboardCard = forwardRef<HTMLDivElement, DashboardCardProps>((props, ref) => {
  const {
    entity,
    plantData = {} as DashboardInfo,
    linkTo = routeMap.plantCalendar.replace(/:plantId/, entity.id),
    loading,
    onClick,
  } = props;

  const bodyColClasses = cls('grid-col-2', styles.bodyCol, {
    [styles.bodyColLoadingState]: loading,
  });
  const headerClasses = cls('grid-col-2', styles.bodyColHeader);

  return (
    <div
      className={styles.dashboardCardWrapper}
      data-testid="dashboard-card"
      data-plantId={entity.id}
      ref={ref}
    >
      <div className={styles.dashboardCardHeader}>
        <div onClick={() => onClick?.(entity.id)}>
          <Link to={linkTo} className={styles.plantName}>
            {entity.name}
          </Link>
        </div>
        <RoundPlantIcon
          size={IconSize.extraLarge}
          src={entity?.picture || plantImgPlaceholder}
          className={styles.plantImg}
        />
      </div>
      <div className={styles.dashboardCardBody}>
        <div className={styles.bodyRow}>
          <div className="grid-col-6"></div>
          <div className={headerClasses}>{I18n.t('day')}</div>
          <div className={headerClasses}>{I18n.t('night')}</div>
          <div className={headerClasses}>{I18n.t('all')}</div>
        </div>
        <div className={styles.bodyRow}>
          <div className="grid-col-6">{I18n.t('orders')}</div>
          <div className={bodyColClasses}>{plantData.day_order_count || '-'}</div>
          <div className={bodyColClasses}>{plantData.night_order_count || '-'}</div>
          <div className={bodyColClasses}>{plantData.total_order_count || '-'}</div>
        </div>
        <div className={styles.bodyRow}>
          <div className="grid-col-6">{I18n.t('materials')}</div>
          <div className={bodyColClasses}>{plantData.day_materials_count || '-'}</div>
          <div className={bodyColClasses}>{plantData.night_materials_count || '-'}</div>
          <div className={bodyColClasses}>{plantData.total_materials_count || '-'}</div>
        </div>
        <div className={styles.bodyRow}>
          <div className="grid-col-6">{I18n.t('dashboardPage.totalQuantity')}</div>
          <div className={bodyColClasses}>{plantData.day_quantity || '-'}</div>
          <div className={bodyColClasses}>{plantData.night_quantity || '-'}</div>
          <div className={bodyColClasses}>{plantData.total_quantity || '-'}</div>
        </div>
      </div>
      <div className={styles.dashboardCardFooter}>
        <div onClick={() => onClick?.(entity.id)}>
          <Link className={styles.goToPlantLink} to={linkTo}>
            {I18n.t('dashboardPage.goToPlant')}
          </Link>
        </div>
      </div>
    </div>
  );
});

DashboardCard.displayName = 'DashboardCard';
export default DashboardCard;
