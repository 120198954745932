import { Space, SpacePermissions } from 'Types/spaceTypes';
import { Action } from './helper';

export enum SpaceActionTypes {
  SET_SPACES = '@spaces/SET_SPACES',
  SET_LAST_ACTIVE_SPACE = '@space/SET_LAST_ACTIVE_SPACE',
  SET_SPACE_PERMISSIONS_REQUESTED = '@space/SET_SPACE_PERMISSIONS_REQUESTED',
  SET_SPACE_PERMISSIONS_SUCCEEDED = '@space/SET_SPACE_PERMISSIONS_SUCCEEDED',
  SET_SPACE_PERMISSIONS_FAILED = '@space/SET_SPACE_PERMISSIONS_FAILED',
}

export type SetSpacesPermissionsAction = Action<
  SpaceActionTypes.SET_SPACE_PERMISSIONS_REQUESTED,
  SpacePermissions[]
>;

export type SetSpacesPermissionsActionSucceeded = Action<
  SpaceActionTypes.SET_SPACE_PERMISSIONS_SUCCEEDED,
  SpacePermissions[]
>;

export type SetSpacesPermissionsActionFailed = Action<
  SpaceActionTypes.SET_SPACE_PERMISSIONS_FAILED,
  unknown
>;

export const setSpaces = (spaces: Space[]): SetSpaceAction => ({
  type: SpaceActionTypes.SET_SPACES,
  payload: spaces,
});

export const setLastActiveSpace = (spaceId: string): SetLastActiveSpaceAction => ({
  type: SpaceActionTypes.SET_LAST_ACTIVE_SPACE,
  payload: spaceId,
});

export const setSpacesPermissions = (
  spacesPermissions: SpacePermissions[],
): SetSpacesPermissionsAction => ({
  type: SpaceActionTypes.SET_SPACE_PERMISSIONS_REQUESTED,
  payload: spacesPermissions,
});

export const setSpacesPermissionsSucceeded = (
  plantsPermissions: SpacePermissions[],
): SetSpacesPermissionsActionSucceeded => ({
  type: SpaceActionTypes.SET_SPACE_PERMISSIONS_SUCCEEDED,
  payload: plantsPermissions,
});

export const setSpacesPermissionsFailed = (error: unknown): SetSpacesPermissionsActionFailed => ({
  type: SpaceActionTypes.SET_SPACE_PERMISSIONS_FAILED,
  payload: error,
});

export type SetSpaceAction = Action<SpaceActionTypes.SET_SPACES, Space[]>;
export type SetLastActiveSpaceAction = Action<SpaceActionTypes.SET_LAST_ACTIVE_SPACE, string>;

export type SpaceActions =
  | SetSpaceAction
  | SetLastActiveSpaceAction
  | SetSpacesPermissionsAction
  | SetSpacesPermissionsActionSucceeded
  | SetSpacesPermissionsActionFailed;
