import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import PlantDemandAxios from './plantDemandAxios';
import apiMap from './utils/apiMap/apiMap';
import { GraphQLError, GraphQLErrorData, GraphQLValidationError } from './errors/GraphQLError';
import { isAxiosError } from 'Errors/utils';
export const GET = 'get';
export const POST = 'post';
export const PATCH = 'patch';

export const makeRequest =
  (action: string, url: string, params?: Record<string, any>) => async (data?: any) => {
    return PlantDemandAxios[action](url, data, params);
  };

interface GraphQLQuery {
  query: string;
  variables?: Record<string, any>;
}

export const makeGraphQLRequest = async (
  query: GraphQLQuery,
  key?: string,
  options?: AxiosRequestConfig,
) => {
  const _options = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response: AxiosResponse = await PlantDemandAxios.post(apiMap.graphql, query, _options);

    validateGraphQLResponse(response, key);

    return response.data.data;
  } catch (error) {
    // handle invalid query
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError<any, any>;
      const errors = axiosError.response?.data.errors || [];
      const errorMsgs = errors.map(err => err.message);
      const errorMsg = errorMsgs.join('\n');
      throw new GraphQLError(errorMsg);
    }

    // rethrow unknown errors
    throw error;
  }
};

const validateGraphQLResponse = (response: AxiosResponse, key?: string) => {
  if (!key) return;

  if (response.data.errors) {
    if (Array.isArray(response.data.errors)) {
      const errorMessage = response.data.errors[0].message || 'Something went wrong';
      throw new GraphQLError(errorMessage);
    }

    throw new GraphQLError('Something went wrong');
  }

  const errorsData = response.data.data[key];
  if (!errorsData) return;

  const fieldErrors: GraphQLErrorData[] = errorsData.fieldErrors;
  const errorType = errorsData.__typename;

  if (errorType === 'ValidationError') {
    const messages = fieldErrors.map(
      fieldError => `${fieldError.fieldName}: ${fieldError.errorDescription}`,
    );
    const message = messages.join('\n');
    throw new GraphQLValidationError(message, fieldErrors);
  }
};
