import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GetOrderFieldStylesAction,
  ListOrderDateFieldsAction,
  ListOrderFieldsAction,
  ListPlantMaterialsActionRequested,
  PlantActionTypes,
  SetLastActivePlantAction,
  SetOrderFieldStylesAction,
  SetPlantsPermissionsAction,
} from 'Redux/actions/actionTypes/plantActionTypes';
import { CustomFieldStyle, Material, PlantOrderField } from 'Types/plantTypes';

import {
  listOrderFields as listOrderFieldsService,
  listOrderDateFields as listOrderDateFieldsService,
  setActivePlant,
  getPlantMaterials,
  getCustomFieldStyles,
  setCustomFieldStyles,
} from 'Services/plantService';
import {
  listOrderFieldsSucceeded,
  listOrderFieldsFailed,
  setPlantsPermissionsSucceeded,
  setPlantsPermissionsFailed,
  listMaterialsSucceeded,
  listMaterialsFailed,
  listOrderDateFieldsFailed,
  listOrderDateFieldsSucceeded,
  getOrderFieldStylesSucceeded,
  getOrderFieldStylesFailed,
  setOrderFieldStylesSucceeded,
  setOrderFieldStylesFailed,
} from 'Redux/actions/plantActions';

export function* fetchOrderFields({ payload }: ListOrderFieldsAction) {
  try {
    const orderFields: PlantOrderField[] = yield call(
      listOrderFieldsService,
      payload.plantId,
      payload.excludeHiddenFields,
    );
    yield put(listOrderFieldsSucceeded(orderFields));
  } catch (error) {
    yield put(listOrderFieldsFailed(error));
  }
}

export function* fetchOrderDateFields({ payload }: ListOrderDateFieldsAction) {
  try {
    const orderDateFields: PlantOrderField[] = yield call(listOrderDateFieldsService, payload);
    yield put(listOrderDateFieldsSucceeded(orderDateFields));
  } catch (error) {
    yield put(listOrderDateFieldsFailed(error));
  }
}

export function* fetchPlantPermissions({ payload }: SetPlantsPermissionsAction) {
  try {
    yield put(setPlantsPermissionsSucceeded(payload));
  } catch (error) {
    yield put(setPlantsPermissionsFailed(error));
  }
}

export function* fetchPlantMaterials({ payload }: ListPlantMaterialsActionRequested) {
  try {
    const materials: Material[] = yield call(getPlantMaterials, payload);
    yield put(listMaterialsSucceeded(materials));
  } catch (error) {
    yield put(listMaterialsFailed(error));
  }
}

export function* saveLastActivePlant({ payload }: SetLastActivePlantAction) {
  yield call(setActivePlant, payload);
}

export function* fetchOrderFieldStyles({ payload }: GetOrderFieldStylesAction) {
  try {
    const orderFieldStyles: CustomFieldStyle[] = yield call(getCustomFieldStyles, payload);
    yield put(getOrderFieldStylesSucceeded(orderFieldStyles));
  } catch (error) {
    yield put(getOrderFieldStylesFailed(error));
  }
}

export function* saveOrderFieldStyles({ payload }: SetOrderFieldStylesAction) {
  try {
    const orderFieldStyles: CustomFieldStyle[] = yield call(setCustomFieldStyles, payload);
    yield put(setOrderFieldStylesSucceeded(orderFieldStyles));
  } catch (error) {
    yield put(setOrderFieldStylesFailed(error));
  }
}

export default function* watchPlants() {
  yield takeLatest(PlantActionTypes.LIST_PLANT_ORDER_FIELDS_REQUESTED, fetchOrderFields);
  yield takeLatest(PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED, fetchOrderDateFields);
  yield takeLatest(PlantActionTypes.SET_PLANT_PERMISSIONS_REQUESTED, fetchPlantPermissions);
  yield takeLatest(PlantActionTypes.LIST_PLANT_MATERIALS_REQUESTED, fetchPlantMaterials);
  yield takeLatest(PlantActionTypes.SET_LAST_ACTIVE_PLANT, saveLastActivePlant);
  yield takeLatest(PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_REQUESTED, fetchOrderFieldStyles);
  yield takeLatest(PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_REQUESTED, saveOrderFieldStyles);
}
