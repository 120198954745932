import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { setNextLocation } from '../../services/locationRedirect';
import { isLoggedIn } from '../../utils/authService';
import routeMap from '../../route-maps';

class AuthRoute extends Component<any, any> {
  getRoute = renderProps => {
    const { component: Comp, location, ...rest } = this.props;
    if (!isLoggedIn()) {
      setNextLocation(
        location.pathname === routeMap.logout
          ? routeMap.dashboard
          : location.pathname + location.search,
      );
      return <Redirect to={routeMap.login} />;
    }

    return <Comp {...renderProps} />;
  };

  render() {
    const { component, uiState, ...rest } = this.props;
    return <Route {...rest} render={renderProps => this.getRoute(renderProps)} />;
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, null)(AuthRoute);
