import * as actions from '../actions/roleActions';

const initialState = [];

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ROLES:
      return action.roles;
    default:
      return state;
  }
};

export default roleReducer;
