import { h } from 'preact';
import cls from 'classnames';
import { I18n } from 'react-redux-i18n';
import styles from './permissionDeniedStyles.scss';

const PermissionDenied = () => {
  return (
    <div className={cls(styles.permissionDeniedWrapper, 'pageBody')}>
      <h3 className={styles.permissionDeniedHeader}>{I18n.t('permissionDenied.header')}</h3>
      <p className={styles.permissionDeniedMsg}>{I18n.t('permissionDenied.permissionDeniedMsg')}</p>
    </div>
  );
};

export default PermissionDenied;
