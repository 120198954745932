import {
  MixDesign,
  PlantId,
  PlantOrderField,
  Material,
  PlantsPermissions,
  Plant,
  PlantResponse,
  PlantsPermissionsResponse,
  CustomFieldStyle,
  Customer,
  CustomerField,
  GetCustomersApiParams,
  OrderFieldsType,
} from 'Types/plantTypes';
import { AxiosResponse } from 'axios';
import {
  AddMaterialFormData,
  AddNewOrderProps,
  CreatePlantParams,
  CreateSubscriberParams,
  DeleteOrderFieldProps,
  MixDesignFormData,
  MixDesignRequestPayload,
  MixDesignServerResponse,
  MoveOrderFieldProps,
  PlantInfo,
  PlantMaterialProps,
  RemoveMixDesignProps,
  RenameFieldProps,
  Subscriber,
  UpdateMaterialFormData,
  UpdateOrderFieldProps,
} from 'Types/plantSettingsTypes';
import { Optional, PaginationData } from 'Types/commonTypes';
import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';
import { get, removeItem, set } from './storageService';
import { makeGraphQLRequest } from '../request';
import { convertToDefaultTime, isLegacyDate } from './timeService';
import { ExportCustomersParams } from 'Redux/actions/plantSettingsActions';
import { FetchCustomersProps } from 'Redux/actions/customerActions';

const LAST_ACTIVE_PLANT = 'last_active_plant';

export const getPlantPermissions = async (): Promise<PlantsPermissions[]> => {
  const response: AxiosResponse<PlantsPermissionsResponse[]> = await axios.get(apiMap.permissions);
  const mappedPlantsPermissions: PlantsPermissions[] = response.data.map(
    ({ plant_id, ...rest }) => ({
      plant_id: plant_id.toString(),
      ...rest,
    }),
  );

  return mappedPlantsPermissions;
};

export const createPlant = async (plantData: CreatePlantParams) => {
  const variables = {
    name: plantData.name,
    address: plantData.address,
    customerName: plantData.customerName,
    plantType: plantData.plantType,
    maxCapacity: plantData.maxCapacity,
    nightShiftFrom: plantData.nightShiftFrom,
    nightShiftTo: plantData.nightShiftTo,
    sourcePlantId: plantData.sourcePlantId,
  };
  const query = {
    query: `mutation createPlant($name: String, $address: String, $customerName: String, $plantType: PlantTypesEnum, $maxCapacity: Int, $nightShiftFrom: Time, $nightShiftTo: Time, $sourcePlantId: Int) {
      createPlant(name: $name, address: $address, customerName: $customerName, plantType: $plantType, maxCapacity: $maxCapacity, nightShiftFrom: $nightShiftFrom, nightShiftTo: $nightShiftTo, sourcePlantId: $sourcePlantId) {
        __typename
        ... on CreatePlantResponse {
          id
          name
          address
          picture
          maxCapacity
          business {
            id
          }
          owner {
            id
          }
          nightShiftFrom
          nightShiftTo
          customerName
          sourcePlantId
          plantType
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'createPlant');
  return response.createPlant;
};

export const getPlantById = async (plantId: PlantId): Promise<PlantInfo> => {
  let queryParams = '';

  if (plantId) {
    queryParams = queryParams.concat(`plantId: ${Number(plantId)}, `);
  }

  const query = `
    {
      plantById(${queryParams}) {
        id
        name
        address
        plantType
        picture
        maxCapacity
        nightShiftFrom
        nightShiftTo
        showProducedMaterials
      }
    }
  `;

  const response = await makeGraphQLRequest({ query });
  return response.plantById;
};

// TODO move to plantSettingsService once the plant-members branch is merged
export const getSubscribers = async (plantId: PlantId): Promise<Subscriber[]> => {
  const response: AxiosResponse<Subscriber[]> = await axios.get(apiMap.subscribers(plantId));
  return response.data;
};

// TODO move to plantSettingsService once the plant-members branch is merged
export const createSubscriber = async ({
  params,
  data,
}: CreateSubscriberParams): Promise<Subscriber> => {
  const response: AxiosResponse<Subscriber> = await axios.post(
    apiMap.addNewSubscriber(params.plantId),
    data,
  );
  return response.data;
};

export const updatePlant = async (plantData: Partial<PlantInfo>): Promise<PlantInfo> => {
  const variables = {
    plantId: plantData.id ? parseInt(plantData.id) : undefined,
    name: plantData.name,
    plantType: plantData.plantType,
    address: plantData.address,
    maxCapacity: plantData.maxCapacity,
    nightShiftFrom: plantData.nightShiftFrom,
    nightShiftTo: plantData.nightShiftTo,
    showProducedMaterials: plantData.showProducedMaterials,
  };

  const query = {
    query: `mutation updatePlantInformation($plantId: Int, $name: String, $plantType: PlantTypesEnum, $address: String, $maxCapacity: Int, $nightShiftFrom: Time, $nightShiftTo: Time, $showProducedMaterials: Boolean) {
      updatePlantInformation(plantId: $plantId, name: $name, plantType: $plantType, address: $address, maxCapacity: $maxCapacity, nightShiftFrom: $nightShiftFrom, nightShiftTo: $nightShiftTo, showProducedMaterials: $showProducedMaterials) {
        __typename
        ... on UpdatePlantInformationResponse {
          success
          plant {
            id
            name
            plantType
            address
            picture
            maxCapacity
            nightShiftFrom
            nightShiftTo
            showProducedMaterials
          }
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'updatePlantInformation');
  return response.updatePlantInformation.plant;
};

export const updatePlantRest = async (plantId: PlantId, formData) => {
  const { data } = await axios.patch(apiMap.updatePlant(plantId), formData);
  return data;
};

export const exportPlantInfo = async (plantId: PlantId) => {
  window.open(apiMap.exportPlantInfoCSV(plantId), '_blank');
};

export const getPlants = callback => {
  axios.get(apiMap.plants).then(({ data }) => {
    callback(data);
  });
};

export const getPlantsList = async (showInactivePlants = false): Promise<Plant[]> => {
  const response: AxiosResponse<PlantResponse[]> = await axios.get(
    apiMap.plantsList(showInactivePlants),
  );

  const mappedPlants: Plant[] = response.data.map(({ id, source_plant, ...rest }) => ({
    id: id.toString(),
    source_plant: source_plant?.toString() || null,
    ...rest,
  }));

  return mappedPlants;
};

export const deletePlant = async (id: PlantId) => {
  const response = await axios.delete(apiMap.deletePlant(id));
  return response.data;
};

export const activatePlant = async (id: PlantId): Promise<string> => {
  const response = await axios.get(apiMap.activatePlant(id));
  return response.data;
};

export const createNewPlant = data => axios.post(apiMap.newPlant, data);

export const listOrderFields = async (
  plantId: PlantId,
  excludeHiddenFields?: boolean,
): Promise<PlantOrderField[]> => {
  const response = await axios.get<PlantOrderField[]>(apiMap.orderFields(plantId));
  if (excludeHiddenFields) {
    return response.data.filter(field => field.field_type !== OrderFieldsType.Hidden);
  }
  return response.data;
};

export const listOrderDateFields = async (plantId: PlantId): Promise<PlantOrderField[]> => {
  const response = await axios.get<PlantOrderField[]>(apiMap.orderDateFields(plantId));
  return response.data;
};

export const addOrderDateFields = async ({
  plantId,
  data,
}: AddNewOrderProps): Promise<PlantOrderField> => {
  const response = await axios.post<PlantOrderField>(apiMap.addOrderDateFields(plantId), data);
  return response.data;
};

export const updateOrderDateField = async ({
  plantId,
  fieldId,
  data,
}: UpdateOrderFieldProps): Promise<PlantOrderField> => {
  const response: AxiosResponse<PlantOrderField> = await axios.patch(
    apiMap.updateOrderDateField(plantId, fieldId),
    data,
  );

  return response.data;
};

export const deleteOrderDateField = async ({ plantId, orderFieldId }: DeleteOrderFieldProps) => {
  const response = await axios.delete(apiMap.deleteOrderDateField(plantId, orderFieldId));
  return response;
};

export const moveOrderDateField = async ({
  plantId,
  orderFieldId,
  newIndex,
}: MoveOrderFieldProps): Promise<PlantOrderField[]> => {
  const response: AxiosResponse<PlantOrderField[]> = await axios.post(
    apiMap.moveOrderDateField(plantId, orderFieldId),
    { new_index: newIndex },
  );
  return response.data;
};

export const renameOrderDateField = async ({
  plantId,
  fieldId,
  data,
}: RenameFieldProps): Promise<{ order_date_field: PlantOrderField }> => {
  const response: AxiosResponse<{
    order_date_field: PlantOrderField;
  }> = await axios.post(apiMap.renameOrderDateField(plantId, fieldId), data);

  return response.data;
};

export const addOrderField = async ({
  plantId,
  data,
}: AddNewOrderProps): Promise<PlantOrderField> => {
  const response: AxiosResponse<PlantOrderField> = await axios.post(
    apiMap.addNewOrderFields(plantId),
    data,
  );
  return response.data;
};

export const updateOrderField = async ({
  plantId,
  fieldId,
  data,
}: UpdateOrderFieldProps): Promise<PlantOrderField> => {
  const response: AxiosResponse<PlantOrderField> = await axios.patch(
    apiMap.updateOrderField(plantId, fieldId),
    data,
  );

  return response.data;
};

export const deleteOrderField = async ({ plantId, orderFieldId }: DeleteOrderFieldProps) => {
  const response = await axios.delete(apiMap.deleteOrderField(plantId, orderFieldId));
  return response;
};

export const renameOrderField = async ({
  plantId,
  fieldId,
  data,
}: RenameFieldProps): Promise<{ order_field: PlantOrderField }> => {
  const response: AxiosResponse<{ order_field: PlantOrderField }> = await axios.post(
    apiMap.renameOrderField(plantId, fieldId),
    data,
  );

  return response.data;
};

export const moveOrderField = async ({
  plantId,
  orderFieldId,
  newIndex,
}: MoveOrderFieldProps): Promise<PlantOrderField[]> => {
  const response: AxiosResponse<PlantOrderField[]> = await axios.post(
    apiMap.moveOrderField(plantId, orderFieldId),
    { new_index: newIndex },
  );
  return response.data;
};

export const setActivePlant = (plantId: PlantId) => {
  set(LAST_ACTIVE_PLANT, plantId);
};

export const getLastActivePlant = () => {
  try {
    return get<PlantId>(LAST_ACTIVE_PLANT);
  } catch (e) {
    return null;
  }
};

export const getPlantMaterials = async (plantId: PlantId): Promise<Material[]> => {
  const response = await axios.get(apiMap.plantMaterials(plantId));
  return response.data;
};

export const getPlantMaterialById = async ({ plantId, materialId }: PlantMaterialProps) => {
  const response = await axios.get(apiMap.plantMaterial(plantId, materialId));
  return response.data;
};

export const addPlantMaterial = async (data: AddMaterialFormData): Promise<Material> => {
  const response: AxiosResponse<Material> = await axios.post(
    apiMap.addNewMaterial(data.plant),
    data,
  );
  return response.data;
};

export const updatePlantMaterial = async (material: UpdateMaterialFormData) => {
  const response = await axios.put(apiMap.plantMaterial(material.plant, material.id), material);
  return response.data;
};

export const deletePlantMaterial = async (params: PlantMaterialProps) => {
  const response = await axios.delete(apiMap.deleteMaterial(params.plantId, params.materialId));
  return response.data;
};

export const updateCurrentInventoryAmountOfMaterials = async (
  plantId: PlantId,
  material: Record<string, Optional<number>>,
) => {
  const materialPromises = Object.keys(material).map(materialId => {
    return axios.patch(apiMap.plantMaterial(plantId, materialId), {
      current_inventory_amount: material[materialId],
    });
  });

  const result = await Promise.all(materialPromises);
  return result.map(res => res.data);
};

export const getMixDesigns = async (plantId: PlantId): Promise<MixDesign[]> => {
  let queryParams = '';

  if (plantId) {
    queryParams = queryParams.concat(`plantId: ${Number(plantId)}, `);
  }

  const query = `
    {
      mixDesigns(${queryParams}) {
        id
        outputMaterial {
          id
          name
          systemColor
          active
        }
        expirationDate
        specificationBasis
        verified
      }
    }
  `;

  const response = await axios.get(apiMap.graphql, { params: { query: query } });
  const { mixDesigns }: { mixDesigns: MixDesign[] } = response.data.data;
  const sortedMixDesigns = mixDesigns.sort((a, b) =>
    a.outputMaterial.name.localeCompare(b.outputMaterial.name),
  );

  return sortedMixDesigns;
};

export const getMixDesignById = async (mixDesignId: string): Promise<MixDesign> => {
  const query = `
    {
      mixDesignById(mixDesignId: ${mixDesignId}) {
        id
        outputMaterial {
          id
        }
        expirationDate
        specificationBasis
        verified
        components {
          id
          proportion
          material {
            id
          }
        }
      }
    }
  `;

  const response = await axios.get(apiMap.graphql, { params: { query: query } });
  const { mixDesignById } = response.data.data;

  return mixDesignById;
};

const mapMixDesignFormData = (formData: MixDesignFormData): MixDesignRequestPayload => {
  const data = {
    ...(formData.id && { id: formData.id }),
    components: formData.components.map(component => ({
      ...(component.id && { id: component.id }),
      material: component.material,
      proportion: +component.proportion,
    })),
    expiration_date:
      formData.expirationDate && !isLegacyDate(formData.expirationDate)
        ? convertToDefaultTime(formData.expirationDate)
        : formData.expirationDate,
    output_material: formData.outputMaterial,
    plant: formData.plant,
    removed: null,
    specification_basis: formData.specificationBasis,
    verified: formData.verified,
  };

  return data;
};

export const createMixDesign = async (formData: MixDesignFormData) => {
  const data = mapMixDesignFormData(formData);
  const response = await axios.post(apiMap.addMixDesign(formData.plant), data);
  return response.data;
};

export const updateMixDesign = async (
  formData: MixDesignFormData,
): Promise<MixDesignServerResponse> => {
  const data = mapMixDesignFormData(formData);
  const response = await axios.put(apiMap.plantMixDesignById(data.plant, data.id), data);
  return response.data;
};

export const deleteMixDesign = async ({ plantId, mixDesignId }: RemoveMixDesignProps) => {
  const response = await axios.delete(apiMap.deleteMixDesign(plantId, mixDesignId));
  return response.data;
};

export const getPlantCustomers = async (
  params: FetchCustomersProps,
): Promise<{ items: string[]; nextPage: string; totalCount: number; totalPages: number }> => {
  const variables = {
    plantId: parseInt(params.plantId),
    pageSize: params.pageSize,
    pageCursor: params.page,
    customerName: params.customerName,
  };

  const query = {
    query: `query plantCustomers($plantId: Int, $pageSize: Int, $pageCursor: Int, $customerName: String) {
      plantCustomers(plantId: $plantId, pageSize: $pageSize, pageCursor: $pageCursor, customerName: $customerName) {
        totalPages
        totalCount
        nextPage
        items
      }
    }
    `,
    variables,
  };

  const { plantCustomers } = await makeGraphQLRequest(query);

  return plantCustomers;
};

export const getPlantCustomersApi = async ({
  plantId,
  name,
  page,
  pageSize,
  includeRemoved = false,
}: GetCustomersApiParams): Promise<PaginationData<Customer>> => {
  const response = await axios.get<PaginationData<Customer>>(
    `${apiMap.listPlantCustomers({ plantId, name, page, pageSize, includeRemoved })}`,
  );
  return response.data;
};

export const getCustomerFields = async (plantId: PlantId): Promise<CustomerField[]> => {
  const response = await axios.get<CustomerField[]>(apiMap.getCustomerFields(plantId));
  return response.data;
};

export const getCustomerById = async (
  plantId: PlantId,
  customerId: string,
  includeRemoved?: boolean,
): Promise<Customer> => {
  const response = await axios.get<Customer>(
    apiMap.customerById(plantId, customerId, includeRemoved),
  );
  return response.data;
};

export const updateCustomer = async (
  plantId: PlantId,
  customerId: string,
  data: Customer,
): Promise<Customer> => {
  const response = await axios.patch<Customer>(apiMap.customerById(plantId, customerId), data);
  return response.data;
};

export const deleteCustomer = async (plantId: PlantId, customerId: string): Promise<null> => {
  const response = await axios.delete<null>(apiMap.customerDelete(plantId, customerId));
  return response.data;
};

export const restoreCustomer = async (plantId: PlantId, customerId: string): Promise<any> => {
  const response = await axios.get<any>(apiMap.customerRestore(plantId, customerId));
  return response.data;
};

export const exportPlantCustomers = async ({ targetPlant, customers }: ExportCustomersParams) => {
  const url = `${apiMap.exportPlantCustomers(targetPlant)}`;
  const response = await axios.post(url, { customers });
  return response.data;
};

export const getCustomFieldStyles = async (plantId: PlantId): Promise<CustomFieldStyle[]> => {
  try {
    const customFieldStyles = get<CustomFieldStyle[]>(`customFieldStyles__${[plantId]}`);
    if (!customFieldStyles) return [];

    return customFieldStyles;
  } catch (error) {
    throw error;
  }
};

export const setCustomFieldStyles = async ({
  plantId,
  fieldStyles,
}): Promise<CustomFieldStyle[]> => {
  try {
    set(`customFieldStyles__${[plantId]}`, fieldStyles);
    return fieldStyles;
  } catch (error) {
    throw error;
  }
};

export const removeCustomFieldStyles = async (plantId: PlantId) => {
  removeItem(`customFieldStyles__${[plantId]}`);
};
