import { useRef, useState } from 'preact/hooks';
import { Fragment } from 'preact/jsx-runtime';
import { useSelector } from 'react-redux';

import { RootState } from 'Redux/reducers';
import { UserProfile } from 'Types/userTypes';
import { useOnClickOutside } from '../../../../src/helpers/hooks/useOnClickOutside';
import { ProfileBox } from '../profileBox';

import styles from '../profileButton/profileButton.scss';

interface ProfileButtonProps {
  opened: boolean;
  onClick: () => void;
}

export const ProfileButton = ({ opened, onClick }: ProfileButtonProps): JSX.Element => {
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user) as UserProfile;
  const profileBoxRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(profileBoxRef, () => setIsProfileOpen(false));

  const handleClick = (): void => {
    setIsProfileOpen(!isProfileOpen);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonContainer} onClick={handleClick} data-testid="profile-button">
        <div className={styles.profileButton}>
          {user.firstName.charAt(0)}
          {user.lastName.charAt(0)}
        </div>
        <div className={styles.firstName}>{user.firstName}</div>
      </div>
      {(isProfileOpen || opened) && (
        <div ref={profileBoxRef}>
          <ProfileBox setIsProfileOpen={setIsProfileOpen} onClick={onClick} />
        </div>
      )}
    </div>
  );
};
