import { PlantState, PlantType, Plant } from 'Types/plantTypes';
import { RequestStatusType } from 'Types/commonTypes';
import { PlantActions, PlantActionTypes } from 'Redux/actions/actionTypes/plantActionTypes';
import { partition, sortList } from '../../helpers/array';

const initialState: PlantState = {
  plants: [],
  plantsPermissions: [],
  customerPortals: [],
  activePlants: [],
  inactivePlants: [],
  materials: [],
  orderFields: [],
  orderDateFields: [],
  orderFieldStyles: [],
  activePlantId: null,
};

const plantReducer = (state = initialState, action: PlantActions) => {
  switch (action.type) {
    case PlantActionTypes.SET_PLANTS:
      const isCustomerPortal = (item: Plant) => item.plant_type === PlantType.Customer;
      const [customerPortals, plants] = partition(action.payload, isCustomerPortal);

      return {
        ...state,
        plants: sortList(plants, 'name'),
        customerPortals: sortList(customerPortals, 'name'),
        fetchingPlantsStatus: RequestStatusType.Completed,
      };
    case PlantActionTypes.SET_PLANT_PERMISSIONS_REQUESTED:
      return { ...state, fetchingPlantPermissionsStatus: RequestStatusType.InProgress };
    case PlantActionTypes.SET_PLANT_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        plantsPermissions: sortList(action.payload, 'plant_name'),
        fetchingPlantPermissionsStatus: RequestStatusType.Completed,
      };
    case PlantActionTypes.SET_PLANT_PERMISSIONS_FAILED:
      return {
        ...state,
        fetchingPlantPermissionsStatus: RequestStatusType.Completed,
        fetchingPlantPermissionsError: action.payload,
      };
    case PlantActionTypes.SET_ACTIVE_INACTIVE_PLANTS:
      const isActive = item => item.removed === null;
      let [activePlants, inactivePlants] = partition(action.payload, isActive);
      activePlants = sortList(activePlants, 'name');
      inactivePlants = sortList(inactivePlants, 'name');
      return { ...state, inactivePlants, activePlants };
    case PlantActionTypes.LIST_PLANT_ORDER_FIELDS_REQUESTED:
      return { ...state, fetchingOrderFieldsStatus: RequestStatusType.InProgress };
    case PlantActionTypes.LIST_PLANT_ORDER_FIELDS_SUCCEEDED:
      return {
        ...state,
        orderFields: action.payload,
        fetchingOrderFieldsStatus: RequestStatusType.Completed,
        fetchingOrderFieldsError: null,
      };
    case PlantActionTypes.LIST_PLANT_ORDER_FIELDS_FAILED:
      return {
        ...state,
        fetchingOrderFieldsStatus: RequestStatusType.Completed,
        fetchingOrderFieldsError: action.payload,
      };

    case PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED:
      return { ...state, fetchingOrderDateFieldsStatus: RequestStatusType.InProgress };
    case PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_SUCCEEDED:
      return {
        ...state,
        orderDateFields: action.payload,
        fetchingOrderDateFieldsStatus: RequestStatusType.Completed,
        fetchingOrderDateFieldsError: null,
      };
    case PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_FAILED:
      return {
        ...state,
        fetchingOrderDateFieldsStatus: RequestStatusType.Completed,
        fetchingOrderDateFieldsError: action.payload,
      };

    case PlantActionTypes.LIST_PLANT_MATERIALS_REQUESTED:
      return { ...state, fetchingMaterialStatus: RequestStatusType.InProgress };
    case PlantActionTypes.LIST_PLANT_MATERIALS_SUCCEEDED:
      return {
        ...state,
        materials: action.payload,
        fetchingMaterialStatus: RequestStatusType.Completed,
        fetchingMaterialError: null,
      };
    case PlantActionTypes.LIST_PLANT_MATERIALS_FAILED:
      return {
        ...state,
        fetchingMaterialStatus: RequestStatusType.Completed,
        fetchingMaterialError: action.payload,
      };

    case PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_REQUESTED:
      return { ...state, fetchingOrderFieldStylesStatus: RequestStatusType.InProgress };
    case PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED:
      return {
        ...state,
        orderFieldStyles: action.payload,
        fetchingOrderFieldStylesStatus: RequestStatusType.Completed,
        fetchingOrderFieldStylesError: null,
      };
    case PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_FAILED:
      return {
        ...state,
        fetchingOrderFieldStylesStatus: RequestStatusType.Completed,
        fetchingOrderFieldStylesError: action.payload,
      };

    case PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_REQUESTED:
      return { ...state, fetchingOrderFieldStylesStatus: RequestStatusType.InProgress };
    case PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED:
      return {
        ...state,
        orderFieldStyles: action.payload,
        fetchingOrderFieldStylesStatus: RequestStatusType.Completed,
        fetchingOrderFieldStylesError: null,
      };
    case PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_FAILED:
      return {
        ...state,
        fetchingOrderFieldStylesStatus: RequestStatusType.Completed,
        fetchingOrderFieldStylesError: action.payload,
      };

    case PlantActionTypes.SET_LAST_ACTIVE_PLANT:
      return {
        ...state,
        activePlantId: action.payload,
      };
    default:
      return state;
  }
};

export default plantReducer;
