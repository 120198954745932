import { h } from 'preact';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Badge from 'Components/Badges/';
import { RootState } from 'Redux/reducers';
import { isMatchingRoute } from '../../../helpers/url';
import styles from './menutabs.scss';

export type MenuTab = {
  link: string;
  title: string;
  type?: 'navlink' | 'hyperlink';
  visible?: boolean;
  exactMatch?: boolean;
};

type MenuTabsProps = {
  items: MenuTab[];
  'aria-label'?: string;
};

const MenuTabs = ({ items = [], 'aria-label': ariaLabel = 'tabs' }: MenuTabsProps) => {
  const requestCount = useSelector((state: RootState) => state.orderRequests.requestCount);

  const buildNavLink = (
    to: string,
    text: string,
    type = 'navlink',
    exactMatch = type === 'hyperlink' ? false : true,
  ) => {
    return type === 'hyperlink' ? (
      <a
        href={to}
        class={
          isMatchingRoute(to, false, true)
            ? [styles['menu-tab-link'], styles['link-active']].join(' ')
            : styles['menu-tab-link']
        }
      >
        {text}
      </a>
    ) : (
      <NavLink
        to={to}
        exact={exactMatch}
        class={styles['menu-tab-link']}
        activeClassName={[styles['menu-tab-link'], styles['link-active']].join(' ')}
      >
        {text}
      </NavLink>
    );
  };

  return (
    <ul class={styles['menu-tabs']} role="tablist" aria-label={ariaLabel}>
      {items.map(item => (
        <li class={styles['menu-tab-item']} role="tab">
          {item.title === 'Requests' && <Badge count={requestCount} />}
          {buildNavLink(item.link, item.title, item.type, item.exactMatch)}
        </li>
      ))}
    </ul>
  );
};

export default MenuTabs;
