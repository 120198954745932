import { Action } from './helper';
import { DeleteNoteParams, ListNotesParams, NoteParams } from '../../services/noteService';

export const SET_ORDER_LIST_FILTERS = '@notes/SET_ORDER_LIST_FILTERS';

export enum NoteActionTypes {
  NOTES_FETCH_REQUESTED = '@notes/NOTES_FETCH_REQUESTED',
  NOTES_FETCH_SUCCEEDED = '@notes/NOTES_FETCH_SUCCEEDED',
  NOTES_FETCH_FAILED = '@notes/NOTES_FETCH_FAILED',

  NOTE_CREATE_REQUESTED = '@notes/NOTE_CREATE_REQUESTED',
  NOTE_CREATE_SUCCEEDED = '@notes/NOTE_CREATE_SUCCEEDED',
  NOTE_CREATE_FAILED = '@notes/NOTE_CREATE_FAILED',

  NOTE_EDIT_REQUESTED = '@notes/NOTE_EDIT_REQUESTED',
  NOTE_EDIT_SUCCEEDED = '@notes/NOTE_EDIT_SUCCEEDED',
  NOTE_EDIT_FAILED = '@notes/NOTE_EDIT_FAILED',

  NOTE_DELETE_REQUESTED = '@notes/NOTE_DELETE_REQUESTED',
  NOTE_DELETE_SUCCEEDED = '@notes/NOTE_DELETE_SUCCEEDED',
  NOTE_DELETE_FAILED = '@notes/NOTE_DELETE_FAILED',

  NOTES_CLEAR_REQUESTED = '@notes/NOTES_CLEAR_REQUESTED',
}

export type CreateNoteActionRequested = Action<NoteActionTypes.NOTE_CREATE_REQUESTED, NoteParams>;

export type EditNoteActionRequested = Action<NoteActionTypes.NOTE_EDIT_REQUESTED, NoteParams>;

export type DeleteNoteActionRequested = Action<
  NoteActionTypes.NOTE_DELETE_REQUESTED,
  DeleteNoteParams
>;

export type ListNotesActionRequested = Action<
  NoteActionTypes.NOTES_FETCH_REQUESTED,
  ListNotesParams
>;

export const listNotes = (params: ListNotesParams): ListNotesActionRequested => ({
  type: NoteActionTypes.NOTES_FETCH_REQUESTED,
  payload: params,
});

export const createNote = (params: NoteParams) => ({
  type: NoteActionTypes.NOTE_CREATE_REQUESTED,
  payload: params,
});

export const editNote = (params: NoteParams): EditNoteActionRequested => ({
  type: NoteActionTypes.NOTE_EDIT_REQUESTED,
  payload: params,
});

export const deleteNote = (params: DeleteNoteParams) => ({
  type: NoteActionTypes.NOTE_DELETE_REQUESTED,
  payload: params,
});

export const clearNotes = () => ({
  type: NoteActionTypes.NOTES_CLEAR_REQUESTED,
});
