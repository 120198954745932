import { RootState } from 'Redux/reducers';
import { PlantId } from 'Types/plantTypes';

export const approvingOrderRequestPermissions = (state: RootState, plantId: PlantId): boolean => {
  const plantsPermissions = state.plants.plantsPermissions;
  const currentPlantPermissions = plantsPermissions.find(p => p.plant_id === plantId);

  if (!currentPlantPermissions) return false;
  return currentPlantPermissions.permissions.approve_order_request;
};
