import { h, cloneElement, toChildArray, isValidElement } from 'preact';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from 'Redux/reducers';
import { setNextLocation } from 'Services/locationRedirect';
import { isLoggedIn } from '../../utils/authService';
import routeMap from '../../route-maps';

const AuthRouteWithPlantValidation = ({ children, ...rest }) => {
  const plants = useSelector((state: RootState) => state.plants.plants);
  const customerPortals = useSelector((state: RootState) => state.plants.customerPortals);
  const activePlantId = useSelector((state: RootState) => state.plants.activePlantId);

  const getRoute = routeProps => {
    const urlPlantId = routeProps.match.params.plantId;
    const plantExist = !!plants.find(plant => plant.id == urlPlantId);
    const customerPortalExist = !!customerPortals.find(ps => ps.id == urlPlantId);
    const plantId = plantExist || customerPortalExist ? urlPlantId : activePlantId;

    if (!isLoggedIn()) {
      setNextLocation(location.pathname + location.search);
      return <Redirect to={routeMap.login} />;
    }

    if (urlPlantId && !plantExist && !customerPortalExist) {
      if (!plantId) return;
      if (plantId == urlPlantId) return <Redirect to={routeMap.plantNotExist} />;

      const newPathPrefix = generatePath(routeProps.match.path, {
        ...routeProps.match.params,
        plantId,
      });
      const newPath = routeProps.location.pathname
        .replace(routeProps.match.url, newPathPrefix)
        .replace('//', '/');

      return (
        <Redirect
          to={{
            ...routeProps.location,
            pathname: newPath,
          }}
        />
      );
    }

    return toChildArray(children).map(child => {
      if (isValidElement(child)) {
        return cloneElement(child, { ...routeProps });
      }
      return child;
    });
  };

  return <Route {...rest} render={routeProps => getRoute(routeProps)} />;
};

export default AuthRouteWithPlantValidation;
