import { plantActionTypes, UserActions } from '../actions/userActions';
import { UserReducerState } from 'Types/userTypes';

const initialState: UserReducerState = {
  tryNewDesign: true,
  firstName: '',
  lastName: '',
  isSuperuser: false,
  email: '',
  fullName: '',
  id: 1,
  profile: { tryGenericEntities: false },
};

const userReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case plantActionTypes.SET_USER_PROFILE:
      return { ...state, ...action.payload };
    case plantActionTypes.TRY_NEW_DESIGN_REQUESTED:
      return { ...state };
    case plantActionTypes.TRY_NEW_DESIGN_SUCCEEDED:
      return { ...state, tryNewDesign: false };
    case plantActionTypes.TRY_NEW_DESIGN_FAILED:
      return { ...state, tryNewDesign: true };
    default:
      return state;
  }
};

export default userReducer;
