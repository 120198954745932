import { ComponentChildren, RefObject } from 'preact';
import { useMemo } from 'preact/hooks';
import cls from 'classnames';
import { SetValueConfig } from 'react-hook-form';
import InputField from '../inputField';
import DropDownMenu from 'Components/DropDownMenu';
import DropDownItem from 'Components/DropDownMenuItem';
import ColorCircle from 'Components/ColorCircle';

import {
  HighlightedOption,
  isHighlightedOption,
  isOptionWithExtraFieldsData,
  Option,
} from 'Types/commonTypes';
import { OrderFieldsType } from 'Types/plantTypes';
import { useDropdown } from '../../../helpers/hooks/useDropdown';
import { booleanToString } from '../../../utils/boolean';
import arrowDown from 'Assets/icons/arrowDown.svg';
import styles from './inputDropDownStyles.scss';

export interface InputDropDownProps {
  options: Array<Option | HighlightedOption>;
  selected?: Option | HighlightedOption;
  open?: boolean;
  name?: string;
  className?: string;
  placeholder?: string;
  formLabel?: string | ComponentChildren;
  inputRef?: RefObject<HTMLInputElement>;
  hasError?: boolean;
  async?: boolean;
  loadingOptions?: boolean;
  totalOptionsCount?: number;
  errorMessage?: string;
  allowUnknownOption?: boolean;
  disabled?: boolean;
  renderWithTooltip?: boolean;
  noOptionsMessage?: string;
  footerContent?: ComponentChildren;
  onChange?: (e) => void;
  onInputChange?: (e: InputEvent) => void;
  onLoadAllOptions?: () => void;
  setValue?: (name: string, value: any, config?: SetValueConfig) => void;
  id?: string;
}

const InputDropDown = ({
  options,
  selected,
  open = false,
  name = 'InputDropDown',
  className,
  placeholder,
  formLabel,
  errorMessage,
  hasError,
  async,
  loadingOptions = false,
  totalOptionsCount,
  noOptionsMessage,
  allowUnknownOption = false,
  disabled,
  renderWithTooltip = false,
  onChange,
  onInputChange,
  onLoadAllOptions,
  setValue,
  id,
}: InputDropDownProps) => {
  const selectedOptionMemo = useMemo(() => {
    return selected ? [selected] : [];
  }, [selected]);

  const {
    getRootProps,
    getInputProps,
    getDropdownProps,
    getOptionProps,
    highlightedOptionIndex,
    isOpen,
    availableOptions,
    selectedOption,
  } = useDropdown({
    options,
    selected: selectedOptionMemo,
    isMultiSelect: false,
    open,
    name,
    allowUnknownOption,
    async,
    totalOptionsCount,
    disabled,
    setValue,
    onChange,
    onInputChange,
    onLoadAllOptions,
  });

  const classnames = cls(styles.customSelect, { [styles['chevron-flip']]: isOpen, disabled });
  const dataTestIdValue = name ? `${name}-input-dropdown-field` : 'input-dropdown-field';

  const renderExtraFieldsData = () => {
    if (!renderWithTooltip || !selectedOption) return null;

    return (
      <div className={styles.extraFieldsDataSection}>
        {isOptionWithExtraFieldsData(selectedOption) && selectedOption.extraFieldsData?.length
          ? selectedOption.extraFieldsData?.map(field => {
              if (!field.key || !field.value || !field.type) return null;
              if (field.type === OrderFieldsType.Hidden) return null;
              const value =
                field.type.toLocaleLowerCase() === OrderFieldsType.Checkbox.toLocaleLowerCase()
                  ? booleanToString(field.value)
                  : field.value.toString();

              return (
                <div className={styles.chip}>
                  <div className={styles.key}>{field.key}</div>
                  <div className={styles.value}>{value}</div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <div className={cls(styles.customSelectWrapper, className)}>
      <div className={classnames} {...getRootProps()} data-testid={`${dataTestIdValue}-wrapper`}>
        <InputField
          formLabel={formLabel}
          placeholder={placeholder}
          name={name}
          id={id}
          hasError={hasError}
          errorMessage={!isOpen ? errorMessage : null}
          data-testid={dataTestIdValue}
          startAdornment={selectedOption?.labelPrefix}
          endAdornment={
            <>
              <ColorCircle
                color={
                  isHighlightedOption(selectedOption) ? selectedOption.highlightColor : undefined
                }
                className={styles.highlightedColor}
              />
              <img
                src={arrowDown}
                className={cls(styles.arrowIcon, {
                  [styles.arrowIconRotated]: isOpen,
                })}
              />
            </>
          }
          {...getInputProps()}
        />
      </div>

      <DropDownMenu
        isOpen={isOpen}
        selectedOption={selectedOption}
        highlightedOptionIndex={highlightedOptionIndex}
        dropdownMenuProps={getDropdownProps()}
        loading={loadingOptions}
        noOptionsMessage={noOptionsMessage}
      >
        {availableOptions.map((option, index) => (
          <DropDownItem
            key={`${option.label}-${index}`}
            option={option}
            index={index}
            showTooltip={renderWithTooltip}
            optionProps={getOptionProps()}
          />
        ))}
      </DropDownMenu>
      {renderExtraFieldsData()}
    </div>
  );
};

export default InputDropDown;
