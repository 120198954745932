import { h } from 'preact';
import { I18n } from 'react-redux-i18n';
import Tooltip from '../../Tooltip';
import personIcon from 'Assets/icons/person.svg';

import styles from './formLabel.scss';

const FormLabel = ({
  className,
  label,
  htmlFor,
  visibleToCustomer,
  isFieldDeleted,
  isFieldSupported,
  isFieldReadOnly,
}: any) => (
  <div className={[className, styles.formLabelContainer].join(' ')}>
    <label className={styles.label} htmlFor={htmlFor}>
      {label}
    </label>
    {visibleToCustomer && !isFieldDeleted && (
      <Tooltip title={I18n.t('orderForm.customerVisibleField')}>
        {/* <div className={styles.personIcon} /> */}
        <img src={personIcon} alt="visible to customer" />
      </Tooltip>
    )}
    {!isFieldSupported && !isFieldDeleted && (
      <Tooltip title={I18n.t('orderForm.unknownFieldType')}>
        <div className={styles.alertIcon} />
      </Tooltip>
    )}
    {isFieldDeleted && (
      <Tooltip title={I18n.t('orderForm.fieldIsRemoved')}>
        <div className={styles.closureIcon} />
      </Tooltip>
    )}
    {isFieldReadOnly && <span className={styles.readOnlyField}>(Read Only)</span>}
  </div>
);

export default FormLabel;
