import { createPortal, useEffect, useMemo } from 'preact/compat';

const Portal = ({ show, children }: { show: boolean; children: any }) => {
  const portalDivWrapper = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    if (!show) return;
    portalDivWrapper.id = 'portal';
    portalDivWrapper.setAttribute('role', 'tooltip');
    portalDivWrapper.setAttribute('data-testid', 'tooltip');
    document.body.appendChild(portalDivWrapper);

    return () => {
      document.body.removeChild(portalDivWrapper);
    };
  }, [show, portalDivWrapper]);

  if (!show) return null;

  return createPortal(children, portalDivWrapper);
};

export default Portal;
