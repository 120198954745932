import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSuppliersFailed,
  FetchSuppliersRequested,
  fetchSuppliersSucceeded,
  SupplierActionTypes,
} from 'Redux/actions/supplierActions';
import { getAllSuppliers } from 'Services/supplierService';

export function* fetchSuppliersSaga({ payload }: FetchSuppliersRequested) {
  try {
    const suppliers = yield call(getAllSuppliers);
    yield put(fetchSuppliersSucceeded(suppliers));
  } catch (error) {
    yield put(fetchSuppliersFailed(error));
  }
}

export default function* watchNotes() {
  yield takeLatest(SupplierActionTypes.FETCH_SUPPLIERS_REQUESTED, fetchSuppliersSaga);
}
