import { Business } from 'Types/businessType';
import { Action } from './helper';

export enum BusinessActionTypes {
  SET_BUSINESSES = '@businesses/SET_BUSINESSES',
}

export type SetBusinessAction = Action<BusinessActionTypes.SET_BUSINESSES, Business[]>;

export type BusinessActions = SetBusinessAction;

export const setBusinesses = (businesses: Business[]): SetBusinessAction => ({
  type: BusinessActionTypes.SET_BUSINESSES,
  payload: businesses,
});
