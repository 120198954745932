import { h } from 'preact';
import { useState } from 'preact/compat';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  openFeedbackModal,
  sendSupportMessage,
} from '../../redux/actions/sendSupportMessageActions';
import Button from '../buttons';
import SupportModal from './SupportModal';
import helpIcon from '../../assets/icons/helpIcon.svg';
import styles from './styles.scss';
import { RootState } from 'Redux/reducers';

const FloatingHelpButton = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const supportMessage = useSelector((state: RootState) => state.sendSupportMessage);
  const isOpen = useSelector((state: RootState) => state.sendSupportMessage.openFeedbackModal);
  const user = useSelector((state: RootState) => state.user);
  const supportingData = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const { i18n, modals, ...rest } = supportingData;
    const data = {
      email,
      message,
      supporting_data: rest,
      url: window.location.href,
    };

    dispatch(sendSupportMessage(data));
  };

  const handleHelpButtonClick = () => {
    dispatch(openFeedbackModal(true));
  };

  const handleModalClose = () => {
    dispatch(openFeedbackModal(false));
  };
  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleChange = e => {
    setMessage(e.target.value);
  };

  return (
    <div
      className={cls(styles.buttonWrapper, {
        [styles.supportModal]: supportMessage.openFeedbackModal,
      })}
    >
      {!isOpen && (
        <Button
          label={I18n.t('helpButtonText')}
          icon={helpIcon}
          onClick={handleHelpButtonClick}
          data-testid="floating-help-button"
        />
      )}
      {isOpen && (
        <SupportModal
          onChange={handleChange}
          onEmailChange={handleChangeEmail}
          onSubmit={handleSubmit}
          onClose={handleModalClose}
          message={message}
          email={email}
          messageStatus={supportMessage}
          loggedIn={!!user.id}
        />
      )}
    </div>
  );
};

export default FloatingHelpButton;
