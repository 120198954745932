import { PlantSettingsActions, PlantSettingsActionTypes } from 'Redux/actions/plantSettingsActions';
import { CustomerId } from 'Types/plantTypes';
import { Subscriber } from 'Types/plantSettingsTypes';
import { RequestStatusType } from 'Types/commonTypes';
import { PlantPermissions, Role } from 'Services/plantSettingsService';

export interface PlantSettingsReducerState {
  subscribers: Subscriber[];
  fetchingSubscribersStatus?: RequestStatusType;
  fetchingSubscribersError?: any;
  creatingSubscriberStatus?: RequestStatusType;
  creatingSubscriberError?: any;
  exportedCustomers: CustomerId[];
  exportingPlantCustomersStatus?: RequestStatusType;
  exportingPlantCustomersError?: any;
  roles: Role[];
  fetchingRolesStatus?: RequestStatusType;
  fetchingRolesError?: any;
  fetchingPlantMembersError?: any;
  plantMemberPermissions: PlantPermissions[];
}

const initialState: PlantSettingsReducerState = {
  subscribers: [],
  exportedCustomers: [],
  roles: [],
  plantMemberPermissions: [],
};

const plantSettingsReducer = (
  state = initialState,
  action: PlantSettingsActions,
): PlantSettingsReducerState => {
  switch (action.type) {
    case PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_REQUESTED:
      return { ...state, exportingPlantCustomersStatus: RequestStatusType.InProgress };
    case PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_SUCCEEDED:
      return {
        ...state,
        exportedCustomers: action.payload,
        exportingPlantCustomersStatus: RequestStatusType.Completed,
        exportingPlantCustomersError: null,
      };
    case PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_FAILED:
      return {
        ...state,
        exportingPlantCustomersStatus: RequestStatusType.Completed,
        exportingPlantCustomersError: action.payload,
      };
    default:
      return state;
  }
};

export default plantSettingsReducer;
