import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { NavLink } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';

import RoundPlantIcon, { IconSize } from 'Components/plantPicture/roundPlantIcon';
import InputField from '../inputField';

import { PlantId } from 'Types/plantTypes';
import { Option } from 'Types/commonTypes';
import SearchIcon from 'Assets/icons/search-icon.svg';
import { asId } from '../../../helpers/asId';
import { useOnClickOutside } from '../../../helpers/hooks/useOnClickOutside';

import styles from './selectPlants.scss';

export type EntityOption = Option & { link?: string };

export type DropdownEntity = {
  entryType: 'plant' | 'customerPortal' | 'space';
  fields: EntityOption[];
};

interface PlantDropdownFieldProps {
  entities: DropdownEntity[];
  selected: PlantId;
  className?: string;
  isUsingNavLinks?: boolean;
  shouldTruncate?: boolean;
  onChange?: (option: EntityOption) => void;
}

const PlantDropdownField = ({
  entities,
  selected,
  className,
  isUsingNavLinks,
  shouldTruncate,
  onChange,
}: PlantDropdownFieldProps) => {
  const dropdownFieldRef = useRef<HTMLDivElement>(null);
  const dropDownMenuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DropdownEntity[]>(entities);
  const [selectedOption, setSelectedOption] = useState<EntityOption | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  useOnClickOutside(dropdownFieldRef, () => setOpen(false));

  useEffect(() => {
    const entityFields = entities.map(entity => entity.fields).flat();
    const item = entityFields.find(option => asId(option.value) === asId(selected));

    setSelectedOption(item || null);
  }, [selected, entities]);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
      const activeItem = dropDownMenuRef.current?.querySelector('[data-active-item="true"]');
      if (!activeItem) return;

      const offsetTop = (activeItem as HTMLDivElement)?.offsetTop - 45;

      dropDownMenuRef.current?.scrollTo(0, offsetTop);
    }
  }, [open]);

  const handleItemClick = (option: EntityOption) => {
    setOptions(entities);
    setOpen(false);
    onChange?.(option);
  };

  const handleToggleDropDownMenu = () => {
    if (!open) {
      setSearchTerm('');
      setOptions(entities);
    }
    setOpen(!open);
  };

  const handleSearch = (e: Event) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);

    if (!value) {
      setOptions(entities);
      return;
    }
    const filteredEntities = entities.map(entity => {
      return {
        ...entity,
        fields: entity.fields.filter(field =>
          field.label.toLowerCase().includes(value.toLowerCase()),
        ),
      };
    });

    setOptions(filteredEntities);
  };

  const renderDropDown = () => {
    if (!open) return null;

    return (
      <div className={cls(styles.dropdownMenu)} data-testid="plant-selector-dropdown-menu">
        <div className={styles.searchPlantInputWrapper}>
          <InputField
            value={searchTerm}
            startAdornment={<img src={SearchIcon} alt="search" />}
            placeholder={I18n.t('search')}
            className={styles.searchPlantInput}
            ref={inputRef}
            onChange={handleSearch}
          />
        </div>
        <div className={styles.dropdown} ref={dropDownMenuRef}>
          {options.map(entity => {
            return (
              <div>
                <div className={styles.groupByHeader}>{I18n.t(entity.entryType)}</div>
                {renderOptions(entity.fields)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderOptions = (options: EntityOption[]) => {
    if (!options.length) {
      return (
        <div className={styles.dropdownMenuItem}>
          <label className={styles.dropdownMenuLabel}>{I18n.t('noOptions')}</label>
        </div>
      );
    }
    return options.map(option => (
      <div
        key={option.value}
        className={cls(styles.dropdownMenuItem, {
          [styles.active]: option.value === selectedOption?.value,
        })}
        onClick={() => handleItemClick(option)}
        data-active-item={option.value === selectedOption?.value ?? null}
        data-testid="dropdown-menu-item"
      >
        <RoundPlantIcon size={IconSize.medium} src={option.icon} className="ml-md" />
        {isUsingNavLinks === true ? ( // isUsingNavLinks is explicitly set and eq true
          <NavLink to={option.link}>
            <label className={styles.dropdownMenuLabel}>{option.label}</label>
          </NavLink>
        ) : isUsingNavLinks === false ? ( // isUsingNavLinks is explicitly set and eq false
          <a href={option.link}>
            <label className={styles.dropdownMenuLabel}>{option.label}</label>
          </a>
        ) : (
          // isUsingNavLinks is not set
          <label className={styles.dropdownMenuLabel}>{option.label}</label>
        )}
      </div>
    ));
  };

  const classnames = cls(styles.selectedPlant, { [styles['chevron-flip']]: open });

  return (
    <div className={cls(styles.plantDropdownField, className)} ref={dropdownFieldRef}>
      <div
        onClick={handleToggleDropDownMenu}
        className={classnames}
        data-testid="report-plant-selector"
      >
        <RoundPlantIcon size={IconSize.large} src={selectedOption?.icon} />
        <label className={cls(styles.label, { [styles.truncate]: shouldTruncate })}>
          {selectedOption?.label}
        </label>
      </div>
      {renderDropDown()}
    </div>
  );
};

export default PlantDropdownField;
