import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';

import Button from 'Components/buttons';
import plusIcon from 'Components/buttons/icons/plus_dark.svg';
import Checkbox from 'Components/fields/checkbox';
import InputField from 'Components/fields/inputField';
import { FormValues } from 'Components/schemaForm';
import DateQuantityPickerRow from './DateQuantityPickerRow/DateQuantityPickerRow';
import FutureQuantity from './FutureQuantity';

import { getNextWorkingDay, addBusinessDays, getDateISOString } from 'Services/dateService';
import { PlantOrderField } from 'Types/plantTypes';
import { OrderExtraField } from 'Types/orderTypes';
import { Configuration } from 'Types/spaceTypes';
import { useSelection } from '../../helpers/hooks/useSelection';
import styles from './dateQuantityPicker.scss';
export interface QuantityPickerDate {
  id?: string;
  quantity: number;
  deliveryOn: string;
  loadTime?: string;
  nightshift: boolean;
  selected?: boolean;
  extraFields: OrderExtraField[];
}

export interface DateQuantityPickerProps {
  initialDates?: QuantityPickerDate[];
  extraFields: PlantOrderField[];
  shouldHideDailyOrderFields?: boolean;
  selectedDate?: string;
  desiredFutureQuantity?: number;
  calendarOpen?: boolean;
  showMoveFormBtn?: boolean;
  disabled?: boolean;
  errors?: any[];
  isEntryType?: boolean;
  spaceConfiguration?: Configuration;
  onMoveOrderDates?: (selectedOrderDatesId: (string | undefined)[]) => void;
}

const DateQuantityPicker = ({
  calendarOpen,
  showMoveFormBtn,
  disabled,
  extraFields,
  shouldHideDailyOrderFields,
  desiredFutureQuantity,
  errors,
  isEntryType = false,
  spaceConfiguration = {} as Configuration,
  onMoveOrderDates = () => undefined,
}: DateQuantityPickerProps) => {
  const [moveDates, setMoveDates] = useState<number>(0);
  const [moveDatesView, toggleMoveDatesView] = useState<boolean>(false);
  const shouldShowValue = !isEntryType || spaceConfiguration.value_present;

  const configNames = {
    quantity: {
      label: isEntryType ? spaceConfiguration.value_name : 'quantity',
      fieldName: isEntryType ? 'value' : 'quantity',
    },
    deliveryOn: {
      label: isEntryType ? spaceConfiguration.entry_date_name_single : 'deliveryOn',
      fieldName: isEntryType ? 'date' : 'deliveryOn',
    },
    loadTime: {
      label: isEntryType ? spaceConfiguration.entry_time_name : 'loadTime',
      fieldName: isEntryType ? 'time' : 'loadTime',
    },
  };

  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray<FormValues, 'dates', '_id'>({
    keyName: '_id',
    name: 'dates',
  });
  const watchDatesArray = methods.watch('dates');
  const controlledDates: any[] = fields.map((field, index) => {
    return {
      ...field,
      ...watchDatesArray[index],
    };
  });

  const {
    selectItem,
    deselectItem,
    selectAllItems,
    clearSelectedItems,
    isItemSelected,
    areAllItemsSelected,
    isAnyItemSelected,
    getItemsIndexesToRemove,
    getSelectedItems,
  } = useSelection({ fields });

  const calculcateFutureQuantity = () => {
    return controlledDates
      .filter(date => {
        const deliveryOnDateObj = new Date(date.deliveryOn);
        const currentDateISOString = getDateISOString();
        const currentDateObj = new Date(currentDateISOString);
        return deliveryOnDateObj.valueOf() - currentDateObj.valueOf() >= 0;
      })
      .map(date => parseInt(date.quantity))
      .reduce((acc, el) => acc + el, 0);
  };

  const handleAddDate = () => {
    const previousDate: QuantityPickerDate = controlledDates[controlledDates.length - 1];
    const newDate: any = {
      [configNames.quantity.fieldName]: previousDate[configNames.quantity.fieldName],
      [configNames.deliveryOn.fieldName]: getNextWorkingDay(
        previousDate[configNames.deliveryOn.fieldName],
      ),
      [configNames.loadTime.fieldName]: previousDate[configNames.loadTime.fieldName],
      selected: false,
      nightshift: previousDate.nightshift,
      extraFields: previousDate.extraFields.map(extraField => ({
        ...extraField,
        value: null,
      })),
    };
    append({ ...newDate });
  };

  const handleSelect = (index: number) => {
    const isSelected = isItemSelected(index);

    isSelected ? deselectItem(index) : selectItem(index);
    toggleMoveDatesView(false);
  };

  const handleSelectAll = ({ target }) => {
    target.checked ? selectAllItems() : clearSelectedItems();
    toggleMoveDatesView(false);
  };

  const handleRemove = () => {
    if (fields.length <= 1) return;

    const datesToRemove = getItemsIndexesToRemove();

    remove(datesToRemove);
    clearSelectedItems();
  };

  const handleMoveDate = () => {
    const daysToMove = moveDates;

    methods.setValue(
      'dates',
      fields.map((date, i) => {
        return isItemSelected(i)
          ? { ...date, deliveryOn: addBusinessDays(date.deliveryOn, daysToMove) }
          : date;
      }),
    );

    setMoveDates(0);
    clearSelectedItems();
  };

  const showMoveDatesView = () => toggleMoveDatesView(true);

  const handleMoveDateChange = e => {
    const datesToMove = e.target.value;
    setMoveDates(datesToMove);
  };

  const moveToAnotherPlant = () => {
    const selectedOrderDates = getSelectedItems();
    const selectesDatesIds = selectedOrderDates.map(date => date.id);

    onMoveOrderDates(selectesDatesIds);
  };

  const renderMoveDateView = () => (
    <div className={styles.moveDates} data-testid="move-dates-view">
      <span className="mr-md">{I18n.t('by')}</span>
      <div style="width: 50px">
        <InputField
          type="number"
          name="moveDates"
          value={moveDates.toString()}
          disabled={disabled}
          onChange={handleMoveDateChange}
        />
      </div>
      <span className="ml-md mr-md">{I18n.t('weekdays')}</span>
      <Button label={I18n.t('orderForm.moveDates')} disabled={disabled} onClick={handleMoveDate} />
    </div>
  );

  const renderSelectDateView = () => {
    if (!isAnyItemSelected) return null;

    return (
      <div className={styles.selectedDateButtons}>
        {!moveDatesView && (
          <Fragment>
            <Button
              label={I18n.t('remove')}
              color="warning"
              disabled={disabled}
              onClick={handleRemove}
            />
            <Button
              color="accent"
              label={I18n.t('orderForm.moveDates')}
              disabled={disabled}
              onClick={showMoveDatesView}
            />
            {showMoveFormBtn && (
              <Button
                color="accent"
                label={I18n.t('orderForm.movePlant')}
                className="phone-hidden"
                disabled={disabled}
                onClick={moveToAnotherPlant}
              />
            )}
          </Fragment>
        )}

        {moveDatesView && renderMoveDateView()}
      </div>
    );
  };

  const renderAddDateBtn = () => {
    if (isAnyItemSelected) return null;

    return (
      <Button
        color="alternative"
        icon={plusIcon}
        label={I18n.t('orderForm.addDates')}
        disabled={disabled}
        className={styles.addDateBtn}
        onClick={handleAddDate}
      />
    );
  };

  return (
    <div className={styles.dateQuantityContainer} data-testid="date-quantity-picker">
      {!!controlledDates.length && (
        <div>
          <div className={styles.dateQuantityHeader} data-testid="dqp-header">
            <div className={styles.header}>
              {isEntryType
                ? `${spaceConfiguration.entry_name_single} Days`
                : I18n.t('orderForm.deliveryDates')}
            </div>
            {renderAddDateBtn()}
            {renderSelectDateView()}
          </div>
          <div className="grid-row ai-center phone-hidden mb-sm" data-testid="dqp-labels">
            {!shouldHideDailyOrderFields && <div className="grid-col grid-col-md-1"></div>}
            <div className="grid-col grid-col-md-1">
              <Checkbox
                formLabel="#"
                onChange={handleSelectAll}
                checked={areAllItemsSelected}
                disabled={disabled}
                margin="none"
              />
            </div>
            {shouldShowValue && (
              <div className="grid-col grid-col-md-2">
                <span className={styles.th}>
                  {isEntryType ? configNames.quantity.label : I18n.t('orderForm.quantity')}
                </span>
              </div>
            )}
            <div
              className={cls('grid-col', {
                ['grid-col-md-3']: shouldShowValue,
                ['grid-col-md-4']: !shouldShowValue,
              })}
            >
              <span className={styles.th}>
                {isEntryType ? configNames.deliveryOn.label : I18n.t('orderForm.deliveryDate')}
              </span>
            </div>
            <div
              className={cls('grid-col', {
                ['grid-col-md-3']: shouldShowValue,
                ['grid-col-md-4']: !shouldShowValue,
              })}
            >
              <span className={styles.th}>
                {isEntryType ? configNames.loadTime.label : I18n.t('loadTime')}
              </span>
            </div>
          </div>
          <div data-testid="dqp-dates-container">
            {controlledDates.map((date, i) => (
              <DateQuantityPickerRow
                key={date._id}
                date={date}
                extraFields={extraFields}
                index={i}
                selected={isItemSelected(i) || false}
                calendarOpen={calendarOpen}
                shouldHideDailyOrderFields={shouldHideDailyOrderFields}
                disabled={disabled}
                errors={errors}
                isEntryType={isEntryType}
                spaceConfiguration={spaceConfiguration}
                onSelect={handleSelect}
              />
            ))}
          </div>
          <div
            className={styles.addOrderDateBottomContainer}
            data-testid="dqp-add-order-date-bottom-container"
          >
            <FutureQuantity
              totalFuture={calculcateFutureQuantity()}
              totalOrdered={desiredFutureQuantity}
            />
            {renderAddDateBtn()}
            {renderSelectDateView()}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateQuantityPicker;
