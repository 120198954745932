const NEXT_LOCATION = 'next_loaction';

export const setNextLocation = location => {
  localStorage.setItem(NEXT_LOCATION, location);
};

export const getNextLocation = () => {
  return localStorage.getItem(NEXT_LOCATION);
};

export const deleteNextLocation = () => {
  localStorage.removeItem(NEXT_LOCATION);
};
