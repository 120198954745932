import { h } from 'preact';
import { I18n } from 'react-redux-i18n';
import cls from 'classnames';

import { getCurrentYear, getNow } from 'Services/dateService';
import { smoothScrollTo } from '../../utils/smoothScrollTo';

import logo from 'Assets/img/plant-demand-logo.svg';
import arrowUp from 'Assets/icons/arrowUp.svg';
import iosAppIcon from '../../assets/img/ios.png';
import androidAppIcon from '../../assets/img/android.png';

import styles from './styles.scss';

interface NewFooterProps {
  className?: string;
}
const NewFooter = ({ className }: NewFooterProps) => {
  const scrollToTop = () => {
    smoothScrollTo(100);
  };

  return (
    <div className={cls(styles.footerWrapper, className)}>
      <div className={[styles.footer, 'layoutContainer'].join(' ')}>
        <div role="button" className={styles.arrowBtn} onClick={scrollToTop}>
          <img src={arrowUp} />
        </div>
        <div className={styles.leftSection}>
          <div className={styles.sectionHeader}>{I18n.t('help')}</div>
          <div>
            <a
              href="https://plantdemand.com/academy/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {I18n.t('academy')}
            </a>
          </div>
          <div>
            <a href="mailto:support@plantdemand.com" className={styles.link}>
              {I18n.t('support')}
            </a>
          </div>
        </div>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.sectionHeader}>{I18n.t('contact')}</div>
          <div>
            <a href="mailto:support@plantdemand.com" className={styles.link}>
              {I18n.t('email')}
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/plantdemand/posts/?feedView=all"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {I18n.t('linkedIn')}
            </a>
            -
            <a
              href="https://www.instagram.com/plantdemand"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {I18n.t('instagram')}
            </a>
          </div>

          <div className={styles.appIconContainer}>
            <a
              href="https://apps.apple.com/en/app/plant-demand/id1079440019"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.appIconMargin}
            >
              <img className={styles.appIcon} src={iosAppIcon} alt="Download iOS App" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.plantdemand.android"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.appIcon} src={androidAppIcon} alt="Download Android App" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.subFooter}>
        <div className="layoutContainer">
          <div className={styles.copyRight}>
            <div>
              2014 - {getCurrentYear()} ©
              <a href="/site/impressum/" className={styles.companyName}>
                {I18n.t('mesch')}
              </a>{' '}
              {I18n.t('allRights')}
            </div>
            <div>
              <a className={styles.copyRightLink} href="https://status.plantdemand.com/">
                {I18n.t('copyright.systemStatus')} -
              </a>
              <a className={styles.copyRightLink} href="/site/privacy-policy/">
                {I18n.t('privacyPolicy')} -
              </a>
              <a className={styles.copyRightLink} href="/site/terms-and-conditions/">
                {I18n.t('termsOfService')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
