import { call, put, takeLatest } from 'redux-saga/effects';

import { listNotes, createNote, editNote, deleteNote } from '../../services/noteService';
import {
  CreateNoteActionRequested,
  DeleteNoteActionRequested,
  EditNoteActionRequested,
  ListNotesActionRequested,
  NoteActionTypes,
} from '../actions/noteActions';

export function* fetchNotes({ payload }: ListNotesActionRequested) {
  try {
    const notes = yield call(listNotes, payload);
    yield put({ type: NoteActionTypes.NOTES_FETCH_SUCCEEDED, notes });
  } catch (error) {
    yield put({ type: NoteActionTypes.NOTES_FETCH_FAILED, error });
  }
}

export function* createNoteSaga({ payload }: CreateNoteActionRequested) {
  try {
    const note = yield call(createNote, payload);
    yield put({ type: NoteActionTypes.NOTE_CREATE_SUCCEEDED, note });
  } catch (error) {
    yield put({ type: NoteActionTypes.NOTE_CREATE_FAILED, error });
  }
}

export function* editNoteSaga({ payload }: EditNoteActionRequested) {
  try {
    const note = yield call(editNote, payload);
    yield put({ type: NoteActionTypes.NOTE_EDIT_SUCCEEDED, note });
  } catch (error) {
    yield put({ type: NoteActionTypes.NOTE_EDIT_FAILED, error });
  }
}

export function* deleteNoteSaga({ payload }: DeleteNoteActionRequested) {
  try {
    const noteId = yield call(deleteNote, payload);
    yield put({ type: NoteActionTypes.NOTE_DELETE_SUCCEEDED, noteId });
  } catch (error) {
    yield put({ type: NoteActionTypes.NOTE_DELETE_FAILED, error });
  }
}

export default function* watchNotes() {
  yield takeLatest(NoteActionTypes.NOTES_FETCH_REQUESTED, fetchNotes);
  yield takeLatest(NoteActionTypes.NOTE_CREATE_REQUESTED, createNoteSaga);
  yield takeLatest(NoteActionTypes.NOTE_EDIT_REQUESTED, editNoteSaga);
  yield takeLatest(NoteActionTypes.NOTE_DELETE_REQUESTED, deleteNoteSaga);
}
