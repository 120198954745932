import { setAccessToken, setCSRFToken } from '../utils/authService';
import apiMap from '../utils/apiMap/apiMap';
import { makeGraphQLRequest } from '../request';
import plantDemandAxios from '../plantDemandAxios';
import originalAxios, { AxiosResponse } from 'axios';
import { PlantId } from 'Types/plantTypes';
import { GraphQLError } from 'Errors/GraphQLError';

interface LoginInfo {
  username: string;
  password: string;
}
interface SetPlantNotificationProps {
  checked: boolean;
  plantId: PlantId;
}

export type UserPlantSettings = {
  plantId: PlantId;
  checked: boolean;
  receiveNotifications?: boolean;
  receiveDailyReport?: boolean;
  receiveCustomerNotifications?: boolean;
};

export type ChangePasswordpayload = {
  confirmed_password: string;
  old_password: string;
  password: string;
};

export type SignUpPayload = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password1: string;
  password2: string;
};

export type SignUpWithInvitationKeyPayload = SignUpPayload & { invitationKey: string };

export const ajaxLoginWithCookies = async ({ username, password }: LoginInfo) => {
  const formData = new FormData();
  formData.append('password', password);
  formData.append('username', username);

  const { data } = await plantDemandAxios.post(apiMap.ajaxLogin, formData);
  return data;
};

export const registerUser = async (data: SignUpPayload): Promise<{ detail: 'ok' }> => {
  const response: AxiosResponse<{ detail: 'ok' }> = await plantDemandAxios.post(
    apiMap.register,
    data,
  );
  return response.data;
};

export const registerUserWithInvitationKey = async ({
  invitationKey,
  ...rest
}: SignUpWithInvitationKeyPayload): Promise<{ detail: 'ok' }> => {
  const response: AxiosResponse<{ detail: 'ok' }> = await plantDemandAxios.post(
    apiMap.registerWithInvitationKey(invitationKey),
    rest,
  );

  return response.data;
};

export const login = async ({ username, password }: LoginInfo) => {
  const { data } = await plantDemandAxios.post(apiMap.login, { password, username });
  setAccessToken(data.token);
  await ajaxLoginWithCookies({ username, password });
};

export const checkSSODomain = async (domain: string): Promise<{ detail: string }> => {
  const { data } = await plantDemandAxios.get(apiMap.checkSSODomain(domain));
  return data;
};

export const logout = async (): Promise<string> => {
  const response: AxiosResponse<string> = await plantDemandAxios.post(apiMap.logout);
  return response.data;
};

/**
 * Retrieve the token from the server, update axios x-csrftoken header and save it to localStorage
 */
export const getCsrfToken = async () => {
  const { data } = await plantDemandAxios.get(apiMap.csrfToken);
  plantDemandAxios.defaults.headers.common['x-csrftoken'] = data.csrf;
  setCSRFToken(data.csrf);
  return data;
};

export const generateNewJwtToken = async () => {
  return originalAxios.get(apiMap.generateNewJwtToken);
};

export const changePassword = async (
  changePasswordPayload: ChangePasswordpayload,
): Promise<{ Success: boolean }> => {
  const response: AxiosResponse<{ Success: boolean }> = await plantDemandAxios.patch(
    apiMap.changePassword,
    changePasswordPayload,
  );
  return response.data;
};

export const tryNewDesign = async () => {
  const query = {
    query: `mutation {
      saveUserSettings(settingName: TRY_NEW_APP, settingValue: false) {
        __typename
        ... on SaveUserSettingsResponse {
          succeeded
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
  `,
  };

  const response = await makeGraphQLRequest(query, 'saveUserSettings');
  return response;
};
export const setReceiveNotifications = async ({ checked, plantId }: SetPlantNotificationProps) => {
  const query = {
    query: `mutation {
      saveUserSettings(settingName:RECEIVE_NOTIFICATIONS, settingValue:${checked}, plantId:${plantId}) {
        __typename
        ... on SaveUserSettingsResponse {
          succeeded
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }`,
  };

  const response = await makeGraphQLRequest(query, 'saveUserSettings');
  return response;
};

export const setDailyReportNotifications = async ({
  checked,
  plantId,
}: SetPlantNotificationProps) => {
  const query = {
    query: `mutation {
      saveUserSettings(settingName:RECEIVE_DAILY_REPORT, settingValue:${checked}, plantId:${plantId}) {
        __typename
        ... on SaveUserSettingsResponse {
          succeeded
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }`,
  };

  const response = await makeGraphQLRequest(query, 'saveUserSettings');
  return response;
};

export const setReceiveCustomerNotifications = async ({
  checked,
  plantId,
}: SetPlantNotificationProps) => {
  const query = {
    query: `mutation {
      saveUserSettings(settingName:RECEIVE_CUSTOMER_NOTIFICATIONS, settingValue:${checked}, plantId:${plantId}) {
        __typename
        ... on SaveUserSettingsResponse {
          succeeded
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }`,
  };

  const response = await makeGraphQLRequest(query, 'saveUserSettings');
  return response;
};

export const getUserPlantSettings = async (): Promise<UserPlantSettings[]> => {
  const query = {
    query: `{
      userPlantSettingsList{
        receiveNotifications,
        receiveDailyReport,
        receiveCustomerNotifications,
        plantId
      }
    }`,
  };

  const response = await makeGraphQLRequest(query);

  const userPlantSettings: UserPlantSettings[] = response.userPlantSettingsList;

  return userPlantSettings.map(plantSettings => {
    return {
      ...plantSettings,
      plantId: plantSettings.plantId.toString(),
    };
  });
};

export const getLoginStatus = async () => {
  const loginStatus = await plantDemandAxios.get(apiMap.loginStatus);

  return {
    userName: loginStatus.data.user_name,
    isAuthenticated: loginStatus.data.is_authenticated,
  };
};

export const getUserProfile = async () => {
  const query = {
    query: `{
      userInformation {
        id
        email
        firstName
        lastName
        fullName
        isSuperuser
        profile {
          tryGenericEntities
        }
      }
    }`,
  };

  const { userInformation } = await makeGraphQLRequest(query);
  const loginProfileResponse = await getLoginStatus();

  const profileData = {
    ...userInformation,
    userName: loginProfileResponse.userName,
    isAuthenticated: loginProfileResponse.isAuthenticated,
  };

  return profileData;
};

export const setFullName = async (firstName: string, lastName: string) => {
  const variables = {
    newFirstName: firstName,
    newLastName: lastName,
  };
  const query = {
    query: `mutation editUserUsername($newFirstName: String, $newLastName: String){
      editUserUsername(newFirstName: $newFirstName, newLastName: $newLastName) {
        __typename
        ... on EditUserUsernameResponse {
          success
          user {
            id
            firstName
            lastName
          }
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'editUserUsername');
  return response.editUserUsername;
};
