import { h, Component } from 'preact';
import cls from 'classnames';
import OptionalFormLabel from '../OptionalFormLabel';
import styles from './checkbox.scss';

class Checkbox extends Component<any, any> {
  componentDidUpdate(previousProps, previousState, previousContext) {
    if (this.props.checked !== previousState.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  componentWillMount() {
    this.setState({ checked: this.props.checked });
  }

  handleOnChange(event) {
    this.setState({ checked: event.target.checked });

    this.props.onChange && this.props.onChange(event);
  }

  render() {
    const {
      formLabel,
      name,
      value,
      inputRef,
      id,
      disabled,
      className,
      margin,
      hasError,
      errorMessage,
      other,
    } = this.props;
    const { checked } = this.state;

    return (
      <div className={cls(styles.checkboxWrapper, className)}>
        <label class={styles.checkboxContainer}>
          <input
            type="checkbox"
            checked={checked}
            value={value}
            name={name}
            ref={inputRef}
            id={id}
            onChange={this.handleOnChange.bind(this)}
            disabled={disabled}
            {...other}
          />
          <span
            className={cls(styles.checkboxCheckmark, { [styles.checkboxCheckmarkError]: hasError })}
          ></span>
          {formLabel && (
            <OptionalFormLabel
              className={styles.optionalFormLabel}
              forComponentId={id}
              margin={margin}
            >
              {formLabel}
            </OptionalFormLabel>
          )}
        </label>
        {hasError && (
          <span
            className={styles.errorStyle}
            data-testid="input-error-message"
            role="alert"
            aria-label={errorMessage}
          >
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
}

export default Checkbox;
