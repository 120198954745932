import { useRef } from 'preact/hooks';
import cls from 'classnames';
import styles from './dateQuantityPickerExtraFieldsStyles.scss';
import { ComponentChild } from 'preact';

type DateQuantityPickerExtraFieldsType = {
  expanded: boolean;
  className?: string;
  children: ComponentChild;
};

const DateQuantityPickerExtraFields = ({
  expanded,
  className,
  children,
}: DateQuantityPickerExtraFieldsType) => {
  const contentEl = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cls(className, styles.extraFieldsWrapper, {
        [styles.extraFieldsExpanded]: expanded,
      })}
      style={{ height: expanded ? contentEl?.current?.scrollHeight : '0px' }}
      ref={contentEl}
    >
      {children}
    </div>
  );
};

export default DateQuantityPickerExtraFields;
