import { ModalActions, ModalActionTypes, ModalProps } from '../actions/modalActions';

const initialState: ModalProps[] = [];

const modalReducer = (state = initialState, action: ModalActions) => {
  switch (action.type) {
    case ModalActionTypes.MODAL_OPEN:
      return [...state, action.payload];
    case ModalActionTypes.MODAL_CLOSE:
      return state.slice(0, -1);
    default:
      return state;
  }
};

export default modalReducer;
