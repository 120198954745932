import { CustomerActions, CustomerActionTypes } from 'Redux/actions/customerActions';
import { RequestStatusType } from 'Types/commonTypes';

type CustomerReducerInitialState = {
  totalPages: number;
  totalCount: number;
  nextPage: number | null;
  data: string[];
  fetchingCustomersStatus?: RequestStatusType;
  fetchingCustomersError?: any;
  fetchingMoreCustomersStatus?: RequestStatusType;
  fetchingMoreCustomersError?: any;
};

const initialState: CustomerReducerInitialState = {
  totalPages: 0,
  totalCount: 0,
  nextPage: null,
  data: [],
};

const plantReducer = (
  state = initialState,
  action: CustomerActions,
): CustomerReducerInitialState => {
  switch (action.type) {
    case CustomerActionTypes.FETCH_CUSTOMERS_REQUESTED:
      return { ...state, fetchingCustomersStatus: RequestStatusType.InProgress };
    case CustomerActionTypes.FETCH_CUSTOMERS_SUCCEEDED:
      return {
        ...state,
        data: action.payload.items,
        totalCount: action.payload.totalCount,
        nextPage: action.payload.nextPage,
        fetchingCustomersStatus: RequestStatusType.Completed,
        fetchingCustomersError: null,
      };
    case CustomerActionTypes.FETCH_CUSTOMERS_FAILED:
      return {
        ...state,
        fetchingCustomersStatus: RequestStatusType.Completed,
        fetchingCustomersError: action.payload,
      };
    case CustomerActionTypes.FETCH_MORE_CUSTOMERS_REQUESTED:
      return { ...state, fetchingMoreCustomersStatus: RequestStatusType.InProgress };
    case CustomerActionTypes.FETCH_MORE_CUSTOMERS_SUCCEEDED:
      return {
        ...state,
        data: [...state.data, ...action.payload.items],
        totalCount: action.payload.totalCount,
        nextPage: action.payload.nextPage,
        fetchingMoreCustomersStatus: RequestStatusType.Completed,
        fetchingMoreCustomersError: null,
      };
    case CustomerActionTypes.FETCH_MORE_CUSTOMERS_FAILED:
      return {
        ...state,
        fetchingMoreCustomersStatus: RequestStatusType.Completed,
        fetchingMoreCustomersError: action.payload,
      };
    default:
      return state;
  }
};

export default plantReducer;
