export const de = {
  copyright: {
    systemStatus: 'Systemstatus',
  },
  mixPlant: 'Pflanze mischen',
  aggregatePlant: 'Gesamtanlage',
  pavingSchedule: 'Pflasterplan',
  home: 'Start',
  mesch: 'MeSch Software UG (Haftungsbeschränkt).',
  allRights: 'Alle Rechte vorbehalten.',
  privacyPolicy: 'Datenschutz',
  termsOfService: 'Nutzungsbedingungen',
  whatsTheName: 'Wie heißt der neue Mitarbeiter?',
  employeeEmail: 'E-Mail-Adresse des Mitarbeiters:',
  dashboard: 'Dashboard',
  paving: 'Pflasterung',
  plant: 'Anlage',
  role: 'Rolle',
  cancel: 'Abbrechen',
  ok: 'OK',
  save: 'Speichern',
  add: 'Hinzufügen',
  type: 'Typ',
  required: 'Pflichtfeld',
  visibleToCustomers: 'Für Kunden sichtbar?',
  export: 'Exportieren',
  newField: 'Neues Feld',
  oldPassword: 'Altes Passwort',
  newPassword: 'Neues Passwort',
  confirmNewPassword: 'Neues Passwort Wiederholung',
  oldPasswordRequired: 'Das alte Passwort ist erforderlich!',
  newPasswordRequired: 'Das neue Passwort ist erforderlich!',
  newPasswordConfirmation: 'Bitte geben Sie Ihr neues Passwort erneut ein.',
  passwordsDontMatch: 'Die beiden Passwortfelder stimmten nicht überein.',
  passwordMinLength: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  passwordMustBeDifferent: 'Das neue Passwort muss sich vom alten Passwort unterscheiden.',
  passwordChangeDone: 'Dein Passwort wurde geändert. Klicken Sie hier, um sich abzumelden.',
  nameChangeDone: 'Ihr Name wurde geändert.',
  changePassword: 'Passwort ändern',
  password: 'Passwort',
  passwordPlaceholder: 'Passwort...',
  repeatNewPassword: 'Bitte das neue Passwort wiederholen!',
  repeatPassword: 'Passwort wiederholen',
  repeatPasswordPlaceholder: 'Passwort wiederholen...',
  termsAndConditions:
    'Starten Sie meine kostenlose Testphase und akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
  termsAndConditionsRequiredMsg: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
  email: 'Email',
  emailAddress: 'E-Mail-Addresse',
  emailPlaceholder: 'max@mustermann.de',
  emailAddressPlaceholder: 'max@mustermann.de',
  subscriberNamePlaceholder: 'Abonnentenname',
  code: 'Code',
  phone: 'Telefon',
  phoneNumber: 'Telefonnummer',
  fieldIsRequired: 'Das Feld %{fieldName} ist erforderlich',
  fieldIsMissing: '%{fieldName} existiert nicht',
  company: 'Gesellschaft',
  message: 'Nachricht',
  send: 'Senden',
  sending: 'Sendet',
  sendInvite: 'Einladung Senden',
  sendToMe: 'Zu mir Senden',
  sendToOrg: 'An mein Team senden',
  print: 'Drucken',
  download: 'Download',
  printReport: 'Druckbericht',
  minimumAmountIsRequired: 'Mindestmenge erforderlich!',
  maximumAmountIsRequired: 'Höchstbetrag erforderlich!',
  currentAmountIsRequired: 'Aktueller Betrag erforderlich!',
  nameIsRequired: 'Name ist erforderlich!',
  selectCountryCode: 'Bitte Ländercode auswählen!',
  firstNamePlaceholder: 'Vorname',
  lastNamePlaceholder: 'Nachname',
  reports: 'Auswertungen',
  account: 'Konto',
  referAFriend: 'Empfehlen Sie einen Freund',
  logOut: 'Abmelden',
  organizationMembership: 'Organisationsmitgliedschaft',
  employeeCount: '%{count} Mitarbeiter',
  members: 'Mitglieder',
  addAnEmployee: 'Fügen Sie einen Mitarbeiter hinzu',
  addANewPlant: 'Füge eine neue Pflanze hinzu',
  onlyAddEmployees: 'Sie sollten nur Mitarbeiter von %{name} hinzufügen.',
  onlyAddCurrent: 'Fügen Sie nur aktuelle Mitarbeiter hinzu',
  inviteNewEmployee: 'Laden Sie einen neuen Mitarbeiter zu %{name} ein',
  plants: 'Pflanzen',
  schedule: 'Zeitplan',
  edit: 'Bearbeiten',
  create: 'Erstellen',
  createPlant: 'Anlage erstellen',
  editPlant: 'Anlage bearbeiten',
  chooseSupplier: 'Anbieter wählen',
  supplier: 'Lieferant',
  suppliers: 'Lieferanten',
  materials: 'Materialien',
  productionMode: 'Produktionsmodus',
  mixDesignLabel: 'Mischrezept',
  activate: 'Aktivieren',
  deactivate: 'Deaktivieren',
  actions: 'Aktionen',
  notifications: 'Benachrichtigungen',
  address: 'Adresse',
  name: 'Name',
  firstName: 'Vorname',
  lastName: 'Nachname',
  saveName: 'Namen speichern',
  date: 'Datum',
  expired: 'Abgelaufen',
  picture: 'Bild',
  maxCapacity: 'Maximale Kapazität',
  plantName: 'Anlagenname',
  plantType: 'Anlagentyp',
  plantTypePlaceholder: 'Anlagentyp auswählen',
  search: 'Suche',
  searchPlaceholder: 'Suche...',
  nightShift: 'Nachtschicht',
  nightShiftCaps: 'NACHTSCHICHT',
  nightShiftFrom: 'Nachtschicht von',
  nightShiftTo: 'Nachtschicht bis',
  inactivePlants: 'Inaktive Anlagen',
  activePlants: 'Aktive Anlagen',
  addPlant: 'Anlage hinzufügen',
  addInputMaterial: 'Eingangsmaterial hinzufügen',
  addNewSupplier: 'Neuen Lieferanten hinzufügen',
  addSupplier: 'Lieferant hinzufügen',
  editSupplier: 'Lieferant bearbeiten',
  deleteSupplier: 'Lieferant löschen',
  deleteSupplierMessage: 'Möchten Sie den Lieferanten wirklich löschen?',
  deleteOrderField: 'Formularfeld löschen',
  deleteOrderFieldMessage: 'Möchten Sie das Formularfeld wirklich löschen?',
  yesDelete: 'Ja, löschen',
  yesDeactivate: 'Ja, deaktivieren',
  yesActivate: 'Ja, aktivieren',
  yes: 'Ja',
  description: 'Beschreibung',
  addEmpoyee: 'Mitarbeiter hinzufügen',
  reactivatePlant: 'Anlage reaktivieren',
  deactivatePlant: 'Anlage deaktivieren',
  configureSupplier: 'Lieferant konfigurieren',
  configurePlant: 'Anlage konfigurieren',
  billing: 'Abrechnung',
  changeCard: 'Karte ändern',
  confirm: 'Bestätigen',
  aysReactivate: 'Möchten Sie %{name} wirklich reaktivieren?',
  aysDeactivate: 'Möchten Sie %{name} wirklich deaktivieren?',
  toggleNavigation: 'Navigation ändern',
  login: 'Anmeldung',
  signUp: 'Anmelden',
  tryItOut: 'Zur Demo',
  learnMore: 'Mehr lernen',
  contact: 'Kontakt',
  services: 'Dienstleistungen',
  help: 'Hilfe',
  remove: 'Löschen',
  deletedPrefix: '[GELÖSCHT]',
  copy: 'Kopieren',
  verified: 'Bestätigt',
  newOrderRequest: 'Neue Anfrage',
  helpButtonText: 'BRAUCHEN SIE HILFE?',
  supportModalTitle: 'Wie können wir helfen?',
  supportModalInputPlaceholder: 'Was ist Ihre Frage oder Ihr Problem mit der Software?',
  supportModalSent: 'Die Anfrage wurde erfolgreich versendet.',
  supportModalError: 'Bitte kontaktieren Sie uns unter support@plantdemand.com',
  loading: 'Lädt...',
  submit: 'Absendn',
  academy: 'Akademie',
  support: 'Hilfe',
  walkthrough: 'Demo',
  reportBug: 'Technischen Fehler melden',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: 'Passwort vergessen?',
  staySignedIn: 'Angemeldet bleiben',
  logInAgain: 'Nochmal anmelden',
  logInPlantDemand: 'Melden Sie sich bei PlantDemand an',
  passwordRequiredFieldMsg: 'Sie müssen ein Passwort angeben',
  passwordMinLengthErrorMsg: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  emailRequiredFieldMsg: 'E-Mail erforderlich',
  newToPD: 'Neu bei PlantDemand?',
  newToPDText:
    'Wir freuen uns, Sie hier zu sehen! Sie können ein Konto erstellen und in 4 Minuten mit der Planung beginnen.',
  externalSupplierNotification:
    'Wenn Sie versuchen, einem externen Lieferanten oder Kunden Zugriff auf Ihre Anlagen zu gewähren, verwenden Sie stattdessen die Benutzerverwaltungsfunktionen auf der Registerkarte Anlagenkonfiguration.',
  inviteFriend: 'Einen Kontakt einladen',
  thankYou: 'Danke',
  referAFriendMessage:
    'Wir freuen uns, dass Ihnen PlantDemand gefällt und Sie es ihren Kollegen weiterempfehlen möchten.',
  thanks: 'Vielen Dank',
  reminder: 'Erinnerung:',
  warningCardTitleOnlyCurrentEmployee: 'Fügen Sie nur aktuelle Mitarbeiter hinzu',
  warningCardTextOnlyAddEmployee: 'Sie sollten nur Mitarbeiter hinzufügen',
  warningCardTextUseManagmentFeatures:
    'Wenn Sie versuchen, einem externen Lieferanten oder Kunden Zugriff auf Ihre Anlagen zu gewähren, verwenden Sie stattdessen die Benutzerverwaltungsfunktionen auf der Registerkarte Anlagenkonfiguration.',
  errorCardText:
    'Es gibt keine aktiven Werke in diesem Geschäft. Bitte fügen Sie eine neue Pflanze hinzu.',
  pendingInvitations: 'Ausstehende Einladungen',
  deleteInvitation: 'Einladung löschen?',
  aysDeleteInvitation: 'Möchten Sie die Einladung für %{user} wirklich löschen?',
  inputForecast: 'Eingangs-Prognose',
  outputForecast: 'Ausgangs-Prognose',
  production: 'Produktion',
  inventoryForecast: 'Bestandsprognose',
  dayView: 'Tagesansicht',
  using: 'Mit',
  by: 'Durch',
  from: 'Von',
  until: 'Bis um',
  weekdays: 'Wochentage',
  filters: 'Filter',
  shortcuts: 'Abkürzungen',
  for: 'ZUM',
  selectedPlants: '%{count} Anlagen ausgewählt',
  plantsCaps: 'ANLAGEN',
  noPlants: 'Keine Anlagen',
  forAllPlants: 'FÜR ALLE ANLAGEN',
  forOnePlant: 'FÜR EINE ANLAGEN',
  thisWeek: 'In dieser Woche',
  nextWeek: 'Nächste Woche',
  nextTwoWeeks: 'Zwei Wochen',
  threeWeeks: 'Drei Wochen',
  thisMonth: 'Diesen Monat',
  nextMonth: 'Nächsten Monat',
  thisQuarter: 'Dieses Quartal',
  thisYear: 'Dieses Jahr',
  day: 'Tag',
  night: 'Nacht',
  week: 'Woche',
  month: 'Monat',
  refresh: 'Aktualisierung',
  selectMaterial: 'Material auswählen',
  xlsx: 'XLSX',
  youDontHavePlants: 'Sie haben noch keine Anlagen.',
  youCanConfigurePlants: 'Sie können Ihre Anlagen konfigurieren',
  accountDownCase: 'Konto',
  screen: 'Bildschirm.',
  youDontHaveOrganisation:
    'Sie haben noch keine Organisation. Wenn Ihr Unternehmen PlantDemand bereits verwendet, bitten Sie Ihren Vorgesetzten, Sie einzuladen. Auf der können Sie Ihre Unternehmensanlagen konfigurieren',
  page: 'Seite.',
  registerOrganisation: 'Registrieren Sie eine neue Organisation',
  askCompanyToConfigureBusiness:
    'Wenn Ihr Unternehmen PlantDemand bereits verwendet, bitten Sie Ihren Vorgesetzten, Sie einzuladen. Auf der können Sie Ihre Unternehmensanlagen konfigurieren',
  availableFields: 'Verfügbare Felder:',
  updatedMaterialMsg: 'Das Material wurde erfolgreich aktualisiert',
  updatedMaterialFailedMsg: 'Material kann nicht aktualisiert werden',
  deleteMaterialMsg: 'Das Material wurde erfolgreich gelöscht',
  deleteMaterialFailedMsg: 'Material kann nicht gelöscht werden',
  addNewSupplierMsg: 'Der Lieferant wurde erfolgreich hinzugefügt',
  groupBy: 'Zusammenfassen nach:',
  pivotReport: 'Pivot-Table',
  productionReport: 'Produktionsprognose',
  noReportData: 'Für den ausgewählten Zeitraum liegen keine Daten vor.',
  materialName: 'Materialname',
  total: 'Gesamt',
  customer: 'Kunde',
  dates: 'Termine',
  quantity: 'Menge',
  loadTime: 'Ladezeit',
  material: 'Material',
  compact: 'Kompakt',
  minimal: 'Minimal',
  materialCompact: 'Material kompakt',
  requestPending: 'Anfrage ausstehend',
  list: 'Liste',
  endDailyReport: 'BEENDEN SIE DEN TAGESBERICHT FÜR',
  addTime: 'Zeit hinzufügen',
  to: 'Bis',
  orderBy: 'Sortieren nach',
  me: 'Mir',
  myOrganization: 'Meine Organisation',
  teamNotifications: 'Teambenachrichtigungen',
  dailyReport: 'Tagesbericht',
  plantSettings: 'Benachrichtigungseinstellungen',
  myProfile: 'Mein Profil',
  all: 'Alle',
  pleaseSelect: 'Bitte auswählen',
  selectAll: 'Alle auswählen',
  inventory: 'Inventar',
  desiredRange: 'Gewünschte Reichweite:',
  minimumInventory: 'Mindestbestand:',
  maximumInventory: 'Maximaler Bestand:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Δ',
  inventoryForecastLegend: 'Δ - Veränderung ↑ - Produktion ↓ - Verkauf',
  goToPlant: 'Zur Anlage gehen',
  noOrdersOn: 'Keine Bestellungen auf',
  emailSentToMe: 'E-Mail wurde an Sie gesendet',
  emailSentToMyOrganization: 'Die E-Mail wurde an Ihre Organisation gesendet',
  removeNoteConfirmMessage: 'Diese Notiz wirklich löschen?',
  comment: 'Kommentar',
  updates: 'Aktualisierung',
  addComment: 'Einen Kommentar hinzufügen',
  commentPlaceholder: 'Geben Sie Ihren Kommentar ein',
  dropdownChoosePlaceholder: '- Wählen -',
  showAll: 'Alles zeige',
  showLatest: 'Neueste anzeigen',
  hide: 'Verstecken',
  show: 'Zeigen',
  errorTitle: 'Fehler',
  noOptions: 'Keine Optionen',
  components: 'Komponenten',
  errorMessage: 'Hoppla! Etwas ist schief gelaufen',
  requestErrorMessage:
    'Es ist ein Fehler aufgetreten. Wenn Sie nicht weiterkommen, drücken Sie bitte auf Hilfe.',
  tryNewAppVersionMessage:
    'Wir freuen uns, dass Sie die neue Version von PlantDemand ausprobieren 🎉 Senden Sie uns gerne Ihr Feedback.',
  switchInterfaceMessage: 'Wechseln Sie zur ursprünglichen Schnittstelle',
  overProductionTitle: 'Überproduktion',
  overProductionMessage: 'Sie sind um %{overProductionCount} überlastet',
  none: 'Keiner',
  showAllOptions: 'Alle %{count} Optionen anzeigen',
  visibleToCustomersLabel: 'Für Kunden sichtbar?',
  requestMaterial: 'Material anfordern',
  calendar: {
    addOrderText: 'Bestellung hinzufügen',
    tentative: 'Vorsichtig',
  },
  checkbox: {
    selectAll: 'Wählen Sie Alle',
    deselectAll: 'Alle abwählen',
  },
  calendarWeek: {
    newOrder: 'Neue Ordnung',
    addNote: 'Notiz hinzufügen',
    viewInputMaterial: 'Eingabematerial anzeigen',
    viewDayReport: 'Tagesbericht anzeigen',
    viewEndOfDayReport: 'Tagesabschlussbericht anzeigen',
  },
  reportsPage: {
    calendarData: 'Kalenderdaten',
    groupBy: 'Zusammenfassen nach',
    pivotBy: 'Pivot um',
    pivotByPlaceholder: 'Schwenken um...',
    presets: 'Vorwahlen...',
    timeFrame: 'Zeitraum',
    sendReportFailedMsg: 'Der Bericht kann nicht gesendet werden',
  },
  calendarFilterBar: {
    fields: 'Felder',
    fieldsPlaceholder: 'Ausgewählte Felder',
    countFieldsPlaceholder: '%{count} Felder',
    startDate: 'Anfangsdatum',
    endDate: 'Enddatum',
    view: 'Bestellansicht',
    move: 'Bewegen',
    plants: 'Anlagen',
    dates: 'Termine',
    dateRange: 'Datumsbereich',
    selectRange: 'Bereich auswählen',
    quantityRange: 'Menge Bereich',
    min: 'Minimum',
    max: 'Maximum',
    goToToday: 'Zu heute gehen',
  },
  plantDropdown: {
    plantImageAlt: 'Anlagen bild',
  },
  modal: {
    close: 'Schließen',
  },
  orderModal: {
    deleteOrder: 'Auftrag löschen',
    deleteOrderConfirmationMsg:
      'Diese Aktion löscht %{count} Bestellung(en). Sind Sie sich sicher?',
    notifyTeam: 'Team benachrichtigen',
    disabledChangePlantTooltipTitle:
      'Bitte speichern Sie diese Bestellung, bevor Sie sie in ein anderes Werk verschieben',
  },
  orderRequestModal: {
    orderRequest: 'Bestellanfrage',
    confirmedOrder: 'Bestellung bestätigen',
    urgentRequest: 'Dringend',
  },
  noteModal: {
    addNoteDate: 'Datum hinzufügen',
    noteDate: 'Datum',
    plant_closure: 'Anlagenschließung',
    custom: 'Notiz',
    unknown: 'Unbekannt',
    crew: 'Team / Urlaub',
    maintenance: 'Wartung',
    alert: 'Alarm',
    inspection: 'Inspektion',
    other: 'Sonstiges',
    fieldError: 'Dieses Feld ist erforderlich',
    descriptionLabel: 'Beschreibung *',
    descriptionPlaceholder: 'Beschreibung ...',
    titleLabel: 'Titel',
    titlePlaceholder: 'Kunde',
    typeLabel: 'Notiztyp',
    typePlaceholder: 'Anlagenschließung',
    serverErrorText1:
      'Leider konnten wir Ihre Notiz nicht speichern. Unser Server hat den folgenden Fehler ausgegeben',
    serverErrorText2:
      'Wenn dieses Problem weiterhin auftritt, <a>klicken Sie hier</a> , um sich an PlantDemand zu wenden',
    notifyTeam: 'Team benachrichtigen',
  },
  orderForm: {
    order: 'Bestellung',
    addDates: 'Termine hinzufügen',
    deliveryDates: 'Lieferdaten',
    quantity: 'Menge *',
    deliveryDate: 'Lieferdatum',
    deliveryTime: 'Lieferzeit',
    nightShift: 'Nachtschicht',
    create: 'Erstellen',
    save: 'Speichern',
    saving: 'Speichere',
    remove: 'Löschen',
    copy: 'Kopieren',
    copyOrderMsg:
      'Wir haben eine Kopie der Bestellung #%{copyOrderId} erstellt. Wählen Sie das neue Lieferdatum und drücken Sie &quot;Speichern&quot;',
    moveDates: 'Termine ändern',
    movePlant: 'Anlage ändern',
    changePlant: 'Werk ändern',
    customerVisibleField: 'Dieses Feld ist für die Kunden sichtbar',
    fieldIsRemoved: 'Dieses Feld wurde aus Ihrem Formular entfernt',
    errorMessage: 'Etwas ist schief gelaufen',
    unknownFieldType: 'Unbekannter Feldtyp',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Eingangsmaterial',
    quantity: 'Menge',
    supplier: 'Anbieter',
    noOrdersMessage:
      'Es liegen keine Einsatzmaterialien vor, da an diesem Datum keine Aufträge terminiert sind',
    addOrderLink: 'Klicken Sie hier, um eine Bestellung hinzuzufügen.',
    noInputMaterials:
      '<span>Dieser %{name} hat keine Eingangsmaterialien.</span> <a>Klicken Sie hier</a> <span>, um Eingabematerial zu %{name} hinzuzufügen</span>',
    noInputMaterialsForDay: 'Für diesen Tag sind keine Inputmaterialien verfügbar.',
    clickToAddOrder: 'Klicken Sie hier, um eine neue Bestellung hinzuzufügen',
    clickToAddMixDesign: 'Klicken Sie hier, um Ihre Mix-Designs zu verwalten.',
  },
  monthNames: {
    January: 'Januar',
    February: 'Februar',
    March: 'Marsch',
    April: 'April',
    May: 'Kann',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Dezember',
  },
  activityList: {
    order: 'Befehl',
    time: 'Zeit',
    change: 'Veränderung',
    user: 'Benutzer',
    noData: 'Keine Ergebnisse gefunden.',
  },
  ordersList: {
    edit: 'Bearbeiten',
    material: 'Material',
    quantity: 'Menge',
    customer: 'Kunde',
    deliveryDate: 'Lieferdatum',
    createdDate: 'Erstellungsdatum',
    noData: 'Keine Ergebnisse gefunden.',
    checkAll: '#',
    actions: 'Aktionen',
  },
  plantHeader: {
    orders: 'Aufträge',
    planner: 'Planer',
    activity: 'Aktivität',
    requests: 'Anfragen',
    productionSchedule: 'Produktionsplan',
    plantSettings: 'Anlageneinstellungen',
    cogIcon: 'Zahnrad-Symbol',
    newOrder: 'Neue Ordnung',
  },
  accountHeader: {
    account: 'Konto',
    membership: 'Mitgliedschaft',
    plants: 'Pflanzen',
    suppliers: 'Lieferanten',
    billing: 'Abrechnung',
    notifications: 'Benachrichtigungen',
    myProfile: 'Mein Profil',
  },
  moveOrder: {
    selectOrdersHeader: 'Ihre ausgewählten Bestellungen',
    selectPlant: 'Pflanze auswählen',
    selectMaterial: 'Material auswählen',
    moveSelectedOrdersBtn: 'Ausgewählte Bestellungen verschieben',
    copySelectedOrdersBtn: 'Ausgewählte Bestellungen kopieren',
    disableBtnMessage: 'Bitte Zielwerk und Material auswählen',
    disableMoveOrderBtnMessage: 'Bitte wählen Sie mindestens ein Feld aus',
    keepOriginalOrderLabel: 'Behalten Sie die ursprüngliche Reihenfolge bei',
    selectMatchingField: 'Entfernen Sie das %{fieldName}-Feld',
    confirmPlantAndMaterial: 'Werk &amp; Material bestätigen',
    moveOrderFrom: 'Bestellung verschieben von',
    moveOrderTo: 'Bestellung verschieben nach',
    deselectOrderMessage: 'Das Bestelldatum wurde abgewählt.',
    undo: 'Rückgängig machen',
    bubbleMsgHeader: 'Verschieben Sie Ihre Eingabefelder',
    bubbleMsgSubHeader:
      'Passen Sie die Eingabefelder an, um sicherzustellen, dass keine Informationen verloren gehen, wenn Sie Ihre ausgewählten Bestellungen verschieben. Wählen Sie mindestens ein Eingabefeld aus, um diese Bestellung zu verschieben.',
  },
  orderFormConfiguration: {
    fieldAddedSuccessfully: 'Das Feld wurde erfolgreich hinzugefügt',
    addFieldFailedMsg: 'Das Feld kann nicht hinzugefügt werden',
    fieldUpdatedSuccessfully: 'Das Feld wurde erfolgreich aktualisiert',
    fieldDeletedSuccessfully: 'Das Feld wurde erfolgreich gelöscht',
    fieldMovedSuccessfully: 'Das Feld wurde erfolgreich verschoben',
  },
  OrderFormConfigInfoPanel: {
    header: 'Hier können Sie die Felder auf Ihren Pflanzenbestellformularen anpassen:',
    requiredFields: 'Benötigte Felder',
    optionalFields: 'Optionale Felder',
    optionsHeaderExampleText: 'Sie können zum Beispiel:',
    addTextFieldExampleText: 'Fügen Sie ein Textfeld für LKW-Ladungen hinzu',
    addTextAreaExampleText: 'Fügen Sie einen Textbereich mit Abholanweisungen hinzu',
    addCheckboxExampleText:
      'Fügen Sie ein Kontrollkästchen hinzu, um Abholungen in der Nachtschicht anzuzeigen',
    visibleToCustomersText:
      'Markieren Sie Felder als für Kunden sichtbar, damit Kunden diese Felder bei Bestellanfragen anzeigen und bearbeiten können.',
    fieldOrder: 'Feldreihenfolge',
    fieldName: 'Feldname',
  },
  addInputMaterialPage: {
    materialProperties: 'Materialeigenschaften',
    materialName: 'Material Name',
    supplierProductName: 'Produktcode/Name des Lieferanten',
    supplierProductNamePlaceholder: 'Geben Sie den Produktcode/Namen des Lieferanten ein Name',
    inventoryAmount: 'Bestandsmenge',
    minimum: 'Minimum',
    maximum: 'Maximal',
    current: 'Aktuell',
    systemColor: 'Systemfarbe',
    materialColor: 'Materialfarbe',
    isOutputMaterial: 'Ausgangsmaterial ist',
    addInputMaterial: 'Eingabematerial hinzufügen',
    materialNamePlaceholder: 'Materialname (zB 1234 - Name)',
    deleteMaterial: 'Material löschen',
    deleteMaterialConfirmationMsg: 'Möchten Sie dieses Material wirklich löschen?',
  },
  addSupplierModal: {
    supplierName: 'Name des Anbieters',
    supplierAddress: 'Lieferantenadresse',
  },
  mixDesign: {
    deleteMixDesign: 'Mischdesign löschen',
    deleteMixDesignderConfirmationMsg: 'Möchten Sie dies wirklich löschen?',
    updatedMixDesignSucceededMsg: 'Das Mix-Design wurde erfolgreich aktualisiert',
    updatedMixDesignFailedMsg: 'Mix-Design kann nicht aktualisiert werden',
    createdMixDesignSucceededMsg: 'Das Mix-Design wurde erfolgreich erstellt',
    createdMixDesignFailedMsg: 'Mix-Design kann nicht erstellt werden',
    deleteMixDesignSucceededMsg: 'Das Mix-Design wurde erfolgreich gelöscht',
    deleteMixDesignFailedMsg: 'Mischdesign kann nicht gelöscht werden',
    addMixDesign: 'Mix-Design hinzufügen',
    addNewMaterial: 'Neues Material hinzufügen',
    addInputMaterial: 'Eingabematerial hinzufügen',
    addOutputMaterial: 'Ausgabematerial hinzufügen',
    selectOutputMaterial: 'Wählen Sie Ausgabematerial',
    expirationDate: 'Verfallsdatum',
    specificationBasis: 'Spezifikationsbasis',
    addComponent: 'Komponente hinzufügen',
    percent: 'Prozent',
    components: 'Komponenten',
    noComponentsMsg: 'Es gibt keine Komponenten',
    noMixDesignsMsg: 'Keine Mix-Designs gefunden',
    mixDesignErrorMsg: 'Das Mix-Design kann nicht geladen werden',
  },
  materialsPage: {
    noMaterialsMsg: 'Keine Materialien gefunden',
    outputMaterial: 'Ausgangsmaterial',
  },
  informativePanel: {
    productionMode: 'Produktionsmodus',
    productionModeDescription:
      'Produktionsmodi produzieren „Rohstoffe“, die direkt bestellt oder über „Mix-Designs“ zu neuen Materialien kombiniert werden können.',
    rawMaterial: 'Rohstoffe %',
    mixDesign: 'Mix-Design',
    mixDesignDescription:
      'Kombinieren Sie &quot;Rohstoffe&quot;, um &quot;Ausgangsmaterialien&quot; zu erstellen.',
  },
  subscribers: {
    subscribers: 'Abonnenten',
    newSubscriber: 'Neuer Abonnent',
    addNewSubscriber: 'Neuen Abonnenten hinzufügen',
    infoSection:
      'Sie können E-Mail-Adressen von Personen hinzufügen, die Berichte als Teil Ihrer Organisation erhalten.',
  },
  customers: {
    infoSection: 'Exportieren Sie diese Liste in ein anderes Werk. Neuen Kunden anlegen',
    viewOrders: 'Bestellungen anzeigen',
    noCustomersMsg: 'Keine Kunden gefunden',
    targetPlant: 'Zielpflanze',
    selectTargetPlant: 'Zielpflanze auswählen',
    exportCustomers: 'Kunden exportieren',
    customerName: 'Kundenname',
    customerList: 'Kundenliste',
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'Anlageninformationen wurden erfolgreich aktualisiert',
    changePhoto: 'Foto ändern',
    toLabel: 'zu',
    exportSectionText1: 'Ihre PlantDemand-Daten gehören Ihnen!',
    exportSectionText2:
      'Exportieren Sie Ihre Bestelldaten als CSV-Datei, um Backups zu erstellen, oder verwenden Sie sie, um Ihre eigenen Excel-Berichte zu erstellen. Denken Sie daran, hier erneut vorbeizuschauen, um die aktuellste Version der Daten zu erhalten.',
    exportSectionText3:
      'Klicken Sie mit der rechten Maustaste auf den unten stehenden Link und wählen Sie „Ziel speichern unter“ oder „Link speichern unter“;',
    exportCSVFileLabel: 'CSV-Datei exportieren',
    backToPlant: 'Zurück zur Pflanze',
  },
  plantMembers: {
    editMemberInfo: 'Mitgliedsinformationen bearbeiten',
    addNewMember: 'Neues Mitglied hinzufügen',
    name: 'Name',
    namePlaceholder: 'Mitgliedsname',
    email: 'E-Mail-Addresse',
    emailPlaceholder: 'E-Mail-Addresse',
    close: 'Nah dran',
    remove: 'Entfernen',
    save: 'Speichern',
    userList: 'Benutzerliste',
    newEmployeeBtn: 'Neuer Angestellter',
    employee: 'Angestellter',
    role: 'Rolle',
    plant: 'Anlage',
    plantAccessUserRoles: 'Anlagenzugang &amp; Benutzerrollen',
    access: 'Zugang',
    noAccessPlaceholder: 'Kein Zugang',
    deleteMembershipTitle: 'Mitgliedschaft löschen',
    deleteMembershipMessage: 'Möchten Sie die Mitgliedschaft wirklich löschen?',
    noRoleSelectedMessage: 'Bitte wählen Sie mindestens eine Rolle aus',
    noPermissionsMessage: 'Sie sind nicht berechtigt, den Zugriff zu ändern',
    cannotUpdateYourPermissionsMsg: 'Ihre Berechtigungen können nicht aktualisiert werden',
    cannotDeleteInvitationMessage: 'Einladung kann nicht gelöscht werden',
    updatePermissionsMessage: 'Berechtigungen wurden erfolgreich aktualisiert',
    inviteNewMemberMessage: 'Das Mitglied wurde erfolgreich eingeladen',
    deleteInvitationMessage: 'Die Einladung wurde erfolgreich gelöscht',
  },
  plantPermissionsTable: {
    permissionsText: 'Berechtigungen',
    needCustomPermissionText: 'Benötigen Sie eine benutzerdefinierte Berechtigung?',
    contactUs: 'Kontaktiere uns',
    task: 'Aufgabe',
    plantManager: 'Betriebsleiter',
    dispatch: 'Versenden',
    QA: 'Qualitätssicherung',
    customer: 'Kunde',
    viewCalendar: 'Kalender ansehen',
    enterOrders: 'Bestellungen eingeben',
    viewPlantSettings: 'Anlageneinstellungen anzeigen',
    changePlantSettings: 'Anlageneinstellungen ändern',
    viewReports: 'Berichte anzeigen',
    inviteUsers: 'Benutzer einladen',
    manageOrderPlanner: 'Auftragsplaner verwalten',
    limited: 'Begrenzt',
  },
  suppliersPage: {
    noSuppliersMsg: 'Keine Lieferanten gefunden',
    cannotCreateSupplier: 'Der Lieferant konnte nicht erstellt werden',
  },
  onBoarding: {
    createCompany: 'Erstellen Sie Ihre Organisation',
    companyName: 'Name der Firma',
    addFirstPlant: 'Fügen Sie Ihre erste Pflanze hinzu',
    uploadPlantImage: 'Pflanzenfoto hinzufügen',
    addFirstOutputMaterial: 'Fügen Sie Ihr erstes Ausgangsmaterial hinzu',
    outputMaterialName: 'Name des Ausgangsmaterials',
    outputMaterialInfo: 'Dies ist der Name eines Materials, das Sie verkaufen',
    materialSetup: 'Materialeinrichtung',
    fileSizeBig:
      'Bildgröße zu groß. Bitte ändern Sie die Größe oder versuchen Sie es mit einer anderen Datei.',
    error: 'Ein Fehler ist aufgetreten',
  },
  productionModePage: {
    composition: 'Komposition',
    hourlyRate: 'Stundensatz',
    defaultHourlyRate: 'Standard-Stundensatz',
    addProductionMode: 'Produktionsmodus hinzufügen',
    updatedProductionModeMsg: 'Der Produktionsmodus wurde erfolgreich aktualisiert',
    cannotUpdateProductionModeMsg: 'Der Produktionsmodus kann nicht aktualisiert werden',
    deleteProductionMode: 'Produktionsmodus löschen',
    deleteProductionModeConfirmMsg: 'Möchten Sie diesen Produktionsmodus wirklich entfernen?',
    deleteProductionModeSucceededMsg: 'Der Produktionsmodus wurde erfolgreich gelöscht',
    deleteProductionModeFailedMsg: 'Produktionsmodus kann nicht gelöscht werden',
    createProductionModeSucceededMsg: 'Der Produktionsmodus wurde erfolgreich erstellt',
    createProductionModeFailedMsg: 'Produktionsmodus kann nicht erstellt werden',
  },
  orderRequests: {
    changeOrder: 'Bestellung ändern',
    noRequests: 'Es gibt derzeit keine ausstehenden Anfragen für diese Anlage.',
  },
};
