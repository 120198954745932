import {
  CreateOrderRequestProps,
  LoadOrderRequestsParams,
  LoadProducedMaterialsParams,
  ProducedMaterialType,
  RequestOrderDatesPaginationType,
} from 'Types/orderRequestTypes';
import { CreateOrderResponse } from 'Types/orderTypes';
import { PlantId } from 'Types/plantTypes';
import {
  CreateOrderRequestActionRequested,
  CreateOrderRequestActionSucceeded,
  CreateOrderRequestActionFailed,
  LoadOrderRequestActionRequested,
  LoadOrderRequestActionSucceeded,
  LoadOrderRequestActionFailed,
  OrderRequestsActionTypes,
  LoadProducedMaterialsActionRequested,
  LoadProducedMaterialsActionSucceeded,
  LoadProducedMaterialsActionFailed,
  FetchOrderRequestCountActionRequested,
  FetchOrderRequestCountActionSucceeded,
  FetchOrderRequestCountActionFailed,
} from './actionTypes/orderRequestActionTypes';

export const loadOrderRequests = (
  params: LoadOrderRequestsParams,
): LoadOrderRequestActionRequested => ({
  type: OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_REQUESTED,
  payload: params,
});

export const loadOrderRequestsSucceeded = (
  params: RequestOrderDatesPaginationType,
): LoadOrderRequestActionSucceeded => ({
  type: OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_SUCCEEDED,
  payload: params,
});

export const loadOrderRequestsFailed = (params: unknown): LoadOrderRequestActionFailed => ({
  type: OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_FAILED,
  payload: params,
});

export const loadProducedMaterials = (
  params: LoadProducedMaterialsParams,
): LoadProducedMaterialsActionRequested => ({
  type: OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_REQUESTED,
  payload: params,
});

export const loadProducedMaterialsSucceeded = (
  params: ProducedMaterialType[],
): LoadProducedMaterialsActionSucceeded => ({
  type: OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_SUCCEEDED,
  payload: params,
});

export const loadProducedMaterialsFailed = (
  params: unknown,
): LoadProducedMaterialsActionFailed => ({
  type: OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_FAILED,
  payload: params,
});

export const createOrderRequest = (
  params: CreateOrderRequestProps,
): CreateOrderRequestActionRequested => ({
  type: OrderRequestsActionTypes.CREATE_ORDER_REQUEST_REQUESTED,
  payload: params,
});

export const createOrderRequestSucceeded = (
  params: CreateOrderResponse,
): CreateOrderRequestActionSucceeded => ({
  type: OrderRequestsActionTypes.CREATE_ORDER_REQUEST_SUCCEEDED,
  payload: params,
});

export const createOrderRequestFailed = (params: unknown): CreateOrderRequestActionFailed => ({
  type: OrderRequestsActionTypes.CREATE_ORDER_REQUEST_FAILED,
  payload: params,
});

export const fetchOrderRequestsCount = (
  plantId: PlantId,
): FetchOrderRequestCountActionRequested => ({
  type: OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_REQUESTED,
  payload: plantId,
});

export const fetchOrderRequestsCountSucceeded = (
  requestCount: number,
): FetchOrderRequestCountActionSucceeded => ({
  type: OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_SUCCEEDED,
  payload: requestCount,
});

export const fetchOrderRequestsCountFailed = (
  params: unknown,
): FetchOrderRequestCountActionFailed => ({
  type: OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_FAILED,
  payload: params,
});
