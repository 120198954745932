import { h } from 'preact';
import styles from './roundPlantIcon.scss';
import plantImg from '../../assets/img/plant-placeholder.png';
import { I18n } from 'react-redux-i18n';
import capitalize from 'lodash/capitalize';
import cls from 'classnames';

export interface PlantIcon {
  className?: string;
  src?: string | null;
  title?: string;
  size?: IconSize;
  alt?: string;
}

export enum IconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

const getDefaultPlantAvatar = e => {
  e.target.onError = null;
  e.target.src = plantImg;
};

const RoundPlantIcon = ({ className, src, title, size = IconSize.large, alt }: PlantIcon) => {
  const plantImgSizeClassName = cls(
    styles[`plantIcon${capitalize(size)}`],
    className,
    styles.plantImg,
  );

  return (
    <img
      className={plantImgSizeClassName}
      src={src || plantImg}
      title={title || I18n.t('plantDropdown.plantImageAlt')}
      onError={e => getDefaultPlantAvatar(e)}
      alt={alt || I18n.t('plantDropdown.plantImageAlt')}
    />
  );
};

export default RoundPlantIcon;
