export type GraphQLErrorData = { fieldName: string; fieldPath: string[]; errorDescription: string };

export class GraphQLError extends Error {
  constructor(message: string) {
    super(message);

    this.name = 'GraphQLError';
  }
}

export class GraphQLValidationError extends GraphQLError {
  error: GraphQLErrorData[];
  constructor(message: string, error: GraphQLErrorData[]) {
    super(message);

    this.name = 'GraphQLValidationError';
    this.error = error;
  }
}
