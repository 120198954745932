import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';
import { PlantId } from 'Types/plantTypes';
import { CalendarNoteTypes, PlantNote } from 'Types/noteTypes';
import { DateStringISO, DateStringLegacy } from './timeService';
import { AxiosResponse } from 'axios';
import { makeGraphQLRequest } from '../request';

export interface NoteParams {
  plantId: PlantId;
  data: DataParams;
}

export interface DataParams {
  id?: string;
  calendar_type?: string;
  dates: NoteDate[];
  description: string;
  title: string;
  type: CalendarNoteTypes;
  icon?: string;
  notify_team?: boolean;
  visible_to_customer: boolean;
}

export interface NoteDate {
  date: DateStringLegacy;
  id: number;
}

export interface DeleteNoteParams {
  plantId: string;
  noteId: string;
}

export interface ListNotesParams {
  plantId: string;
  dateFrom: DateStringISO;
  dateUntil: DateStringISO;
}

export interface ListNotesResponse {
  calendarNoteTypes: CalendarNoteTypes;
  calendarNotes: PlantNote[];
  orderDates: OrderDates;
}

export interface OrderDates {
  items: {
    id: string;
  }[];
  nextPage: null | number;
  totalCount: number;
  totalPages: null | number;
}

export const listNotes = async (
  params: ListNotesParams,
): Promise<AxiosResponse<ListNotesResponse>> => {
  const query = `
  {
    calendarNoteTypes
    calendarNotes(
      plantId: ${params.plantId},
      dateFrom: "${params.dateFrom}",
      dateUntil: "${params.dateUntil}"
      ) {
        id,
        calendarType,
        icon,
        type,
        description,
        title,
        visibleToCustomer
        dates {
          date
        }
        title
    },
    orderDates(
      plantId: ${params.plantId},
      dateFrom: "${params.dateFrom}",
      dateUntil: "${params.dateUntil}"
      ) {
        totalPages
        totalCount
        nextPage,
        items{ id, }
    }
  }`;

  const response = await makeGraphQLRequest({ query });
  const notes = response.calendarNotes.map(note => {
    const isOvercapacityType =
      note.type.toLocaleLowerCase() === CalendarNoteTypes.Custom.toLocaleLowerCase() &&
      note.title === CalendarNoteTypes.OverCapacity;
    return {
      ...note,
      type: isOvercapacityType ? CalendarNoteTypes.OverCapacity : note.type,
    };
  });
  return { ...response, calendarNotes: notes };
};

export const createNote = (params: NoteParams) => {
  // todo: move this away
  return axios.post(apiMap.note(params.plantId) + 'new/', params.data).then(response => {
    return response.data;
  });
};

export const editNote = (params: NoteParams) => {
  return axios
    .put(apiMap.note(params.plantId) + params.data.id + '/', params.data)
    .then(response => {
      return response.data;
    });
};

export const deleteNote = (params: DeleteNoteParams) => {
  return axios.delete(apiMap.note(params.plantId) + params.noteId + '/delete/').then(response => {
    return params.noteId;
  });
};
