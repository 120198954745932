import { useSelector } from 'react-redux';
import DashboardCard from '../DashboardCard';
import { RootState } from 'Redux/reducers';
import { getCustomerPortalDashboardInfo } from 'Services/dashboardService';
import { useDashboard } from '../useDashboard';
import styles from './customerPortalsStyles.scss';

const CustomerPortals = ({ date }) => {
  const customerPortals = useSelector((state: RootState) => state.plants.customerPortals);
  const { plantData, loading, setRef } = useDashboard({
    date,
    getDashboardInfoService: getCustomerPortalDashboardInfo,
  });

  return (
    <div className={styles.customerPortals} data-testid="customer-portals-dashboard">
      {customerPortals.map(customerPortal => (
        <DashboardCard
          key={customerPortal.id}
          entity={customerPortal}
          plantData={plantData[customerPortal.id]}
          loading={loading && !plantData[customerPortal.id]}
          ref={setRef}
        />
      ))}
      {!customerPortals.length && <p>You do not have access to any Customer portals</p>}
    </div>
  );
};

export default CustomerPortals;
