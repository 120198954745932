import { h } from 'preact';
import { I18n } from 'react-redux-i18n';
import Button from 'Components/buttons';
import Textarea from 'Components/fields/Textarea';
import InputField from 'Components/fields/inputField';
import closeIcon from 'Assets/icons/cross.svg';
import { RequestStatusType } from '../../../types/commonTypes';
import styles from './styles.scss';

const SupportModal = ({
  onChange,
  onSubmit,
  onClose,
  onEmailChange,
  email,
  message,
  messageStatus,
  loggedIn,
}) => {
  const shouldDisableSubmitBtn =
    (!loggedIn && (!email || !message)) ||
    !message ||
    messageStatus.sendingMessageStatus === RequestStatusType.InProgress;

  return (
    <div className={styles.modal__content} data-testid="support-modal">
      <div className={styles.modal__header}>
        <h3 className={styles.modal__title}>{I18n.t('supportModalTitle')}</h3>
        <button type="button" className={styles.modal__close} onClick={onClose}>
          {I18n.t('modal.close')} <img className={styles.modal__cross} src={closeIcon} />
        </button>
      </div>
      <div className={styles.modal__body}>
        {!loggedIn && (
          <InputField
            className={styles.textarea}
            placeholder={I18n.t('email')}
            onChange={onEmailChange}
          />
        )}
        <Textarea
          value={message}
          className={styles.textarea}
          placeholder={I18n.t('supportModalInputPlaceholder')}
          onChange={onChange}
        />
      </div>
      <div className={[styles.modal__footer, styles.supportModalFooter].join(' ')}>
        <Button
          label={I18n.t('submit')}
          disabled={shouldDisableSubmitBtn}
          onClick={onSubmit}
          data-testid="support-modal-submit-btn"
        />
        {messageStatus.sendingMessageError ? (
          <div className={styles.messageStatusError}>
            {I18n.t('errorTitle')}: {messageStatus.sendingMessageError}.{' '}
            {I18n.t('supportModalError')}
          </div>
        ) : (
          messageStatus.sendingMessageStatus === RequestStatusType.Completed && (
            <div className={styles.messageStatus}></div>
          )
        )}
      </div>
    </div>
  );
};

export default SupportModal;
