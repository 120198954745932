import { h } from 'preact';
import ContentLoader from 'react-content-loader';

const MyContentLoader = ContentLoader as any;

const DropDownMenuLoader = () => {
  return (
    <MyContentLoader height="300" width="100%" preserveAspectRatio="none" backgroundColor="#fff">
      <rect x="2%" y="10" rx="3" ry="3" width="15%" height="30" />
      <rect x="19%" y="10" rx="3" ry="3" width="27%" height="30" />
      <rect x="48%" y="10" rx="3" ry="3" width="50%" height="30" />
      <rect x="2%" y="50" rx="3" ry="3" width="96%" height="2" />

      <rect x="2%" y="60" rx="3" ry="3" width="22%" height="30" />
      <rect x="26%" y="60" rx="3" ry="3" width="30%" height="30" />
      <rect x="58%" y="60" rx="3" ry="3" width="40%" height="30" />
      <rect x="2%" y="100" rx="3" ry="3" width="96%" height="2" />

      <rect x="2%" y="110" rx="3" ry="3" width="27%" height="30" />
      <rect x="31%" y="110" rx="3" ry="3" width="35%" height="30" />
      <rect x="68%" y="110" rx="3" ry="3" width="30%" height="30" />
      <rect x="2%" y="150" rx="3" ry="3" width="96%" height="2" />

      <rect x="2%" y="160" rx="3" ry="3" width="36%" height="30" />
      <rect x="40%" y="160" rx="3" ry="3" width="44%" height="30" />
      <rect x="86%" y="160" rx="3" ry="3" width="12%" height="30" />
      <rect x="2%" y="200" rx="3" ry="3" width="96%" height="2" />

      <rect x="2%" y="210" rx="3" ry="3" width="27%" height="30" />
      <rect x="31%" y="210" rx="3" ry="3" width="35%" height="30" />
      <rect x="68%" y="210" rx="3" ry="3" width="30%" height="30" />
      <rect x="2%" y="250" rx="3" ry="3" width="96%" height="2" />

      <rect x="2%" y="260" rx="3" ry="3" width="22%" height="30" />
      <rect x="26%" y="260" rx="3" ry="3" width="30%" height="30" />
      <rect x="58%" y="260" rx="3" ry="3" width="40%" height="30" />
    </MyContentLoader>
  );
};

export default DropDownMenuLoader;
