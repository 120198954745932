import { Action } from './helper';
import { PlantId, CustomerId } from 'Types/plantTypes';

export type ExportCustomersParams = {
  sourcePlant: PlantId;
  targetPlant: PlantId;
  customers: CustomerId[];
};

export enum PlantSettingsActionTypes {
  EXPORT_PLANT_CUSTOMERS_REQUESTED = '@plantSettings/EXPORT_PLANT_CUSTOMERS_REQUESTED',
  EXPORT_PLANT_CUSTOMERS_SUCCEEDED = '@plantSettings/EXPORT_PLANT_CUSTOMERS_SUCCEEDED',
  EXPORT_PLANT_CUSTOMERS_FAILED = '@plantSettings/EXPORT_PLANT_CUSTOMERS_FAILED',
  UPDATE_PLANT_MEMBER_PERMISSIONS_REQUESTED = '@plantSettings/UPDATE_PLANT_MEMBER_PERMISSIONS_REQUESTED',
  UPDATE_PLANT_MEMBER_PERMISSIONS_SUCCEEDED = '@plantSettings/UPDATE_PLANT_MEMBER_PERMISSIONS_SUCCEEDED',
  UPDATE_PLANT_MEMBER_PERMISSIONS_FAILED = '@plantSettings/UPDATE_PLANT_MEMBER_PERMISSIONS_FAILED',
  INVITE_NEW_MEMBER_REQUESTED = '@plantSettings/INVITE_NEW_MEMBER_REQUESTED',
  INVITE_NEW_MEMBER_SUCCEEDED = '@plantSettings/INVITE_NEW_MEMBER_SUCCEEDED',
  INVITE_NEW_MEMBER_FAILED = '@plantSettings/INVITE_NEW_MEMBER_FAILED',
}

export type UserId = number;

export type PlantSettingsActions =
  | ExportPlantCustomersActionRequested
  | ExportPlantCustomersActionSucceeded
  | ExportPlantCustomersActionFailed;

export type ExportPlantCustomersActionRequested = Action<
  PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_REQUESTED,
  ExportCustomersParams
>;

export type ExportPlantCustomersActionSucceeded = Action<
  PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_SUCCEEDED,
  CustomerId[]
>;

export type ExportPlantCustomersActionFailed = Action<
  PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_FAILED,
  unknown
>;

export const exportPlantCustomers = (
  params: ExportCustomersParams,
): ExportPlantCustomersActionRequested => ({
  type: PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_REQUESTED,
  payload: params,
});

export const exportPlantCustomersSucceeded = (
  params: CustomerId[],
): ExportPlantCustomersActionSucceeded => ({
  type: PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_SUCCEEDED,
  payload: params,
});

export const exportPlantCustomersFailed = (params: unknown): ExportPlantCustomersActionFailed => ({
  type: PlantSettingsActionTypes.EXPORT_PLANT_CUSTOMERS_FAILED,
  payload: params,
});
