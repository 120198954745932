import { h, ComponentChild } from 'preact';
import cls from 'classnames';
import styles from './stickybar.scss';
import { useEffect, useRef, useState } from 'preact/hooks';

type StickyBarProps = {
  className?: string;
  stickyClassName?: string;
  offsetTop?: number;
  zIndex?: number;
  options?: {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold?: number | number[];
  };
  children: ComponentChild;
  onStickyChange?: (isSticky: boolean) => void;
};

const StickyBar = ({
  className,
  stickyClassName,
  offsetTop = 0,
  zIndex,
  options,
  children,
  onStickyChange,
}: StickyBarProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sticky = stickyRef.current;
    if (!sticky) return;

    const observer = new IntersectionObserver(([e]) => {
      const sticky = e.intersectionRatio < 1;
      setIsSticky(sticky);
      onStickyChange?.(sticky);
    }, options);

    observer.observe(sticky);

    return () => {
      observer.unobserve(sticky);
    };
  }, [offsetTop]);

  const stickyCls = isSticky && stickyClassName ? stickyClassName : undefined;

  return (
    <div className={cls(className, styles.stickyBar, stickyCls)} style={{ zIndex }} ref={stickyRef}>
      {children}
    </div>
  );
};

export default StickyBar;
