import { Supplier } from 'src/types/supplierTypes';
import { SupplierActionTypes, SuppliersActions } from '../actions/supplierActions';

export interface SupplierState {
  data: Supplier[];
  fetchingSuppliersStatus?: string;
  fetchingSuppliersError?: any;
}

const initialState: SupplierState = {
  data: [],
};

const supplierReducer = (state = initialState, action: SuppliersActions): SupplierState => {
  switch (action.type) {
    case SupplierActionTypes.FETCH_SUPPLIERS_REQUESTED:
      return { ...state, fetchingSuppliersStatus: 'IN_PROGRESS' };
    case SupplierActionTypes.FETCH_SUPPLIERS_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        fetchingSuppliersStatus: 'COMPLETED',
        fetchingSuppliersError: null,
      };
    case SupplierActionTypes.FETCH_SUPPLIERS_FAILED:
      return {
        ...state,
        data: [],
        fetchingSuppliersStatus: 'COMPLETED',
        fetchingSuppliersError: action.payload,
      };
    case SupplierActionTypes.SET_SUPPLIERS:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default supplierReducer;
