import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { DashboardDataParams, DashboardPlantInfo } from 'Services/dashboardService';
import { PlantId } from 'Types/plantTypes';
import { isInRange } from '../../utils';
import debounceEvent from '../../utils/debounce';
import { useQuery } from '../../helpers/hooks/useQuery';
import { getWebSocket, subscribeToPlants, unsubscribeToPlants } from 'Services/webSocketService';
import { WebSocketEvent, WebSocketEvents } from 'Types/commonTypes';
import { DateStringISO } from 'Services/timeService';

type PlantDashboardData = {
  plantId: PlantId;
  ref: HTMLDivElement;
  visible: boolean;
  loaded: boolean;
  subscribed: boolean;
};

type useDashboardProps = {
  date: DateStringISO;
  getDashboardInfoService: (params: DashboardDataParams) => Promise<DashboardPlantInfo>;
};

const INITIAL_COUNT = 12;

export const useDashboard = ({ date, getDashboardInfoService }: useDashboardProps) => {
  const [params, setParams] = useState<DashboardDataParams>({} as DashboardDataParams);
  const [loadedItems, setLoadedItems] = useState<DashboardPlantInfo>({});
  const cardsRef = useRef<PlantDashboardData[]>([]);

  const resetCardsRefLoadedItems = () => {
    cardsRef.current = cardsRef.current.map(item => ({
      ...item,
      loaded: false,
      subscribed: false,
    }));
  };

  const handleSubscribeToPlants = () => {
    const plantsToSubscribe = cardsRef.current
      .filter(item => item.loaded)
      .filter(item => !item.subscribed)
      .map(item => item.plantId);

    subscribeToPlants(plantsToSubscribe);

    cardsRef.current = cardsRef.current.map(item => ({
      ...item,
      subscribed: item.loaded,
    }));
  };

  const getItemsInTheCurrentViewPort = useCallback(() => {
    cardsRef.current = cardsRef.current.map(plant => ({
      ...plant,
      visible: isElementInViewport(plant.ref),
    }));

    return cardsRef.current.filter(item => item.visible);
  }, []);

  useEffect(() => {
    resetCardsRefLoadedItems();
    setLoadedItems({});

    const itemsInTheCurrentViewPort = getItemsInTheCurrentViewPort();

    setParams(prevParams => ({
      ...prevParams,
      date,
      plantIds: itemsInTheCurrentViewPort.map(item => item.plantId),
    }));
  }, [date, getItemsInTheCurrentViewPort]);

  useEffect(() => {
    const plantsDataToLoad = cardsRef.current.slice(0, INITIAL_COUNT).map(item => item.plantId);
    setParams(prevParams => ({ ...prevParams, plantIds: plantsDataToLoad }));
  }, []);

  const { loading } = useQuery(getDashboardInfoService, [params], {
    initialData: {},
    enabled: !!params.date && !!params.plantIds.length,
    onSuccess: data => {
      setLoadedItems(items => ({ ...items, ...data }));

      cardsRef.current = cardsRef.current.map(item => ({
        ...item,
        visible: isElementInViewport(item.ref),
        loaded: item.loaded || !!data[item.plantId],
      }));

      handleSubscribeToPlants();
    },
  });

  useEffect(() => {
    const ws = getWebSocket();
    if (!ws) return;

    ws.onmessage = (event: MessageEvent) => {
      const eventData: WebSocketEvent = JSON.parse(event.data);

      const orderEvents = [
        WebSocketEvents.ORDER_CREATED,
        WebSocketEvents.ORDER_UPDATED,
        WebSocketEvents.ORDER_DELETED,
      ];

      if (orderEvents.includes(eventData.type)) {
        const updatedPlantId = eventData.data.plant_id.toString();
        setParams(prevParams => ({ ...prevParams, plantIds: [updatedPlantId] }));
      }
    };

    return () => {
      const plantsToUnsubscribe = cardsRef.current
        .filter(item => item.subscribed)
        .map(item => item.plantId);
      unsubscribeToPlants(plantsToUnsubscribe);
    };
  }, []);

  const isElementInViewport = (el: HTMLDivElement) => {
    const rect = el.getBoundingClientRect();

    return (
      isInRange(rect.top, 0, window.innerHeight) ||
      isInRange(rect.bottom, 0, window.innerHeight) ||
      isInRange(0, rect.top, rect.bottom) ||
      isInRange(window.innerHeight, rect.top, rect.bottom)
    );
  };

  const handleScroll = useCallback(() => {
    const itemsInViewPort = getItemsInTheCurrentViewPort();
    const notLoadedItems = itemsInViewPort.filter(item => !item.loaded);

    if (!notLoadedItems.length) return;

    const plantIds = notLoadedItems.map(item => item.plantId);
    // setItemsToLoad(plantIds);
    setParams(prevParams => ({
      ...prevParams,
      plantIds,
    }));
  }, [getItemsInTheCurrentViewPort]);

  useEffect(() => {
    document.addEventListener(
      'scroll',
      debounceEvent(() => handleScroll(), 400, { trailing: true }),
      { passive: true },
    );

    return () =>
      document.removeEventListener(
        'scroll',
        debounceEvent(() => handleScroll(), 400, { trailing: true }),
      );
  }, [handleScroll]);

  const setRef = useCallback(node => {
    if (!node) return;

    const plantId = node.getAttribute('data-plantId');

    cardsRef.current.push({
      plantId,
      ref: node,
      loaded: false,
      visible: false,
      subscribed: false,
    });
  }, []);

  return {
    plantData: loadedItems,
    loading,
    setRef,
  };
};
