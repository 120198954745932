import routeMap from './route-map';
import Login from 'Routes/login';
import SignUp from 'Routes/SignUp';
import SignUpComplete from 'Routes/SignUpComplete';
import PageNotFound from 'Routes/pageNotFound';

const noAuthOnlyRoutes = [
  {
    path: routeMap.login,
    comp: Login,
    exact: true,
  },
  {
    path: routeMap.signUp,
    comp: SignUp,
    exact: true,
  },
  {
    path: routeMap.signUpComplete,
    comp: SignUpComplete,
    exact: true,
  },
  {
    path: routeMap.pageNotFound,
    comp: PageNotFound,
    exact: true,
  },
];

export default noAuthOnlyRoutes;
