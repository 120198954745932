import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';
import { I18n } from 'react-redux-i18n';
import { makeGraphQLRequest } from '../request';

import {
  buildCustomRequiredFields,
  buildOrderFields,
  buildStaticFields,
  getFieldName,
} from './orderModalService';

import {
  LoadOrderRequestsParams,
  LoadProducedMaterialsParams,
  RequestCardType,
  OrderRequestType,
  ProducedMaterialType,
  RequestOrderDatesPaginationType,
  ProducedMaterialResponseType,
} from 'Types/orderRequestTypes';
import { OrderRequestStatus } from 'Types/commonTypes';
import { PlantId } from 'Types/plantTypes';
import { AxiosResponse } from 'axios';
import { formModeType } from 'Types/orderTypes';
import { ApprovedRejectedType } from 'Components/modal/customModals/OrderRequestModal/OrderRequestModal';
import { LabelPosition } from 'Components/fields/InputFieldWrapper';

export const getOrderRequests = async (
  params: LoadOrderRequestsParams,
): Promise<RequestOrderDatesPaginationType> => {
  const variables = {
    plantId: parseInt(params.plantId),
    orderRequestDateIds: params.orderRequestDateIds,
    dateFrom: params.dateFrom,
    dateUntil: params.dateUntil,
    searchString: params.searchString,
    quantityFrom: params.quantityFrom,
    quantityTo: params.quantityTo,
    pageSize: params.pageSize || 40,
    pageCursor: params.pageCursor,
    includeRemoved: params.includeRemoved,
    orderBy: params.orderBy,
  };

  const query = {
    query: `query requestOrderDates($plantId: Int, $orderRequestDateIds: [ID], $dateFrom: Date, $dateUntil: Date, $searchString: String, $quantityFrom: Int, $quantityTo: Int, $pageSize: Int, $pageCursor: String, $includeRemoved: Boolean, $orderBy: [OrderRequestDateSortEnum]) {
      requestOrderDates(plantId: $plantId, orderRequestDateIds: $orderRequestDateIds, dateFrom: $dateFrom, dateUntil: $dateUntil, searchString: $searchString, quantityFrom: $quantityFrom, quantityTo: $quantityTo, pageSize: $pageSize, pageCursor: $pageCursor, includeRemoved: $includeRemoved, orderBy: $orderBy) {
        items {
          id
          quantity
          deliveryOn
          nightshift
          loadTime
          extraFields {
            key
            value
            type
            visibleToCustomer
          }
          orderRequest {
            id
            status
            createdAt
            order {
              id
            }
            extraFields {
              key
              value
              type
              visibleToCustomer
            }
            material {
              id
              systemColor
              name
              removed
              active
            }
            customer {
              id
              name
              extraFields {
                key
                value
                type
              }
            }
          }
        }
        nextPage
        totalPages
        totalCount
      } 
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'requestOrderDates');
  const responseWithPendingRequests = {
    ...response.requestOrderDates,
    items: response.requestOrderDates.items.filter(request => {
      return request.orderRequest.status === OrderRequestStatus.Pending;
    }),
  };

  return responseWithPendingRequests;
};

export const createOrderRequest = async ({ plantId, data }) => {
  const url = `${apiMap.createOrderRequest(plantId)}`;
  const { data: responseData } = await axios.post(url, data);
  return responseData;
};

export const getProducedMaterials = async (
  params: LoadProducedMaterialsParams,
): Promise<ProducedMaterialResponseType[]> => {
  const variables = {
    plantId: parseInt(params.plantId),
    dateFrom: params.dateFrom,
    dateUntil: params.dateUntil,
  };

  const query = {
    query: `query producedMaterials($plantId: Int, $dateFrom: Date, $dateUntil: Date) {
      producedMaterials(plantId: $plantId, dateFrom: $dateFrom, dateUntil: $dateUntil) {
        date
        nightshift
        producedMaterials {
          id
          name
          systemColor
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'producedMaterials');

  return response.producedMaterials;
};

export const getPlantOrderRequestCount = async (plantId: PlantId): Promise<number> => {
  let queryParams = '';

  if (plantId) {
    queryParams = queryParams.concat(`plantId: ${plantId}, `);
  }

  const query = `
    {
      plantOrderRequestCount(${queryParams})
    }
  `;

  const response = await makeGraphQLRequest({ query });

  return response.plantOrderRequestCount;
};

export const getRequests = async (plantId: PlantId): Promise<RequestCardType[]> => {
  const response: AxiosResponse<RequestCardType[]> = await axios.get(
    apiMap.getOrderRequest(plantId),
  );
  return response.data;
};

export const orderRequestById = async (
  plantId: PlantId,
  requestId: string,
): Promise<OrderRequestType> => {
  const variables = {
    plantId: parseInt(plantId),
    orderRequestId: parseInt(requestId),
  };

  const query = {
    query: `query orderRequestById($plantId: Int, $orderRequestId: Int) {
      orderRequestById(plantId: $plantId, orderRequestId: $orderRequestId) {
        id
        dates {
          id
          quantity
          deliveryOn
          loadTime
          nightshift
          extraFields {
            key
            value
            type
            visibleToCustomer
          }
        }
        material {
          id
          name
          systemColor
          removed
          active
        },
        order {
          id
        }
        customer {
          id
          name
          extraFields {
            key
            value
            type
          }
        }
        extraFields {
          key
          value
          type
          visibleToCustomer
        }
        status
        feed {
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          records
          comment
          messages {
            message
          }
        }
      }
    }
    `,
    variables,
  };

  const response = await makeGraphQLRequest(query, 'orderRequestById');

  return response.orderRequestById;
};

const buildRequiredFields = ({ plantMaterials, selectedDate, formMode }) => {
  const material = {
    key: 'material',
    title: I18n.t('material'),
    type: 'inputDropdown',
    labelPosition: LabelPosition.top,
    enum: plantMaterials,
    placeholder: I18n.t('dropdownChoosePlaceholder'),
    allowUnknownOption: false,
    cols: 12,
    customValidation: option => {
      if (!option?.value) return I18n.t('fieldIsRequired', { fieldName: 'Material' });

      const isOptionExist = plantMaterials.find(material => material.value == option.value);
      if (!isOptionExist) return I18n.t('fieldIsMissing', { fieldName: 'Material' });
    },
  };

  const dates = {
    key: 'dates',
    title: I18n.t('dates'),
    type: 'dateQuantityPicker',
    selectedDate: selectedDate,
    formMode,
    calendarOpen: false,
    showMoveFormBtn: formMode !== formModeType.CREATE,
    cols: 12,
  };

  return [material, dates];
};

export const buildSchemaFromOrderFields = ({
  plantOrderFields = [],
  orderExtraFields = [],
  plantMaterials = [],
  selectedDate,
  formMode,
  formName,
  plantName,
  disabled = false,
}) => {
  const fields = buildOrderFields(plantOrderFields, orderExtraFields, disabled);

  const customFields = fields.map(field => {
    const fieldName: string = getFieldName(field.name);

    return {
      key: fieldName,
      title: field.name,
      name: fieldName,
      type: field.field_type,
      labelPosition: LabelPosition.left,
      visibleToCustomer: field.visible_to_customer,
      deleted: !!field.deleted,
      cols: 12,
      isSupported: field.field_type,
      isCustomField: true,
    };
  });

  const defaultRequiredFields = buildRequiredFields({
    plantMaterials,
    selectedDate,
    formMode,
  });

  const customRequiredFields = buildCustomRequiredFields(plantOrderFields);
  const staticFields = buildStaticFields({
    plantName,
    formMode,
    isCustomerPlant: true,
    disabled: false,
  });

  const requiredFields = Object.keys({
    ...defaultRequiredFields,
    ...customRequiredFields,
  });

  const schema = {
    title: formName,
    required: requiredFields,
    properties: [
      ...staticFields,
      ...defaultRequiredFields,
      ...customRequiredFields,
      ...customFields,
    ],
  };

  return schema;
};

export const updateOrderRequest = ({
  plantId,
  orderRequestId,
  data,
}: {
  plantId: PlantId;
  orderRequestId: string;
  data: any;
}) => {
  const url = apiMap.updateOrderRequest(plantId, orderRequestId);
  return axios.patch(url, data);
};

export const approveRejectOrderRequest = async (
  plantId: PlantId,
  orderRequestId: string,
  data: any,
  status: ApprovedRejectedType,
) => {
  const url =
    status === ApprovedRejectedType.Approved
      ? apiMap.approveOrderRequest(plantId, orderRequestId)
      : apiMap.rejectOrderRequest(plantId, orderRequestId);

  const response = await axios.patch(url, data);

  return response.data;
};
