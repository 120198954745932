import { SupportMessageActionTypes } from '../actions/sendSupportMessageActions';
import { AxiosError } from 'axios';

export interface MessageState {
  sendingMessageStatus: null | string;
  sendingMessageError: null | AxiosError;
  openFeedbackModal: null | boolean;
}

export const initialState: MessageState = {
  sendingMessageStatus: null,
  sendingMessageError: null,
  openFeedbackModal: false,
};

const sendSupportMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_REQUESTED:
      return { ...state, sendingMessageStatus: 'IN_PROGRESS' };
    case SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_SUCCEEDED:
      return {
        ...state,
        sendingMessageStatus: 'COMPLETED',
        sendingMessageError: null,
        openFeedbackModal: false,
      };
    case SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_FAILED:
      return {
        sendingMessageStatus: 'COMPLETED',
        sendingMessageError: action.payload.message,
        openFeedbackModal: true,
      };
    case SupportMessageActionTypes.OPEN_FEEDBACK_MODAL:
      return { ...state, openFeedbackModal: action.payload };
    default:
      return state;
  }
};

export default sendSupportMessageReducer;
