import { I18n } from 'react-redux-i18n';
import { DateStringISO } from 'Services/timeService';
import { HexColorCode, Optional, RequestStatusType } from './commonTypes';

export type PlantId = string;
export type CustomerId = string;

export interface BasePlant<T> {
  id: T;
  name: string;
  address: string;
  picture: Optional<string>;
  max_capacity: number;
  plant_type: PlantType;
  night_shift_from: string;
  night_shift_to: string;
  business: number;
  business_name: string;
  owner: number;
  removed: boolean | null;
  customer_name?: string;
  source_plant: PlantId | null;
  show_produced_materials: boolean;
}

export interface Plant extends BasePlant<PlantId> {
  id: PlantId;
}

export type PlantOption = Pick<Plant, 'id' | 'name' | 'picture'>;

export interface PlantResponse extends BasePlant<number> {
  id: number;
}

export enum PlantType {
  Mix = 'Mix',
  Aggregate = 'Aggregate',
  PavingSchedule = 'PavingSchedule',
  Customer = 'Customer',
}

export const plantTypeOptions = [
  { label: I18n.t('plantTypes.mixPlant'), value: PlantType.Mix },
  { label: I18n.t('plantTypes.aggregatePlant'), value: PlantType.Aggregate },
  { label: I18n.t('plantTypes.pavingSchedule'), value: PlantType.PavingSchedule },
  { label: I18n.t('plantTypes.customer'), value: PlantType.Customer },
];

export interface BasePlantsPermissions<T> {
  plant_id: T;
  plant_name: string;
  user_id: number;
  user_name: string;
  role: string;
  permissions: PlantPermissionsOptions;
}

export interface PlantsPermissions extends BasePlantsPermissions<PlantId> {
  plant_id: PlantId;
}
export interface PlantsPermissionsResponse extends BasePlantsPermissions<number> {
  plant_id: number;
}

export interface PlantPermissionsOptions {
  view_production_modes: boolean;
  edit_users: boolean;
  view_order_request: boolean;
  approve_order_request: boolean;
  view_order_plan: boolean;
  edit_production_modes: boolean;
  edit_mix_designs: boolean;
  create_order_request: boolean;
  edit_order_plan: boolean;
  create_temporary_orders: boolean;
  view_plant_configuration: boolean;
  edit_materials: boolean;
  edit_plant_configuration: boolean;
  view_reports: boolean;
  edit_order_request: boolean;
  view_orders: boolean;
  edit_orders: boolean;
  edit_order_form: boolean;
}

export interface PlantState {
  plants: Plant[];
  plantsPermissions: PlantsPermissions[];
  customerPortals: Plant[];
  activePlants: Plant[];
  inactivePlants: Plant[];
  materials: Material[];
  orderFields: PlantOrderField[];
  orderDateFields: PlantOrderField[];
  orderFieldStyles: CustomFieldStyle[];
  fetchingQuantityRangeStatus?: string;
  fetchingOrderFieldsStatus?: string;
  fetchingMaterialStatus?: RequestStatusType;
  fetchingMaterialError?: any;
  activePlantId: PlantId | null;
  fetchingPlantPermissionsStatus?: RequestStatusType;
  fetchingPlantsStatus?: RequestStatusType;
  fetchingSuppliersStatus?: RequestStatusType;
}

export interface Material {
  current_inventory_amount: null | number;
  current_inventory_updated_on: null | string;
  description?: string;
  id: string;
  is_output_material: boolean;
  max_inventory_amount?: null | number;
  min_inventory_amount?: null | number;
  mix_designs: number[];
  name: string;
  plant: number;
  removed: null | boolean;
  supplier_id?: null | string;
  supplier_name?: null | string;
  supplier_product_name: null | string;
  system_color: HexColorCode;
  active: boolean;
}

export enum OrderFieldsType {
  Text = 'Text',
  TextArea = 'TextArea',
  Checkbox = 'Checkbox',
  Time = 'Time',
  Date = 'Date',
  Enumeration = 'Enumeration',
  ReadOnly = 'ReadOnly',
  Hidden = 'Hidden',
}

export interface PlantOrderField {
  id?: number;
  field_type: string;
  name: string;
  plant: PlantId;
  visible_to_customer: boolean;
  is_required: boolean;
}

export interface MixDesignComponent {
  id: string;
  material: OutputMaterial;
  mixDesign: { id: number };
  proportion: number;
}

export interface OutputMaterial {
  id: string;
  name: string;
  systemColor: HexColorCode;
  active: boolean;
}

export interface MixDesign {
  id: number;
  expirationDate: DateStringISO;
  outputMaterial: OutputMaterial;
  specificationBasis: string;
  verified: boolean;
  components: MixDesignComponent[];
}

export type CustomFieldStyle = {
  fieldName: string;
  day: string;
  night: string;
  format: FieldFormat | '';
};

export enum FieldFormat {
  Bold = 'Bold',
  Italic = 'Italic',
  Underline = 'Underline',
  Strikethrough = 'Strikethrough',
}

export type CustomerExtraField = {
  type: OrderFieldsType;
  key: string;
  value: string;
};

export enum CustomerHighlightColor {
  None = 'None',
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export type Customer = {
  id: CustomerId;
  name: string;
  plant: PlantId;
  extra_fields_data: CustomerExtraField[];
  highlight_color: CustomerHighlightColor;
  removed: DateStringISO | null;
  created_at: DateStringISO;
  modified_at: DateStringISO;
};

export type CustomerField = {
  id: string;
  name: string;
  field_type: OrderFieldsType;
  plant: PlantId;
  day_report_printing: boolean;
  options: string[];
};

export type GetCustomersApiParams = {
  plantId: PlantId;
  name?: string;
  page?: number | null;
  pageSize?: number;
  includeRemoved?: boolean;
};
