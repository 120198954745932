import { ComponentChildren, h } from 'preact';
import cls from 'classnames';
import styles from './optionalFormLabel.scss';

export interface OptionalFormLabelProps {
  forComponentId?: string;
  margin?: 'normal' | 'none';
  className?: string;
  children: ComponentChildren | string;
}

const OptionalFormLabel = ({
  children,
  forComponentId,
  margin,
  className,
}: OptionalFormLabelProps) => {
  const classes = cls(styles.formLabel, className, { [styles.marginNone]: margin === 'none' });
  return children && typeof children === 'string' ? (
    <label htmlFor={forComponentId} className={classes}>
      {children}
    </label>
  ) : (
    <div className={className}>{children}</div>
  );
};
export default OptionalFormLabel;
