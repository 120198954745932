import TagManager from 'react-gtm-module';

export default function (userId?: string) {
  const GTM_ID = process.env.GTM_ID;

  if (GTM_ID) {
    const tagManagerArgs = {
      gtmId: GTM_ID,
      dataLayer: {
        userId: userId,
        userProject: 'Plant Demand',
      },
      dataLayerName: 'PageDataLayer',
    };
    TagManager.initialize(tagManagerArgs);
  } else {
    console.log('Unable to init Google Tag Manager. GTM_ID is not provided');
  }
}
