import { PlantId } from 'Types/plantTypes';
import apiMap from '../utils/apiMap/apiMap';

type SendMessageEvent = {
  type: 'subscribe' | 'unsubscribe';
  data: {
    plant_ids: PlantId[];
  };
};

let ws: WebSocket | null;
const pendingEvents: SendMessageEvent[] = [];

export const initWebSocket = (accessToken: string) => {
  ws = new WebSocket(apiMap.wsConnect(accessToken));

  ws.onopen = () => {
    pendingEvents.forEach(event => {
      sendMessage({ type: event.type, plantIds: event.data.plant_ids });
    });

    pendingEvents.length = 0;
  };

  return ws;
};

export const getWebSocket = () => {
  return ws;
};

export const sendMessage = ({
  type,
  plantIds,
}: {
  type: SendMessageEvent['type'];
  plantIds: PlantId[];
}) => {
  if (!ws) return;

  const data: SendMessageEvent = {
    type,
    data: {
      plant_ids: plantIds,
    },
  };

  if (ws.readyState !== 1) {
    pendingEvents.push(data);
  } else {
    ws.send(JSON.stringify(data));
  }
};

export const subscribeToPlants = (plantIds: PlantId[]) => {
  sendMessage({ type: 'subscribe', plantIds });
};

export const unsubscribeToPlants = (plantIds: PlantId[]) => {
  sendMessage({ type: 'unsubscribe', plantIds });
};
