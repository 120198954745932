import { NoteActionTypes } from '../actions/noteActions';

export interface NoteState {
  data: any;
}
const initialState: NoteState = {
  data: [],
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case NoteActionTypes.NOTES_FETCH_REQUESTED:
      return { ...state, fetchingNotesStatus: 'IN_PROGRESS' };
    case NoteActionTypes.NOTES_FETCH_SUCCEEDED:
      const data = [...action.notes.calendarNotes];
      const dataTypes = action.notes.calendarNoteTypes;

      return {
        ...state,
        data: data,
        dataTypes: dataTypes,
        fetchingNotesStatus: 'COMPLETED',
        fetchingNotesError: null,
      };
    case NoteActionTypes.NOTES_FETCH_FAILED:
      return { ...state, fetchingNotesStatus: 'COMPLETED', fetchingNotesError: action.error };

    //  This is not needed for now, if needed in future uncomment and update the noteSaga
    // case actions.NOTE_FETCH_REQUESTED:
    //   return { ...state, fetchingNoteStatus: 'IN_PROGRESS' };
    // case actions.NOTE_FETCH_SUCCEEDED:
    //   return {
    //     ...state,
    //     note: action.note,
    //     fetchingNoteStatus: 'COMPLETED',
    //     fetchingNoteError: null,
    //   };
    // case actions.NOTE_FETCH_FAILED:
    //   return { ...state, fetchingNoteStatus: 'COMPLETED', fetchingNoteError: action.error };

    case NoteActionTypes.NOTE_CREATE_REQUESTED:
      return { ...state, creatingNoteStatus: 'IN_PROGRESS' };
    case NoteActionTypes.NOTE_CREATE_SUCCEEDED:
      const notes = state.data;
      notes.push(action.note);

      return {
        ...state,
        data: notes,
        creatingNoteStatus: 'COMPLETED',
        creatingNoteError: null,
      };
    case NoteActionTypes.NOTE_CREATE_FAILED:
      return { ...state, creatingNoteStatus: 'COMPLETED', creatingNoteError: action.error };

    case NoteActionTypes.NOTE_EDIT_REQUESTED:
      return { ...state, editingNoteStatus: 'IN_PROGRESS' };
    case NoteActionTypes.NOTE_EDIT_SUCCEEDED:
      const allNotes = state.data;

      Object.assign(
        allNotes.find(n => parseInt(n.id) === parseInt(action.note.id)),
        action.note,
      );

      return {
        ...state,
        data: allNotes,
        editingNoteStatus: 'COMPLETED',
        editingNoteError: null,
      };
    case NoteActionTypes.NOTE_EDIT_FAILED:
      return { ...state, editingNoteStatus: 'COMPLETED', editingNoteError: action.error };

    case NoteActionTypes.NOTE_DELETE_REQUESTED:
      return { ...state, deletingNoteStatus: 'IN_PROGRESS' };
    case NoteActionTypes.NOTE_DELETE_SUCCEEDED:
      const newNotes = state.data;

      const deletedNoteIndex = state.data.indexOf(
        newNotes.find(n => parseInt(n.id) === parseInt(action.noteId)),
      );

      newNotes.splice(deletedNoteIndex, 1);

      return {
        ...state,
        data: newNotes,
        deletingNoteStatus: 'COMPLETED',
        deletingNoteError: null,
      };
    case NoteActionTypes.NOTE_DELETE_FAILED:
      return { ...state, deletingNoteStatus: 'COMPLETED', deletingNoteError: action.error };
    default:
      return state;
    case NoteActionTypes.NOTES_CLEAR_REQUESTED:
      return { ...state, data: [] };
  }
};

export default noteReducer;
