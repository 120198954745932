import { I18n } from 'react-redux-i18n';
import { put, takeEvery } from 'redux-saga/effects';
import { AlertType } from '../../components/alerts/singleAlert';
import { triggerPageLevelAlert } from '../actions/pageLevelAlertActions';

export function* logErrors(errorAction: any) {
  const isAxiosError =
    errorAction.payload?.isAxiosError || errorAction.error?.isAxiosError || false;

  // if the error is axiosError do not trigger an alert here.
  // axios interceptor will take care of this instead.
  if (isAxiosError) return;

  const message = `${I18n.t('requestErrorMessage')}${errorAction.payload?.message}`;
  yield put(triggerPageLevelAlert({ type: AlertType.Error, message }));
}

export default function* watchLogErrors() {
  yield takeEvery(action => /_FAILED$/.test(action.type), logErrors);
}
