import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { AsyncReturnType, RequestConfig } from 'src/types/commonTypes';
import { getErrorMessage } from './getErrorMessage';
import { useDeepCompareMemoize } from './useDeepCompareMemoize';

type UseQueryOptions<T> = {
  enabled?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (error: string) => void;
  initialData?: T;
};

const defaultOptions: UseQueryOptions<any> = {
  enabled: true,
};

/**
 * useQuery hook allows us to fetch data from an API, while checking for errors and whether or not it's still loading
 *
 * @param {requestCallback} asyncFunction - An asynchronous function that return a promise
 * @param {Object[]} params - The params that would be passed to asyncFunction. Must be wrapped in []
 * @param {Object} options - Optional option object
 */
export const useQuery = <T extends (...args: any[]) => Promise<AsyncReturnType<T>>>(
  asyncFunction: T,
  params: Parameters<T>,
  options?: UseQueryOptions<AsyncReturnType<T>>,
) => {
  const controllerRef = useRef<AbortController>();
  const parsedOptions = {
    ...defaultOptions,
    ...options,
  };

  const [data, setData] = useState<AsyncReturnType<T>>(
    parsedOptions.initialData as AsyncReturnType<T>,
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(parsedOptions.enabled ? true : false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const memoizedParams = useDeepCompareMemoize(params);

  const runQuery = useCallback(
    async (args?: { params?: Parameters<T>; reason?: 'internal' | 'refetch' }) => {
      const reason = args?.reason || 'internal';
      controllerRef.current = new AbortController();
      const runQueryParams = args?.params || memoizedParams;
      const config: RequestConfig = {
        signal: controllerRef.current.signal,
      };
      setLoading(reason === 'internal');
      setError(null);

      try {
        const response = await asyncFunction(...runQueryParams, config);
        parsedOptions.onSuccess?.(response);
        setError(null);
        setData(response);
        setLoaded(true);
      } catch (error) {
        setData(parsedOptions.initialData);
        const errorMessage = getErrorMessage(error);
        setError(errorMessage);
        parsedOptions.onError?.(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [asyncFunction, memoizedParams],
  );

  useEffect(() => {
    if (parsedOptions.enabled) {
      runQuery();
    }

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [runQuery, parsedOptions.enabled]);

  return {
    data,
    loading,
    loaded,
    error,
    refetch: (params?: Parameters<T>) => runQuery({ params, reason: 'refetch' }),
  };
};
