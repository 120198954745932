export const SIDEBAR_OPEN = '@sidebar/SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = '@sidebar/SIDEBAR_CLOSE';

export const showSidebar = () => ({
  type: SIDEBAR_OPEN,
});

export const hideSidebar = () => ({
  type: SIDEBAR_CLOSE,
});
