import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { forwardRef } from 'preact/compat';
import cls from 'classnames';
import { I18n } from 'react-redux-i18n';
import closeIcon from 'Assets/icons/cross.svg';

import styles from './modals.scss';

const ModalWindow = forwardRef<HTMLDivElement, any>((props, ref) => {
  const {
    title,
    bodyContent,
    bodyNoPadding,
    footerContent,
    onClose,
    headerColor,
    headerClassName,
    contentClassName,
    'data-testid': dataTestId = undefined,
  } = props;
  useEffect(() => {
    document.body.style.setProperty('overflow', 'hidden');
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <div
      className={[styles.modal__instance, styles.modal__open].join(' ')}
      style={'display: block;'}
      ref={ref}
    >
      <div className={styles.modal__dialog}>
        <div className={cls(styles.modal__content, contentClassName)} data-testid={dataTestId}>
          {headerColor && <div className={styles[headerColor]} />}
          <div
            className={[styles.modal__header, headerClassName].join(' ')}
            data-testid="modal-header"
          >
            <h3 className={styles.modal__title}>{title}</h3>
            <button type="button" className={styles.modal__close} onClick={onClose}>
              {I18n.t('modal.close')} <img alt="" className={styles.modal__cross} src={closeIcon} />
            </button>
          </div>
          {!!bodyContent && (
            <div
              className={[styles.modal__body, bodyNoPadding ? styles.body_p_0 : ''].join(' ')}
              data-testid="modal-body"
            >
              {bodyContent}
            </div>
          )}
          {!!footerContent && (
            <div className={styles.modal__footer} data-testid="modal-footer">
              {footerContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

ModalWindow.displayName = 'ModalWindow';
export default ModalWindow;
