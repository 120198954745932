const prefix = process.env.ROUTE_PREFIX ? process.env.ROUTE_PREFIX : '';

interface Parameters {
  [name: string]: any;
}

const fromPattern =
  (pattern: string) =>
  (params: Parameters = {}) => {
    const queryString = Object.entries(params)
      .filter(([key]) => !pattern.includes(':' + key))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const fullQueryString = queryString.length > 0 ? '?' + queryString : '';
    return (
      Object.entries(params).reduce(
        (accumulator, entry) => accumulator.replace(':' + entry[0], entry[1]),
        pattern,
      ) + fullQueryString
    );
  };

const routeMap = {
  // old app
  register: '/register',
  refer: '/refer',
  reportsByDay: fromPattern(prefix + '/reports/:typeId/:plantId/'),
  reportsDayView: fromPattern(prefix + '/reports/dayView/'),
  reportsPrintReport: fromPattern(
    '/plant/:plantId/report/pandas2/:resolutionId/:dateFrom/:dateUntil/?format=xlsx&fields=:groupById&source=:typeId',
  ),
  productionPrintReport: fromPattern(
    '/plant/:plantId/report/:typeId/:resolutionId/:dateFrom/:dateUntil/?format=xlsx',
  ),
  reportsPrintPreview: '/plant/:plantId/day_report/:date/pdf/?sorting=:groupById',
  singlePlant: '/plant/:plantId',
  forecasts: '/plant/:plantId/#?tab=forecasts',
  requests: '/plant/:plantId/#?tab=requests',
  prodSchedule: '/plant/:plantId/#?tab=production_schedule',
  configuration: '/plant/:plantId/#?tab=plant_configuration',
  orderPreview: '/plant/:plantId/#/?order=:orderId',

  // new app
  root: prefix + '/',
  login: prefix + '/login',
  signUp: prefix + '/signup',
  signUpComplete: prefix + '/signup/complete',
  logout: prefix + '/logout',
  dashboard: prefix + '/dashboard',
  dashboardPlants: prefix + '/dashboard/plants',
  dashboardCustomerPortals: prefix + '/dashboard/customerPortals',
  dashboardSpaces: prefix + '/dashboard/spaces',

  plantCalendar: prefix + '/plant/:plantId',
  plantOrders: prefix + '/plant/:plantId/orders',
  plantForecasts: prefix + '/plant/:plantId/forecasts',
  plantActivity: prefix + '/plant/:plantId/activity',
  plantRequests: prefix + '/plant/:plantId/requests',

  moveOrder: prefix + '/plant/:plantId/moveOrder',

  plantGeneralConfiguration: prefix + '/plant/:plantId/configuration/general',
  plantOrderFormConfiguration: prefix + '/plant/:plantId/configuration/orderForm',
  plantExportImport: prefix + '/plant/:plantId/configuration/exportImport',

  plantMixDesignsOverview: prefix + '/plant/:plantId/configuration/mixDesigns/',
  plantNewMixDesign: prefix + '/plant/:plantId/configuration/mixDesigns/new/',
  plantEditMixDesign: prefix + '/plant/:plantId/configuration/mixDesigns/:designId/edit/',

  plantProductionModesOverview: prefix + '/plant/:plantId/configuration/productionModes/',
  plantNewProductionMode: prefix + '/plant/:plantId/configuration/productionModes/new/',
  plantEditProductionMode:
    prefix + '/plant/:plantId/configuration/productionModes/:productionModeId/edit/',

  plantMaterialsConfiguration: prefix + '/plant/:plantId/configuration/materials',
  plantEditMaterialConfiguration: prefix + '/plant/:plantId/configuration/materials/:materialId',

  plantPermissionsConfiguration: prefix + '/plant/:plantId/configuration/permissions',
  plantCustomersConfiguration: prefix + '/plant/:plantId/configuration/customers',
  plantSubscribersConfiguration: prefix + '/plant/:plantId/configuration/subscribers',

  account: prefix + '/profile',
  accountOrganizationMembership: prefix + '/profile/membership',
  accountPlants: prefix + '/profile/plants/',
  accountActivePlants: prefix + '/profile/plants/active',
  accountInactivePlants: prefix + '/profile/plants/inactive',
  accountPlantList: prefix + '/profile/plants/list/',
  newPlant: prefix + '/profile/plants/new/',
  customerPlants: prefix + '/profile/customers',
  accountNotifications: prefix + '/profile/notifications',
  myProfile: prefix + '/profile/me',

  changePassword: prefix + '/password/change',
  changePasswordDone: prefix + '/password/change/done',

  accountSuppliers: prefix + '/profile/suppliers/',
  listSuppliers: prefix + '/profile/suppliers/list/',
  newSupplier: prefix + '/profile/suppliers/new/',
  editSupplier: prefix + '/profile/suppliers/edit/:supplierId/',
  deleteSupplier: prefix + '/profile/suppliers/delete/:supplierId/',

  reports: prefix + '/reports/',
  settings: prefix + '/plant/:plantId/settings/',
  formConfiguration: prefix + '/plant/:plantId/settings/formConfiguration/',
  orderFormConfiguration: prefix + '/plant/:plantId/settings/formConfiguration/order/',
  orderDateFormConfiguration: prefix + '/plant/:plantId/settings/formConfiguration/orderDate/',
  plantInformation: prefix + '/plant/:plantId/settings/plantInformation/',
  materialManagement: prefix + '/plant/:plantId/settings/materialManagement/',
  materialManagementMaterialsList:
    prefix + '/plant/:plantId/settings/materialManagement/materials/',
  materialManagementMaterialEdit:
    prefix + '/plant/:plantId/settings/materialManagement/materials/:materialId/',
  materialManagementAddNewMaterial:
    prefix + '/plant/:plantId/settings/materialManagement/materials/new/',
  materialManagementProductionMode:
    prefix + '/plant/:plantId/settings/materialManagement/productionMode/',
  materialManagementProductionModeEdit:
    prefix + '/plant/:plantId/settings/materialManagement/productionMode/:productionModeId/',
  materialManagementProductionModeAdd:
    prefix + '/plant/:plantId/settings/materialManagement/productionMode/new/',
  materialManagementProductionModeCopy:
    prefix + '/plant/:plantId/settings/materialManagement/productionMode/:productionModeId/copy/',
  materialManagementMixDesign: prefix + '/plant/:plantId/settings/materialManagement/mixDesign/',
  materialManagementMixDesignEdit:
    prefix + '/plant/:plantId/settings/materialManagement/mixDesign/:mixDesignId/',
  materialManagementMixDesignAdd:
    prefix + '/plant/:plantId/settings/materialManagement/mixDesign/new/',
  materialManagementCopyMixDesign:
    prefix + '/plant/:plantId/settings/materialManagement/mixDesign/:mixDesignId/copy/',
  materialManagementSuppliers: prefix + '/plant/:plantId/settings/materialManagement/suppliers/',
  members: prefix + '/plant/:plantId/settings/members/',
  membersList: prefix + '/plant/:plantId/settings/members/list',
  addNewMember: prefix + '/plant/:plantId/settings/members/new/',
  updateMember: prefix + '/plant/:plantId/settings/members/:memberId/',
  customers: prefix + '/plant/:plantId/settings/customers/',
  customersList: prefix + '/plant/:plantId/settings/customers/list/',
  customersExport: prefix + '/plant/:plantId/settings/customers/export/',
  addNewCustomer: prefix + '/plant/:plantId/settings/customers/new/',
  editCustomer: prefix + '/plant/:plantId/settings/customers/:customerId/',
  subscribers: prefix + '/plant/:plantId/settings/subscribers/',
  subscribersList: prefix + '/plant/:plantId/settings/subscribers/list/',
  addNewSubscriber: prefix + '/plant/:plantId/settings/subscribers/new/',
  onBoarding: prefix + '/onBoarding',
  onBoardingAddPlant: prefix + '/onBoarding/addPlant',
  onBoardingAddMaterials: prefix + '/onBoarding/addMaterials/',
  space: `${prefix}/space/:spaceId`,
  spaceSettings: `${prefix}/space/:spaceId/settings`,
  spaceFormConfiguration: `${prefix}/space/:spaceId/settings/formConfiguration`,
  spaceMembers: `${prefix}/space/:spaceId/settings/members/`,
  spaceMembersList: `${prefix}/space/:spaceId/settings/members/list/`,
  addSpaceMembers: `${prefix}/space/:spaceId/settings/members/new/`,
  updateSpaceMembers: `${prefix}/space/:spaceId/settings/members/:memberId/`,
  primaryFieldsConfiguration: `${prefix}/space/:spaceId/settings/formConfiguration/primaryFields/`,
  primaryFieldCreate: `${prefix}/space/:spaceId/settings/formConfiguration/primaryFields/new/`,
  primaryFieldEdit: `${prefix}/space/:spaceId/settings/formConfiguration/primaryFields/:fieldId/`,
  primaryField2: `${prefix}/space/:spaceId/primaryFields/:fieldName/:primaryFieldId`,
  secondaryFieldsConfiguration: `${prefix}/space/:spaceId/settings/formConfiguration/secondaryFields`,
  spaceInformation: `${prefix}/space/:spaceId/settings/spaceInformation`,
  spaceNamingConfiguration: `${prefix}/space/:spaceId/settings/namingConfiguration`,
  namingConfigurationsList: `${prefix}/space/:spaceId/settings/namingConfiguration/list`,
  namingConfigurationCreate: `${prefix}/space/:spaceId/settings/namingConfiguration/new`,
  namingConfigurationEdit: `${prefix}/space/:spaceId/settings/namingConfiguration/:configurationId`,

  permissionDenied: `${prefix}/permission-denied`,
  plantNotExist: `${prefix}/plant-not-exist`,
  pageNotFound: prefix + '/page-not-found',
};

export default routeMap;
