import { GetInputMaterialProps, ListOrdersParams, ListOrdersResponse } from 'Types/orderTypes';
import { InputOutputReportDataResponse } from 'Types/reportTypes';
import {
  ClearOrdersRequest,
  FetchOrdersActionFailed,
  FetchOrdersActionRequested,
  FetchOrdersActionSucceeded,
  GetInputMaterialActionFailed,
  GetInputMaterialActionRequested,
  GetInputMaterialActionSucceeded,
  LoadMoreOrdersActionFailed,
  LoadMoreOrdersActionRequested,
  LoadMoreOrdersActionSucceeded,
  OrdersActionTypes,
} from './actionTypes/orderActionTypes';

export const listOrders = (params: ListOrdersParams): FetchOrdersActionRequested => ({
  type: OrdersActionTypes.ORDERS_FETCH_REQUESTED,
  payload: params,
});

export const listOrdersSucceeded = (params: ListOrdersResponse): FetchOrdersActionSucceeded => ({
  type: OrdersActionTypes.ORDERS_FETCH_SUCCEEDED,
  payload: params,
});

export const listOrdersFailed = (params: unknown): FetchOrdersActionFailed => ({
  type: OrdersActionTypes.ORDERS_FETCH_FAILED,
  payload: params,
});

export const loadMoreOrders = (params: ListOrdersParams): LoadMoreOrdersActionRequested => ({
  type: OrdersActionTypes.LOAD_MORE_ORDERS_REQUESTED,
  payload: params,
});

export const loadMoreOrdersSucceeded = (
  params: ListOrdersResponse,
): LoadMoreOrdersActionSucceeded => ({
  type: OrdersActionTypes.LOAD_MORE_ORDERS_SUCCEEDED,
  payload: params,
});

export const loadMoreOrdersFailed = (params: unknown): LoadMoreOrdersActionFailed => ({
  type: OrdersActionTypes.LOAD_MORE_ORDERS_FAILED,
  payload: params,
});

export const clearOrders = (): ClearOrdersRequest => ({
  type: OrdersActionTypes.ORDERS_CLEAR_REQUESTED,
  payload: null,
});

export const getInputMaterials = (
  params: GetInputMaterialProps,
): GetInputMaterialActionRequested => ({
  type: OrdersActionTypes.ORDERS_INPUT_MATERIALS_REQUEST,
  payload: params,
});

export const getInputMaterialsSucceeded = (
  params: InputOutputReportDataResponse,
): GetInputMaterialActionSucceeded => ({
  type: OrdersActionTypes.ORDERS_INPUT_MATERIALS_SUCCEEDED,
  payload: params,
});

export const getInputMaterialsFailed = (params: unknown): GetInputMaterialActionFailed => ({
  type: OrdersActionTypes.ORDERS_INPUT_MATERIALS_FAILED,
  payload: params,
});
