import { TranslationKeys } from './en';

export const es: TranslationKeys = {
  copyright: {
    systemStatus: 'Estado del sistema',
  },
  mixPlant: 'fábrica mixta',
  aggregatePlant: 'Fábrica de agregados',
  pavingSchedule: 'Calendario de pavimentación',
  home: 'Casa',
  mesch: 'MeSch Software UG',
  allRights: '(de responsabilidad limitada). Reservados todos los derechos.',
  privacyPolicy: 'Política de privacidad',
  termsOfService: 'Términos de servicio',
  whatsTheName: '¿Cómo se llama el nuevo empleado?',
  employeeEmail: 'La dirección de correo electrónico del empleado:',
  dashboard: 'Tablero',
  paving: 'Pavimentación',
  plant: 'Planta',
  generalOrderFields: 'Campos de orden general',
  dailyOrderFields: 'Campos de pedidos diarios',
  invitation: 'Invitación',
  role: 'Role',
  cancel: 'Cancelar',
  ok: 'OK',
  save: 'Ahorrar',
  add: 'Agregar',
  type: 'Escribe',
  required: 'Requerido',
  visibleToCustomers: 'Visible para los clientes?',
  export: 'Exportar',
  newField: 'Nuevo campo',
  oldPassword: 'Contraseña anterior',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  oldPasswordRequired: '¡Se requiere la contraseña anterior!',
  newPasswordRequired: '¡Se requiere la nueva contraseña!',
  newPasswordConfirmation: 'Por favor, escriba de nuevo su nueva contraseña.',
  passwordsDontMatch: 'Los dos campos de contraseña no coincidían.',
  passwordMinLength: 'La contraseña debe tener al menos 8 caracteres',
  passwordMustBeDifferent: 'La nueva contraseña debe ser diferente de la contraseña anterior.',
  passwordChangeDone: 'Tu contraseña fue cambiada. Click aqui para salir.',
  nameChangeDone: 'Tu nombre fue cambiado.',
  changePassword: 'Cambia la contraseña',
  password: 'Clave',
  passwordPlaceholder: 'Clave...',
  repeatNewPassword: '¡Repita la nueva contraseña!',
  repeatPassword: 'Repita la contraseña',
  repeatPasswordPlaceholder: 'Repita la contraseña...',
  termsAndConditions: 'Inicie mi Prueba Gratuita y acepte los Términos y Condiciones',
  termsAndConditionsRequiredMsg: 'Por favor acepte los Términos y Condiciones',
  email: 'Correo electrónico',
  emailAddress: 'Dirección de correo electrónico',
  emailPlaceholder: 'Correo electrónico...',
  emailAddressPlaceholder: 'Dirección de correo electrónico',
  subscriberNamePlaceholder: 'Nombre del suscriptor',
  code: 'Código',
  phone: 'Teléfono',
  phoneNumber: 'Número de teléfono',
  fieldIsRequired: 'El campo %{fieldName} es obligatorio',
  fieldIsMissing: '%{fieldName} no existe',
  company: 'Compañía',
  message: 'Mensaje',
  send: 'Enviar',
  sending: 'Enviando',
  sendInvite: 'Enviar invitación',
  sendToMe: 'Enviar a mí',
  sendToOrg: 'Enviar a mi equipo',
  print: 'Impresión',
  download: 'Descargar',
  printReport: 'Imprimir reporte',
  minimumAmountIsRequired: '¡Se requiere una cantidad mínima!',
  maximumAmountIsRequired: '¡Se requiere la cantidad máxima!',
  currentAmountIsRequired: '¡Se requiere la cantidad actual!',
  nameIsRequired: '¡Se requiere el nombre!',
  selectCountryCode: '¡Seleccione el código de país!',
  firstNamePlaceholder: 'Nombre',
  lastNamePlaceholder: 'Apellido',
  reports: 'Informes',
  account: 'Cuenta',
  referAFriend: 'Recomendar un amigo',
  logOut: 'Cerrar sesión',
  organizationMembership: 'Membresía de la organización',
  employeeCount: '%{count} empleados',
  members: 'miembros',
  addAnEmployee: 'Agregar un empleado',
  addANewPlant: 'Agregar una nueva fábrica',
  onlyAddEmployees: 'Solo debe agregar empleados de %{name}.',
  onlyAddCurrent: 'Agregar solo empleados actuales',
  inviteNewEmployee: 'Invitar a un nuevo empleado a %{name}',
  plants: 'Fábricas',
  schedule: 'Calendario',
  edit: 'Editar',
  create: 'Crear',
  createPlant: 'Crear fábrica',
  editPlant: 'Editar fábrica',
  chooseSupplier: 'elegir proveedor',
  supplier: 'Proveedor',
  suppliers: 'Proveedores',
  materials: 'Materiales',
  productionMode: 'Modo de producción',
  mixDesignLabel: 'Diseño de mezcla',
  activate: 'Activar',
  deactivate: 'Desactivar',
  actions: 'Comportamiento',
  notifications: 'Notificaciones',
  address: 'Dirección',
  name: 'Nombre',
  customers: 'Clientes',
  firstName: 'Primer nombre',
  lastName: 'Apellido',
  saveName: 'Guardar nombre',
  date: 'Fecha',
  expired: 'Venció',
  picture: 'Imagen',
  maxCapacity: 'Máxima capacidad',
  plantName: 'Nombre de fábrica',
  spaceName: 'Space Name',
  plantType: 'Tipo de fábrica',
  plantTypePlaceholder: 'Seleccionar tipo de fábrica',
  search: 'Búsqueda',
  searchPlaceholder: 'Búsqueda...',
  nightShift: 'Turno nocturno',
  nightShiftFrom: 'Turno de noche desde',
  nightShiftTo: 'Turno de noche a',
  inactivePlants: 'Fábricas inactivas',
  activePlants: 'Fábricas Activas',
  tonsPerDay: 'toneladas/día',
  addPlant: 'Agregar fábrica',
  addInputMaterial: 'Agregar material de entrada',
  addNewSupplier: 'Agregar nuevo proveedor',
  addSupplier: 'Agregar proveedor',
  editSupplier: 'Editar proveedor',
  deleteSupplier: 'Eliminar proveedor',
  deleteSupplierMessage: '¿Está seguro de que desea eliminar el proveedor?',
  deleteOrderField: 'Eliminar campo de formulario de pedido',
  deleteOrderFieldMessage: '¿Está seguro de que desea eliminar el campo de pedido?',
  yesDelete: 'Sí, Eliminar',
  yesDeactivate: 'Sí, Desactivar',
  yesActivate: 'Sí, Activar',
  yes: 'Sí',
  description: 'Descripción',
  addEmpoyee: 'Agregar empleado',
  reactivatePlant: 'Reactivar Fábrica',
  deactivatePlant: 'Desactivar fábrica',
  configureSupplier: 'Configurar proveedor',
  configurePlant: 'Configurar Fábrica',
  billing: 'Facturación',
  changeCard: 'Cambiar tarjeta',
  confirm: 'Confirmar',
  aysReactivate: '¿Está seguro de que desea reactivar %{name}?',
  aysDeactivate: '¿Está seguro de que desea desactivar %{name}?',
  aysDelete: '¿Estás seguro de que quieres eliminar?',
  aysRestore: '¿Está seguro de que desea restaurar %{name}?',
  updateEntitySuccessMsg: '%{entity} %{name} se ha actualizado correctamente',
  deleteEntitySuccessMsg: '%{entity} %{name} se ha eliminado correctamente',
  toggleNavigation: 'Alternar Navegación',
  login: 'Acceso',
  loginSSOBtnLabel: 'Inicia sesión con tu cuenta de la empresa',
  signUp: 'Inscribirse',
  tryItOut: '¡Pruébalo!',
  learnMore: 'Aprende más',
  contact: 'Contacto',
  services: 'Servicios',
  help: 'Ayuda',
  remove: 'Remover',
  deletedPrefix: '[ELIMINADO]',
  inactivePrefix: '[INACTIVO]',
  copy: 'Copiar',
  verified: 'Verificado',
  newOrderRequest: 'Solicitud de nuevo pedido',
  helpButtonText: '¿NECESITAS AYUDA?',
  supportModalTitle: '¿Cómo podemos ayudar?',
  supportModalInputPlaceholder: 'Escriba su pregunta o problema con el software...',
  supportModalSent: 'El mensaje fue enviado con éxito.',
  supportModalError: 'Póngase en contacto con nosotros en support@plantdemand.com',
  loading: 'Cargando...',
  submit: 'Enviar',
  academy: 'Academia',
  support: 'Apoyo',
  walkthrough: 'Tutorial',
  reportBug: 'Reportar un error',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: '¿Se te olvidó tu contraseña?',
  staySignedIn: 'Permanecer conectado',
  logInAgain: 'Ingresar de nuevo',
  logInPlantDemand: 'Iniciar sesión en PlantDemand',
  passwordRequiredFieldMsg: 'Debe especificar una contraseña',
  passwordMinLengthErrorMsg: 'La contraseña debe tener al menos %{length} caracteres',
  emailRequiredFieldMsg: 'El correo electronico es requerido',
  newToPD: '¿Nuevo en PlantDemand?',
  newToPDText:
    '¡Estamos felices de verte aquí! Puede crear una cuenta y comenzar a programar tu fábrica en 4 minutos.',
  externalSupplierNotification:
    'Si está tratando de dar acceso a sus fábricas a un proveedor o cliente externo, utilice las funciones de administración de usuarios en la pestaña de configuración de la planta.',
  inviteFriend: 'Invita a un amigo',
  thankYou: 'Gracias',
  referAFriendMessage:
    'Nos alegra que esté disfrutando de PlantDemand y quiera recomendarlo a sus amigos y colegas.',
  thanks: 'Gracias',
  reminder: 'Recordatorio:',
  warningCardTitleOnlyCurrentEmployee: 'Agregar solo empleados actuales',
  warningCardTextOnlyAddEmployee: 'Solo debe agregar empleados de',
  warningCardTextUseManagmentFeatures:
    'Si está tratando de dar acceso a sus fábricas a un proveedor o cliente externo, utilice las funciones de administración de usuarios en la pestaña de configuración de la planta.',
  errorCardText: 'No hay fábricas activas en este negocio. Por favor agregue una nueva fábrica.',
  pendingInvitations: 'Invitaciones pendientes',
  deleteInvitation: '¿Eliminar invitación?',
  aysDeleteInvitation: '¿Está seguro de que desea eliminar la invitación para %{user}?',
  inputForecast: 'Pronóstico de entrada',
  outputForecast: 'Pronóstico de salida',
  production: 'Producción',
  inventoryForecast: 'Pronóstico de inventario',
  dayView: 'Vista del día',
  using: 'Usando',
  by: 'Por',
  from: 'De',
  until: 'Hasta que',
  weekdays: 'Días de semana',
  filters: 'Filtros',
  shortcuts: 'Atajos',
  for: 'POR',
  selectedPlants: '%{count} fábrica(s) seleccionadas',
  plantsCaps: 'FÁBRICAS',
  noPlants: 'Sin fábricas',
  forAllPlants: 'PARA TODAS LAS FÁBRICAS',
  forOnePlant: 'PARA UNA FÁBRICA',
  thisWeek: 'Esta semana',
  nextWeek: 'La próxima semana',
  nextTwoWeeks: 'Dos semanas',
  threeWeeks: 'Tres semanas',
  thisMonth: 'Este mes',
  nextMonth: 'Próximo mes',
  thisQuarter: 'Este cuarto',
  thisYear: 'Este año',
  day: 'Día',
  night: 'Noche',
  week: 'Semana',
  month: 'Mes',
  refresh: 'Actualizar',
  selectMaterial: 'Seleccionar material',
  xlsx: 'XLSX',
  youDontHavePlants: 'Aún no tienes ninguna fábrica.',
  youCanConfigurePlants: 'Puede configurar sus fábricas en el',
  accountDownCase: 'cuenta',
  screen: 'pantalla.',
  youDontHaveOrganisation:
    'Aún no tienes una organización. Si su empresa ya está utilizando PlantDemand, solicite a su gerente que lo invite. Ahí podría configurar las fábricas de su negocio',
  page: 'página.',
  registerOrganisation: 'Registrar una nueva organización',
  askCompanyToConfigureBusiness:
    'Si su empresa ya está utilizando PlantDemand, solicite a su gerente que lo invite. Ahí podría configurar las fábricas de su negocio',
  availableFields: 'Campos disponibles:',
  updatedMaterialMsg: 'El material se actualizó con éxito.',
  updatedMaterialFailedMsg: 'No se puede actualizar el material',
  deleteMaterialMsg: 'El material fue eliminado con éxito',
  deleteMaterialFailedMsg: 'No se puede eliminar material',
  addNewSupplierMsg: 'El proveedor fue agregado exitosamente',
  groupBy: 'Agrupar por:',
  pivotReport: 'Informe de pivote',
  productionReport: 'Reporte de produccion',
  noReportData: 'No hay datos para las fechas seleccionadas.',
  materialName: 'Nombre del material',
  total: 'Total',
  customer: 'Cliente',
  dates: 'Fechas',
  quantity: 'Cantidad',
  loadTime: 'Tiempo de carga',
  material: 'Material',
  compact: 'Compacto',
  classic: 'Clásico',
  requestPending: 'Pedido pendiente',
  obsolete: 'Obsoleto',
  list: 'Lista',
  endDailyReport: 'TERMINAR EL INFORME DIARIO PARA',
  addTime: 'Agregar tiempo',
  to: 'A',
  orderBy: 'ordenar por',
  me: 'Yo',
  myOrganization: 'Mi organización',
  teamNotifications: 'Notificaciones de equipo',
  dailyReport: 'Reporte diario',
  plantSettings: 'Configuracion de notificaciones',
  myProfile: 'Mi perfil',
  all: 'Todos',
  pleaseSelect: 'Seleccione',
  selectAll: 'Seleccionar todo',
  inventory: 'Inventario',
  desiredRange: 'Rango deseado:',
  minimumInventory: 'Inventario mínimo:',
  maximumInventory: 'Inventario máximo:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Δ',
  inventoryForecastLegend: 'Δ - Cambio ↑ - Producción ↓ - Ventas',
  goToPlant: 'Ir a fábrica',
  noOrdersOn: 'Sin pedidos en',
  emailSentToMe: 'Se me ha enviado un correo electrónico',
  emailSentToMyOrganization: 'El correo electrónico ha sido enviado a mi Organización',
  removeNoteConfirmMessage: '¿Seguro que quieres eliminar esta nota?',
  comment: 'Comentario',
  updates: 'Actualizaciones',
  addComment: 'Agregar comentario',
  commentPlaceholder: 'Escriba su comentario',
  dropdownChoosePlaceholder: '- Elegir -',
  showAll: 'Mostrar todo',
  showLatest: 'Mostrar lo último',
  hide: 'Ocultar',
  show: 'Espectáculo',
  errorTitle: 'Error',
  noOptions: 'Sin opciones',
  components: 'Componentes',
  errorMessage: '¡Ups! Algo salió mal',
  requestErrorMessage: 'Ocurrió un error. Si está atascado, presione el botón de ayuda.',
  tryNewAppVersionMessage:
    'Estamos emocionados de que estés probando la nueva versión de PlantDemand 🎉 No dudes en enviarnos tus comentarios.',
  switchInterfaceMessage: 'Cambiar a la interfaz original',
  overProductionTitle: 'Superproducción',
  overProductionMessage: 'Tiene un exceso de capacidad por %{overProductionCount} para %{shift}',
  none: 'Ninguna',
  showAllOptions: 'Mostrar todos los elementos de %{count}',
  visibleToCustomersLabel: 'Visible para los clientes?',
  requestMaterial: 'Solicitar material',
  noTableDataMsg: 'No hay datos todavía.',
  reset: 'Reiniciar',
  dashboardPage: {
    goToPlant: 'Go to plant',
    totalQuantity: 'Total Quantity',
  },
  calendar: {
    addOrderText: 'Añadir pedido',
    tentative: 'Tentativo',
  },
  checkbox: {
    selectAll: 'Seleccionar todo',
    deselectAll: 'Deseleccionar todo',
  },
  calendarWeek: {
    newOrder: 'Nuevo orden',
    addNote: 'Añadir la nota',
    viewInputMaterial: 'Ver material de entrada',
    viewDayReport: 'Ver informe del día',
    viewEndOfDayReport: 'Ver informe de fin de día',
  },
  reportsPage: {
    calendarData: 'Datos del calendario',
    groupBy: 'Agrupar por',
    pivotBy: 'pivotar por',
    pivotByPlaceholder: 'Pivotar por...',
    presets: 'Preajustes...',
    timeFrame: 'Periodo de tiempo',
    sendReportFailedMsg: 'No se puede enviar el informe',
    sendToMyOrg: 'Enviar a mi organización',
    sendToMyOrgConfirmMsg:
      '¿Está seguro de que desea enviar este informe a todos los miembros de su organización?',
    saveInventoryValuesBtn: 'Guardar los valores del inventario',
    updateInventoryValuesSuccessMsg: 'Los valores del inventario han sido actualizados',
    updateInventoryValuesErrorMsg: 'No se pudo actualizar los valores del inventario',
  },
  calendarFilterBar: {
    fields: 'Campos',
    fieldsPlaceholder: 'Campos seleccionados',
    countFieldsPlaceholder: '%{count} Campos',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    view: 'Vista de pedido',
    move: 'Muevete',
    plants: 'Fábricas',
    dates: 'fechas',
    dateRange: 'Rango de fechas',
    selectRange: 'Seleccionar rango',
    quantityRange: 'Rango Cantidad',
    min: 'mínimo',
    max: 'máx.',
    goToToday: 'ir a hoy',
  },
  plantDropdown: {
    plantImageAlt: 'Imagen de la planta',
  },
  modal: {
    close: 'Cerca',
  },
  orderModal: {
    loadOrderDataErrorMsg: 'No se pudo eliminar el enlace',
    createOrderErrorMsg: 'No se pudo crear el pedido',
    updateOrderErrorMsg: 'No se pudo actualizar el pedido',
    deleteOrder: 'Eliminar Orden',
    deleteNote: 'Eliminar Nota',
    deleteOrderConfirmationMsg: 'Esta acción eliminará %{count} pedido(s). ¿Está seguro?',
    deleteOrderErrorMsg: 'No se pudo eliminar el pedido',
    notifyTeam: 'Notificar al equipo',
    disabledChangePlantTooltipTitle: 'Guarde este pedido antes de moverlo a otra fábrica',
    deleteAttachmentText: 'Eliminar archivo adjunto',
    deleteAttachmentQuestion: '¿Estás seguro de que quieres eliminar el archivo adjunto?',
    deleteAttachmentErrorMsg: 'No se pudo eliminar el archivo adjunto',
    downloadAttachmentFailedMsg: 'No se pudo descargar el archivo adjunto',
    deleteLinkText: 'Eliminar enlace',
    deleteLinkQuestion: '¿Estás seguro de que quieres eliminar el enlace?',
    deleteLinkErrorMsg: 'No se pudo eliminar el enlace',
    externalLinks: 'Enlaces externos',
  },
  orderRequestModal: {
    orderRequest: 'Solicitud de orden',
    confirmedOrder: 'Pedido confirmado',
    urgentRequest: 'Pedido urgente',
    rejectRequest: 'Solicitud de rechazo',
    rejectReason: 'Indique un motivo para rechazar la solicitud.',
    noRejectReason: 'No se ha proporcionado ningún motivo de rechazo.',
  },
  noteModal: {
    addNoteDate: 'Agregar fecha de nota',
    noteDate: 'Fecha de la nota',
    plant_closure: 'Cierre de Planta',
    space_closure: 'Cierre de Espacios',
    custom: 'Nota',
    unknown: 'Desconocido',
    crew: 'Tripulación / Tiempo libre',
    maintenance: 'Mantenimiento',
    alert: 'Alerta',
    inspection: 'Inspección',
    over_capacity: 'Exceso de Capacidad',
    other: 'Otro',
    fieldError: 'Este campo es obligatorio',
    descriptionLabel: 'Descripción *',
    descriptionPlaceholder: 'Nota descripción...',
    titleLabel: 'Título',
    titlePlaceholder: 'Cliente',
    typeLabel: 'Tipo de nota',
    typePlaceholder: 'Cierre de Fábrica',
    serverErrorText1:
      'Lamentablemente, no pudimos guardar su nota. Nuestro servidor dio el siguiente error',
    serverErrorText2:
      'Si este problema continúa <a>, haga clic aquí</a> para comunicarse con PlantDemand',
    notifyTeam: 'Notificar al equipo',
  },
  orderForm: {
    order: 'Ordenar',
    addDates: 'Agregar fechas',
    deliveryDates: 'Fechas de entrega',
    quantity: 'Cantidad *',
    deliveryDate: 'Fecha de entrega',
    deliveryTime: 'El tiempo de entrega',
    nightShift: 'Turno nocturno',
    create: 'Crear',
    save: 'Ahorrar',
    saving: 'Ahorro...',
    remove: 'Remover',
    copy: 'Copiar',
    copyOrderMsg:
      'Creamos una copia del pedido #%{copyOrderId}. Seleccione la nueva fecha de entrega y presione &quot;Guardar&quot;',
    moveDates: 'Mover fechas',
    movePlant: 'Mover fábrica',
    changePlant: 'Cambiar Fábrica',
    customerVisibleField: 'Este campo es visible para los clientes.',
    fieldIsRemoved: 'Este campo fue eliminado de su formulario',
    errorMessage: 'Algo salió mal',
    unknownFieldType: 'Tipo de campo desconocido',
    cancel: 'Cancelar',
    reject: 'Rechazar',
    approve: 'Aprobar',
    dailyOrderFieldsTooltipText: 'Haga clic en la flecha para ver los campos de pedidos diarios',
  },
  dateQuantityPicker: {
    noOrderFieldsYetMsg: 'No hay campos todavía. Vaya a la página Configuración para agregar uno',
    clickToAddDetailsMsg: 'Haga clic aquí para agregar detalles de pedidos diarios',
    tooltipTitle: 'Detalles diarios',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Material de entrada',
    quantity: 'Cantidad',
    supplier: 'Proveedor',
    noOrdersMessage: 'No hay materiales de entrada porque no hay pedidos programados en esta fecha',
    addOrderLink: 'Haga clic para agregar un pedido.',
    noInputMaterials:
      '<span>Este %{name} no tiene materiales de entrada.</span> <a>Haga clic aquí</a> <span>para agregar material de entrada a %{name}</span>',
    noInputMaterialsForDay: 'No hay materiales de entrada disponibles para este día.',
    clickToAddOrder: 'Haga clic aquí para agregar un nuevo pedido',
    clickToAddMixDesign: 'Haga clic aquí para administrar sus diseños de mezcla.',
  },
  monthNames: {
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  },
  activityList: {
    order: 'Ordenar',
    time: 'Tiempo',
    change: 'Cambio',
    user: 'Usuario',
    noData: 'No se han encontrado resultados.',
  },
  ordersList: {
    edit: 'Editar',
    material: 'Material',
    quantity: 'Cantidad',
    customer: 'Cliente',
    deliveryDate: 'Fecha de entrega',
    createdDate: 'Fecha de creación',
    noData: 'No se han encontrado resultados.',
    checkAll: '#',
    actions: 'Comportamiento',
  },
  plantHeader: {
    orders: 'Pedidos',
    planner: 'Planificador',
    activity: 'Actividad',
    requests: 'Peticiones',
    productionSchedule: 'Horario de produccion',
    plantSettings: 'Configuración de la fábrica',
    cogIcon: 'Icono de engranaje',
    newOrder: 'Nuevo orden',
  },
  accountHeader: {
    account: 'Cuenta',
    membership: 'Afiliación',
    plants: 'Fábricas',
    customers: 'Cliente',
    suppliers: 'Proveedores',
    billing: 'Facturación',
    notifications: 'Notificaciones',
    myProfile: 'Mi perfil',
  },
  moveOrder: {
    selectOrdersHeader: 'Tus pedidos seleccionados',
    selectPlant: 'Seleccionar Planta',
    selectMaterial: 'Seleccionar Material',
    moveSelectedOrdersBtn: 'Mover pedidos seleccionados',
    copySelectedOrdersBtn: 'Copiar pedidos seleccionados',
    disableBtnMessage: 'Seleccione la planta y el material de destino',
    disableMoveOrderBtnMessage: 'Seleccione al menos un campo',
    keepOriginalOrderLabel: 'Mantener el orden original',
    selectMatchingField: 'Eliminar el campo %{fieldName}',
    confirmPlantAndMaterial: 'Confirmar fábrica y material',
    moveOrderFrom: 'Mover orden de',
    moveOrderTo: 'Mover pedido a',
    deselectOrderMessage: 'La fecha del pedido ha sido deseleccionada.',
    undo: 'Deshacer',
    bubbleMsgHeader: 'Mueva sus campos de entrada',
    bubbleMsgSubHeader:
      'Haga coincidir los campos de entrada para asegurarse de que no se pierda información cuando mueva los pedidos seleccionados. Seleccione al menos un campo de entrada para mover este pedido.',
  },
  OrderFormConfigInfoPanel: {
    header: 'Aquí, puede personalizar los campos en sus formularios de pedido de fábricas:',
    requiredFields: 'Campos requeridos',
    optionalFields: 'Campos opcionales',
    optionsHeaderExampleText: 'Por ejemplo, puedes:',
    addTextFieldExampleText: 'Agregar un campo de texto para cargas de camiones',
    addTextAreaExampleText: 'Agregue un área de texto con instrucciones de recogida',
    addCheckboxExampleText:
      'Agregue una casilla de verificación para mostrar las recolecciones del turno de noche',
    visibleToCustomersText:
      'Marque los campos como visibles para el cliente, de modo que los clientes puedan ver y editar estos campos en las solicitudes de pedido.',
    fieldOrder: 'Orden de campo',
    fieldName: 'Nombre del campo',
  },
  addInputMaterialPage: {
    materialProperties: 'Propiedades materiales',
    materialName: 'Nombre del material',
    supplierProductName: 'Código/nombre del producto del proveedor',
    supplierProductNamePlaceholder: 'Ingrese el código/nombre del producto del proveedor',
    inventoryAmount: 'Cantidad de inventario',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    current: 'Actual',
    systemColor: 'Color del sistema',
    materialColor: 'Color de materiales',
    isOutputMaterial: 'es material de salida',
    addInputMaterial: 'Añadir material de entrada',
    materialNamePlaceholder: 'Nombre del material (ej. 1234 - Nombre)',
    deleteMaterial: 'Eliminar material',
    deleteMaterialConfirmationMsg: '¿Estás seguro que quieres borrar todo este material?',
  },
  addSupplierModal: {
    supplierName: 'Nombre del proveedor',
    supplierAddress: 'Dirección del proveedor',
  },
  mixDesign: {
    deleteMixDesign: 'Eliminar diseño de mezcla',
    deleteMixDesignderConfirmationMsg: '¿Estás seguro que quieres eliminar esto?',
    updatedMixDesignSucceededMsg: 'El diseño de la mezcla se ha actualizado con éxito.',
    updatedMixDesignFailedMsg: 'No se puede actualizar el diseño de la mezcla',
    createdMixDesignSucceededMsg: 'El diseño de la mezcla se ha creado con éxito.',
    createdMixDesignFailedMsg: 'No se puede crear un diseño de mezcla',
    deleteMixDesignSucceededMsg: 'El diseño de la mezcla se ha eliminado con éxito.',
    deleteMixDesignFailedMsg: 'No se puede eliminar el diseño de mezcla',
    addMixDesign: 'Agregar diseño de mezcla',
    addNewMaterial: 'Agregar nuevo material',
    addInputMaterial: 'Añadir material de entrada',
    addOutputMaterial: 'Agregar material de salida',
    selectOutputMaterial: 'Seleccionar material de salida',
    expirationDate: 'Fecha de caducidad',
    specificationBasis: 'Base de especificación',
    addComponent: 'Agregar componente',
    percent: 'Por ciento',
    components: 'Componentes',
    noComponentsMsg: 'No hay componentes',
    noMixDesignsMsg: 'No se encontraron diseños mixtos',
    mixDesignErrorMsg: 'No se puede cargar el diseño de la mezcla',
  },
  materialsPage: {
    noMaterialsMsg: 'No se encontraron materiales',
    outputMaterial: 'Material de salida',
  },
  informativePanel: {
    productionMode: 'Modo de producción',
    productionModeDescription:
      'Los modos de producción producen &quot;materias primas&quot;, que pueden pedirse directamente o combinarse en nuevos materiales utilizando &quot;diseños mixtos&quot;.',
    rawMaterial: 'Materia prima %',
    mixDesign: 'Diseño de mezcla',
    mixDesignDescription:
      'Combine &quot;materias primas&quot; para crear &quot;materiales de salida&quot;.',
  },
  subscribers: {
    subscribers: 'Suscriptores',
    newSubscriber: 'Nuevo Suscriptor',
    addNewSubscriber: 'Agregar nuevo suscriptor',
    infoSection:
      'Puede agregar direcciones de correo electrónico de personas que recibirán informes como parte de su organización.',
  },
  customersPage: {
    infoSection: 'Exporte esta lista a otra fábrica. Crear nuevo cliente',
    viewOrders: 'Ver pedidos',
    viewAsCustomer: 'Ver como cliente',
    noCustomersMsg: 'No se encontraron clientes',
    targetPlant: 'Fábrica de destino',
    selectTargetPlant: 'Seleccione la fábrica de destino',
    exportCustomers: 'Exportar Clientes',
    customerName: 'Nombre del cliente',
    customerList: 'Lista de clientes',
    addNewCustomer: 'Add new customer',
    company: 'Empresa',
    customerCompanyName: 'Nombre de la empresa del cliente',
    createCustomerPortal: 'Crear portal de clientes',
    createPortalErrorMsg:
      'Algunas solicitudes fallaron. Vuelva a intentarlo si continúa viendo este error, presione el botón de ayuda',
    editCustomer: 'Editar cliente',
    deleteCustomer: 'Eliminar cliente',
    restoreCustomer: 'Restaurar cliente',
    restoreEntitySuccessMsg: 'Cliente %{name} restaurado con éxito',
    inactiveCustomerMsg:
      'Este cliente está actualmente inactivo. Haga clic en "Restaurar" para activarlo nuevamente',
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'La información de la fábrica se actualizó correctamente',
    changePhoto: 'Cambiar foto',
    toLabel: 'a',
    exportSectionText1: '¡Sus datos de PlantDemand le pertenecen!',
    exportSectionText2:
      'Exporte los datos de su pedido como un archivo CSV para hacer copias de seguridad o utilícelos para crear sus propios informes de Excel. Recuerde volver a consultar aquí para obtener la versión más actualizada de los datos.',
    exportSectionText3:
      'Haga clic derecho en el enlace a continuación y seleccione &quot;Guardar destino como&quot; o &quot;Guardar enlace como&quot;;',
    exportCSVFileLabel: 'Exportar archivo CSV',
    backToPlant: 'volver a la fábrica',
    updatePlantInfoErrorMsg: 'No se pudo actualizar la información de la fábrica',
    changePlantImgSuccessMsg: 'La imagen de la fábrica se ha cambiado con éxito',
    changePlantImgFailedMsg: 'No se pudo cambiar la imagen de la fábrica.',
    exportPlantInfoErrorMsg: 'No se pudo exportar la información de la fábrica',
  },
  spaceInformation: {
    updateSpaceInfoSuccessMsg: 'Space information has been updated successfully',
    updateSpaceInfoErrorMsg: 'Could not update the space information',
  },
  plantMembers: {
    editMemberInfo: 'Editar información de miembro',
    addNewMember: 'Agregar nuevo miembro',
    name: 'Nombre',
    fistNamePlaceholder: 'Primer nombre del miembro',
    lastNamePlaceholder: 'Apellido del miembro',
    email: 'Dirección de correo electrónico',
    emailPlaceholder: 'Dirección de correo electrónico',
    close: 'Cerca',
    remove: 'Remover',
    save: 'Ahorrar',
    userList: 'Lista de usuarios',
    newEmployeeBtn: 'Nuevo empleado',
    employee: 'Empleado',
    role: 'Role',
    plant: 'Fábrica',
    plantAccessUserRoles: 'Acceso a la fábrica y roles de usuario',
    access: 'Acceso',
    noAccessPlaceholder: 'Sin acceso',
    deleteMembershipTitle: 'Eliminar membresía',
    deleteMembershipMessage: '¿Está seguro de que desea eliminar la membresía?',
    noRoleSelectedMessage: 'Seleccione al menos un rol',
    noPermissionsMessage: 'No tienes permisos para modificar el acceso',
    cannotUpdateYourPermissionsMsg: 'No se pueden actualizar sus permisos',
    cannotDeleteInvitationMessage: 'No se puede eliminar la invitación',
    updatePermissionsMessage: 'Los permisos se han actualizado con éxito',
    updatePermissionsError: 'Could not update the member permissions',
    removePermissionsError: 'Could not remove the member permissions',
    inviteNewMemberMessage: 'El miembro ha sido invitado con éxito.',
    deleteInvitationMessage: 'La invitación ha sido eliminada con éxito.',
    noMembersFound: 'No se encontraron miembros',
    deleteInvitationError: 'Could not delete the invitation',
  },
  plantPermissionsTable: {
    permissionsText: 'Permisos',
    needCustomPermissionText: '¿Necesita un permiso personalizado?',
    contactUs: 'Contáctenos',
    task: 'Tarea',
    plantManager: 'Gerente de la fábrica',
    dispatch: 'Despacho',
    QA: 'Control de calidad',
    customer: 'Cliente',
    viewCalendar: 'Ver calendario',
    enterOrders: 'Introducir pedidos',
    viewPlantSettings: 'Ver la configuración de la fábrica',
    changePlantSettings: 'Cambiar la configuración de la fábrica',
    viewReports: 'Ver los informes',
    inviteUsers: 'Invitar usuarios',
    manageOrderPlanner: 'Gestionar el planificador de pedidos',
    limited: 'Limitado',
  },
  suppliersPage: {
    noSuppliersMsg: 'No se encontraron proveedores',
    cannotCreateSupplier: 'No se pudo crear el proveedor',
  },
  onBoarding: {
    createCompany: 'Crea tu organización',
    companyName: 'Nombre de empresa',
    addFirstPlant: 'Agrega tu primera fábrica',
    uploadPlantImage: 'Agregar foto de la fábrica',
    addFirstOutputMaterial: 'Agrega tu primer material de salida',
    outputMaterialName: 'Nombre del material de salida',
    outputMaterialInfo: 'Este es el nombre de un material que vende',
    materialSetup: 'Configuración de materiales',
    fileSizeBig:
      'Tamaño de la imagen demasiado grande. Cambie el tamaño o pruebe con otro archivo.',
    error: 'Se ha producido un error',
    isDuplicate: 'Este valor ya ha sido ingresado.',
    toCalendar: 'Guardar e ir al Calendario',
    toAddMixDesign: 'Guardar y Agregar Otra Mezcla',
  },
  productionModePage: {
    composition: 'Composición',
    hourlyRate: 'Tarifa por hora',
    defaultHourlyRate: 'Tarifa por hora predeterminada',
    addProductionMode: 'Agregar modo de producción',
    updatedProductionModeMsg: 'El modo de producción se actualizó con éxito.',
    cannotUpdateProductionModeMsg: 'No se puede actualizar el modo de producción',
    deleteProductionMode: 'Eliminar modo de producción',
    deleteProductionModeConfirmMsg: '¿Está seguro de que desea eliminar este modo de producción?',
    deleteProductionModeSucceededMsg: 'El modo de producción se eliminó correctamente',
    deleteProductionModeFailedMsg: 'No se puede eliminar el modo de producción',
    createProductionModeSucceededMsg: 'El modo de producción se ha creado con éxito.',
    createProductionModeFailedMsg: 'No se puede crear el modo de producción',
  },
  orderRequests: {
    changeOrder: 'Cambia el orden',
    reviewRequest: 'Revisión de solicitud',
    noRequests: 'Actualmente no hay solicitudes pendientes para esta fábrica.',
  },
  termsAndConditionsCustomerPortal: 'Crear mi cuenta gratuita y aceptar las',
  termsAndConditionsText: 'Términos y condiciones',
  orders: 'Pedidos',
  nightShiftCaps: 'TURNO NOCTURNO',
  minimal: 'Mínimo',
  materialCompact: 'Material compacto',
  customerRequestNotifications: 'Solicitudes de clientes',
  sendFeedback: 'Enviar comentarios',
  customerPortal: 'Portal del Cliente',
  createPortal: 'Create Portal',
  attachments: 'Attachments',
  customerPortals: 'Portales de Clientes',
  portal: 'Portal',
  active: 'Activo?',
  configurePortal: 'Puede configurar el portal del cliente a continuación',
  link: 'Enlace',
  movingOrderInProgressMsg: 'Мoviendo 2 pedidos en curso',
  moveOrderSuccessMsg: 'Has movido %{count} pedidos del %{dateFrom} al %{dateTo}',
  moveOrderErrorMsg: 'No se pudieron mover los pedidos',
  orderAttachment: {
    attachFile: 'Adjuntar archivo',
  },
  orderFormConfiguration: {
    fieldAddedSuccessfully: 'El campo fue agregado exitosamente',
    addFieldFailedMsg: 'No se puede agregar el campo',
    fieldUpdatedSuccessfully: 'El campo se actualizó correctamente',
    fieldDeletedSuccessfully: 'El campo fue eliminado con éxito',
    fieldMovedSuccessfully: 'El campo se movió con éxito',
    noOrderFieldsMsg: 'No hay campos todavía. Haz clic en el botón “Nuevo Campo” para agregar uno.',
    renameFieldInProgressMsg: 'Actualizando nombre de campo. Espere por favor...',
    renameFieldSuccessMsg: 'El campo ha sido renombrado con éxito',
    renameFieldFailedMsg: 'No se pudo cambiar el nombre del campo',
  },
  permissionDenied: {
    header: 'Permiso denegado',
    permissionDeniedMsg: 'Usted no tiene permiso para acceder a esta página',
  },
  signUpPage: {
    loginToPlantDemandText: 'Iniciar sesión en PlantDemand',
    loginText:
      '¡Estamos felices de verte aquí! Puede crear una cuenta y comenzar a programar en 4 minutos.',
    invitationText:
      'Ha sido invitado a PlantDemand para facilitar la programación de la planta. Complete la configuración de su cuenta para acceder al programa.',
    hello: 'Hola',
    invitationText1: 'Has sido invitado a',
    invitationText2: 'Complete el formulario de registro para comenzar.',
    by: 'por',
    invitationExpiredText:
      'Este enlace de registro ha caducado. Pídale a su gerente que lo invite nuevamente o presione el botón "Necesito ayuda".',
    newToPlantDemandText: '¿Nuevo en PlantDemand?',
  },
  color: 'Color',
  settingsPage: {
    orderFormConfiguration: 'Configuración del formulario de pedido',
    plantInformation: 'Información de la planta',
    materialManagement: 'Gestión de materiales',
  },
  customFieldStyles: {
    bold: 'Negrilla',
    italic: 'Cursiva',
    underline: 'Subrayar',
    strikethrough: 'Tachado',
    default: 'Por defecto',
    fieldName: 'Nombre del campo',
    dayColor: 'Color del turno de día',
    nightColor: 'Color del turno de noche',
    textFormat: 'Formato de texto',
    fieldStyles: 'Estilos de campo',
  },
  passwordValidation: {
    email: 'Por favor, introduce una dirección de correo electrónico válida',
    lowerCase: 'La contraseña debe contener letras minúsculas',
    upperCase: 'La contraseña debe contener letras mayúsculas',
    number: 'La contraseña debe contener un número',
    symbol: 'La contraseña debe contener el símbolo',
  },
  passwordRequirements: {
    passwordMustContainMsg: 'Su contraseña debe contener:',
    passwordStrength: 'Seguridad de la contraseña: ',
    weak: 'Débil',
    strong: 'Fuerte',
    minLengthMsg: 'Al menos %{length} caracteres',
    lowerCaseMsg: 'Al menos una letra minúscula (a-z)',
    upperCaseMsg: 'Al menos una letra mayúscula (A-Z)',
    numberMsg: 'Al menos un número (0-9)',
    symbolMsg: 'Al menos un símbolo (! @ # $ % & + -)',
  },
  signUpComplete: {
    activateAccount: 'Activa tu cuenta',
    activationMsg1: '¡Ya casi has llegado! Hemos enviado un correo electrónico a',
    activationMsg2:
      'Para activar su cuenta, simplemente haga clic en el enlace proporcionado en el correo electrónico. En caso de que no pueda encontrarlo, verifique dos veces su carpeta de correo no deseado',
    activationMsg3:
      'Si necesita ayuda, contáctenos haciendo clic en el botón de ayuda a continuación',
    goToMainPage: 'Ir a la página principal',
  },
  webSocket: {
    reportDataUpdated:
      'Los datos del informe se han actualizado. Actualice la página para ver la información más reciente',
    orderUpdated:
      'El pedido ha sido actualizado. Haga clic en el botón Actualizar para recargar los datos',
  },
  logoutPage: {
    loggedOut: 'Logged Out',
    thanksForUsingPD: 'Thanks for using PlantDemand',
    signInAgain: 'Sign in again',
    tryAgain: 'Try again',
    backToHome: 'Back to Home page',
    somethingWentWrong: 'Something went wrong',
    logoutError: 'Could not logged you out',
  },
  membership: {
    noPlantsAssigned: 'No plants assigned',
    plantsAndRoles: 'Plants & roles',
  },
};
