import { AxiosResponse } from 'axios';
import { I18n } from 'react-redux-i18n';
import orderBy from 'lodash/orderBy';
import { convertLegacyToIsoDate, DateStringISO } from 'Services/timeService';
import { getFieldName } from './orderModalService';
import { get, set } from './storageService';
import { CustomFieldProps, OrderModalCustomFieldsType } from 'Types/OrderModalTypes';
import { formModeType } from 'Types/orderTypes';
import { OrderFieldsType } from 'Types/plantTypes';
import { Option, Optional } from 'Types/commonTypes';
import { Configuration, Space } from 'Types/spaceTypes';
import { LabelPosition } from 'Components/fields/InputFieldWrapper';
import { spaceApiMap } from '../utils/apiMap';
import axios from '../plantDemandAxios';

export type EntryDate = {
  id: string;
  created_at: DateStringISO;
  updated_at: DateStringISO;
  value: string;
  date: string;
  time: string;
  nightshift: boolean;
  secondary_fields_data: SecondaryFieldData[];
  entry: EntryDateEntry;
  removed: string | null;
  space: string;
};
export type EntryDateEntry = {
  id: string;
  space: string;
  created_at: DateStringISO;
  updated_at: DateStringISO;
  primary_fields_data: PrimaryFieldData[];
  secondary_fields_data: SecondaryFieldData[];
  removed: string | null;
};

export type EntryPrimaryFieldDataValue = {
  field_value_id: string;
  value: string;
  secondary_fields_data: SecondaryFieldData;
};

export type PrimaryFieldDataValue = {
  id: string;
  primary_field: string;
  value: string;
  secondary_fields_data: SecondaryFieldData[];
  removed: Optional<string>;
};

export type PrimaryFieldData = {
  field_id: string;
  field_name: string;
  is_multiselect: boolean;
  is_open: boolean;
  values: EntryPrimaryFieldDataValue[];
};

export type PrimaryField = {
  id: string;
  configuration: string;
  name: string;
  is_open: boolean;
  is_multiselect: boolean;
  values: PrimaryFieldDataValue[];
  secondary_fields: PrimaryFieldSecondaryField[];
  removed: Optional<string>;
};

export type SecondaryField = {
  id: string;
  configuration: string;
  name: string;
  field_type: OrderFieldsType;
  is_required: boolean;
  options: Optional<string[]>;
  default: Optional<string>;
  primary_field: string;
  removed: Optional<string>;
};

export type PrimaryFieldSecondaryField = {
  id: string;
  primary_field: string;
  configuration: string;
  name: string;
  field_type: OrderFieldsType;
  is_required: boolean;
  options: Optional<string[]>;
  default: Optional<string>;
};

export type PrimaryFieldSecondaryFieldNew = Omit<PrimaryFieldSecondaryField, 'id'>;

export type SecondaryFieldData = {
  field_id: string;
  field_name: string;
  field_type: OrderFieldsType;
  value: number | string | boolean | undefined;
};

const LAST_ACTIVE_SPACE = 'last_active_space';

export const getEntryDates = async ({
  spaceId,
  dateFrom,
  dateUntil,
  includeRemoved,
}: {
  spaceId: string;
  dateFrom?: string;
  dateUntil?: string;
  includeRemoved?: boolean;
}): Promise<EntryDate[]> => {
  const url = spaceApiMap.getEntryDates({ spaceId, dateFrom, dateUntil, includeRemoved });
  const response: AxiosResponse<EntryDate[]> = await axios.get(url);

  return response.data;
};

export type EntryDateType = {
  id: string;
  space: string;
  // entryId: string;
  created_at: string;
  updated_at: string;
  value: string;
  date: string;
  time: string;
  nightshift: boolean;
  secondary_fields_data?: SecondaryField[];
};

export type Entry = {
  id: string;
  space_id: string;
  dates: EntryDateType[];
  created_at: string;
  updated_at: string;
  removed: string | null;
  primary_fields_data: PrimaryFieldData[];
  secondary_fields_data: SecondaryFieldData[];
};

export const getEntryById = async ({
  spaceId,
  entryId,
}: {
  spaceId: string;
  entryId: string;
}): Promise<Entry> => {
  const url = spaceApiMap.getEntryById(spaceId, entryId);
  const response: AxiosResponse<Entry> = await axios.get(url);

  return response.data;
};

export const createEntry = async (
  spaceId: string,
  payload: {
    primary_fields_data: { field_id: string; value: string }[];
    secondary_fields_data: { field_id: string; value: string }[];
    dates: {
      date: string;
      time: string;
      value: string;
      secondary_fields_data: { field_id: string; value: string }[];
    }[];
  },
) => {
  const url = spaceApiMap.createEntry(spaceId);
  const response: AxiosResponse<Entry> = await axios.post(url, payload);

  return response.data;
};

export const updateEntry = async (
  spaceId: string,
  entryId: string,
  payload: {
    primary_fields_data: { field_id: string; value: string }[];
    secondary_fields_data: { field_id: string; value: string }[];
    dates: {
      date: string;
      time: string;
      value: string;
      secondary_fields_data: { field_id: string; value: string }[];
    }[];
  },
) => {
  const url = spaceApiMap.updateEntry(spaceId, entryId);
  const response: AxiosResponse<Entry> = await axios.patch(url, payload);

  return response.data;
};

export const deleteEntry = async (spaceId: string, entryId: string): Promise<null> => {
  const url = spaceApiMap.deleteEntry(spaceId, entryId);
  const response: AxiosResponse<null> = await axios.delete(url);

  return response.data;
};

export type CreatePrimaryFieldPayload = Pick<
  PrimaryField,
  'name' | 'configuration' | 'is_multiselect' | 'is_open'
>;

export const createPrimaryField = async (
  spaceId: string,
  data: CreatePrimaryFieldPayload,
): Promise<PrimaryField> => {
  const response: AxiosResponse<PrimaryField> = await axios.post(
    spaceApiMap.createPrimaryField(spaceId),
    data,
  );
  return response.data;
};

export const getSpacePrimaryFields = async (spaceId: string): Promise<PrimaryField[]> => {
  const url = spaceApiMap.getSpacePrimaryFields(spaceId);
  const response: AxiosResponse<PrimaryField[]> = await axios.get(url);

  return response.data;
};

export const getPrimaryFieldById = async (
  spaceId: string,
  fieldId: string,
): Promise<PrimaryField> => {
  const url = spaceApiMap.getPrimaryFieldById(spaceId, fieldId);
  const response: AxiosResponse<PrimaryField> = await axios.get(url);

  return response.data;
};

export type PrimaryFieldFormData = {
  spaceId: string;
  fieldId: string;
  data: { configuration: string; name: string; is_open: boolean; is_multiselect: boolean };
};

export const updatePrimaryField = async (formData: PrimaryFieldFormData): Promise<PrimaryField> => {
  const { spaceId, fieldId, data } = formData;
  const response: AxiosResponse<PrimaryField> = await axios.patch(
    spaceApiMap.updatePrimaryField(spaceId, fieldId),
    data,
  );
  return response.data;
};

export const deletePrimaryField = async (spaceId: string, fieldId: string): Promise<null> => {
  const response = await axios.delete(spaceApiMap.deletePrimaryField(spaceId, fieldId));
  return response.data;
};

export const createSecondaryField = async ({ spaceId, data }): Promise<SecondaryField> => {
  const url = spaceApiMap.createEntrySecondaryField(spaceId);
  const response: AxiosResponse<SecondaryField> = await axios.post(url, data);

  return response.data;
};

export const getEntrySecondaryFields = async (spaceId: string): Promise<SecondaryField[]> => {
  const url = spaceApiMap.getEntrySecondaryFields(spaceId);
  const response: AxiosResponse<SecondaryField[]> = await axios.get(url);

  return response.data;
};

export const updateSecondaryField = async ({ spaceId, fieldId, data }): Promise<SecondaryField> => {
  const url = spaceApiMap.updateEntrySecondaryField(spaceId, fieldId);
  const response: AxiosResponse<SecondaryField> = await axios.patch(url, data);

  return response.data;
};

export const deleteSecondaryField = async ({ spaceId, fieldId }): Promise<string> => {
  await axios.delete(spaceApiMap.deleteEntrySecondaryField(spaceId, fieldId));
  return fieldId;
};

export const createPrimaryFieldSecondaryField = async ({
  spaceId,
  primaryFieldId,
  data,
}: {
  spaceId: string;
  primaryFieldId: string;
  data: PrimaryFieldSecondaryFieldNew;
}): Promise<PrimaryFieldSecondaryField> => {
  const response: AxiosResponse<PrimaryFieldSecondaryField> = await axios.post(
    spaceApiMap.createPrimaryFieldSecondaryField(spaceId, primaryFieldId),
    data,
  );
  return response.data;
};

export const updatePrimaryFieldSecondaryField = async ({
  spaceId,
  primaryFieldId,
  secondaryFieldId,
  data,
}) => {
  const response = await axios.patch(
    spaceApiMap.updatePrimaryFieldSecondaryField(spaceId, primaryFieldId, secondaryFieldId),
    data,
  );
  return response.data;
};

export const deletePrimaryFieldSecondaryField = async ({
  spaceId,
  primaryFieldId,
  secondaryFieldId,
}): Promise<string> => {
  await axios.delete(
    spaceApiMap.deletePrimaryFieldSecondaryField(spaceId, primaryFieldId, secondaryFieldId),
  );
  return secondaryFieldId;
};

export const getEntryDatesSecondaryFields = async (spaceId: string): Promise<SecondaryField[]> => {
  const url = spaceApiMap.getEntryDatesSecondaryFields(spaceId);
  const response: AxiosResponse<SecondaryField[]> = await axios.get(url);

  return response.data;
};

export const getSpaces = async () => {
  const url = spaceApiMap.getSpaces();
  const response = await axios.get(url);

  return response.data;
};

export const getSpaceById = async (spaceId: string): Promise<Space> => {
  const url = spaceApiMap.getSpaceById(spaceId);
  const response = await axios.get(url);

  return response.data;
};

export const updateSpace = async (
  spaceId: string,
  payload: FormData | Partial<Space>,
): Promise<Space> => {
  const url = spaceApiMap.updateSpace(spaceId);
  const response = await axios.patch(url, payload);

  return response.data;
};

export const getPrimaryFieldValueById = async ({
  spaceId,
  primaryFieldId,
  fieldValueId,
}: {
  spaceId: string;
  primaryFieldId: string;
  fieldValueId: string;
}): Promise<PrimaryFieldDataValue> => {
  const url = spaceApiMap.getPrimaryFieldValueById(spaceId, primaryFieldId, fieldValueId);
  const response = await axios.get(url);
  return response.data;
};

export const getPrimaryFieldSecondaryFields = async ({
  spaceId,
  primaryFieldId,
}: {
  spaceId: string;
  primaryFieldId: string;
}): Promise<SecondaryField[]> => {
  const url = spaceApiMap.getPrimaryFieldSecondaryFields(spaceId, primaryFieldId);
  const response = await axios.get(url);
  return response.data;
};

export const getPrimaryFieldValues = async (spaceId: string, primaryFieldId: string) => {
  const url = spaceApiMap.getPrimaryFieldValues(spaceId, primaryFieldId);
  const response = await axios.get(url);
  return response.data;
};

export const createPrimaryFieldValue = async ({
  spaceId,
  primaryFieldId,
  payload,
}: {
  spaceId: string;
  primaryFieldId: string;
  payload: any;
}) => {
  const url = spaceApiMap.createPrimaryFieldValue({ spaceId, primaryFieldId });
  const response = await axios.post(url, payload);
  return response.data;
};
export const updatePrimaryFieldValue = async ({
  spaceId,
  primaryFieldId,
  valueId,
  payload,
}: {
  spaceId: string;
  primaryFieldId: string;
  valueId: string;
  payload: any;
}) => {
  const url = spaceApiMap.updatePrimaryFieldValue({ spaceId, primaryFieldId, valueId });
  const response = await axios.patch(url, payload);
  return response.data;
};

export const deletePrimaryFieldValue = async ({
  spaceId,
  primaryFieldId,
  valueId,
}: {
  spaceId: string;
  primaryFieldId: string;
  valueId: string;
}): Promise<null> => {
  const url = spaceApiMap.deletePrimaryFieldValue({ spaceId, primaryFieldId, valueId });
  const response = await axios.delete(url);
  return response.data;
};

export const setActiveSpace = (spaceId: string) => {
  set(LAST_ACTIVE_SPACE, spaceId);
};

export const getLastActiveSpace = () => {
  try {
    return get<string>(LAST_ACTIVE_SPACE);
  } catch (e) {
    return null;
  }
};

export const buildStaticFields = ({ spaceName, formMode, disabled, spaceConfiguration }) => {
  const spaceField = {
    key: 'space',
    title: spaceConfiguration.space_name,
    type: 'SpaceName',
    cols: 12,
    labelPosition: LabelPosition.top,
    hideChangePlantAction: formMode === formModeType.CREATE,
    spaceName,
    static: true,
    disabled,
  };

  return [spaceField];
};

const buildPrimaryFields = ({
  primaryFields,
  entryDateSecondaryFields,
  selectedDate,
  disabled,
  formMode,
  spaceConfiguration,
}: {
  primaryFields: PrimaryField[];
  entryDateSecondaryFields: SecondaryField[];
  selectedDate?: string;
  disabled: boolean;
  formMode: formModeType;
  spaceConfiguration: Configuration;
}) => {
  const primaryFieldsObject = primaryFields.map(primaryField => {
    return {
      key: getFieldName(primaryField.name),
      title: primaryField.name,
      type: primaryField.is_multiselect
        ? OrderModalCustomFieldsType.MultiSelect
        : OrderModalCustomFieldsType.InputDropdown,
      labelPosition: LabelPosition.top,
      options: primaryField.values.map(val => ({
        label: val.value,
        value: val.id,
        extraFieldsData: val.secondary_fields_data.map(data => ({
          key: data.field_name,
          value: data.value,
        })),
      })),
      renderWithTooltip: true,
      placeholder: primaryField.name,
      allowUnknownOption: primaryField.is_open,
      noOptionsMessage: I18n.t('noProjectMsg'),
      cols: 6,
      disabled,
      customValidation: (options?: Option | Option[]) => {
        const errorMsg = I18n.t('fieldIsRequired', { fieldName: primaryField.name });
        if (!options) return errorMsg;

        if (Array.isArray(options)) {
          if (!options.length) return errorMsg;
        } else {
          if (!options.value) return errorMsg;
        }
      },
    };
  });

  const dateQuantityPicker = {
    key: 'dates',
    title: I18n.t('dates'),
    type: OrderModalCustomFieldsType.DateQuantityPicker,
    selectedDate: selectedDate,
    extraFields: entryDateSecondaryFields,
    shouldHideDailyOrderFields: false,
    isEntryType: true,
    spaceConfiguration,
    formMode,
    calendarOpen: formMode === formModeType.COPY,
    showMoveFormBtn: formMode !== formModeType.CREATE,
    cols: 12,
    disabled,
  };

  return {
    dateQuantityPicker: [dateQuantityPicker],
    primaryFields: primaryFieldsObject,
  };
};

const buildSecondaryFields = ({
  fields,
  disabled,
}: {
  fields: SecondaryField[];
  disabled: boolean;
}) => {
  const secondaryFields: CustomFieldProps[] = fields.map(field => ({
    key: getFieldName(field.name),
    title: field.name,
    name: getFieldName(field.name),
    type: field.field_type as OrderFieldsType,
    labelPosition: LabelPosition.left,
    visibleToCustomer: false,
    deleted: false,
    cols: 12,
    isSupported: true,
    isCustomField: true,
    disabled,
  }));
  return secondaryFields;
};

export const buildEntrySchema = ({
  entry,
  spaceName,
  spacePrimaryFields,
  entrySecondaryFields,
  formMode,
  disabled,
  selectedDate,
  spaceConfiguration,
}: {
  entry: Entry;
  spaceName: string;
  spacePrimaryFields: PrimaryField[];
  entrySecondaryFields: SecondaryField[];
  formMode: formModeType;
  disabled: boolean;
  selectedDate?: string;
  spaceConfiguration: Configuration;
}) => {
  const staticFields = buildStaticFields({ spaceName, formMode, disabled, spaceConfiguration });
  const { dateQuantityPicker, primaryFields } = buildPrimaryFields({
    primaryFields: spacePrimaryFields,
    entryDateSecondaryFields: [],
    formMode,
    disabled,
    selectedDate,
    spaceConfiguration,
  });
  const secondaryFields = buildSecondaryFields({ fields: entrySecondaryFields, disabled });
  return {
    title: 'Entry',
    required: primaryFields.map(pf => pf.key),
    properties: [...staticFields, ...primaryFields, ...dateQuantityPicker, ...secondaryFields],
  };
};

export const mapEntryToFormData = ({
  entry,
  selectedDate,
  formMode,
  nightshift,
}: {
  entry: Entry;
  selectedDate?: DateStringISO;
  nightshift?: boolean;
  formMode: formModeType;
}) => {
  if (formMode === formModeType.CREATE) {
    const initialState = [
      {
        value: '',
        date: selectedDate,
        time: undefined,
        selected: false,
        nightshift,
        extraFields: [],
      },
    ];
    return { dates: initialState };
  }

  const primaryFields = entry.primary_fields_data.reduce((acc, el) => {
    const key: string = getFieldName(el.field_name);
    acc[key] = el.is_multiselect
      ? el.values.map(val => ({
          value: val.field_value_id,
          label: val.value,
          secondary_fields_data: val.secondary_fields_data,
        }))
      : {
          value: el.values[0].field_value_id,
          label: el.values[0].value,
          secondary_fields_data: el.values[0].secondary_fields_data,
        };
    return acc;
  }, {});

  const entryDates = entry.dates.map(date => ({
    ...date,
    quantity: date.value + '',
    date: date.date,
    time: date.time,
    value: date.value,
    extraFields: [],
  }));

  const sortedDates = orderBy(entryDates, ['date', 'time']);

  const secondaryFieldsData = entry.secondary_fields_data.reduce((acc, el) => {
    const fieldName = getFieldName(el.field_name);
    if (el.field_type === OrderFieldsType.Date && el.value) {
      acc[fieldName] = convertLegacyToIsoDate(el.value);
    } else {
      acc[fieldName] = el.value ? el.value : null;
    }

    return acc;
  }, {});

  return { ...primaryFields, dates: sortedDates, ...secondaryFieldsData };
};

export const mapFormDataToEntry = ({
  formData,
  entryId,
  primaryFields = [],
  secondaryFields,
  formMode,
}: {
  formData: any;
  entryId?: string;
  primaryFields: PrimaryField[];
  secondaryFields: SecondaryField[];
  formMode: formModeType;
}): {
  primary_fields_data: { field_id: string; value: string }[];
  secondary_fields_data: { field_id: string; value: string }[];
  dates: {
    date: string;
    time: string;
    value: string;
    secondary_fields_data: { field_id: string; value: string }[];
  }[];
} => {
  const primaryFieldsData = Object.keys(formData).reduce((acc, formDataKey) => {
    const primaryField = primaryFields.find(field => getFieldName(field.name) === formDataKey);
    if (primaryField) {
      if (Array.isArray(formData[formDataKey])) {
        const arr = formData[formDataKey].map(data => ({
          field_id: primaryField.id,
          value: data.label,
        }));
        acc.push(...arr);
        return acc;
      }

      acc.push({ field_id: primaryField.id, value: formData[formDataKey].label });
      return acc;
    }
    return acc;
  }, [] as any[]);
  const secondaryFieldsData = secondaryFields
    .filter(field => {
      const fieldName = getFieldName(field.name);
      return !!formData[fieldName];
    })
    .map(field => ({
      field_id: field.id,
      value: formData[getFieldName(field.name)],
    }));
  const dates = formData.dates.map(date => {
    return {
      value: date.value,
      date: date.date,
      time: date.time,
      secondary_fields_data: [],
    };
  });
  // const secondaryFieldsData = secondaryFields.map(field => {
  //   const fieldName = getFieldName(field.name);
  //   if (!!formData[fieldName]) {
  //     return { fieldId: field.id, value: formData[fieldName] };
  //   }
  // });
  return {
    primary_fields_data: primaryFieldsData,
    secondary_fields_data: secondaryFieldsData,
    dates: dates,
  };
};
