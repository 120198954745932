import { call, put, takeLatest } from 'redux-saga/effects';
import { I18n } from 'react-redux-i18n';
import { sendMessage } from '../../services/sendSupportMessageService';
import {
  SendSupportMessageActionRequested,
  SupportMessageActionTypes,
} from '../actions/sendSupportMessageActions';
import { triggerPageLevelAlert } from '../actions/pageLevelAlertActions';
import { AlertType } from '../../components/alerts/singleAlert';

export function* sendSupportMessage({ payload }: SendSupportMessageActionRequested) {
  try {
    yield call(sendMessage, payload);
    yield put({ type: SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_SUCCEEDED });
    yield put(
      triggerPageLevelAlert({
        type: AlertType.Success,
        message: I18n.t('supportModalSent'),
      }),
    );
  } catch (error) {
    yield put({ type: SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_FAILED, payload: error });
  }
}

export default function* watchSendSupportMessage() {
  yield takeLatest(SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_REQUESTED, sendSupportMessage);
}
