import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';
import * as qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { buildLoadTime, convertLegacyToIsoDate, toDefaultDateFormat } from './timeService';
import { getCalendarFilters } from './calendarFilterService';
import { getFirstAndLastDateOfMonth, getFirstDayOfWeek, getLastDayOfWeek } from './dateService';

import { OrderFieldsType, PlantId } from 'Types/plantTypes';
import {
  CreateOrderProps,
  CreateOrderResponse,
  DeleteOrderProps,
  GetOrderByIdParams,
  ListOrdersParams,
  ListOrdersResponse,
  Order,
  OrderDateSortEnum,
  UpdateOrderProps,
  OrderFeed,
  DeleteOrderAttachmentParams,
  OrderAttachment,
  OrderLink,
  MoveOrderDatePayload,
  MoveOrCopyOrderDatesProps,
  OrderId,
  GetInputMaterialProps,
  OrderExtraField,
} from 'Types/orderTypes';
import { makeGraphQLRequest } from '../request';
import { sortDateList } from '../helpers/array';
import { CalendarFiltersType, CalendarViewType } from '../types/commonTypes';
import { getBooleanValue } from '../utils/boolean';

export const getOrders = async (params: ListOrdersParams): Promise<ListOrdersResponse> => {
  const query = { query: buildQuery(params) };

  const response = await makeGraphQLRequest(query);
  return response.orderDates;
};

export const getOrderById = async (params: GetOrderByIdParams): Promise<Order> => {
  const { plantId, orderId } = params;

  let queryParams = '';

  if (plantId) {
    queryParams = queryParams.concat(`plantId: ${Number(plantId)}, `);
  }

  if (orderId) {
    queryParams = queryParams.concat(`orderId: ${Number(orderId)}`);
  }

  const query = `
    {
      orderById(${queryParams}) {
        id
        createdAt
        customer {
          id
          name
          removed
          extraFields {
            key
            value
            type
          }
        }
        removed
        extraFields {
          value
          key
          type
          visibleToCustomer
        }
        material {
          id
          systemColor
          name
          removed
          active
        }
        tentative
        feed {
          id
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          records
          comment
          messages {
            message
          }
        }
        dates {
          id
          quantity
          deliveryOn
          loadTime
          nightshift
          extraFields {
            value
            key
            type
            visibleToCustomer
          }
        }
        requests {
          id
          status
        }
      }
    }
  `;

  const data = { query };
  const { orderById }: { orderById: Order } = await makeGraphQLRequest(data, 'orderById');

  const prepareExtraFields = (field: OrderExtraField): OrderExtraField => {
    let value = field.value;
    if (field.type === OrderFieldsType.Checkbox) {
      value = getBooleanValue(value);
    }
    return { ...field, value };
  };

  const extraFields = orderById.extraFields.map(field => prepareExtraFields(field));

  const dates = orderById.dates.map(date => {
    return {
      ...date,
      extraFields: date.extraFields.map(field => prepareExtraFields(field)),
    };
  });

  sortDateList<OrderFeed>(orderById.feed, 'createdAt');

  const result = {
    ...orderById,
    extraFields,
    dates,
  };
  return result;
};

export const createOrder = async ({
  plantId,
  data,
}: CreateOrderProps): Promise<CreateOrderResponse> => {
  const url = `${apiMap.createOrder(plantId)}`;
  const { data: responseData } = await axios.post(url, data);
  return {
    orderId: responseData.id.toString(),
    deliveryOn: convertLegacyToIsoDate(responseData.dates[0].delivery_on),
  };
};

export const updateOrder = async ({ plantId, orderId, data, file, links }: UpdateOrderProps) => {
  const url = apiMap.updateOrder(plantId, orderId);
  const requests = [axios.patch(url, data)];

  if (file) requests.push(addOrderAttachment({ plantId, orderId, file }));
  if (links) requests.push(...links.map(link => addOrderLink({ plantId, orderId, link })));

  const [updatedOrderResponse] = await Promise.all(requests);

  return updatedOrderResponse.data;
};

export const getOrderAttachments = async ({ plantId, orderId }): Promise<OrderAttachment[]> => {
  const url = apiMap.listOrderAttachments(plantId, orderId);
  const response = await axios.get(url);
  return response.data;
};

export const addOrderAttachment = async ({ plantId, orderId, file }) => {
  const url = apiMap.addOrderAttachment(plantId, orderId);
  const response = await axios.post(url, file);
  return response.data;
};

export const deleteOrderAttachment = ({
  plantId,
  orderId,
  attachmentId,
}: DeleteOrderAttachmentParams) => {
  const url = apiMap.deleteOrderAttachment(plantId, orderId, attachmentId);
  return axios.delete(url);
};

export const downloadOrderAttachment = ({ plantId, orderId, attachmentId }) => {
  const url = apiMap.downloadOrderAttachment(plantId, orderId, attachmentId);
  return axios.get(url);
};

export const getOrderLinks = async ({ plantId, orderId }): Promise<OrderLink[]> => {
  const url = apiMap.listOrderLinks(plantId, orderId);
  const response = await axios.get(url);
  return response.data;
};

export const addOrderLink = async ({
  plantId,
  orderId,
  link,
}: {
  plantId: PlantId;
  orderId: OrderId;
  link: OrderLink;
}) => {
  const url = apiMap.addOrderLink(plantId, orderId);
  const response = await axios.post(url, link);
  return response.data;
};

export const deleteOrderLink = ({
  plantId,
  orderId,
  linkId,
}: {
  plantId: PlantId;
  orderId: OrderId;
  linkId: string;
}) => {
  const url = apiMap.deleteOrderLink(plantId, orderId, linkId);
  return axios.delete(url);
};

export const deleteOrder = async ({ plantId, orderId }: DeleteOrderProps) => {
  const url = apiMap.deleteOrder(plantId, orderId);
  const response = await axios.delete(url);
  return response.data;
};

export const moveOrderDates = async ({
  plantId,
  data,
}: {
  plantId: PlantId;
  data: MoveOrderDatePayload;
}) => {
  const promises = data.orders.map(order => {
    const params = {
      id: order.orderId,
      material: data.materialId,
      customer: order.customer,
      extraFields: order.extraFields,
      dates: order.orderDates.map(orderDate => {
        return {
          id: orderDate.id,
          quantity: orderDate.quantity,
          delivery_on: toDefaultDateFormat(orderDate.deliveryOn),
          load_time: orderDate.loadTime ? buildLoadTime(orderDate.loadTime) : null,
          nightshift: orderDate.nightshift,
          extra_fields_data: orderDate.extraFields.map(dateExtraField => {
            const fieldValue =
              dateExtraField.type === OrderFieldsType.Date
                ? toDefaultDateFormat(dateExtraField)
                : dateExtraField.value;
            return {
              key: dateExtraField.key,
              value: fieldValue,
              type: dateExtraField.type,
              visible_to_customer: dateExtraField.visibleToCustomer,
            };
          }),
        };
      }),
    };

    const url = apiMap.updateOrder(plantId, order.orderId);
    return axios.patch(url, params);
  });

  const result = await Promise.all(promises);
  return result;
};

export const moveOrder = async (params: MoveOrCopyOrderDatesProps) => {
  const variables = {
    customFieldMappings: params.customFieldMappings,
    orderDates: params.orderDates,
    toPlantId: parseInt(params.toPlantId),
    toMaterialId: params.toMaterialId,
  };

  const query = {
    query: `mutation moveOrderDates($customFieldMappings: [MappingInput]!, $orderDates: [Int]!, $toPlantId: Int!, $toMaterialId: Int!) {
      moveOrderDates(customFieldMappings: $customFieldMappings, orderDates: $orderDates, toPlantId: $toPlantId, toMaterialId: $toMaterialId) {
        __typename
        ... on MoveOrderDatesResponse {
          success
          newOrder {
            id
          }
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  return await makeGraphQLRequest(query, 'moveOrderDates');
};

export const copyOrder = async (params: MoveOrCopyOrderDatesProps) => {
  const variables = {
    customFieldMappings: params.customFieldMappings,
    orderDates: params.orderDates,
    toPlantId: parseInt(params.toPlantId),
    toMaterialId: params.toMaterialId,
  };

  const query = {
    query: `mutation copyOrderDates($customFieldMappings: [MappingInput]!, $orderDates: [Int]!, $toPlantId: Int!, $toMaterialId: Int!) {
      copyOrderDates(customFieldMappings: $customFieldMappings, orderDates: $orderDates, toPlantId: $toPlantId, toMaterialId: $toMaterialId) {
        __typename
        ... on CopyOrderDatesResponse {
          success
          newOrder {
            id
          }
        }
        ... on ValidationError {
          fieldErrors {
            fieldName
            fieldPath
            errorDescription
          }
        }
      }
    }
    `,
    variables,
  };

  return await makeGraphQLRequest(query, 'copyOrderDates');
};

export const getCustomFieldMapping = async ({
  fromPlantId,
  toPlantId,
}: {
  fromPlantId: PlantId;
  toPlantId: PlantId;
}) => {
  const variables = {
    fromPlantId: parseInt(fromPlantId),
    toPlantId: parseInt(toPlantId),
  };

  const query = {
    query: `query customFieldMapping($fromPlantId: Int!, $toPlantId: Int!) {
      customFieldMapping(fromPlantId: $fromPlantId, toPlantId: $toPlantId) {
        fromName
        toName
      }
    }
    `,
    variables,
  };

  const { customFieldMapping } = await makeGraphQLRequest(query, 'moveOrder');

  return customFieldMapping;
};

export const getInputMaterials = (params: GetInputMaterialProps) => {
  const request = apiMap.getInputOutputReport(
    'input',
    params.plantId,
    'day',
    params.dateFrom,
    params.dateUntil,
    'material,supplier',
    params.sourceId,
  );

  return axios.get(request, {}).then(response => {
    return response.data;
  });
};

function buildQuery(params: ListOrdersParams) {
  let queryParams = ``;

  if (params.plantId) {
    queryParams = queryParams.concat(`plantId: ${Number(params.plantId)}, `);
  }

  if (params.orderDateIds && params.orderDateIds.length) {
    queryParams = queryParams.concat(`orderDateIds: ${JSON.stringify(params.orderDateIds)}, `);
  }

  if (params.dateFrom) {
    queryParams = queryParams.concat(`dateFrom: "${params.dateFrom}", `);
  }

  if (params.dateUntil) {
    queryParams = queryParams.concat(`dateUntil: "${params.dateUntil}", `);
  }

  if (params.quantityFrom) {
    queryParams = queryParams.concat(`quantityFrom: ${Number(params.quantityFrom)}, `);
  }

  if (params.quantityTo) {
    queryParams = queryParams.concat(`quantityTo: ${Number(params.quantityTo)}, `);
  }

  if (params.searchString && params.searchString.length) {
    queryParams = queryParams.concat(`searchString: "${params.searchString}", `);
  }

  if (params.pageCursor) {
    const pageSize = params.pageSize ? Number(params.pageSize) : 40;

    queryParams = queryParams.concat(`pageCursor: "${Number(params.pageCursor)}", `);
    queryParams = queryParams.concat(`pageSize: ${pageSize}, `);
  }

  if (params.orderBy) {
    queryParams = queryParams.concat(`orderBy: ${params.orderBy}`);
  }

  const query = `
  {
    orderDates(${queryParams}) {
      items {
        id
        quantity
        deliveryOn
        nightshift
        loadTime
        extraFields {
          key,
          value,
          type,
          visibleToCustomer,
        },
        order {
          id
          tentative
          createdAt
          extraFields {
            key,
            value,
            type,
            visibleToCustomer,
          },
          material {
            id
            systemColor
            name
            removed
            active
          }
          customer {
            id
            name
            removed
            extraFields {
              key
              value
              type
            }
          }
        }
      }
      nextPage,
      totalPages,
      totalCount
    }
  }`;

  return query;
}

export const getReloadOrdersParams = (plantId: PlantId, calendarFilters: any) => {
  const view = calendarFilters.view || CalendarViewType.List;

  let reloadOrdersParams: ListOrdersParams;

  if (view === CalendarViewType.List) {
    reloadOrdersParams = {
      plantId,
      dateFrom: calendarFilters.startDateListView,
      dateUntil: calendarFilters.endDateListView,
      pageCursor: 1,
      quantityFrom: calendarFilters.quantityMin,
      quantityTo: calendarFilters.quantityMax,
      searchString: calendarFilters.search,
      orderBy: OrderDateSortEnum.CREATED_AT_DESC,
    };
  } else {
    reloadOrdersParams = {
      plantId,
      dateFrom: getFirstDayOfWeek(calendarFilters.startDate),
      dateUntil: getLastDayOfWeek(calendarFilters.endDate),
    };
  }

  return reloadOrdersParams;
};

export const getDefaultFilters = (location, filters, view?): CalendarFiltersType => {
  const { startDate, endDate } = getFirstAndLastDateOfMonth();
  const _view = view || filters.view;

  const defaultFilters: CalendarFiltersType = {
    view: _view,
    ...(_view !== CalendarViewType.List && {
      startDate: filters?.startDate || startDate,
      endDate: filters?.endDate || endDate,
    }),
    ...(_view === CalendarViewType.List && {
      startDateListView: filters?.startDateListView,
      endDateListView: filters?.endDateListView,
      view: CalendarViewType.List,
    }),
  };

  const allowedFilters: (keyof CalendarFiltersType)[] = [
    'startDate',
    'endDate',
    'startDateListView',
    'endDateListView',
    'order',
    'copyOrder',
    'orderRequest',
    'confirmedOrderId',
    'date',
    'view',
    'fieldsArr',
    'search',
    'quantityMin',
    'quantityMax',
    'entryId',
    'copyEntryId',
    'fieldValueId',
    'copyFieldValueId',
  ];

  const queryFilters = qs.parse(location?.search, { arrayFormat: 'bracket-separator' });
  const savedFilters = getCalendarFilters();

  // if query filters are set they should be used
  if (!isEmpty(queryFilters)) {
    for (const key in queryFilters) {
      if (queryFilters[key]) {
        if (key === 'fields') {
          defaultFilters.fieldsArr = savedFilters?.fieldsArr;
        } else if (allowedFilters.includes(key as keyof CalendarFiltersType)) {
          defaultFilters[key] = queryFilters[key];
        }
      }
    }
  }

  // if query filters are not set try using the saved filters
  if (isEmpty(queryFilters) && !isEmpty(savedFilters)) {
    for (const key in savedFilters) {
      if (savedFilters[key]) {
        if (key === 'fields') {
          defaultFilters.fieldsArr = savedFilters?.fieldsArr;
        } else if (allowedFilters.includes(key as keyof CalendarFiltersType)) {
          defaultFilters[key] = savedFilters[key];
        }
      }
    }
  }

  return defaultFilters;
};

export const orderFieldsTypes = [
  { value: OrderFieldsType.Text, label: OrderFieldsType.Text },
  { value: OrderFieldsType.TextArea, label: OrderFieldsType.TextArea },
  { value: OrderFieldsType.Checkbox, label: OrderFieldsType.Checkbox },
  { value: OrderFieldsType.Time, label: OrderFieldsType.Time },
  { value: OrderFieldsType.Date, label: OrderFieldsType.Date },
  { value: OrderFieldsType.ReadOnly, label: OrderFieldsType.ReadOnly },
];
