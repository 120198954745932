import { useEffect, useState } from 'preact/hooks';

export const useRevealOnScroll = ref => {
  const [revealed, setRevealed] = useState<boolean>(false);

  useEffect(() => {
    const refHeight = ref.current?.offsetHeight || 0;
    const handleScroll = () => {
      setRevealed(window.pageYOffset > refHeight);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  return revealed;
};
