import { useEffect, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { Redirect, Switch, Route, useHistory, useLocation } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import * as qs from 'query-string';

import { I18n } from 'react-redux-i18n';
import MenuTabs from 'Components/header/menu-tabs';
import NewFooter from 'Components/NewFooter';
import DatePicker from 'Components/fields/datePicker';
import CustomerPortals from './CustomerPortals';
import Plants from './Plants';
import NavBar from 'Components/NavBar';

import { dateObjectToISO } from 'Services/dateService';
import { RootState } from 'Redux/reducers';
import { RequestStatusType } from 'Types/commonTypes';
import routeMap from '../../route-maps';
import styles from './dashboardStyles.scss';
import {
  DATE_URL_KEY,
  getDateOffsetInitialState,
  getDateValue,
  parseDateInfo,
  setDateOffsetToLocalStorage,
} from 'Services/dashboardService';
import Spaces from './Spaces';

const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();

  const customerPortals = useSelector((state: RootState) => state.plants.customerPortals);
  const plants = useSelector((state: RootState) => state.plants.plants);
  const spaces = useSelector((state: RootState) => state.spaces.spaces);
  const businesses = useSelector((state: RootState) => state.businesses);
  const fetchingPlantsStatus = useSelector((state: RootState) => state.plants.fetchingPlantsStatus);
  const fetchingSpacesStatus = useSelector((state: RootState) => state.spaces.fetchingSpacesStatus);
  const fetchingPlantPermissionsStatus = useSelector(
    (state: RootState) => state.plants.fetchingPlantPermissionsStatus,
  );
  const fetchingSpacePermissionsStatus = useSelector(
    (state: RootState) => state.spaces.fetchingSpacesPermissionsStatus,
  );
  const [dateOffset, setDateOffset] = useState<number>(() =>
    getDateOffsetInitialState(location.search),
  );
  const [date, setDate] = useState(() => getDateValue(dateOffset));

  useEffect(() => {
    const newDate = getDateValue(dateOffset);
    const dateValue = dateOffset < -2 || dateOffset > 2 ? newDate : `P${dateOffset}D`;
    setDate(newDate);
    setDateOffsetToLocalStorage(dateValue);

    const url = `${history.location.pathname}?${qs.stringify({
      [DATE_URL_KEY]: dateValue,
    })}`;
    history.replace(url);
  }, [dateOffset, history]);

  const menuItems = [
    {
      link: routeMap.dashboardPlants,
      title: I18n.t('plants'),
      visible: true,
    },
    {
      link: routeMap.dashboardCustomerPortals,
      title: I18n.t('customerPortals'),
      visible: true,
    },
    {
      link: routeMap.dashboardSpaces,
      title: 'Spaces',
      visible: true,
    },
  ];

  useEffect(() => {
    if (
      fetchingPlantsStatus === RequestStatusType.Completed &&
      fetchingPlantPermissionsStatus === RequestStatusType.Completed &&
      fetchingSpacesStatus === RequestStatusType.Completed &&
      fetchingSpacePermissionsStatus === RequestStatusType.Completed
    ) {
      const hasNoBusiness = isEmpty(businesses);
      const hasNoOwnPlants = isEmpty(plants);
      const hasNoCustomerPortals = isEmpty(customerPortals);
      const hasNoSpaces = isEmpty(spaces);
      const hasNoPlants = hasNoCustomerPortals && hasNoOwnPlants && hasNoSpaces;

      if (hasNoBusiness) {
        history.push(routeMap.onBoarding);
        return;
      } else if (hasNoPlants) {
        history.push(routeMap.onBoardingAddPlant);
        return;
      }
    }
  }, [
    businesses,
    plants,
    customerPortals,
    spaces,
    fetchingPlantsStatus,
    fetchingPlantPermissionsStatus,
    fetchingSpacesStatus,
    fetchingSpacePermissionsStatus,
    history,
  ]);

  const handleDateChange = (date: Date) => {
    const ISODateString = dateObjectToISO(date);
    const offset = parseDateInfo(ISODateString);
    setDate(ISODateString);
    setDateOffset(offset);
    setDateOffsetToLocalStorage(ISODateString);

    const url = `${history.location.pathname}?${qs.stringify({
      [DATE_URL_KEY]: ISODateString,
    })}`;
    history.replace(url);
  };
  const handlePrevDay = () => setDateOffset(prevDateOffset => prevDateOffset - 1);
  const handleNextDay = () => setDateOffset(prevDateOffset => prevDateOffset + 1);

  return (
    <div className="pageBody" data-testid="dashboard-route">
      <NavBar>
        <MenuTabs items={menuItems} aria-label="Dashboard Tabs" />
        <div className={styles.datePickerContainer}>
          <DatePicker
            selectRange={false}
            allowEmptyValue={false}
            date={date}
            showArrowsNavigation
            onChange={handleDateChange}
            onPrevArrowClick={handlePrevDay}
            onNextArrowClick={handleNextDay}
          />
        </div>
      </NavBar>
      <div className="layoutContainer">
        <Switch>
          <Redirect exact from={routeMap.dashboard} to={routeMap.dashboardPlants} />
          <Route path={routeMap.dashboardPlants}>
            <Plants date={date} />
          </Route>
          <Route path={routeMap.dashboardCustomerPortals}>
            <CustomerPortals date={date} />
          </Route>
          <Route path={routeMap.dashboardSpaces}>
            <Spaces date={date} />
          </Route>
        </Switch>
      </div>
      <NewFooter className={styles.footer} />
    </div>
  );
};

export default Dashboard;
