export const partition = <T, Fn extends (item: T) => boolean>(
  list: T[],
  filterFn: Fn,
): [T[], T[]] => {
  return [list.filter(filterFn), list.filter(item => !filterFn(item))];
};

export const sortList = <T extends Record<string, any>, K extends keyof T>(array: T[], key: K) => {
  return array.sort((a, b) => a[key].localeCompare(b[key]));
};

export const sortDateList = <T extends Record<string, any>>(array: T[], key: keyof T) => {
  return array.sort((a, b) => {
    const dateA = new Date(a[key]).getTime();
    const dateB = new Date(b[key]).getTime();

    return dateA < dateB ? 1 : -1;
  });
};
