import { useDispatch, useSelector } from 'react-redux';
import DashboardCard from '../DashboardCard';
import { setLastActiveSpace } from 'Redux/actions/spaceActions';
import { RootState } from 'Redux/reducers';
import routeMap from '../../../route-maps';
import styles from './spacesStyles.scss';

const Spaces = ({ date }) => {
  const dispatch = useDispatch();
  const spaces = useSelector((state: RootState) => state.spaces.spaces);

  const handleClick = (spaceId: string) => {
    dispatch(setLastActiveSpace(spaceId));
  };

  return (
    <div className={styles.spaces} data-testid="customer-portals-dashboard">
      {spaces.map(space => (
        <DashboardCard
          key={space.id}
          entity={space}
          linkTo={routeMap.space.replace(/:spaceId/, space.id)}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};
export default Spaces;
