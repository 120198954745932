import { DateStringISO } from 'Services/timeService';
import { CustomerHighlightColor, OrderFieldsType } from './plantTypes';

export type BaseOption = {
  label: string;
  value: string;
  icon?: Optional<string>;
  iconActive?: string;
  labelPrefix?: Optional<string>;
};

export type HighlightedOption = BaseOption & {
  highlightColor: CustomerHighlightColor;
};
export type OptionWithExtraFieldsData = BaseOption & {
  extraFieldsData: { key: string; value: any; type: OrderFieldsType }[];
};

export type OptionWithColor = BaseOption & {
  color: string;
};

export type Option = BaseOption | HighlightedOption | OptionWithExtraFieldsData | OptionWithColor;

export const isHighlightedOption = (
  option: Optional<Option | HighlightedOption>,
): option is HighlightedOption => {
  if (!option) return false;
  return 'highlightColor' in option;
};

export const isOptionWithColor = (
  option: Optional<Option | OptionWithColor>,
): option is OptionWithColor => {
  if (!option) return false;
  return 'color' in option;
};

export const isOptionWithExtraFieldsData = (
  option: Optional<Option | OptionWithExtraFieldsData>,
): option is OptionWithExtraFieldsData => {
  if (!option) return false;
  return 'extraFieldsData' in option;
};

export enum OrderRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum Direction {
  Row = 'row',
  Column = 'column',
}

export enum RequestStatusType {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export enum CalendarViewType {
  Material = 'material',
  Customer = 'customer',
  Minimal = 'minimal',
  MaterialCompact = 'materialCompact',
  List = 'list',
}

export type CalendarFiltersType = {
  startDate?: DateStringISO;
  endDate?: DateStringISO;
  startDateListView?: DateStringISO;
  endDateListView?: DateStringISO;
  order?: string;
  copyOrder?: string;
  orderRequest?: string;
  confirmedOrderId?: string;
  date?: DateStringISO;
  view?: CalendarViewType;
  fields?: string;
  fieldsArr?: string[];
  search?: string;
  quantityMax?: string;
  quantityMin?: string;
  material?: string;
  nightshift?: boolean;
  entryId?: string;
  copyEntryId?: string;
  fieldValueId?: string;
  copyFieldValueId?: string;
};
export type HexColorCode = string;
export type MaterialId = string;

export type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export type ValueType<T> = T extends Promise<infer U> ? U : T;

export interface OptionWithIcon {
  label: string;
  value: string;
  icon?: string;
  iconActive?: string;
}

export interface PaginationData<T> {
  count: number;
  next: Optional<number>;
  previous: Optional<number>;
  results: T[];
}
export interface GraphQLPaginationData<T> {
  totalPages: number;
  totalCount: number;
  nextPage: Optional<number>;
  items: T[];
}

export interface Dictionary<T> {
  [index: string]: T;
}

/**
 * @description Making TypeScript's Partial type work for nested objects. Ref: https://grrr.tech/posts/2021/typescript-partial/
 */
export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

export enum PasswordRequirementType {
  required = 'required',
  minLength = 'minLength',
  lowerCase = 'lowerCase',
  upperCase = 'upperCase',
  number = 'number',
  symbol = 'symbol',
  serverError = 'serverError',
}

export type PasswordRequirement = {
  type: PasswordRequirementType;
  message: string;
};

export enum WebSocketEvents {
  ORDER_CREATED = 'order_created',
  ORDER_UPDATED = 'order_updated',
  ORDER_DELETED = 'order_deleted',
  ORDER_REQUEST_CREATED = 'order_request_created',
  ORDER_REQUEST_UPDATED = 'order_request_updated',
  ORDER_REQUEST_DELETED = 'order_request_deleted',
  CALENDAR_NOTE_CREATED = 'calendar_note_created',
  CALENDAR_NOTE_UPDATED = 'calendar_note_updated',
  CALENDAR_NOTE_DELETED = 'calendar_note_deleted',
  ORDER_FIELD_CREATED = 'order_field_created',
  ORDER_FIELD_UPDATED = 'order_field_updated',
  ORDER_FIELD_DELETED = 'order_field_deleted',
  ORDER_DATE_FIELD_CREATED = 'order_date_field_created',
  ORDER_DATE_FIELD_UPDATED = 'order_date_field_updated',
  ORDER_DATE_FIELD_DELETED = 'order_date_field_deleted',
  MATERIAL_CREATED = 'material_created',
  MATERIAL_UPDATED = 'material_updated',
  MATERIAL_DELETED = 'material_deleted',
  PLANT_INFO_CHANGED = 'plant_info_changed',
  PLANT_DELETED = 'plant_deleted',
}

export type WebSocketEvent = {
  type: WebSocketEvents;
  data: {
    plant_id: number;
    order_id: number;
  };
};

export type RequestConfig = {
  signal?: AbortSignal;
};

export type Optional<T> = T | null;

export type TableData<T = any> = {
  data: T[];
}[];
