import { CalendarFiltersType } from 'Types/commonTypes';
import { Action } from './helper';

export const SET_CALENDAR_FILTERS = '@calendarFilters/SET_CALENDAR_FILTERS';

export enum SetCalendarFiltersActionTypes {
  SET_CALENDAR_FILTERS = '@calendarFilters/SET_CALENDAR_FILTERS',
}

export type SetCalendarFiltersActions = SetCalendarFiltersAction;

export type SetCalendarFiltersAction = Action<
  SetCalendarFiltersActionTypes.SET_CALENDAR_FILTERS,
  CalendarFiltersType
>;

export const setCalendarFilters = (filters: CalendarFiltersType): SetCalendarFiltersAction => ({
  type: SetCalendarFiltersActionTypes.SET_CALENDAR_FILTERS,
  payload: filters,
});
