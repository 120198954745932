import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FetchOrdersActionRequested,
  GetInputMaterialActionRequested,
  LoadMoreOrdersActionRequested,
  OrdersActionTypes,
} from 'Redux/actions/actionTypes/orderActionTypes';
import { getOrders, getInputMaterials } from 'Services/orderService';
import {
  listOrdersSucceeded,
  listOrdersFailed,
  loadMoreOrdersSucceeded,
  loadMoreOrdersFailed,
  getInputMaterialsSucceeded,
  getInputMaterialsFailed,
} from '../actions/orderActions';

export function* fetchOrders({ payload }: FetchOrdersActionRequested) {
  try {
    const response = yield call(getOrders, payload);
    yield put(listOrdersSucceeded(response));
  } catch (error) {
    yield put(listOrdersFailed(error));
  }
}

export function* loadMoreOrders({ payload }: LoadMoreOrdersActionRequested) {
  try {
    const response = yield call(getOrders, payload);
    yield put(loadMoreOrdersSucceeded(response));
  } catch (error) {
    yield put(loadMoreOrdersFailed(error));
  }
}

export function* fetchInputMaterials({ payload }: GetInputMaterialActionRequested) {
  try {
    const inputMaterialsData = yield call(getInputMaterials, payload);
    yield put(getInputMaterialsSucceeded(inputMaterialsData));
  } catch (error) {
    yield put(getInputMaterialsFailed(error));
  }
}

export function* watchOrders() {
  yield takeLatest(OrdersActionTypes.ORDERS_FETCH_REQUESTED, fetchOrders);
  yield takeLatest(OrdersActionTypes.LOAD_MORE_ORDERS_REQUESTED, loadMoreOrders);
  yield takeLatest(OrdersActionTypes.ORDERS_INPUT_MATERIALS_REQUEST, fetchInputMaterials);
}
