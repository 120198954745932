import {
  GetOrderFieldStylesAction,
  GetOrderFieldStylesActionFailed,
  GetOrderFieldStylesActionSucceeded,
  ListOrderDateFieldsAction,
  ListOrderDateFieldsActionFailed,
  ListOrderDateFieldsActionSucceeded,
  ListOrderFieldsAction,
  ListOrderFieldsActionFailed,
  ListOrderFieldsActionSucceeded,
  ListPlantMaterialsActionFailed,
  ListPlantMaterialsActionRequested,
  ListPlantMaterialsActionSucceeded,
  PlantActionTypes,
  SetActiveInactivePlantsAction,
  SetLastActivePlantAction,
  SetOrderFieldStylesAction,
  SetOrderFieldStylesActionFailed,
  SetOrderFieldStylesActionSucceeded,
  SetPlantAction,
  SetPlantsPermissionsAction,
  SetPlantsPermissionsActionFailed,
  SetPlantsPermissionsActionSucceeded,
} from './actionTypes/plantActionTypes';
import {
  CustomFieldStyle,
  Material,
  Plant,
  PlantId,
  PlantOrderField,
  PlantsPermissions,
} from 'Types/plantTypes';

export const setPlants = (plants: Plant[]): SetPlantAction => ({
  type: PlantActionTypes.SET_PLANTS,
  payload: plants,
});

export const setPlantsPermissions = (
  plantsPermissions: PlantsPermissions[],
): SetPlantsPermissionsAction => ({
  type: PlantActionTypes.SET_PLANT_PERMISSIONS_REQUESTED,
  payload: plantsPermissions,
});

export const setPlantsPermissionsSucceeded = (
  plantsPermissions: PlantsPermissions[],
): SetPlantsPermissionsActionSucceeded => ({
  type: PlantActionTypes.SET_PLANT_PERMISSIONS_SUCCEEDED,
  payload: plantsPermissions,
});

export const setPlantsPermissionsFailed = (error: unknown): SetPlantsPermissionsActionFailed => ({
  type: PlantActionTypes.SET_PLANT_PERMISSIONS_FAILED,
  payload: error,
});

export const setActiveInactivePlants = (plants: Plant[]): SetActiveInactivePlantsAction => ({
  type: PlantActionTypes.SET_ACTIVE_INACTIVE_PLANTS,
  payload: plants,
});

export const listOrderFields = (
  plantId: PlantId,
  excludeHiddenFields?: boolean,
): ListOrderFieldsAction => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_FIELDS_REQUESTED,
  payload: { plantId, excludeHiddenFields },
});

export const listOrderFieldsSucceeded = (
  params: PlantOrderField[],
): ListOrderFieldsActionSucceeded => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_FIELDS_SUCCEEDED,
  payload: params,
});

export const listOrderFieldsFailed = (error: unknown): ListOrderFieldsActionFailed => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_FIELDS_FAILED,
  payload: error,
});

export const listOrderDateFields = (plantId: PlantId): ListOrderDateFieldsAction => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED,
  payload: plantId,
});

export const listOrderDateFieldsSucceeded = (
  params: PlantOrderField[],
): ListOrderDateFieldsActionSucceeded => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_SUCCEEDED,
  payload: params,
});

export const listOrderDateFieldsFailed = (error: unknown): ListOrderDateFieldsActionFailed => ({
  type: PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_FAILED,
  payload: error,
});

export const setActivePlant = (plantId: PlantId): SetLastActivePlantAction => ({
  type: PlantActionTypes.SET_LAST_ACTIVE_PLANT,
  payload: plantId,
});

export const listMaterials = (plantId: PlantId): ListPlantMaterialsActionRequested => ({
  type: PlantActionTypes.LIST_PLANT_MATERIALS_REQUESTED,
  payload: plantId,
});

export const listMaterialsSucceeded = (
  materials: Material[],
): ListPlantMaterialsActionSucceeded => ({
  type: PlantActionTypes.LIST_PLANT_MATERIALS_SUCCEEDED,
  payload: materials,
});
export const listMaterialsFailed = (error: unknown): ListPlantMaterialsActionFailed => ({
  type: PlantActionTypes.LIST_PLANT_MATERIALS_FAILED,
  payload: error,
});

export const getOrderFieldStyles = (plantId: PlantId): GetOrderFieldStylesAction => ({
  type: PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_REQUESTED,
  payload: plantId,
});

export const getOrderFieldStylesSucceeded = (
  params: CustomFieldStyle[],
): GetOrderFieldStylesActionSucceeded => ({
  type: PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED,
  payload: params,
});

export const getOrderFieldStylesFailed = (error: unknown): GetOrderFieldStylesActionFailed => ({
  type: PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_FAILED,
  payload: error,
});

export const setOrderFieldStyles = (payload: {
  plantId: PlantId;
  fieldStyles: CustomFieldStyle[];
}): SetOrderFieldStylesAction => ({
  type: PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_REQUESTED,
  payload: payload,
});

export const setOrderFieldStylesSucceeded = (
  params: CustomFieldStyle[],
): SetOrderFieldStylesActionSucceeded => ({
  type: PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED,
  payload: params,
});

export const setOrderFieldStylesFailed = (error: unknown): SetOrderFieldStylesActionFailed => ({
  type: PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_FAILED,
  payload: error,
});
