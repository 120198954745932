import { I18n } from 'react-redux-i18n';
import InputField from 'Components/fields/inputField';
import Textarea from 'Components/fields/Textarea';
import Checkbox from 'Components/fields/checkbox';
import DateQuantityPicker from 'Components/dateQuantityPicker';
import InputDropDown from 'Components/fields/InputDropDown';
import TimePicker from 'Components/fields/TimePicker';
import DatePicker from 'Components/fields/datePicker';
import PlantName from 'Components/fields/PlantName';
import { OrderFieldsType } from 'Types/plantTypes';
import { OrderModalCustomFieldsType } from 'Types/OrderModalTypes';
import { Option } from 'Types/commonTypes';
import DropdownCheckbox from 'Components/fields/dropdownCheckbox';

const hasOwnProperty = (object, property) => {
  return Object.hasOwnProperty.call(object, property);
};

// build a form field object with all common properties
export const getFormFieldProps = (schema: any, options: any = {}) => {
  if (schema === null || typeof schema !== 'object' || Array.isArray(schema)) {
    throw new Error('schema property must be an object');
  }

  const properties: any = {};

  if (hasOwnProperty(schema, 'type')) {
    if (Object.keys(mapper).indexOf(schema.type) === -1) {
      console.error(`Unknown field type "${schema.type}"`);
      properties.type = OrderFieldsType.Text;
      properties.isSupported = false;
    } else {
      properties.type = schema.type;
    }
  }
  if (hasOwnProperty(schema, 'key')) {
    properties.key = schema.key;
  }
  if (hasOwnProperty(options, 'required')) {
    properties.isRequiredField = options.required;
  }
  if (hasOwnProperty(schema, 'static')) {
    properties.isStaticField = schema.static;
  }
  if (hasOwnProperty(schema, 'options')) {
    properties.options = schema.options;
  }
  if (hasOwnProperty(schema, 'title')) {
    properties.title = schema.title;
  }
  if (hasOwnProperty(schema, 'placeholder')) {
    properties.placeholder = schema.placeholder;
  }
  if (hasOwnProperty(schema, 'description')) {
    properties.description = schema.description;
  }
  if (hasOwnProperty(schema, 'remote')) {
    properties.remote = schema.remote;
  }
  if (hasOwnProperty(schema, 'deleted')) {
    properties.deleted = schema.deleted;
  }
  if (hasOwnProperty(schema, 'readOnly')) {
    properties.readOnly = schema.readOnly;
  }
  if (options.required && !hasOwnProperty(schema, 'customValidation')) {
    properties.required = {
      value: true,
      message: I18n.t('fieldIsRequired', { fieldName: schema.title }),
    };
  }
  if (hasOwnProperty(schema, 'maxLength')) {
    properties.maxLength = {
      value: schema.maxLength,
      message: `${schema.title} should be max ${schema.maxLength} characters long`,
    };
  }
  if (hasOwnProperty(schema, 'minLength')) {
    properties.minLength = {
      value: schema.minLength,
      message: `${schema.key} should be at least ${schema.minLength} characters long`,
    };
  }
  if (hasOwnProperty(schema, 'minimum')) {
    properties.minimum = {
      value: schema.minimum,
      message: `${schema.key} should be greater than ${schema.minimum}`,
    };
  }
  if (hasOwnProperty(schema, 'maximum')) {
    properties.maximum = {
      value: schema.maximum,
      message: `${schema.key} should be less than ${schema.maximum}`,
    };
  }
  if (hasOwnProperty(schema, 'customValidation')) {
    properties.customValidation = schema.customValidation;
  }

  properties.schema = schema;

  return properties;
};

// map type to component
export const mapper = {
  [OrderFieldsType.Text]: {
    component: InputField,
    defaultProps: property => ({ ...inputFieldProps(property), type: 'text' }),
  },
  [OrderFieldsType.TextArea]: {
    component: Textarea,
    defaultProps: property => ({ ...textareaProps(property) }),
  },
  [OrderFieldsType.Checkbox]: {
    component: Checkbox,
    defaultProps: property => ({ ...checkboxProps(property) }),
  },
  [OrderModalCustomFieldsType.DateQuantityPicker]: {
    component: DateQuantityPicker,
    defaultProps: (property, dates, customEventHandlers) => ({
      ...dateQuantityPickerProps(property, dates, customEventHandlers),
    }),
  },
  [OrderModalCustomFieldsType.InputDropdown]: {
    component: InputDropDown,
    defaultProps: (property, selectedOption, customEventHandlers) => {
      return { ...inputDropdownProps(property, selectedOption, customEventHandlers) };
    },
  },
  [OrderModalCustomFieldsType.MultiSelect]: {
    component: DropdownCheckbox,
    defaultProps: (property, selectedOption = [], customEventHandlers) => {
      return { ...multiSelectProps(property, selectedOption, customEventHandlers) };
    },
  },
  [OrderFieldsType.Date]: {
    component: DatePicker,
    defaultProps: (property, selectedDate) => {
      return { ...datePickerProps(property, selectedDate) };
    },
  },
  [OrderFieldsType.Time]: {
    component: TimePicker,
    defaultProps: (property, selectedDateTime) => {
      return { ...timePickerProps(property, selectedDateTime) };
    },
  },
  [OrderModalCustomFieldsType.PlantName]: {
    component: PlantName,
    defaultProps: (property, plantName, customEventHanslers, dirtyFields) => {
      return { ...plantNameProps(property, plantName, customEventHanslers, dirtyFields) };
    },
  },
  [OrderModalCustomFieldsType.SpaceName]: {
    component: PlantName,
    defaultProps: (property, plantName, customEventHanslers, dirtyFields) => {
      return { ...spaceNameProps(property, plantName, customEventHanslers, dirtyFields) };
    },
  },
  [OrderFieldsType.ReadOnly]: {
    component: InputField,
    defaultProps: property => ({ ...inputFieldProps(property), type: 'text' }),
  },
};

const inputFieldProps = property => {
  return {
    readOnly: property.schema.readOnly || false,
    disabled: property.schema.disabled,
  };
};

const textareaProps = property => ({
  disabled: property.schema.disabled,
});
const checkboxProps = property => ({
  checked: property.schema.default,
  disabled: property.schema.disabled,
});

const dateQuantityPickerProps = (property, dates, customEventHandlers) => {
  return {
    initialDates: dates,
    selectedDate: property.schema.selectedDate,
    extraFields: property.schema.extraFields,
    desiredFutureQuantity: property.schema.desiredFutureQuantity,
    showMoveFormBtn: property.schema.showMoveFormBtn,
    calendarOpen: property.schema.calendarOpen,
    disabled: property.schema.disabled,
    shouldHideDailyOrderFields: property.schema.shouldHideDailyOrderFields,
    isEntryType: property.schema.isEntryType,
    spaceConfiguration: property.schema.spaceConfiguration,
    ...customEventHandlers,
  };
};

const inputDropdownProps = (property, selectedOption, customEventHandlers) => {
  return {
    options: property.options,
    selected: selectedOption,
    allowUnknownOption: property.schema.allowUnknownOption,
    renderWithTooltip: property.schema.renderWithTooltip,
    async: property.schema.async,
    loadingOptions: property.schema.loadingOptions,
    totalOptionsCount: property.schema.totalOptionsCount,
    noOptionsMessage: property.schema.noOptionsMessage,
    disabled: property.schema.disabled,
    ...customEventHandlers,
  };
};

const multiSelectProps = (property, selectedOptions, customEventHandlers) => {
  return {
    options: property.options,
    selectedOptions: selectedOptions,
    allowUnknownOption: property.schema.allowUnknownOption,
    renderWithTooltip: property.schema.renderWithTooltip,
    noOptionsMessage: property.schema.noOptionsMessage,
    disabled: property.schema.disabled,
    showSelectedOptions: true,
    entityName: property.title,
    onChange: (options: Option[]) => {
      return options;
    },
    ...customEventHandlers,
  };
};

const datePickerProps = (property, selectedDate) => {
  return {
    date: selectedDate,
    allowEmptyValue: true,
  };
};

const timePickerProps = (property, selectedDateTime) => {
  return {
    value: selectedDateTime,
    show: true,
    hideOnClear: false,
    disabled: property.schema.disabled,
  };
};

const plantNameProps = (property, plantName, customEventHandlers, dirtyFields) => {
  return {
    plantName: property.schema.plantName,
    hideChangePlantAction: property.schema.hideChangePlantAction,
    disableChangePlantAction: !!Object.keys(dirtyFields).length,
    disabled: property.schema.disabled,
    ...customEventHandlers,
  };
};

const spaceNameProps = (property, spaceName, customEventHandlers, dirtyFields) => {
  return {
    plantName: property.schema.spaceName,
    hideChangePlantAction: property.schema.hideChangePlantAction,
    disableChangePlantAction: !!Object.keys(dirtyFields).length,
    disabled: property.schema.disabled,
    ...customEventHandlers,
  };
};

// build all form fields properties
export const prepareFormFieldsProps = (schema: any = []) => {
  if (!schema.properties) return [];

  // loop through all schema properties and get the default props for every field
  const defaultFields = schema.properties.map(fieldProperty => {
    const key = fieldProperty.key;
    const required = (schema.required && schema.required.indexOf(key) !== -1) || false;

    const fieldProps = getFormFieldProps(fieldProperty, { required });
    return fieldProps;
  });

  return defaultFields;
};
