import { Action } from '../helper';
import {
  CustomFieldStyle,
  Material,
  OrderFieldsType,
  Plant,
  PlantId,
  PlantOrderField,
  PlantsPermissions,
} from 'Types/plantTypes';

export enum PlantActionTypes {
  SET_PLANTS = '@plants/SET_PLANTS',
  SET_ACTIVE_INACTIVE_PLANTS = '@plants/SET_ACTIVE_INACTIVE_PLANTS',

  SET_PLANT_PERMISSIONS_REQUESTED = '@plants/SET_PLANT_PERMISSIONS_REQUESTED',
  SET_PLANT_PERMISSIONS_SUCCEEDED = '@plants/SET_PLANT_PERMISSIONS_SUCCEEDED',
  SET_PLANT_PERMISSIONS_FAILED = '@plants/SET_PLANT_PERMISSIONS_FAILED',

  LIST_PLANT_ORDER_FIELDS_REQUESTED = '@plants/LIST_PLANT_ORDER_FIELDS_REQUESTED',
  LIST_PLANT_ORDER_FIELDS_SUCCEEDED = '@plants/LIST_PLANT_ORDER_FIELDS_SUCCEEDED',
  LIST_PLANT_ORDER_FIELDS_FAILED = '@plants/LIST_PLANT_ORDER_FIELDS_FAILED',

  LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED = '@plants/LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED',
  LIST_PLANT_ORDER_DATE_FIELDS_SUCCEEDED = '@plants/LIST_PLANT_ORDER_DATE_FIELDS_SUCCEEDED',
  LIST_PLANT_ORDER_DATE_FIELDS_FAILED = '@plants/LIST_PLANT_ORDER_DATE_FIELDS_FAILED',

  GET_PLANT_ORDER_FIELD_STYLES_REQUESTED = '@plants/GET_PLANT_ORDER_FIELD_STYLES_REQUESTED',
  GET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED = '@plants/GET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED',
  GET_PLANT_ORDER_FIELD_STYLES_FAILED = '@plants/GET_PLANT_ORDER_FIELD_STYLES_FAILED',

  SET_PLANT_ORDER_FIELD_STYLES_REQUESTED = '@plants/SET_PLANT_ORDER_FIELD_STYLES_REQUESTED',
  SET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED = '@plants/SET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED',
  SET_PLANT_ORDER_FIELD_STYLES_FAILED = '@plants/SET_PLANT_ORDER_FIELD_STYLES_FAILED',

  SET_LAST_ACTIVE_PLANT = '@plants/SET_LAST_ACTIVE_PLANT',

  LIST_PLANT_MATERIALS_REQUESTED = '@plants/LIST_PLANT_MATERIALS_REQUESTED',
  LIST_PLANT_MATERIALS_SUCCEEDED = '@plants/LIST_PLANT_MATERIALS_SUCCEEDED',
  LIST_PLANT_MATERIALS_FAILED = '@plants/LIST_PLANT_MATERIALS_FAILED',
}

export type NewOrderField = {
  name: string;
  field_type?: OrderFieldsType;
  visible_to_customer?: boolean;
};

export type UpdateOrderProps = {
  plantId: PlantId;
  orderFieldId: number;
  data: PlantOrderField;
};

export type SetPlantAction = Action<PlantActionTypes.SET_PLANTS, Plant[]>;

export type SetPlantsPermissionsAction = Action<
  PlantActionTypes.SET_PLANT_PERMISSIONS_REQUESTED,
  PlantsPermissions[]
>;

export type SetPlantsPermissionsActionSucceeded = Action<
  PlantActionTypes.SET_PLANT_PERMISSIONS_SUCCEEDED,
  PlantsPermissions[]
>;

export type SetPlantsPermissionsActionFailed = Action<
  PlantActionTypes.SET_PLANT_PERMISSIONS_FAILED,
  unknown
>;

export type SetActiveInactivePlantsAction = Action<
  PlantActionTypes.SET_ACTIVE_INACTIVE_PLANTS,
  Plant[]
>;

export type ListOrderFieldsAction = Action<
  PlantActionTypes.LIST_PLANT_ORDER_FIELDS_REQUESTED,
  { plantId: PlantId; excludeHiddenFields?: boolean }
>;

export type ListOrderFieldsActionSucceeded = Action<
  PlantActionTypes.LIST_PLANT_ORDER_FIELDS_SUCCEEDED,
  PlantOrderField[]
>;

export type ListOrderFieldsActionFailed = Action<
  PlantActionTypes.LIST_PLANT_ORDER_FIELDS_FAILED,
  unknown
>;

export type GetOrderFieldStylesAction = Action<
  PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_REQUESTED,
  PlantId
>;

export type GetOrderFieldStylesActionSucceeded = Action<
  PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED,
  CustomFieldStyle[]
>;

export type GetOrderFieldStylesActionFailed = Action<
  PlantActionTypes.GET_PLANT_ORDER_FIELD_STYLES_FAILED,
  unknown
>;

export type SetOrderFieldStylesAction = Action<
  PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_REQUESTED,
  { plantId: string; fieldStyles: CustomFieldStyle[] }
>;

export type SetOrderFieldStylesActionSucceeded = Action<
  PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_SUCCEEDED,
  CustomFieldStyle[]
>;

export type SetOrderFieldStylesActionFailed = Action<
  PlantActionTypes.SET_PLANT_ORDER_FIELD_STYLES_FAILED,
  unknown
>;

export type ListOrderDateFieldsAction = Action<
  PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_REQUESTED,
  PlantId
>;

export type ListOrderDateFieldsActionSucceeded = Action<
  PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_SUCCEEDED,
  PlantOrderField[]
>;

export type ListOrderDateFieldsActionFailed = Action<
  PlantActionTypes.LIST_PLANT_ORDER_DATE_FIELDS_FAILED,
  unknown
>;

export type SetLastActivePlantAction = Action<PlantActionTypes.SET_LAST_ACTIVE_PLANT, PlantId>;

export type ListPlantMaterialsActionRequested = Action<
  PlantActionTypes.LIST_PLANT_MATERIALS_REQUESTED,
  PlantId
>;

export type ListPlantMaterialsActionSucceeded = Action<
  PlantActionTypes.LIST_PLANT_MATERIALS_SUCCEEDED,
  Material[]
>;

export type ListPlantMaterialsActionFailed = Action<
  PlantActionTypes.LIST_PLANT_MATERIALS_FAILED,
  unknown
>;

export type PlantActions =
  | ListOrderFieldsAction
  | ListOrderFieldsActionSucceeded
  | ListOrderFieldsActionFailed
  | SetPlantsPermissionsAction
  | SetPlantsPermissionsActionSucceeded
  | SetPlantsPermissionsActionFailed
  | SetPlantAction
  | SetActiveInactivePlantsAction
  | SetLastActivePlantAction
  | ListPlantMaterialsActionRequested
  | ListPlantMaterialsActionSucceeded
  | ListPlantMaterialsActionFailed
  | ListOrderDateFieldsAction
  | ListOrderDateFieldsActionSucceeded
  | ListOrderDateFieldsActionFailed
  | GetOrderFieldStylesAction
  | GetOrderFieldStylesActionSucceeded
  | GetOrderFieldStylesActionFailed
  | SetOrderFieldStylesAction
  | SetOrderFieldStylesActionSucceeded
  | SetOrderFieldStylesActionFailed;
