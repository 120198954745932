import { I18n } from 'react-redux-i18n';

export const booleanToString = (
  value: string | boolean,
  positiveLabel = I18n.t('yes'),
  negativeLabel = I18n.t('no'),
): string => {
  return value === 'true' || value === true ? positiveLabel : negativeLabel;
};

export const getBooleanValue = (value: string | boolean | null) => {
  return value === 'true' || value === true;
};
