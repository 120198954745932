import { GraphQLPaginationData } from 'Types/commonTypes';
import { PlantId } from 'Types/plantTypes';
import { Action } from './helper';

export enum CustomerActionTypes {
  FETCH_CUSTOMERS_REQUESTED = '@customers/FETCH_CUSTOMERS_REQUESTED',
  FETCH_CUSTOMERS_SUCCEEDED = '@customers/FETCH_CUSTOMERS_SUCCEEDED',
  FETCH_CUSTOMERS_FAILED = '@customers/FETCH_CUSTOMERS_FAILED',

  FETCH_MORE_CUSTOMERS_REQUESTED = '@customers/FETCH_MORE_CUSTOMERS_REQUESTED',
  FETCH_MORE_CUSTOMERS_SUCCEEDED = '@customers/FETCH_MORE_CUSTOMERS_SUCCEEDED',
  FETCH_MORE_CUSTOMERS_FAILED = '@customers/FETCH_MORE_CUSTOMERS_FAILED',
}

export interface FetchCustomersProps {
  plantId: PlantId;
  page?: number | null;
  pageSize?: number;
  customerName?: string;
}

export type FetchPlantCustomersRequestedAction = Action<
  CustomerActionTypes.FETCH_CUSTOMERS_REQUESTED,
  FetchCustomersProps
>;

export type FetchPlantCustomersSucceeded = Action<
  CustomerActionTypes.FETCH_CUSTOMERS_SUCCEEDED,
  GraphQLPaginationData<string>
>;

export type FetchPlantCustomersFailed = Action<CustomerActionTypes.FETCH_CUSTOMERS_FAILED, unknown>;

export type FetchMorePlantCustomersRequested = Action<
  CustomerActionTypes.FETCH_MORE_CUSTOMERS_REQUESTED,
  FetchCustomersProps
>;

export type FetchMorePlantCustomersSucceeded = Action<
  CustomerActionTypes.FETCH_MORE_CUSTOMERS_SUCCEEDED,
  GraphQLPaginationData<string>
>;

export type FetchMorePlantCustomersFailed = Action<
  CustomerActionTypes.FETCH_MORE_CUSTOMERS_FAILED,
  unknown
>;

export type CustomerActions =
  | FetchPlantCustomersRequestedAction
  | FetchPlantCustomersSucceeded
  | FetchPlantCustomersFailed
  | FetchMorePlantCustomersRequested
  | FetchMorePlantCustomersSucceeded
  | FetchMorePlantCustomersFailed;

export const listPlantCustomers = ({
  plantId,
  page,
  pageSize,
  customerName,
}: FetchCustomersProps): FetchPlantCustomersRequestedAction => ({
  type: CustomerActionTypes.FETCH_CUSTOMERS_REQUESTED,
  payload: { plantId, page, pageSize, customerName },
});

export const listPlantCustomersSucceeded = (
  data: GraphQLPaginationData<string>,
): FetchPlantCustomersSucceeded => ({
  type: CustomerActionTypes.FETCH_CUSTOMERS_SUCCEEDED,
  payload: data,
});

export const listPlantCustomersFailed = (error: unknown): FetchPlantCustomersFailed => ({
  type: CustomerActionTypes.FETCH_CUSTOMERS_FAILED,
  payload: error,
});

export const listMorePlantCustomers = ({
  plantId,
  page,
  pageSize = 40,
}: FetchCustomersProps): FetchMorePlantCustomersRequested => ({
  type: CustomerActionTypes.FETCH_MORE_CUSTOMERS_REQUESTED,
  payload: { plantId, page, pageSize },
});

export const listMorePlantCustomersSucceeded = (
  data: GraphQLPaginationData<string>,
): FetchMorePlantCustomersSucceeded => ({
  type: CustomerActionTypes.FETCH_MORE_CUSTOMERS_SUCCEEDED,
  payload: data,
});

export const listMorePlantCustomersFailed = (error: unknown): FetchMorePlantCustomersFailed => ({
  type: CustomerActionTypes.FETCH_MORE_CUSTOMERS_FAILED,
  payload: error,
});
