export const ar = {
  copyright: {
    systemStatus: 'حالة النظام',
  },
  mixPlant: 'مصنع ميكس',
  aggregatePlant: 'نبات الركام',
  pavingSchedule: 'جدول الرصف',
  home: 'مسكن',
  mesch: 'MeSch Software UG',
  allRights: '(مسؤولية محدودة). كل الحقوق محفوظة.',
  privacyPolicy: 'سياسة الخصوصية',
  termsOfService: 'شروط الخدمة',
  whatsTheName: 'ما اسم الموظف الجديد؟',
  employeeEmail: 'عنوان البريد الإلكتروني للموظف:',
  dashboard: 'لوحة القيادة',
  paving: 'الرصف',
  plant: 'مصنع',
  role: 'دور',
  cancel: 'يلغي',
  ok: 'موافق',
  save: 'يحفظ',
  add: 'يضيف',
  type: 'يكتب',
  required: 'مطلوب',
  visibleToCustomers: 'مرئي للعملاء؟',
  export: 'يصدّر',
  newField: 'حقل جديد',
  oldPassword: 'كلمة المرور القديمة',
  newPassword: 'كلمة السر الجديدة',
  confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
  oldPasswordRequired: 'كلمة المرور القديمة مطلوبة!',
  newPasswordRequired: 'كلمة المرور الجديدة مطلوبة!',
  newPasswordConfirmation: 'الرجاء كتابة كلمة مرورك الجديدة مرة أخرى.',
  passwordsDontMatch: 'حقلا كلمة المرور غير متطابقين.',
  passwordMinLength: 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل',
  passwordMustBeDifferent: 'يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمة المرور القديمة.',
  passwordChangeDone: 'الباسورد الخاص بك تم تغييرة. اضغط هنا للتسجيل الخروج.',
  nameChangeDone: 'تم تغيير اسمك.',
  changePassword: 'غير كلمة السر',
  password: 'كلمة المرور',
  passwordPlaceholder: 'كلمة المرور...',
  repeatNewPassword: 'كرر كلمة المرور الجديدة!',
  repeatPassword: 'اعد كلمة السر',
  repeatPasswordPlaceholder: 'اعد كلمة السر...',
  termsAndConditions: 'ابدأ الإصدار التجريبي المجاني واقبل الشروط والأحكام',
  termsAndConditionsRequiredMsg: 'الرجاء قبول الشروط والأحكام',
  email: 'البريد الإلكتروني',
  emailAddress: 'عنوان البريد الالكترونى',
  emailPlaceholder: 'البريد الإلكتروني...',
  emailAddressPlaceholder: 'عنوان البريد الالكترونى',
  subscriberNamePlaceholder: 'اسم المشترك',
  code: 'شفرة',
  phone: 'هاتف',
  phoneNumber: 'رقم الهاتف',
  fieldIsRequired: 'الحقل٪ {fieldName} مطلوب',
  fieldIsMissing: '٪ {fieldName} غير موجود',
  company: 'شركة',
  message: 'رسالة',
  send: 'إرسال',
  sending: 'إرسال',
  sendInvite: 'ارسل دعوة',
  sendToMe: 'ترسل لي',
  sendToOrg: 'أرسل إلى فريقي',
  print: 'مطبعة',
  download: 'تحميل',
  printReport: 'اطبع تقرير',
  minimumAmountIsRequired: 'مطلوب الحد الأدنى للمبلغ!',
  maximumAmountIsRequired: 'المبلغ الأقصى المطلوب!',
  currentAmountIsRequired: 'المبلغ الحالي مطلوب!',
  nameIsRequired: 'مطلوب اسم!',
  selectCountryCode: 'الرجاء تحديد رمز الدولة!',
  firstNamePlaceholder: 'الاسم الاول',
  lastNamePlaceholder: 'اللقب',
  reports: 'التقارير',
  account: 'الحساب',
  referAFriend: 'أوص أحد الأصدقاء',
  logOut: 'تسجيل خروج',
  organizationMembership: 'عضوية المنظمة',
  employeeCount: '٪ {عدد} موظف',
  members: 'أعضاء',
  addAnEmployee: 'أضف موظف',
  addANewPlant: 'أضف نبتة جديدة',
  onlyAddEmployees: 'يجب أن تضيف فقط موظفين من٪ {name}.',
  onlyAddCurrent: 'أضف الموظفين الحاليين فقط',
  inviteNewEmployee: 'قم بدعوة موظف جديد إلى٪ {name}',
  plants: 'النباتات',
  schedule: 'برنامج',
  edit: 'يحرر',
  create: 'خلق',
  createPlant: 'إنشاء نبات',
  editPlant: 'تحرير النبات',
  chooseSupplier: 'اختر المورد',
  supplier: 'المورد',
  suppliers: 'الموردين',
  materials: 'المواد',
  productionMode: 'وضع الإنتاج',
  mixDesignLabel: 'تصميم مختلط',
  activate: 'تفعيل',
  deactivate: 'تعطيل',
  actions: 'أجراءات',
  notifications: 'إشعارات',
  address: 'تبوك',
  name: 'اسم',
  firstName: 'الاسم الاول',
  lastName: 'الكنية',
  saveName: 'حفظ الاسم',
  date: 'تاريخ',
  expired: 'منتهي الصلاحية',
  picture: 'صورة',
  maxCapacity: 'السعة القصوى',
  plantName: 'اسم النبات',
  plantType: 'نوع النبات',
  plantTypePlaceholder: 'حدد نوع النبات',
  search: 'يبحث',
  searchPlaceholder: 'يبحث...',
  nightShift: 'التحول الليلي',
  nightShiftFrom: 'التحول الليلي من',
  nightShiftTo: 'التحول الليلي إلى',
  inactivePlants: 'النباتات غير النشطة',
  activePlants: 'النباتات النشطة',
  addPlant: 'أضف النبات',
  addInputMaterial: 'أضف مادة inpput',
  addNewSupplier: 'إضافة مورد جديد',
  addSupplier: 'إضافة مورد',
  editSupplier: 'تحرير المورد',
  deleteSupplier: 'حذف المورد',
  deleteSupplierMessage: 'هل أنت متأكد أنك تريد حذف المورد',
  deleteOrderField: 'حذف حقل نموذج الطلب',
  deleteOrderFieldMessage: 'هل أنت متأكد أنك تريد حذف حقل الطلب؟',
  yesDelete: 'نعم ، احذف',
  yesDeactivate: 'نعم ، قم بإلغاء التنشيط',
  yesActivate: 'نعم ، تفعيل',
  yes: 'نعم',
  description: 'وصف',
  addEmpoyee: 'اضافة موظف',
  reactivatePlant: 'إعادة تنشيط النبات',
  deactivatePlant: 'تعطيل النبات',
  configureSupplier: 'تكوين المورد',
  configurePlant: 'تكوين المصنع',
  billing: 'الفواتير',
  changeCard: 'تغيير البطاقة',
  confirm: 'يتأكد',
  aysReactivate: 'هل أنت متأكد أنك تريد إعادة تنشيط٪ {name}؟',
  aysDeactivate: 'هل أنت متأكد أنك تريد إلغاء تنشيط٪ {name}؟',
  toggleNavigation: 'تبديل التنقل',
  login: 'تسجيل الدخول',
  signUp: 'اشتراك',
  tryItOut: 'حاول!',
  learnMore: 'يتعلم أكثر',
  contact: 'اتصال',
  services: 'خدمات',
  help: 'مساعدة',
  remove: 'إزالة',
  deletedPrefix: '[تم الحذف]',
  copy: 'ينسخ',
  verified: 'تم التحقق',
  newOrderRequest: 'طلب طلب جديد',
  helpButtonText: 'تحتاج مساعدة؟',
  supportModalTitle: 'كيف يمكن أن نساعد؟',
  supportModalInputPlaceholder: 'اكتب سؤالك أو مشكلتك مع البرنامج ...',
  supportModalSent: 'تم إرسال الرسالة بنجاح.',
  supportModalError: 'يرجى الاتصال بنا على support@plantdemand.com',
  loading: 'جار التحميل...',
  submit: 'يُقدِّم',
  academy: 'الأكاديمية',
  support: 'الدعم',
  walkthrough: 'تجول',
  reportBug: 'الإبلاغ عن خطأ',
  instagram: 'انستغرام',
  linkedIn: 'ينكدين',
  forgotPassword: 'هل نسيت كلمة السر؟',
  staySignedIn: 'ابق مسجلا',
  logInAgain: 'تسجيل الدخول مرة أخرى',
  logInPlantDemand: 'قم بتسجيل الدخول إلى PlantDemand',
  passwordRequiredFieldMsg: 'يجب عليك تحديد كلمة مرور',
  passwordMinLengthErrorMsg: 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل',
  emailRequiredFieldMsg: 'البريد الالكتروني مطلوب',
  newToPD: 'جديد في PlantDemand؟',
  newToPDText: 'يسعدنا رؤيتك هنا! يمكنك إنشاء حساب والبدء في الجدولة في 4 دقائق.',
  externalSupplierNotification:
    'إذا كنت تحاول منح مورد خارجي أو وصول عميل إلى مصانعك ، فاستخدم ميزات إدارة المستخدم في علامة تبويب تكوين المصنع بدلاً من ذلك.',
  inviteFriend: 'أدع صديقا',
  thankYou: 'شكرا لك',
  referAFriendMessage: 'يسعدنا أنك تستمتع بـ PlantDemand ونريد إحالته إلى أصدقائك وزملائك.',
  thanks: 'شكرًا',
  reminder: 'تذكير:',
  warningCardTitleOnlyCurrentEmployee: 'أضف الموظفين الحاليين فقط',
  warningCardTextOnlyAddEmployee: 'يجب عليك فقط إضافة موظفين من',
  warningCardTextUseManagmentFeatures:
    'إذا كنت تحاول منح مورد خارجي أو وصول عميل إلى مصانعك ، فاستخدم ميزات إدارة المستخدم في علامة تبويب تكوين المصنع بدلاً من ذلك.',
  errorCardText: 'لا يوجد مصنع نشط في هذا العمل. الرجاء إضافة نبتة جديدة.',
  pendingInvitations: 'الدعوات المعلقة',
  deleteInvitation: 'حذف الدعوة؟',
  aysDeleteInvitation: 'هل أنت متأكد أنك تريد حذف الدعوة لـ٪ {user}؟',
  inputForecast: 'توقعات المدخلات',
  outputForecast: 'توقعات الإخراج',
  production: 'إنتاج',
  inventoryForecast: 'توقعات الجرد',
  dayView: 'عرض اليوم',
  using: 'استخدام',
  by: 'بواسطة',
  from: 'من',
  until: 'حتى',
  weekdays: 'أيام الأسبوع',
  filters: 'المرشحات',
  shortcuts: 'الاختصارات',
  for: 'إلى عن على',
  selectedPlants: 'تم تحديد٪ {عدد} نباتات',
  plantsCaps: 'النباتات',
  noPlants: 'لا نباتات',
  forAllPlants: 'لجميع النباتات',
  forOnePlant: 'لمصنع واحد',
  thisWeek: 'هذا الاسبوع',
  nextWeek: 'الاسبوع المقبل',
  nextTwoWeeks: 'إسبوعين',
  threeWeeks: 'ثلاثة اسابيع',
  thisMonth: 'هذا الشهر',
  nextMonth: 'الشهر القادم',
  thisQuarter: 'هذا الربع',
  thisYear: 'هذه السنة',
  day: 'يوم',
  night: 'ليل',
  week: 'أسبوع',
  month: 'شهر',
  refresh: 'ينعش',
  selectMaterial: 'حدد مادة',
  xlsx: 'XLSX',
  youDontHavePlants: 'ليس لديك أي نباتات بعد.',
  youCanConfigurePlants: 'يمكنك تكوين النباتات الخاصة بك على',
  accountDownCase: 'الحساب',
  screen: 'شاشة.',
  youDontHaveOrganisation:
    'ليس لديك منظمة حتى الآن. إذا كانت شركتك تستخدم PlantDemand بالفعل ، فاطلب من مديرك أن يدعوك. يمكنك تكوين مصانع الأعمال الخاصة بك على',
  page: 'صفحة.',
  registerOrganisation: 'سجل منظمة جديدة',
  askCompanyToConfigureBusiness:
    'إذا كانت شركتك تستخدم PlantDemand بالفعل ، فاطلب من مديرك أن يدعوك. يمكنك تكوين مصانع الأعمال الخاصة بك على',
  availableFields: 'المجالات المتاحة:',
  updatedMaterialMsg: 'تم تحديث المادة بنجاح',
  updatedMaterialFailedMsg: 'لا يمكن تحديث المادة',
  deleteMaterialMsg: 'تم حذف المادة بنجاح',
  deleteMaterialFailedMsg: 'لا يمكن حذف المادة',
  addNewSupplierMsg: 'تمت إضافة المورد بنجاح',
  groupBy: 'مجموعة من:',
  pivotReport: 'تقرير محوري',
  productionReport: 'تقرير الإنتاج',
  noReportData: 'لا توجد بيانات للتواريخ المحددة.',
  materialName: 'اسم المادة',
  total: 'المجموع',
  customer: 'عميل',
  dates: 'تواريخ',
  quantity: 'كمية',
  loadTime: 'وقت التحميل',
  material: 'مواد',
  compact: 'المدمج',
  classic: 'كلاسيك',
  requestPending: 'الطلب معلق',
  list: 'قائمة',
  endDailyReport: 'إنهاء التقرير اليومي لـ',
  addTime: 'إضافة وقت',
  to: 'إلى',
  orderBy: 'ترتيب حسب',
  me: 'أنا',
  myOrganization: 'منظمتي',
  teamNotifications: 'إخطارات الفريق',
  dailyReport: 'تقرير يومي',
  plantSettings: 'إعدادات الإخطارات',
  myProfile: 'ملفي',
  all: 'الجميع',
  pleaseSelect: 'الرجاء التحديد',
  selectAll: 'اختر الكل',
  inventory: 'جرد',
  desiredRange: 'النطاق المطلوب:',
  minimumInventory: 'الحد الأدنى من المخزون:',
  maximumInventory: 'الحد الأقصى للمخزون:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'د',
  inventoryForecastLegend: 'Δ - تغيير ↑ - إنتاج ↓ - مبيعات',
  goToPlant: 'اذهب إلى المصنع',
  noOrdersOn: 'لا توجد أوامر في',
  emailSentToMe: 'تم إرسال البريد الإلكتروني إلي',
  emailSentToMyOrganization: 'تم إرسال البريد الإلكتروني إلى مؤسستي',
  removeNoteConfirmMessage: 'هل أنت متأكد أنك تريد إزالة هذه الملاحظة؟',
  comment: 'تعليق',
  updates: 'التحديثات',
  addComment: 'أضف تعليق',
  commentPlaceholder: 'اكتب تعليقك',
  dropdownChoosePlaceholder: '- يختار -',
  showAll: 'عرض الكل',
  showLatest: 'عرض الأحدث',
  hide: 'يخفي',
  show: 'يعرض',
  errorTitle: 'خطأ',
  noOptions: 'لا يوجد خيارات',
  components: 'عناصر',
  errorMessage: 'أُووبس! هناك خطأ ما',
  requestErrorMessage: 'حدث خطأ. إذا واجهتك مشكلة الرجاء الضغط على زر المساعدة.',
  tryNewAppVersionMessage:
    'يسعدنا أن تجرب الإصدار الجديد من PlantDemand 🎉 لا تتردد في إرسال ملاحظاتك إلينا.',
  switchInterfaceMessage: 'التبديل إلى الواجهة الأصلية',
  overProductionTitle: 'الإفراط في الإنتاج',
  overProductionMessage: 'لقد تجاوزت السعة بنسبة٪ {overProductionCount}',
  none: 'لا أحد',
  showAllOptions: 'إظهار كافة العناصر٪ {عدد}',
  visibleToCustomersLabel: 'مرئي للعملاء؟',
  requestMaterial: 'طلب المواد',
  calendar: {
    addOrderText: 'أضف طلب',
    tentative: 'مُتَردِّد',
  },
  checkbox: {
    selectAll: 'اختر الكل',
    deselectAll: 'الغاء تحديد الكل',
  },
  calendarWeek: {
    newOrder: 'طلب جديد',
    addNote: 'اضف ملاحظة',
    viewInputMaterial: 'عرض مواد الإدخال',
    viewDayReport: 'عرض تقرير اليوم',
    viewEndOfDayReport: 'عرض تقرير نهاية اليوم',
  },
  reportsPage: {
    calendarData: 'بيانات التقويم',
    groupBy: 'مجموعة من',
    pivotBy: 'محور',
    pivotByPlaceholder: 'محوري حسب ...',
    presets: 'الإعدادات المسبقة ...',
    timeFrame: 'الإطار الزمني',
    sendReportFailedMsg: 'لا يمكن إرسال التقرير',
  },
  calendarFilterBar: {
    fields: 'مجالات',
    fieldsPlaceholder: 'الحقول المختارة',
    countFieldsPlaceholder: '٪ {عدد} الحقول',
    startDate: 'تاريخ البدء',
    endDate: 'تاريخ الانتهاء',
    view: 'عرض الطلب',
    move: 'يتحرك',
    plants: 'النباتات',
    dates: 'تواريخ',
    dateRange: 'نطاق الموعد',
    selectRange: 'اختر نطاقا',
    quantityRange: 'المدى الكمية',
    min: 'دقيقة',
    max: 'الأعلى',
    goToToday: 'اذهب الى اليوم',
  },
  plantDropdown: {
    plantImageAlt: 'صورة النبات',
  },
  modal: {
    close: 'قريب',
  },
  orderModal: {
    deleteOrder: 'حذف الطلب',
    deleteOrderConfirmationMsg: 'سيؤدي هذا الإجراء إلى حذف٪ {عدد} من الطلبات. هل أنت واثق؟',
    notifyTeam: 'أبلغ الفريق',
    disabledChangePlantTooltipTitle: 'يرجى حفظ هذا الطلب قبل نقله إلى مصنع آخر',
  },
  orderRequestModal: {
    orderRequest: 'طلب',
    confirmedOrder: 'أمر مؤكد',
    urgentRequest: 'طلب عاجل',
  },
  noteModal: {
    addNoteDate: 'إضافة تاريخ الملاحظة',
    noteDate: 'تاريخ الملاحظة',
    plant_closure: 'إغلاق النبات',
    custom: 'ملحوظة',
    unknown: 'مجهول',
    crew: 'الطاقم / إجازة',
    maintenance: 'اعمال صيانة',
    alert: 'انذار',
    inspection: 'تكمن',
    other: 'آخر',
    fieldError: 'هذه الخانة مطلوبه',
    descriptionLabel: 'وصف *',
    descriptionPlaceholder: 'وصف الملاحظة ...',
    titleLabel: 'عنوان',
    titlePlaceholder: 'عميل',
    typeLabel: 'نوع الملاحظة',
    typePlaceholder: 'إغلاق النبات',
    serverErrorText1: 'للأسف لم نتمكن من حفظ ملاحظتك. قدم خادمنا الخطأ التالي',
    serverErrorText2: 'إذا استمرت هذه المشكلة في الحدوث ، <a>فانقر هنا</a> للوصول إلى PlantDemand',
    notifyTeam: 'أبلغ الفريق',
  },
  orderForm: {
    order: 'ترتيب',
    addDates: 'أضف التواريخ',
    deliveryDates: 'مواعيد التسليم',
    quantity: 'كمية *',
    deliveryDate: 'تاريخ التسليم او الوصول',
    deliveryTime: 'موعد التسليم',
    nightShift: 'التحول الليلي',
    create: 'خلق',
    save: 'يحفظ',
    saving: 'إنقاذ...',
    remove: 'إزالة',
    copy: 'ينسخ',
    copyOrderMsg:
      'أنشأنا نسخة من الأمر #٪ {copyOrderId}. حدد تاريخ التسليم الجديد واضغط على &quot;حفظ&quot;',
    moveDates: 'نقل التواريخ',
    movePlant: 'نقل النبات',
    changePlant: 'تغيير النبات',
    customerVisibleField: 'هذا الحقل مرئي من قبل العملاء',
    fieldIsRemoved: 'تمت إزالة هذا الحقل من النموذج الخاص بك',
    errorMessage: 'هناك خطأ ما',
    unknownFieldType: 'نوع الحقل غير معروف',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'إدخال المواد',
    quantity: 'كمية',
    supplier: 'المورد',
    noOrdersMessage: 'لا توجد مواد إدخال لأنه لا توجد طلبات مجدولة في هذا التاريخ',
    addOrderLink: 'انقر لإضافة طلب.',
    noInputMaterials:
      '<span>لا يحتوي هذا٪ {name} على مواد إدخال.</span> <a>انقر هنا</a> <span>لإضافة مادة الإدخال إلى٪ {name}</span>',
    noInputMaterialsForDay: 'لا توجد مواد مدخلات متاحة لهذا اليوم.',
    clickToAddOrder: 'انقر هنا لإضافة طلب جديد',
    clickToAddMixDesign: 'انقر هنا لإدارة تصميماتك المختلطة.',
  },
  monthNames: {
    January: 'يناير',
    February: 'شهر فبراير',
    March: 'يمشي',
    April: 'أبريل',
    May: 'مايو',
    June: 'يونيه',
    July: 'يوليو',
    August: 'أغسطس',
    September: 'سبتمبر',
    October: 'اكتوبر',
    November: 'شهر نوفمبر',
    December: 'ديسمبر',
  },
  activityList: {
    order: 'ترتيب',
    time: 'زمن',
    change: 'يتغيرون',
    user: 'المستعمل',
    noData: 'لم يتم العثور على نتائج.',
  },
  ordersList: {
    edit: 'يحرر',
    material: 'مواد',
    quantity: 'كمية',
    customer: 'عميل',
    deliveryDate: 'تاريخ التسليم او الوصول',
    createdDate: 'تاريخ الإنشاء',
    noData: 'لم يتم العثور على نتائج.',
    checkAll: '#',
    actions: 'أجراءات',
  },
  plantHeader: {
    orders: 'الطلب #٪ s',
    planner: 'مخطط',
    activity: 'نشاط',
    requests: 'الطلبات',
    productionSchedule: 'جدول إنتاج',
    plantSettings: 'إعدادات المصنع',
    cogIcon: 'رمز الترس',
    newOrder: 'طلب جديد',
  },
  accountHeader: {
    account: 'الحساب',
    membership: 'عضوية',
    plants: 'النباتات',
    suppliers: 'الموردين',
    billing: 'الفواتير',
    notifications: 'إشعارات',
    myProfile: 'ملفي',
  },
  moveOrder: {
    selectOrdersHeader: 'الطلبات التي اخترتها',
    selectPlant: 'حدد النبات',
    selectMaterial: 'حدد مادة',
    moveSelectedOrdersBtn: 'نقل الأوامر المحددة',
    copySelectedOrdersBtn: 'نسخ الطلبات المحددة',
    disableBtnMessage: 'الرجاء تحديد المصنع والمواد المستهدفة',
    disableMoveOrderBtnMessage: 'الرجاء تحديد حقل واحد على الأقل',
    keepOriginalOrderLabel: 'احتفظ بالترتيب الأصلي',
    selectMatchingField: 'إزالة الحقل٪ {fieldName}',
    confirmPlantAndMaterial: 'تأكيد المصنع والمواد',
    moveOrderFrom: 'ترتيب نقل من',
    moveOrderTo: 'نقل الأمر إلى',
    deselectOrderMessage: 'تم إلغاء تحديد تاريخ الطلب.',
    undo: 'الغاء التحميل',
    bubbleMsgHeader: 'انقل حقول الإدخال',
    bubbleMsgSubHeader:
      'قم بمطابقة حقول الإدخال لضمان عدم فقد أي معلومات عند نقل طلباتك المحددة. حدد حقل إدخال واحد على الأقل لنقل هذا الطلب.',
  },
  OrderFormConfigInfoPanel: {
    header: 'هنا ، يمكنك تخصيص الحقول في نماذج طلبات النباتات الخاصة بك:',
    requiredFields: 'الحقول المطلوبة',
    optionalFields: 'الحقول الاختيارية',
    optionsHeaderExampleText: 'على سبيل المثال يمكنك:',
    addTextFieldExampleText: 'أضف حقلاً نصيًا لأحمال الشاحنات',
    addTextAreaExampleText: 'أضف منطقة نصية مع تعليمات الالتقاط',
    addCheckboxExampleText: 'أضف مربع اختيار لإظهار التقاطات بنظام nighthift',
    visibleToCustomersText:
      'قم بتمييز الحقول على أنها مرئية للعميل ، بحيث يمكن للعملاء عرض هذه الحقول وتعديلها في طلبات الطلبات.',
    fieldOrder: 'مجال الطلب',
    fieldName: 'اسم الحقل',
  },
  addInputMaterialPage: {
    materialProperties: 'خصائص المواد',
    materialName: 'اسم المادة',
    supplierProductName: 'رمز / اسم منتج المورد',
    supplierProductNamePlaceholder: 'أدخل اسم / كود منتج المورد',
    inventoryAmount: 'مبلغ الجرد',
    minimum: 'الحد الأدنى',
    maximum: 'أقصى',
    current: 'تيار',
    systemColor: 'لون النظام',
    materialColor: 'لون المادة',
    isOutputMaterial: 'هي مادة الإخراج',
    addInputMaterial: 'أضف مادة الإدخال',
    materialNamePlaceholder: 'اسم المادة (مثال: 1234 - الاسم)',
    deleteMaterial: 'حذف المادة',
    deleteMaterialConfirmationMsg: 'هل أنت متأكد أنك تريد حذف هذه المواد؟',
  },
  addSupplierModal: {
    supplierName: 'اسم المورد',
    supplierAddress: 'عنوان المورد',
  },
  mixDesign: {
    deleteMixDesign: 'حذف تصميم المزيج',
    deleteMixDesignderConfirmationMsg: 'هل أنت متأكد أنك تريد حذف هذا؟',
    updatedMixDesignSucceededMsg: 'تم تحديث تصميم المزيج بنجاح',
    updatedMixDesignFailedMsg: 'لا يمكن تحديث تصميم المزيج',
    createdMixDesignSucceededMsg: 'تم إنشاء تصميم المزيج بنجاح',
    createdMixDesignFailedMsg: 'لا يمكن إنشاء تصميم مختلط',
    deleteMixDesignSucceededMsg: 'تم حذف تصميم المزيج بنجاح',
    deleteMixDesignFailedMsg: 'لا يمكن حذف تصميم المزيج',
    addMixDesign: 'أضف ميكس ديزاين',
    addNewMaterial: 'أضف مادة جديدة',
    addInputMaterial: 'أضف مادة الإدخال',
    addOutputMaterial: 'أضف مادة الإخراج',
    selectOutputMaterial: 'حدد مادة الإخراج',
    expirationDate: 'تاريخ إنتهاء الصلاحية',
    specificationBasis: 'أساس المواصفات',
    addComponent: 'أضف المكون',
    percent: 'نسبه مئويه',
    components: 'عناصر',
    noComponentsMsg: 'لا توجد مكونات',
    noMixDesignsMsg: 'لم يتم العثور على تصميمات مختلطة',
    mixDesignErrorMsg: 'لا يمكن تحميل تصميم المزيج',
  },
  materialsPage: {
    noMaterialsMsg: 'لم يتم العثور على مواد',
    outputMaterial: 'مواد الإخراج',
  },
  informativePanel: {
    productionMode: 'وضع الإنتاج',
    productionModeDescription:
      'تنتج أساليب الإنتاج &quot;مواد خام&quot; يمكن طلبها مباشرة أو دمجها في مواد جديدة باستخدام &quot;تصميمات مختلطة&quot;.',
    rawMaterial: 'مواد خام ٪',
    mixDesign: 'تصميم مختلط',
    mixDesignDescription: 'الجمع بين &quot;المواد الخام&quot; لإنشاء &quot;مواد الإخراج&quot;.',
  },
  subscribers: {
    subscribers: 'مشتركين',
    newSubscriber: 'مشترك جديد',
    addNewSubscriber: 'إضافة مشترك جديد',
    infoSection:
      'يمكنك إضافة عناوين البريد الإلكتروني للأشخاص الذين سيتلقون التقارير كجزء من مؤسستك.',
  },
  customers: {
    infoSection: 'تصدير هذه القائمة إلى مصنع آخر. إنشاء عميل جديد',
    viewOrders: 'عرض الطلبات',
    noCustomersMsg: 'لم يتم العثور على عملاء',
    targetPlant: 'الهدف النبات',
    selectTargetPlant: 'حدد النبات المستهدف',
    exportCustomers: 'عملاء التصدير',
    customerName: 'اسم الزبون',
    customerList: 'قائمة العملاء',
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'تم تحديث معلومات المصنع بنجاح',
    changePhoto: 'غير الصوره',
    toLabel: 'إلى',
    exportSectionText1: 'بيانات PlantDemand الخاصة بك ملك لك!',
    exportSectionText2:
      'قم بتصدير بيانات طلبك كملف CSV لعمل نسخ احتياطية أو استخدمها لإنشاء تقارير Excel الخاصة بك. تذكر إعادة التحقق هنا للحصول على أحدث إصدار من البيانات.',
    exportSectionText3:
      'انقر بزر الماوس الأيمن على الرابط أدناه وحدد &quot;حفظ الهدف باسم&quot; أو &quot;حفظ الارتباط باسم&quot; ؛',
    exportCSVFileLabel: 'تصدير ملف CSV',
    backToPlant: 'العودة إلى النبات',
  },
  plantMembers: {
    editMemberInfo: 'تحرير معلومات العضو',
    addNewMember: 'إضافة عضو جديد',
    name: 'اسم',
    namePlaceholder: 'اسم عضو',
    email: 'عنوان البريد الالكترونى',
    emailPlaceholder: 'عنوان البريد الالكترونى',
    close: 'قريب',
    remove: 'إزالة',
    save: 'يحفظ',
    userList: 'قائمة المستخدم',
    newEmployeeBtn: 'موظف جديد',
    employee: 'موظف',
    role: 'دور',
    plant: 'مصنع',
    plantAccessUserRoles: 'الوصول إلى المصنع وأدوار المستخدم',
    access: 'وصول',
    noAccessPlaceholder: 'ممنوع الدخول',
    deleteMembershipTitle: 'حذف العضوية',
    deleteMembershipMessage: 'هل أنت متأكد أنك تريد حذف العضوية؟',
    noRoleSelectedMessage: 'الرجاء تحديد دور واحد على الأقل',
    noPermissionsMessage: 'ليس لديك أذونات لتعديل الوصول',
    cannotUpdateYourPermissionsMsg: 'لا يمكن تحديث الأذونات الخاصة بك',
    cannotDeleteInvitationMessage: 'لا يمكن حذف الدعوة',
    updatePermissionsMessage: 'تم تحديث الأذونات بنجاح',
    inviteNewMemberMessage: 'تمت دعوة العضو بنجاح',
    deleteInvitationMessage: 'تم حذف الدعوة بنجاح',
  },
  plantPermissionsTable: {
    permissionsText: 'أذونات',
    needCustomPermissionText: 'هل تحتاج إلى إذن مخصص؟',
    contactUs: 'اتصل بنا',
    task: 'مهمة',
    plantManager: 'مدير مصنع',
    dispatch: 'إرسال',
    QA: 'سؤال وجواب',
    customer: 'عميل',
    viewCalendar: 'مشاهدة التقويم',
    enterOrders: 'أدخل الطلبات',
    viewPlantSettings: 'عرض إعدادات المصنع',
    changePlantSettings: 'تغيير إعدادات المصنع',
    viewReports: 'عرض التقارير',
    inviteUsers: 'قم بدعوة المستخدمين',
    manageOrderPlanner: 'إدارة مخطط الطلبات',
    limited: 'محدود',
  },
  suppliersPage: {
    noSuppliersMsg: 'لم يتم العثور على موردين',
    cannotCreateSupplier: 'تعذر إنشاء المورد',
  },
  onBoarding: {
    createCompany: 'أنشئ مؤسستك',
    companyName: 'اسم الشركة',
    addFirstPlant: 'أضف نباتك الأول',
    uploadPlantImage: 'إضافة صور النبات',
    addFirstOutputMaterial: 'أضف مادة الإخراج الأولى الخاصة بك',
    outputMaterialName: 'اسم مادة الإخراج',
    outputMaterialInfo: 'هذا هو اسم المادة التي تبيعها',
    materialSetup: 'إعداد المواد',
    fileSizeBig: 'حجم الصورة كبير جدًا. يرجى تغيير الحجم أو محاولة ملف آخر.',
    error: 'حدث خطأ',
  },
  productionModePage: {
    composition: 'تكوين',
    hourlyRate: 'المعدل بالساعة',
    defaultHourlyRate: 'معدل الساعة الافتراضي',
    addProductionMode: 'أضف وضع الإنتاج',
    updatedProductionModeMsg: 'تم تحديث وضع الإنتاج بنجاح',
    cannotUpdateProductionModeMsg: 'لا يمكن تحديث وضع الإنتاج',
    deleteProductionMode: 'حذف وضع الإنتاج',
    deleteProductionModeConfirmMsg: 'هل أنت متأكد أنك تريد إزالة وضع الإنتاج هذا؟',
    deleteProductionModeSucceededMsg: 'تم حذف وضع الإنتاج بنجاح',
    deleteProductionModeFailedMsg: 'لا يمكن حذف وضع الإنتاج',
    createProductionModeSucceededMsg: 'تم إنشاء وضع الإنتاج بنجاح',
    createProductionModeFailedMsg: 'لا يمكن إنشاء وضع الإنتاج',
  },
  orderRequests: {
    changeOrder: 'تغيير الترتيب',
    noRequests: 'لا يوجد حاليا أي طلبات معلقة لهذا المصنع.',
  },
};
