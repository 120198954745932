import * as actions from '../actions/pageLevelAlertActions';

export interface PageLevelAlertInitialState {
  alerts: any[];
}

const initialState: PageLevelAlertInitialState = {
  alerts: [], // no default error or empty string so we can check for truth
};

const pageLevelAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_PAGE_LEVEL_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case actions.REMOVE_PAGE_LEVEL_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert !== action.payload),
      };
    default:
      return state;
  }
};

export default pageLevelAlertsReducer;
