import { en } from './en';
import { fr } from './fr';
import { uk } from './uk';
import { ru } from './ru';
import { ar } from './ar';
import { es } from './es';
import { de } from './de';
import { enPaving } from './enPaving';

export const translations = {
  fr: fr,
  en: en,
  uk: uk,
  ru: ru,
  es: es,
  de: de,
  ar: ar,
  ara: ar,
  enPaving: enPaving,
};
