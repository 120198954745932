import * as actions from '../actions/sidebarActions';

export const initialState = {
  visible: true,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SIDEBAR_OPEN:
      return { ...state, visible: true };
    case actions.SIDEBAR_CLOSE:
      return { ...state, visible: false };
    default:
      return state;
  }
};

export default sidebarReducer;
