import { call, put, takeLatest } from 'redux-saga/effects';
import { tryNewDesign } from 'Services/userService';
import {
  plantActionTypes,
  setTryNewDesignSucceeded,
  setTryNewDesignFailed,
} from 'Redux/actions/userActions';

export function* setTryNewDesign() {
  try {
    yield call(tryNewDesign);
    yield put(setTryNewDesignSucceeded(false));
    if (window.location.href.indexOf('/app/profile') !== -1) {
      window.location.replace('/accounts');
    } else if (window.location.href.indexOf('/plant/') !== -1) {
      const index = window.location.href.indexOf('/plant/');
      const url = window.location.href.slice(index);
      window.location.replace(`${url}`);
    } else if (window.location.href.indexOf('/app/reports') !== -1) {
      window.location.replace('/reports/');
    } else {
      window.location.replace('/dashboard/');
    }
  } catch (error) {
    yield put(setTryNewDesignFailed(error));
  }
}

export default function* watchUsers() {
  yield takeLatest(plantActionTypes.TRY_NEW_DESIGN_REQUESTED, setTryNewDesign);
}
