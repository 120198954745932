import cls from 'classnames';
import { CustomerHighlightColor } from 'Types/plantTypes';
import styles from './colorCircleStyles.scss';

type ColorCircle = {
  color?: CustomerHighlightColor;
  className?: string;
};
const ColorCircle = ({ color, className }: ColorCircle) => {
  if (!color) return null;

  const getColorLabel = (): string | null => {
    if (color === CustomerHighlightColor.Error || color === CustomerHighlightColor.Warning) {
      return 'Warning';
    } else if (color === CustomerHighlightColor.Info) {
      return 'Note';
    }

    return null;
  };

  return (
    <div className={styles.colorCircleWrapper}>
      <span className={styles.label}>{getColorLabel()}</span>
      <div
        className={cls(styles.color, className, {
          [styles.colorSuccess]: color === CustomerHighlightColor.Success,
          [styles.colorError]: color === CustomerHighlightColor.Error,
          [styles.colorWarning]: color === CustomerHighlightColor.Warning,
          [styles.colorInfo]: color === CustomerHighlightColor.Info,
        })}
      ></div>
    </div>
  );
};

export default ColorCircle;
