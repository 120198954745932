import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import kebabCase from 'lodash/kebabCase';
import cls from 'classnames';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { mapper, prepareFormFieldsProps } from './utils';
import InputFieldWrapper from 'Components/fields/InputFieldWrapper';
import { QuantityPickerDate } from 'Components/dateQuantityPicker';
import FormLabel from '../fields/formLabel';
import { OrderFieldsType } from 'Types/plantTypes';
import { OrderModalCustomFieldsType } from 'Types/OrderModalTypes';
import { subscribe } from '../../helpers/hooks/useRemoteFormControl';
import { useDeepCompareMemoize } from '../../helpers/hooks/useDeepCompareMemoize';
import styles from './schemaform.scss';

export type FormValues = {
  [key: string]: any;
  dates: Array<QuantityPickerDate>;
};

const SchemaForm = ({
  schema,
  formData,
  onSubmit,
  customEventHandlers = {},
  formRef,
  onValidationErrors,
}: any) => {
  const methods = useForm({
    defaultValues: formData,
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setError,
    setValue,
    watch,
  } = methods;

  const areRemoteFieldsRegisteredRef = useRef(false);

  const errorsArray = Object.keys(errors).map(error => errors[error].message as string);
  const validationErrors = useDeepCompareMemoize(errorsArray);

  useEffect(() => {
    onValidationErrors(validationErrors);
  }, [validationErrors, onValidationErrors]);

  useEffect(() => {
    const remoteFields = schema.properties.filter(property => property.remote);

    remoteFields.forEach((remoteField, i) => {
      const fieldKey = remoteField.key;
      subscribe(fieldKey, evt => {
        setValue(fieldKey, evt.detail);
      });
      areRemoteFieldsRegisteredRef.current = true;
    });
  }, []);

  const handleFormSubmit = () => {
    handleSubmit(formData => onSubmit(formData, setError))();
  };

  // using watch method: https://react-hook-form.com/api#watch because react-form-hook library cache
  // the formData and does not update the values
  const fieldValues = watch();

  const renderControllerField = ({ Field, inputFieldProps, property, rules }) => {
    return (
      <Controller
        name={property.key}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Field
              {...field}
              {...inputFieldProps}
              {...(inputFieldProps.onChange && {
                onChange: val => {
                  const changedValue = inputFieldProps.onChange(val);
                  field.onChange(changedValue);
                },
              })}
              {...(property.type === OrderFieldsType.Checkbox && {
                checked: field.value,
              })}
              className={property.schema.isCustomField ? styles.customField : styles.requiredField}
              placeholder={property.placeholder || inputFieldProps.placeholder}
              readOnly={property.deleted || inputFieldProps.readOnly}
              hasError={!!errors[property.key]}
              errorMessage={errors[property.key]?.message}
              setValue={setValue}
              id={`${field.name}-field`}
            />
          );
        }}
      />
    );
  };

  const buildForm = () => {
    const formFieldsProps = prepareFormFieldsProps(schema);

    const form = formFieldsProps.map(property => {
      const isDateQuantityPicker = property.type === OrderModalCustomFieldsType.DateQuantityPicker;
      const inputFieldObj = mapper[property.type];
      const Field = inputFieldObj.component;
      const inputFieldProps = inputFieldObj.defaultProps(
        property,
        fieldValues[property.key],
        customEventHandlers[property.key],
        dirtyFields,
      );

      const visibleToCustomer = property.schema.visibleToCustomer;
      const isFieldSupported = Object.prototype.hasOwnProperty.call(property.schema, 'isSupported')
        ? property.schema.isSupported
        : true;

      const rules = {
        required: property.required,
        minLength: property.minLength && {
          value: property.minLength.value,
          message: property.minLength.message,
        },
        maxLength: property.maxLength && {
          value: property.maxLength.value,
          message: property.maxLength.message,
        },
        min: property.minimum && {
          value: property.minimum.value,
          message: property.minimum.message,
        },
        max: property.maximum && {
          value: property.maximum.value,
          message: property.maximum.message,
        },
        pattern: {
          value: property.schema.pattern,
          message: property.schema.validationMessage,
        },
        validate: () => property.customValidation?.(getValues()[property.key]),
      };

      const formLabel = (
        <FormLabel
          className={cls(styles.formLabel, { [styles.deletedFieldLabel]: property.deleted })}
          label={property.title}
          htmlFor={`${property.key}-field`}
          visibleToCustomer={visibleToCustomer}
          isFieldDeleted={property.deleted}
          isFieldSupported={isFieldSupported}
          isFieldReadOnly={property.type === OrderFieldsType.ReadOnly}
        />
      );

      const testIdValue = property.remote
        ? 'remote-field'
        : property.isStaticField
        ? 'static-field'
        : property.isRequiredField
        ? 'required-field'
        : 'custom-field';

      return (
        <div
          className={cls(
            `grid-col grid-col-md-${property.schema.cols || 12}`,
            styles.fieldWrapper,
            { [styles.remote]: property.remote },
            { [styles.deletedField]: property.deleted },
          )}
          data-testid={testIdValue}
        >
          {isDateQuantityPicker ? (
            <Field {...inputFieldProps} errors={errors.dates} />
          ) : (
            <InputFieldWrapper
              label={formLabel}
              labelPosition={property.schema.labelPosition}
              labelClassName="grid-col-md-4"
              data-testid={`${kebabCase(property.title)}-field-wrapper`}
            >
              {renderControllerField({ Field, inputFieldProps, property, rules })}
            </InputFieldWrapper>
          )}
        </div>
      );
    });

    return form;
  };

  const form = buildForm();

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid={areRemoteFieldsRegisteredRef.current ? 'schema-form' : undefined}
      >
        <div className="grid-row">{form}</div>
      </form>
    </FormProvider>
  );
};

export default SchemaForm;
