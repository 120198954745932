import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

class NoAuthRoute extends Component<any, any> {
  getRoute = renderProps => {
    const { component: Comp } = this.props;
    return <Comp {...renderProps} />;
  };

  render() {
    const { component, uiState, ...rest } = this.props;

    return <Route {...rest} render={renderProps => this.getRoute(renderProps)} />;
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(NoAuthRoute);
