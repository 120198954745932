export const TRIGGER_PAGE_LEVEL_ALERT = '@pageLevelAlerts/TRIGGER_PAGE_LEVEL_ALERT';
export const ADD_PAGE_LEVEL_ALERT = '@pageLevelAlerts/ADD_PAGE_LEVEL_ALERT';
export const REMOVE_PAGE_LEVEL_ALERT = '@pageLevelAlerts/REMOVE_PAGE_LEVEL_ALERT';

import { Alert, AlertType } from '../../components/alerts/singleAlert';

export const triggerPageLevelAlert = (params: Alert) => ({
  type: TRIGGER_PAGE_LEVEL_ALERT,
  payload: params,
});

export const triggerSuccessAlert = (message: string) => {
  const successAlert: Alert = {
    type: AlertType.Success,
    message,
  };
  return {
    type: TRIGGER_PAGE_LEVEL_ALERT,
    payload: successAlert,
  };
};

export const triggerErrorAlert = (message: string) => {
  const errorAlert: Alert = {
    type: AlertType.Error,
    message,
  };
  return {
    type: TRIGGER_PAGE_LEVEL_ALERT,
    payload: errorAlert,
  };
};

export const triggerWarningAlert = (message: string) => {
  const warningAlert: Alert = {
    type: AlertType.Warning,
    message,
  };
  return {
    type: TRIGGER_PAGE_LEVEL_ALERT,
    payload: warningAlert,
  };
};

export const addPageLevelAlert = (params: Alert) => ({
  type: ADD_PAGE_LEVEL_ALERT,
  payload: params,
});
export const removePageLevelAlert = (params: Alert) => ({
  type: REMOVE_PAGE_LEVEL_ALERT,
  payload: params,
});
