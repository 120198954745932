import 'preact/debug';
import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import * as qs from 'query-string';

import LoadingBar from 'react-redux-loading-bar';

import Alerts from './alerts';
import SideMenu from './side-menu';
import PlantHeader from './header/plant-header';
import FloatingHelpButton from './FloatingHelpButton';
import RoutesRenderer from './router/RoutesRenderer';
import ModalContainer from './modal/modalContainer';

import { getPlantPermissions, getPlantsList, getLastActivePlant } from 'Services/plantService';
import {
  getCalendarFilters,
  getOrdersRoute,
  storeCalendarFilters,
} from 'Services/calendarFilterService';
import { getBusinesses } from 'Services/businessService';
import { getUserProfile, generateNewJwtToken, getCsrfToken } from 'Services/userService';
import { initWebSocket } from 'Services/webSocketService';

import { setBusinesses } from 'Redux/actions/businessActions';
import {
  setPlantsPermissions,
  setActiveInactivePlants,
  setPlants,
  setActivePlant,
} from 'Redux/actions/plantActions';
import { openMenu, closeMenu } from 'Redux/actions/menuActions';
import { setUserProfile } from 'Redux/actions/userActions';
import { history } from 'Redux/store';
import { RootState } from 'Redux/reducers';

import { PlantId } from 'Types/plantTypes';
import { isLoggedIn, setAccessToken, getAccessToken } from '../utils/authService';
import initGoogleTagManager from '../google-tag-manager';
import { addCurrentUserToSentry, initSentry } from '../sentry';
import routeMap from '../route-maps';
import { replacePlantIdInUrl } from '../helpers/url';
import '../assets/scss/style.scss';
import { getLastActiveSpace, getSpaces } from 'Services/entryService';
import { setLastActiveSpace, setSpaces, setSpacesPermissions } from 'Redux/actions/spaceActions';
import { getSpacePermissions } from 'Services/spaceService';

const App = () => {
  const dispatch = useDispatch();

  const lastPlantId = useSelector((state: RootState) => state.plants.activePlantId);
  const lastSpaceId = useSelector((state: RootState) => state.spaces.lastActiveSpace);
  const plants = useSelector((state: RootState) => state.plants.plants);
  const customerPortals = useSelector((state: RootState) => state.plants.customerPortals);
  const spaces = useSelector((state: RootState) => state.spaces.spaces);
  const sideMenuOpened = useSelector((state: RootState) => state.menu.opened);
  const isSideBarVisible = useSelector((state: RootState) => state.sidebar.visible);
  const alerts = useSelector((state: RootState) => state.alerts.alerts);
  const user = useSelector((state: RootState) => state.user);

  const [token, setToken] = useState<string | null>(null);
  const [renderChildComponents, setRenderChildComponents] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      const accessToken = getAccessToken();
      const ssoToken = getSSOToken();
      let tokenFromCookie: string | null = null;

      if (!accessToken && !ssoToken) {
        // try to obtain token if cookie exists from old app
        tokenFromCookie = await requestAccessToken();

        // if token is returned save it to local storage
        if (tokenFromCookie) {
          setAccessToken(tokenFromCookie);
        }
      }

      if (ssoToken) {
        setAccessToken(ssoToken);
      }

      const authToken = ssoToken || accessToken || tokenFromCookie;

      if (authToken) {
        setToken(authToken);

        initWebSocket(authToken);

        const [profile, plantsPermissions, spacesPermissions, businesses, plantsList, spaces] =
          await Promise.all([
            getUserProfile(),
            getPlantPermissions(),
            getSpacePermissions(),
            getBusinesses(),
            getPlantsList(),
            getSpaces(),
          ]);

        await getCsrfToken(); // this function should be invoked after getLognStatus

        addCurrentUserToSentry(profile.fullName);

        initGoogleTagManager(profile.id);

        dispatch(setUserProfile(profile));
        dispatch(setBusinesses(businesses));
        dispatch(setPlantsPermissions(plantsPermissions));
        dispatch(setSpacesPermissions(spacesPermissions));
        dispatch(setPlants(plantsList));
        dispatch(setActiveInactivePlants(plantsList));
        dispatch(setSpaces(spaces));

        const lastActivePlantId =
          getLastActivePlant() || (plantsList.length ? plantsList[0].id : null);

        if (lastActivePlantId) {
          dispatch(setActivePlant(lastActivePlantId));
        }

        const lastActiveSpaceId = getLastActiveSpace() || (spaces.length ? spaces[0].id : null);
        if (lastActiveSpaceId) {
          dispatch(setLastActiveSpace(lastActiveSpaceId));
        }
      } else {
        initGoogleTagManager();
      }

      setRenderChildComponents(true);
    };

    initSentry();
    initApp();
  }, [dispatch]);

  useEffect(() => {
    const handleLocationChange = location => {
      const regex = /plant\/([-+]?\d*\.*\d+)/;
      const space = /space\/([-+]?\d*\.*\d+)/;
      const pathname = location.pathname;

      if (regex.test(pathname)) {
        const urlPlantId = regex.exec(pathname)?.[1];
        if (urlPlantId && urlPlantId !== lastPlantId) {
          clearSelectedPlantFromLocalStorageFilters();
          dispatch(setActivePlant(urlPlantId));
        }
      } else if (space.test(pathname)) {
        const urlSpaceId = space.exec(pathname)?.[1];
        if (urlSpaceId && urlSpaceId !== lastSpaceId) {
          clearSelectedSpaceFromLocalStorageFilters();
          dispatch(setLastActiveSpace(urlSpaceId));
        }
      }
    };

    history.listen(location => {
      handleLocationChange(location);
    });
  }, [dispatch, lastPlantId]);

  function getSSOToken(): string | null {
    const { token, ...rest } = qs.parse(location.search);

    if (!token || typeof token !== 'string') return null;
    return token;
  }

  async function requestAccessToken() {
    try {
      const response = await generateNewJwtToken();
      return response.data ? response.data.token : '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  function clearSelectedPlantFromLocalStorageFilters() {
    const localStorageFilters = getCalendarFilters();
    if (!localStorageFilters) return;

    storeCalendarFilters({ ...localStorageFilters, order: undefined, copyOrder: undefined });
  }

  function clearSelectedSpaceFromLocalStorageFilters() {
    const localStorageFilters = getCalendarFilters();
    if (!localStorageFilters) return;

    storeCalendarFilters({ ...localStorageFilters, entryId: undefined, copyEntryId: undefined });
  }

  const getPlantId = () => {
    const regex = /plant\/([-+]?\d*\.*\d+)/;
    const pathname = location.pathname;

    if (regex.test(pathname)) {
      const plantId = regex.exec(pathname)?.[1];
      if (plantId) return plantId;
    }

    return getLastActivePlant() ? getLastActivePlant() : plants.length ? plants[0].id : null;
  };

  const getSpaceId = () => {
    const regex = /space\/([-+]?\d*\.*\d+)/;
    const pathname = location.pathname;

    if (regex.test(pathname)) {
      const spaceId = regex.exec(pathname)?.[1];
      if (spaceId) return spaceId;
    }

    return getLastActiveSpace() || (spaces.length ? spaces[0].id : null);
  };

  const getPlantRoute = () => {
    const regex = /plant\/([-+]?\d*\.*\d+)/;
    const pathname = window.location.pathname;
    const hash = window.location.hash;

    if (regex.test(pathname)) {
      return pathname.replace(/\d+/, ':plantId') + hash;
    }

    return routeMap.plantCalendar;
  };

  const handleSideBarClose = () => {
    dispatch(closeMenu());
  };

  const plantId = getPlantId();
  const spaceId = getSpaceId();

  return (
    <Router history={history}>
      {!process.env.BUILD_LAYOUT_ONLY && (
        <div>
          <ModalContainer />
          <Alerts alerts={alerts} />
          <LoadingBar className="bar" />
        </div>
      )}
      {token && renderChildComponents && (
        <Fragment>
          {!process.env.BUILD_LAYOUT_ONLY ? (
            <SideMenu
              opened={sideMenuOpened}
              plants={plants}
              customerPortals={customerPortals}
              selectedPlant={plantId}
              selectedSpace={spaceId}
              plantRoute={getPlantRoute()}
              isUsingNavLinks
              isSidebarVisible={isSideBarVisible}
              shouldRenderSpaceScheduleTab={user.isSuperuser || user.profile?.tryGenericEntities}
              onClose={handleSideBarClose}
            />
          ) : (
            <Fragment>
              <SideMenu
                opened={sideMenuOpened}
                plants={plants}
                customerPortals={customerPortals}
                selectedPlant={plantId}
                selectedSpace={spaceId}
                plantRoute={getPlantRoute()}
                isUsingNavLinks={false}
                isSidebarVisible={isSideBarVisible}
                shouldRenderSpaceScheduleTab={user.isSuperuser || user.profile?.tryGenericEntities}
                onClose={handleSideBarClose}
              />
              <PlantHeader
                plantId={getPlantId() as PlantId}
                entities={[
                  {
                    entryType: 'plant',
                    fields: plants.map(plant => ({
                      value: plant.id,
                      label: plant.name,
                      icon: plant.picture,
                      link: getPlantRoute(),
                    })),
                  },
                  {
                    entryType: 'customerPortal',
                    fields: customerPortals.map(plant => ({
                      value: plant.id,
                      label: plant.name,
                      icon: plant.picture,
                      link: getPlantRoute(),
                    })),
                  },
                ]}
                ordersRoute={replacePlantIdInUrl(getOrdersRoute(), getPlantId())}
                isUsingNavLinks={false}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      {renderChildComponents && !process.env.BUILD_LAYOUT_ONLY ? <RoutesRenderer /> : ''}
      <FloatingHelpButton />
    </Router>
  );
};

export default App;
