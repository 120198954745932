import { MutableRef } from 'preact/hooks';

export const smoothScrollTo = (yAxis: number, ref?: MutableRef<HTMLElement | null>) => {
  const target = ref?.current ? ref.current : window;
  const scrollPositionY = ref?.current ? ref.current.scrollTop : window.scrollY;
  const step = 8;

  if (scrollPositionY > yAxis) {
    requestAnimationFrame(() => smoothScrollTo(yAxis, ref));
    target.scrollTo(0, scrollPositionY - scrollPositionY / step);
  }
};
