import { Action } from './helper';
import { SupportMessageParams } from '../../services/sendSupportMessageService';

export enum SupportMessageActionTypes {
  SEND_SUPPORT_MESSAGE_REQUESTED = '@support/SEND_SUPPORT_MESSAGE_REQUESTED',
  SEND_SUPPORT_MESSAGE_SUCCEEDED = '@support/SEND_SUPPORT_MESSAGE_SUCCEEDED',
  SEND_SUPPORT_MESSAGE_FAILED = '@support/SEND_SUPPORT_MESSAGE_FAILED',
  OPEN_FEEDBACK_MODAL = '@support/OPEN_FEEDBACK_MODAL',
}

export type SendSupportMessageActionRequested = Action<
  SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_REQUESTED,
  SupportMessageParams
>;

export type OpenFeedbackModalActionRequested = Action<
  SupportMessageActionTypes.OPEN_FEEDBACK_MODAL,
  boolean
>;

export const sendSupportMessage = (
  params: SupportMessageParams,
): SendSupportMessageActionRequested => ({
  type: SupportMessageActionTypes.SEND_SUPPORT_MESSAGE_REQUESTED,
  payload: params,
});

export const openFeedbackModal = (payload): OpenFeedbackModalActionRequested => ({
  type: SupportMessageActionTypes.OPEN_FEEDBACK_MODAL,
  payload,
});
