import plantDemandAxios from '../plantDemandAxios';
export const ACCESS_TOKEN_KEY = 'token';
export const CSRF_TOKEN_KEY = 'csrftoken';
export const BASE_URL = '/';

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export function getAccessToken() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token == null || isAccessTokenExpired(token)) {
    return null;
  }
  return token;
}

export function getCSRFToken() {
  return localStorage.getItem(CSRF_TOKEN_KEY);
}

export function setCSRFToken(token: string) {
  localStorage.setItem(CSRF_TOKEN_KEY, token);
}

export function getUserId(token: string) {
  let id;
  if (token) {
    const user = JSON.parse(atob(token.split('.')[1]));
    if (user) {
      id = user.user_id;
    }
  }
  return id;
}

export function setAuthorizationHeader(token: string) {
  plantDemandAxios.defaults.headers.common.Authorization = 'JWT ' + token;
}

/**
 * Save the token to localStorage and update axios Authorization header
 */
export function setAccessToken(token: string) {
  setAuthorizationHeader(token);
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function isLoggedIn() {
  const accessToken = getAccessToken();
  return !!accessToken;
}

export const decodeJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const isAccessTokenExpired = (accessToken: string) => {
  const { exp } = decodeJwt(accessToken);
  return new Date(exp * 1000) < new Date();
};
