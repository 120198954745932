import { h } from 'preact';
import styles from './circularLoadingSpinerStyles.scss';

interface CircularLoadingSpinnerProps {
  color?: string;
  secondaryColor?: string;
}

const CircularLoadingSpinner = ({
  color = 'white',
  secondaryColor = 'transparent',
}: CircularLoadingSpinnerProps) => {
  const strokeWidth = 4;
  const circleStyle = { animation: `${styles.circularSpinner} 1s linear infinite` };

  return (
    <svg
      fill="none"
      viewBox="0 0 66 66"
      style={{ color: color, overflow: 'visible', width: '19px' }}
    >
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="currentColor"
        strokeDasharray="1, 174"
        strokeDashoffset="306"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        style={circleStyle}
      />
    </svg>
  );
};

export default CircularLoadingSpinner;
