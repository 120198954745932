import { ComponentChild, h } from 'preact';
import cls from 'classnames';
import OptionalFormLabel from '../OptionalFormLabel';
import styles from './textarea.scss';

export interface TextareaProps {
  className?: string;
  formLabel?: ComponentChild;
  inputRef?: any;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (e) => void;
  onKeyDown?: (e) => void;
  errorMessage?: string;
  id?: string;
}

const Textarea = ({
  className,
  formLabel,
  name,
  value,
  placeholder,
  errorMessage,
  readOnly,
  disabled,
  inputRef,
  id,
  onChange = e => {},
  onKeyDown,
}: TextareaProps) => {
  const classes = cls(styles.textAreaWrapper, className, { disabled: disabled });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    onKeyDown?.(e);
  };

  return (
    <div className={classes}>
      {formLabel && <OptionalFormLabel>{formLabel}</OptionalFormLabel>}
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        className={cls(styles.formField, { [styles.hasError]: errorMessage && !value })}
        id={id}
        ref={inputRef}
        readOnly={readOnly}
        disabled={disabled}
        aria-label={name ? `textarea-${name}` : 'textarea'}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      {errorMessage && !value && <div className={styles.errorStyle}>{errorMessage}</div>}
    </div>
  );
};

export default Textarea;
