import { useState } from 'preact/hooks';
import cls from 'classnames';
import { ComponentChild } from 'preact';
import arrowDown from 'Assets/icons/arrowDown.svg';
import styles from './organizationStyles.scss';

type OrganizationProps = {
  organizationName: string;
  open: boolean;
  children: ComponentChild;
  onCollapse: (collapse: boolean) => void;
};
const Organization = ({
  organizationName,
  open = true,
  children,
  onCollapse,
}: OrganizationProps) => {
  const handleExpandCollapse = () => onCollapse(!open);

  return (
    <div className={styles.organizationWrapper}>
      <div
        className={cls(styles.organizationHeader, { [styles.collapsed]: !open })}
        role="button"
        onClick={handleExpandCollapse}
      >
        <h4 className={styles.heading}>{organizationName}</h4>
        <img
          src={arrowDown}
          className={cls(styles.arrowIcon, {
            [styles.arrowIconRotated]: open,
          })}
        />
      </div>
      {open && <div className={styles.organizationBody}>{children}</div>}
    </div>
  );
};

export default Organization;
