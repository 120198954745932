import { DateStringISO } from 'Services/timeService';

export enum CalendarNoteTypes {
  PlantClosure = 'plant_closure',
  Inspection = 'inspection',
  Custom = 'custom',
  OverCapacity = 'over_capacity',
}

export interface PlantNote {
  id: string;
  title: CalendarNoteTypes;
  description: string;
  date: DateStringISO;
  dates: { date: string }[];
  type: CalendarNoteTypes;
  calendarType: string;
}
