import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Translate as T } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styles from './side-menu-link.scss';

interface SideMenuLinkProps {
  path: string;
  exact?: boolean;
  icon?: string;
  activeIcon?: string;
  hoverIcon?: string;
  label: string;
  active?: boolean;
  isUsingNavLinks?: boolean;
  testId?: string;
  menuIsOpened?: boolean;
}

const SideMenuLink = ({
  path,
  exact,
  icon,
  activeIcon,
  hoverIcon,
  label,
  active,
  isUsingNavLinks,
  testId,
  menuIsOpened,
}: SideMenuLinkProps) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  return (
    <div>
      {isUsingNavLinks ? (
        <NavLink
          to={path}
          exact={exact}
          className={classnames(styles.defaultLink, {
            [styles.openedMenu]: menuIsOpened,
            [styles.active]: active,
          })}
          data-testid={testId}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <img class={styles.icon} src={active ? activeIcon : hovered ? hoverIcon : icon} alt="" />
          <T value={label} />
        </NavLink>
      ) : (
        <a
          href={path}
          data-testid={testId}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          class={menuIsOpened ? styles.openedMenu : active ? styles.active : styles.defaultLink}
        >
          <img class={styles.icon} src={active ? activeIcon : hovered ? hoverIcon : icon} alt="" />
          <T value={label} />
        </a>
      )}
    </div>
  );
};

export default SideMenuLink;
