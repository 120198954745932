import * as qs from 'query-string';
import { DateStringISO } from 'Services/timeService';

export const spaceApiMap = {
  // Configuration
  createConfiguration: (businessId: string) =>
    `/api/generic/business/${businessId}/configurations/new/`,
  listConfigurations: (businessId: string) => `/api/generic/business/${businessId}/configurations/`,
  getConfigurationById: (businessId: string, configId: string) =>
    `/api/generic/business/${businessId}/configurations/${configId}/`,
  updateConfiguration: (businessId: string, configId: string) =>
    `/api/generic/business/${businessId}/configurations/${configId}/update/`,
  deleteConfiguration: (businessId: string, configId: string) =>
    `/api/generic/business/${businessId}/configurations/${configId}/delete/`,
  // User space permissions
  getUserSpacePermissions: () => '/api/generic/user-space-permissions/',
  // Space
  getSpaces: () => '/api/generic/spaces',
  getSpaceById: (spaceId: string) => `/api/generic/spaces/${spaceId}`,
  updateSpace: (spaceId: string) => `/api/generic/spaces/${spaceId}/update`,
  getConfigurations: () => '/api/generic/spaces',
  // Space permissions
  getSpacesPermissions: ({
    spaceId,
    userId,
    email,
  }: {
    spaceId?: string;
    userId?: string;
    email?: string;
  }) => {
    const baseEntryDatesUrl = '/api/generic/space-permissions/?';
    const search = qs.stringify({
      space_id: spaceId,
      user_id: userId,
      email,
    });

    return `${baseEntryDatesUrl}${search}`;
  },
  updateSpacesPermissions: () => '/api/generic/space-permissions/update/',
  deleteSpacesPermissions: () => '/api/generic/space-permissions/delete/',
  // Entry
  getEntryDates: ({
    spaceId,
    dateFrom,
    dateUntil,
    includeRemoved,
  }: {
    spaceId: string;
    dateFrom?: DateStringISO;
    dateUntil?: DateStringISO;
    includeRemoved?: boolean;
  }) => {
    const baseEntryDatesUrl = `/api/generic/space/${spaceId}/entry_dates/?`;
    const search = qs.stringify({
      date_from: dateFrom,
      date_until: dateUntil,
      include_removed: includeRemoved,
    });
    return `${baseEntryDatesUrl}${search}`;
  },
  getEntryById: (spaceId: string, entryId: string) =>
    `/api/generic/space/${spaceId}/entry/${entryId}`,
  createPrimaryField: (spaceId: string) => `/api/generic/space/${spaceId}/primary_fields/new/`,
  getSpacePrimaryFields: (spaceId: string) => `/api/generic/space/${spaceId}/primary_fields/`,
  getPrimaryFieldById: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${fieldId}`,
  updatePrimaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${fieldId}/update/`,
  deletePrimaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${fieldId}/delete/`,
  restorePromaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${fieldId}/restore/`,
  createEntrySecondaryField: (spaceId: string) =>
    `/api/generic/space/${spaceId}/entry/secondary_fields/new/`,
  getEntrySecondaryFields: (spaceId: string) =>
    `/api/generic/space/${spaceId}/entry/secondary_fields/`,
  updateEntrySecondaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/entry/secondary_fields/${fieldId}/update/`,
  deleteEntrySecondaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/entry/secondary_fields/${fieldId}/delete/`,
  // Primary fields Secondary fields
  getPrimaryFieldSecondaryFields: (spaceId: string, primaryFieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/secondary_fields/`,
  createPrimaryFieldSecondaryField: (spaceId: string, fieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${fieldId}/secondary_fields/new/`,
  updatePrimaryFieldSecondaryField: (
    spaceId: string,
    primaryFieldId: string,
    secondaryField: string,
  ) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/secondary_fields/${secondaryField}/update/`,
  deletePrimaryFieldSecondaryField: (
    spaceId: string,
    primaryFieldId: string,
    secondaryField: string,
  ) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/secondary_fields/${secondaryField}/delete/`,
  // Primary field values
  createPrimaryFieldValue: ({ spaceId, primaryFieldId }) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/new/`,
  getPrimaryFieldValues: (spaceId: string, primaryFieldId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/`,
  getPrimaryFieldValueById: (spaceId: string, primaryFieldId: string, valueId: string) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/${valueId}`,
  updatePrimaryFieldValue: ({ spaceId, primaryFieldId, valueId }) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/${valueId}/update/`,
  deletePrimaryFieldValue: ({ spaceId, primaryFieldId, valueId }) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/${valueId}/delete/`,
  restorePrimaryFieldValue: ({ spaceId, primaryFieldId, valueId }) =>
    `/api/generic/space/${spaceId}/primary_fields/${primaryFieldId}/values/${valueId}/restore/`,
  getEntryDatesSecondaryFields: (spaceId: string) =>
    `/api/generic/space/${spaceId}/entry_dates/secondary_fields/`,
  createEntry: (spaceId: string) => `/api/generic/space/${spaceId}/entry/new/`,
  updateEntry: (spaceId: string, entryId: string) =>
    `/api/generic/space/${spaceId}/entry/${entryId}/update/`,
  deleteEntry: (spaceId: string, entryId: string) =>
    `/api/generic/space/${spaceId}/entry/${entryId}/delete/`,

  // Notes
  getNotes: ({
    spaceId,
    dateFrom,
    dateUntil,
  }: {
    spaceId: string;
    dateFrom?: DateStringISO;
    dateUntil?: DateStringISO;
  }) => {
    const baseNoteUrl = `/api/generic/space/${spaceId}/calendar_notes/?`;
    const search = qs.stringify({
      date_from: dateFrom,
      date_until: dateUntil,
    });
    return `${baseNoteUrl}${search}`;
  },
  getNoteDates: (spaceId: string) => `/api/generic/space/${spaceId}/calendar_note_dates/`,
  getNoteTypes: () => '/api/generic/calendar_note_types/',
  getNoteById: (spaceId: string, noteId: string) =>
    `/api/generic/space/${spaceId}/calendar_notes/${noteId}`,
  createNote: (spaceId: string) => `/api/generic/space/${spaceId}/calendar_notes/new/`,
  updateNote: (spaceId: string, noteId: string) =>
    `/api/generic/space/${spaceId}/calendar_notes/${noteId}/`,
  deleteNote: (spaceId: string, noteId: string) =>
    `/api/generic/space/${spaceId}/calendar_notes/${noteId}/delete/`,
};
