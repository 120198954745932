import * as actions from '../actions/menuActions';

type MenuReducerState = {
  opened: boolean;
};

const initialState: MenuReducerState = {
  opened: false,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MENU_OPEN:
      return { ...state, opened: true };
    case actions.MENU_CLOSE:
      return { ...state, opened: false };
    default:
      return state;
  }
};

export default menuReducer;
