import { CalendarFiltersType } from 'Types/commonTypes';
import routeMap from '../../src/route-maps';
import { get, set } from './storageService';
import { DateStringISO } from './timeService';

const calendarFiltersKey = 'calendar_filters';

export interface SetCalendarFiltersParams extends StoreCalendarFiltersParams {
  startDate?: DateStringISO;
  endDate?: DateStringISO;
}

export interface StoreCalendarFiltersParams {
  startDateListView?: DateStringISO;
  endDateListView?: DateStringISO;
  order?: string | null;
  copyOrder?: string | null;
  view?: CalendarViewType;
  fields?: string;
  search?: string;
  quantityMax?: string;
  quantityMin?: string;
}

export enum CalendarViewType {
  Material = 'material',
  Customer = 'customer',
  Minimal = 'minimal',
  List = 'list',
}

export const getCalendarFilters = () => {
  return get<CalendarFiltersType>(calendarFiltersKey);
};

export const getOrdersRoute = () => {
  const filters = getCalendarFilters();

  return filters?.view === CalendarViewType.List ? routeMap.plantOrders : routeMap.plantCalendar;
};

export const storeCalendarFilters = (filters: CalendarFiltersType) => {
  try {
    set(calendarFiltersKey, filters);
  } catch (e) {
    // TODO
  }
};

export const groupByLabelWithoutPrefix = option => {
  return option ? option.replace('x_', '') : option;
};
