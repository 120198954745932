import { Action } from './helper';
import { UserProfile } from 'Types/userTypes';

export enum plantActionTypes {
  SET_USER_PROFILE = '@user/SET_USER_PROFILE',
  TRY_NEW_DESIGN_REQUESTED = '@user/TRY_NEW_DESIGN_REQUESTED',
  TRY_NEW_DESIGN_SUCCEEDED = '@user/TRY_NEW_DESIGN_SUCCEEDED',
  TRY_NEW_DESIGN_FAILED = '@user/TRY_NEW_DESIGN_FAILED',
}

export type UserActions =
  | SetUserProfileAction
  | TryNewDesignRequested
  | TryNewDesignSucceeded
  | TryNewDesignFailed;

export type SetUserProfileAction = Action<plantActionTypes.SET_USER_PROFILE, UserProfile>;
export type TryNewDesignRequested = Action<plantActionTypes.TRY_NEW_DESIGN_REQUESTED, boolean>;
export type TryNewDesignSucceeded = Action<plantActionTypes.TRY_NEW_DESIGN_SUCCEEDED, boolean>;
export type TryNewDesignFailed = Action<plantActionTypes.TRY_NEW_DESIGN_FAILED, unknown>;

export const setUserProfile = (profile: UserProfile): SetUserProfileAction => ({
  type: plantActionTypes.SET_USER_PROFILE,
  payload: profile,
});

export const setTryNewDesign = (payload: boolean): TryNewDesignRequested => ({
  type: plantActionTypes.TRY_NEW_DESIGN_REQUESTED,
  payload,
});

export const setTryNewDesignSucceeded = (payload: boolean): TryNewDesignSucceeded => ({
  type: plantActionTypes.TRY_NEW_DESIGN_SUCCEEDED,
  payload,
});

export const setTryNewDesignFailed = (payload: unknown): TryNewDesignFailed => ({
  type: plantActionTypes.TRY_NEW_DESIGN_FAILED,
  payload,
});
