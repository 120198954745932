import moment from 'moment';
import {
  DateInUnknownTimeZone,
  DateStringISO,
  DEFAULT_NIGHT_SHIFT_FROM_VALUE,
  DEFAULT_NIGHT_SHIFT_TO_VALUE,
  DEFAULT_TIME_FORMAT,
  getDateLocalTimeZone,
  LEGACY_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from './timeService';
import {
  add,
  endOfWeek,
  formatISO,
  isSameDay,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  format,
  getWeek,
  intlFormat,
} from 'date-fns';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const WEEK_DAYS = 7;

export const getWeekNumber = (date: DateStringISO) => getWeek(parseISO(date));

export interface DateRange {
  startDate: DateStringISO;
  endDate: DateStringISO;
}

export function getMonthNumber(date: DateStringISO) {
  return moment(date).month() + 1;
}

export const getFirstAndLastDateOfMonth = (date = new Date()): DateRange => ({
  startDate: moment(date).startOf('month').format(ISO_DATE_FORMAT),
  endDate: moment(date).endOf('month').format(ISO_DATE_FORMAT),
});

export function currentDayNumber(date) {
  return moment(date).dayOfYear();
}

export const isCurrentDay = (date: DateStringISO): boolean => {
  const today = getDateLocalTimeZone(dateObjectToISO(new Date()));
  const dateObject = parseISO(date);
  return isSameDay(today, dateObject);
};

export const isSameDate = (date1: DateStringISO, date2: DateStringISO): boolean => {
  const dateObject1 = parseISO(date1);
  const dateObject2 = parseISO(date2);
  return isSameDay(dateObject1, dateObject2);
};

export const isSameMonthISOString = (
  dateToCheck: DateStringISO,
  dateToCompateWith: DateStringISO,
) => {
  const dateObject1 = parseISO(dateToCheck);
  const dateObject2 = parseISO(dateToCompateWith);

  return isSameMonth(dateObject1, dateObject2);
};

export const addToDate = (date: DateStringISO, duration: Duration): DateStringISO => {
  const dateObject = parseISO(date);
  const finalDate = add(dateObject, duration);
  return dateObjectToISO(finalDate);
};

export const addMonthsToDate = (date: DateStringISO, months: number) => {
  const finalDate = add(parseISO(date), { months: months });
  return dateObjectToISO(finalDate);
};

export const getFirstDayOfMonth = (date: DateStringISO) => {
  const firstDayOfMonth = startOfMonth(parseISO(date));
  return dateObjectToISO(firstDayOfMonth);
};

export const getLastDayOfMonth = (date: DateStringISO) => {
  const lastDayOfMonthDate = lastDayOfMonth(parseISO(date));
  return dateObjectToISO(lastDayOfMonthDate);
};

export const monthNameFromDate = (selectedDate: DateStringISO, monthsAhead = 0): DateStringISO => {
  const futureDate = add(parseISO(selectedDate), { months: monthsAhead });
  return futureDate.toLocaleString(window.navigator.language, { month: 'long' });
};

export const isSameMonth = (dateA = new Date(), dateB = new Date()): boolean => {
  const sameMonth = new Date(dateA).getMonth() === new Date(dateB).getMonth();
  const sameYear = new Date(dateA).getFullYear() === new Date(dateB).getFullYear();
  return sameMonth && sameYear;
};

export const isSameYear = (dateA = new Date(), dateB = new Date()): boolean => {
  return new Date(dateA).getFullYear() === new Date(dateB).getFullYear();
};

export function getFirstWeekOfMonth(date) {
  return moment(date).startOf('month').week();
}

export function getStartDateOfWeekNumber(weekNumber, year): DateStringISO {
  // get the first weekday of the year, so moment set correct week number
  const firstWeekdayOfYear = moment().year(year).startOf('year').weekday(0).format(ISO_DATE_FORMAT);

  return moment(firstWeekdayOfYear).week(weekNumber).format(ISO_DATE_FORMAT);
}

export function toIsoDate(date): DateStringISO {
  return moment(date).format(ISO_DATE_FORMAT);
}

export const dateObjectToISO = (date: Date): DateStringISO => {
  return formatISO(date, { representation: 'date' });
};

export function getYear(date: DateStringISO) {
  return moment(date).year();
}

export const getCurrentYear = () => new Date().getFullYear();

export function getNumberOfWeeksInYear(year: number) {
  return moment(year + '-12-29').week(); // 29th of December is always in the last week of the year
}

export function getNextWorkingDay(date?: DateStringISO) {
  if (date && date !== '' && moment(date).isValid()) {
    const momentDate = moment(date);
    if (momentDate.day() === 5) {
      momentDate.add(3, 'days');
    } else {
      momentDate.add(1, 'days');
    }

    return momentDate.format(ISO_DATE_FORMAT);
  }

  return '';
}

export const getFirstDayOfWeek = (date: DateStringISO) => {
  const dateObject = parseISO(date);
  return dateObjectToISO(startOfWeek(dateObject));
};

export const getLastDayOfWeek = (date: DateStringISO) => {
  const dateObject = parseISO(date);
  return dateObjectToISO(endOfWeek(dateObject));
};

export function localizeDate(date: Date) {
  return intlFormat(
    date,
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    {
      locale: window.navigator.language,
    },
  );
}

//Regex that checks if date format is MM/DD/YYYY
export const isValidUSDateFormat = date => {
  const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  return dateFormat.test(date);
};

export const getMonthNameDayNumber = (date: DateStringISO) => {
  const formattedMonthDay = format(getDateLocalTimeZone(date), 'MMMM d');
  return formattedMonthDay; //@todo add test
};

export function getCalendarType() {
  if (window.navigator.language === 'de') {
    return 'ISO 8601';
  }

  return 'US';
}

export const addBusinessDays = (date, daysToAdd) => {
  const SUNDAY = 0;
  const SATURDAY = 6;

  if (isNaN(parseFloat(daysToAdd)) || !isFinite(daysToAdd)) {
    throw new Error('daysToAdd is not a valida number');
  }

  let remainingDays = Math.abs(daysToAdd);
  const momentDate = moment(date);

  if (!date || !momentDate.isValid()) throw new Error('date param is not a valid date');

  while (remainingDays > 0) {
    momentDate.add(daysToAdd < 0 ? -1 : 1, 'days');
    if (momentDate.day() !== SATURDAY && momentDate.day() !== SUNDAY) {
      remainingDays--;
    }
  }

  return momentDate.format(ISO_DATE_FORMAT);
};

export const getMomentFromDate = (date: Date) => {
  return moment(date);
};

export const getMomentFromDateString = (date: DateStringISO) => {
  return moment(date);
};

export const getDateFromIsoString = (value: DateStringISO): DateInUnknownTimeZone => {
  return moment(value).toDate() as DateInUnknownTimeZone;
};

export const parseDateStringFromServer = date => {
  return moment(date, [SERVER_DATE_FORMAT, LEGACY_DATE_FORMAT]).toDate();
};

export const getStartDate = (currentTime, from, period) => {
  return currentTime.add(from, period).startOf(period).format(LEGACY_DATE_FORMAT);
};

export const getEndDate = (currentTime, from, period) => {
  return currentTime.add(from, period).endOf(period).format(LEGACY_DATE_FORMAT);
};

export const getStartDateOfYear = year => {
  return `01-01-${year}`;
};

export const getEndDateOfYear = year => {
  return `12-31-${year}`;
};

export const isBetweenDates = (
  date: any,
  dateFrom: any = DEFAULT_NIGHT_SHIFT_FROM_VALUE,
  dateTo: any = DEFAULT_NIGHT_SHIFT_TO_VALUE,
) => {
  const momentDate = moment(date, DEFAULT_TIME_FORMAT);
  const momentFrom = moment(dateFrom, DEFAULT_TIME_FORMAT);
  const momentTo = moment(dateTo, DEFAULT_TIME_FORMAT);

  if (!momentDate.isValid()) throw new Error('date param is not a valid date');

  if (momentFrom.hours() > momentTo.hours()) {
    momentTo.add(1, 'day');
  }

  return momentDate.isBetween(momentFrom, momentTo, undefined, '[]');
};

export const getNow = () => new Date();

export const getWeekday = date => {
  return date.toLocaleString(window.navigator.language, { weekday: 'short' });
};

export const getDateISOString = () => moment().format(SERVER_DATE_FORMAT);
