import originalAxios, { AxiosError, AxiosResponse } from 'axios';
import {
  getAccessToken,
  getCSRFToken,
  isAccessTokenExpired,
  removeAccessToken,
} from './utils/authService';
import { store } from 'Redux/store';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { triggerPageLevelAlert } from './redux/actions/pageLevelAlertActions';
import { Alert, AlertType } from './components/alerts/singleAlert';

export const handleRequestFulfilled = config => {
  store.dispatch(showLoading());
  const accessToken = getAccessToken();
  if (accessToken) {
    const expired = isAccessTokenExpired(accessToken);
    if (expired) {
      removeAccessToken();
    }
  }
  return config;
};

export const handleRequestRejected = error => {
  return Promise.reject(error);
};

export const handleResponseFulfilled = function (response: AxiosResponse) {
  store.dispatch(hideLoading());
  return response;
};

export const handleResponseRejected = function (error: AxiosError) {
  store.dispatch(hideLoading());

  const isFormValidationError = error.response?.status === 400;
  const isCanceledError = error.name === 'CanceledError';

  if (!isFormValidationError && !isCanceledError) {
    const alert: Alert = {
      type: AlertType.Error,
      message: error.message,
    };

    store.dispatch(triggerPageLevelAlert(alert));
  }

  return Promise.reject(error);
};

export const getAxios = (axios = originalAxios.create()) => {
  const token = getAccessToken();
  const csrftoken = getCSRFToken();

  if (token) {
    const expired = isAccessTokenExpired(token);
    expired
      ? removeAccessToken()
      : (axios.defaults.headers.common['Authorization'] = 'JWT ' + token);
  }
  if (csrftoken) {
    axios.defaults.headers.common['x-csrftoken'] = csrftoken;
  }

  if (axios.interceptors) {
    axios.interceptors.request.use(handleRequestFulfilled, handleRequestRejected);

    axios.interceptors.response.use(handleResponseFulfilled, handleResponseRejected);
  }
  return axios;
};

export default getAxios();
