export const get = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  if (!item) return null;
  return JSON.parse(item);
};

export const set = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
