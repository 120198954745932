import Button from 'Components/buttons';
import Tooltip from 'Components/Tooltip';
import { pluralize } from '../../utils/pluralize';
import styles from './errorIndicatorStyles.scss';

type ErrorIndicatorProps = {
  show: boolean;
  errors: string[];
  className?: string;
  onClick: () => void;
};

const ErrorIndicator = ({ show, errors, className, onClick }: ErrorIndicatorProps) => {
  if (!show) return null;

  const errorText = pluralize(errors.length, 'Error', 's');

  const getTitleContent = () => {
    return (
      <div className={styles.errorMessageContainer}>
        {errors.map(error => (
          <div>{error}</div>
        ))}
      </div>
    );
  };

  return (
    <div className={className}>
      <Tooltip variant="warning" title={getTitleContent()}>
        <Button
          variant="text"
          label={
            <div className={styles.errorIconContainer}>
              <div className={styles.errorIcon}>!</div>
              <div className={styles.numberOfErrors}>{errorText}</div>
            </div>
          }
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};

export default ErrorIndicator;
