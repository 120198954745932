import { RootState } from 'Redux/reducers';
import { Roles, RolesIds } from 'Services/plantSettingsService';
import {
  Plant,
  PlantId,
  PlantPermissionsOptions,
  PlantsPermissions,
  PlantType,
} from 'Types/plantTypes';
import { EntityAccess } from 'Types/plantSettingsTypes';

export const getCurrentPlantSelector = (state: RootState, plantId: PlantId): Plant | undefined => {
  const plants: Plant[] = state.plants.plants;
  const customerPortals: Plant[] = state.plants.customerPortals;
  const entities = [...plants, ...customerPortals];

  return entities.find(plant => plant.id === plantId);
};

export const getCustomerPortalPlants = (state: RootState, plantId: PlantId): EntityAccess[] => {
  const currentPlant = getCurrentPlantSelector(state, plantId);
  if (!currentPlant || currentPlant.plant_type !== PlantType.Customer) return [];
  const customerPortals: Plant[] = state.plants.customerPortals;

  return customerPortals
    .filter(plant => plant.customer_name === currentPlant.customer_name)
    .map(p => ({
      entityId: p.id,
      entityName: p.name,
      canEditUsers: true,
      roleId: p.id === currentPlant.id ? RolesIds.CustomerContact : undefined,
      roleName: p.id === currentPlant.id ? Roles.CustomerContact : undefined,
    }));
};

export const getCurrentPlantPermissionsSelector = (
  state: RootState,
  plantId: PlantId,
): PlantsPermissions | undefined => {
  const plantsPermissions = state.plants.plantsPermissions;
  const currentPlant = getCurrentPlantSelector(state, plantId);
  const currentPlantPermissions = plantsPermissions.find(pp => pp.plant_id === plantId);
  const sourcePlantPermissions = plantsPermissions.find(
    pp => pp.plant_id === currentPlant?.source_plant,
  );

  const plantPermissions = currentPlantPermissions || sourcePlantPermissions;

  return plantPermissions;
};

export const getPermissionByName = (
  state: RootState,
  plantId: PlantId,
  permission: keyof PlantPermissionsOptions,
) => {
  const currentPlantPermissions = getCurrentPlantPermissionsSelector(state, plantId);
  return currentPlantPermissions?.permissions[permission];
};
