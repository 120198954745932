import { Action } from '../helper';
import { GetInputMaterialProps, ListOrdersParams, ListOrdersResponse } from 'Types/orderTypes';
import { InputOutputReportDataResponse } from 'Types/reportTypes';

export enum OrdersActionTypes {
  ORDERS_FETCH_REQUESTED = '@orders/ORDERS_FETCH_REQUESTED',
  ORDERS_FETCH_SUCCEEDED = '@orders/ORDERS_FETCH_SUCCEEDED',
  ORDERS_FETCH_FAILED = '@orders/ORDERS_FETCH_FAILED',
  LOAD_MORE_ORDERS_REQUESTED = '@orders/LOAD_MORE_ORDERS_REQUESTED',
  LOAD_MORE_ORDERS_SUCCEEDED = '@orders/LOAD_MORE_ORDERS_SUCCEEDED',
  LOAD_MORE_ORDERS_FAILED = '@orders/LOAD_MORE_ORDERS_FAILED',
  ORDERS_INPUT_MATERIALS_SUCCEEDED = '@orders/MATERIALS_INPUT_FETCH_SUCCEEDED',
  ORDERS_INPUT_MATERIALS_FAILED = '@orders/MATERIALS_INPUT_FETCH_FAILED',
  ORDERS_INPUT_MATERIALS_REQUEST = '@orders/MATERIALS_INPUT_FETCH_REQUEST',
  ORDERS_CLEAR_REQUESTED = '@orders/ORDERS_CLEAR_REQUESTED',
}

export const GROUP_BY_MATERIAL = 'materials';
export const RESOLUTION_DAY = 'day';
export const MATERIAL_TYPE_INPUT = 'input';

export type OrderActions =
  | FetchOrdersActionRequested
  | FetchOrdersActionSucceeded
  | FetchOrdersActionFailed
  | LoadMoreOrdersActionRequested
  | LoadMoreOrdersActionSucceeded
  | LoadMoreOrdersActionFailed
  | GetInputMaterialActionRequested
  | GetInputMaterialActionSucceeded
  | GetInputMaterialActionFailed
  | ClearOrdersRequest;

export type FetchOrdersActionRequested = Action<
  OrdersActionTypes.ORDERS_FETCH_REQUESTED,
  ListOrdersParams
>;

export type FetchOrdersActionSucceeded = Action<
  OrdersActionTypes.ORDERS_FETCH_SUCCEEDED,
  ListOrdersResponse
>;

export type FetchOrdersActionFailed = Action<OrdersActionTypes.ORDERS_FETCH_FAILED, unknown>;

export type LoadMoreOrdersActionRequested = Action<
  OrdersActionTypes.LOAD_MORE_ORDERS_REQUESTED,
  ListOrdersParams
>;

export type LoadMoreOrdersActionSucceeded = Action<
  OrdersActionTypes.LOAD_MORE_ORDERS_SUCCEEDED,
  ListOrdersResponse
>;

export type LoadMoreOrdersActionFailed = Action<OrdersActionTypes.LOAD_MORE_ORDERS_FAILED, unknown>;

export type GetInputMaterialActionRequested = Action<
  OrdersActionTypes.ORDERS_INPUT_MATERIALS_REQUEST,
  GetInputMaterialProps
>;

export type GetInputMaterialActionSucceeded = Action<
  OrdersActionTypes.ORDERS_INPUT_MATERIALS_SUCCEEDED,
  InputOutputReportDataResponse
>;

export type GetInputMaterialActionFailed = Action<
  OrdersActionTypes.ORDERS_INPUT_MATERIALS_FAILED,
  unknown
>;

export type ClearOrdersRequest = Action<OrdersActionTypes.ORDERS_CLEAR_REQUESTED, null>;
