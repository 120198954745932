import { all } from 'redux-saga/effects';

import { watchOrders } from './orderSagas';
import watchNotes from './noteSagas';
import watchPlants from './plantSagas';
import watchCalendarFilters from './calendarFilterSagas';
import watchPageLevelAlerts from './pageLevelAlertSagas';
import watchUsers from './userSagas';
import watchSendSupportMessage from './sendSupportMessageSagas';
import watchLogErrors from './logErrorSagas';
import watchSuppliers from './suppliersSagas';
import watchPlantSettings from './plantSettingsSagas';
import watchOrderRequests from './orderRequestSagas';
import watchCustomers from './customerSagas';
import watchSpaces from './spaceSagas';

export default function* rootSaga() {
  yield all([
    watchLogErrors(),
    watchOrders(),
    watchOrderRequests(),
    watchNotes(),
    watchPlants(),
    watchCustomers(),
    watchCalendarFilters(),
    watchPageLevelAlerts(),
    watchUsers(),
    watchSendSupportMessage(),
    watchSuppliers(),
    watchPlantSettings(),
    watchSpaces(),
  ]);
}
