import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'Redux/reducers';
import routeMap from '../../../route-maps/route-map';
import styles from '../profileBox/profileBox.scss';

interface ProfileBoxProps {
  setIsProfileOpen: (state: boolean) => void;
  onClick: () => void;
}

export const ProfileBox = ({ setIsProfileOpen, onClick }: ProfileBoxProps) => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className={styles.profileBox} data-testid="profile-box">
      <div className={styles.userName}>
        {user.firstName} {user.lastName}
      </div>
      <hr width="85%"></hr>
      <div className={styles.links}>
        <Link
          to={routeMap.myProfile}
          className={styles.profileBoxLink}
          onClick={() => {
            setIsProfileOpen(false);
            onClick();
          }}
        >
          {I18n.t('myProfile')}
        </Link>
        <Link
          to={routeMap.logout}
          className={styles.profileBoxLink}
          onClick={() => setIsProfileOpen(false)}
        >
          {I18n.t('logOut')}
        </Link>
      </div>
    </div>
  );
};
