import { DateStringLegacy } from 'Services/timeService';
import { GetCustomersApiParams, PlantId } from 'src/types/plantTypes';
import { getHostName } from '../getHostName';
import * as qs from 'query-string';

const apiMap = {
  login: '/api-token-auth/',
  register: '/api/accounts/register/ ',
  logout: '/api/accounts/logout/',
  registerWithInvitationKey: (invitationKey: string) => `/api/account/register/${invitationKey}`,
  ajaxLogin: '/account/login/ajax/',
  csrfToken: '/account/getCSRFToken/?format=json',
  tryNewDesign: '/account/tryNewDesign/',
  generateNewJwtToken: '/account/generateNewJwtToken/',
  loginStatus: '/account/getLoginStatus/',
  newEmployee: '/account/invite_to/',
  changePassword: '/api/change_password/',
  newSupplier: '/api/supplier/',
  supplierById: supplierId => `/api/supplier/${supplierId}/`,
  allSuppliers: '/api/suppliers_list/?format=json',
  permissions: '/get_permissions/?format=json',
  plants: '/plants/?format=json',
  plantsList: (showInactivePlants = false) =>
    `/api/plants_list/?permissions=view_plant_configuration&show_inactive=${showInactivePlants}&format=json`,
  businesses: '/api/business_list/?format=json',
  membership: '/api/business-user-permissions-list/',
  createOrganization: '/api/business/',
  newPlant: '/api/plant/',
  updatePlant: plantId => `/api/plant/${plantId}/`,
  deletePlant: plantId => `/plant/delete/${plantId}/`,
  activatePlant: plantId => `/plant/restore/${plantId}/`,
  getOrderRequest: plantId => `/plant/${plantId}/order_request/list/`,
  createOrderRequest: plantId => `/plant/${plantId}/order_request/new/`,
  updateOrderRequest: (plantId, requestId) => `/plant/${plantId}/order_request/${requestId}/`,
  approveOrderRequest: (plantId, requestId) =>
    `/plant/${plantId}/order_request/${requestId}/approve/`,
  rejectOrderRequest: (plantId, requestId) =>
    `/plant/${plantId}/order_request/${requestId}/reject/`,
  createOrder: plantId => `/plant/${plantId}/order/new/`,
  updateOrder: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/`,
  deleteOrder: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/delete/`,
  listOrderAttachments: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/attachments/`,
  addOrderAttachment: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/attachments/new/`,
  deleteOrderAttachment: (plantId, orderId, attachmentId) =>
    `/plant/${plantId}/order/${orderId}/attachments/${attachmentId}/`,
  downloadOrderAttachment: (plantId, orderId, attachmentId) =>
    `/plant/${plantId}/order/${orderId}/attachments/${attachmentId}/download/`,
  listOrderLinks: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/links/`,
  addOrderLink: (plantId, orderId) => `/plant/${plantId}/order/${orderId}/links/new/`,
  deleteOrderLink: (plantId, orderId, linkId) =>
    `/plant/${plantId}/order/${orderId}/links/${linkId}/`,
  exportPlantInfoCSV: plantId => `/api/plant/${plantId}/export/orders?format=csv`,
  addNewOrderFields: plantId => `/api/plant/${plantId}/order_field/new/`,
  orderFields: plantId => `/api/plant/${plantId}/order_fields/`,
  renameOrderField: (plantId, orderFieldId) =>
    `/api/plant/${plantId}/order_field/${orderFieldId}/rename/`,
  updateOrderField: (plantId, orderFieldId) => `/api/plant/${plantId}/order_field/${orderFieldId}/`,
  deleteOrderField: (plantId, orderFieldId) =>
    `/api/plant/${plantId}/order_field/${orderFieldId}/delete/`,
  moveOrderField: (plantId, orderFieldId) => `/plant/${plantId}/order_field/${orderFieldId}/move/`,
  orderDateFields: plantId => `/plant/${plantId}/order_date_fields/`,
  renameOrderDateField: (plantId, orderFieldId) =>
    `/plant/${plantId}/order_date_field/${orderFieldId}/rename/`,
  addOrderDateFields: plantId => `/plant/${plantId}/order_date_field/new/`,
  updateOrderDateField: (plantId, orderFieldId) =>
    `/plant/${plantId}/order_date_field/${orderFieldId}/`,
  deleteOrderDateField: (plantId, orderFieldId) =>
    `/plant/${plantId}/order_date_field/${orderFieldId}/delete/`,
  moveOrderDateField: (plantId, orderFieldId) =>
    `/plant/${plantId}/order_date_field/${orderFieldId}/move/`,
  plantMaterials: plantId => `/plant/${plantId}/materials/`,
  plantMaterial: (plantId, materialId) => `/plant/${plantId}/material/${materialId}/`,
  addNewMaterial: plantId => `/plant/${plantId}/material/new/`,
  deleteMaterial: (plantId, materialId) => `/plant/${plantId}/material/${materialId}/delete/`,
  plantMixDesigns: plantId => `/plant/${plantId}/mix_designs/`,
  plantMixDesignById: (plantId, mixDesignId) => `/plant/${plantId}/mix_design/${mixDesignId}/`,
  addMixDesign: plantId => `/plant/${plantId}/mix_design/new/`,
  deleteMixDesign: (plantId, mixDesignId) => `/plant/${plantId}/mix_design/${mixDesignId}/delete/`,
  plantCustomers: (plantId, page, pageSize) => {
    let customersUrl = `/plant/${plantId}/customers/`;

    if (!page || !pageSize) {
      return customersUrl;
    }

    return (customersUrl += `?page=${page}&page_size=${pageSize}`);
  },
  listPlantCustomers: ({
    plantId,
    name,
    page,
    pageSize,
    includeRemoved,
  }: GetCustomersApiParams) => {
    const baseCustomersUrl = `/api/plant/${plantId}/customers/list/?`;
    const search = qs.stringify({
      name,
      page,
      page_size: pageSize,
      include_removed: includeRemoved,
    });

    return `${baseCustomersUrl}${search}`;
  },
  getCustomerFields: (plantId: PlantId) => `/api/plant/${plantId}/customer_fields/`,
  customerById: (plantId: string, customerId: string, includeRemoved?: boolean) => {
    const baseUrl = `/api/plant/${plantId}/customers/${customerId}/`;
    const search = qs.stringify({ include_removed: includeRemoved });
    return `${baseUrl}?${search}`;
  },
  customerDelete: (plantId: string, customerId: string) =>
    `/api/plant/${plantId}/customers/${customerId}/delete/`,
  customerRestore: (plantId: string, customerId: string) =>
    `/api/plant/${plantId}/customers/${customerId}/restore/`,
  subscribers: plantId => `/plant/${plantId}/subscribers/`,
  addNewSubscriber: plantId => `/plant/${plantId}/subscriber/new/`,
  exportPlantCustomers: plantId => `/plant/${plantId}/customers_upload/`,
  plantMemberships: plantId => `/plant/${plantId}/memberships/`,
  plantProductionModes: plantId => `/plant/${plantId}/production_modes/`,
  createProductionMode: plantId => `/plant/${plantId}/production_mode/new/`,
  editProductionMode: (plantId: PlantId, productionModeId: string) =>
    `/plant/${plantId}/production_mode/${productionModeId}/`,
  deleteProductionMode: (plantId: PlantId, productionModeId: string) =>
    `/plant/${plantId}/production_mode/${productionModeId}/delete/`,
  getRoles: '/get_roles/',
  pendingInvitations: '/api/pending_invitations/',
  deletePendingInvitation: (plantId, invitationId) =>
    `/api/plant/${plantId}/invitation/delete/${invitationId}/`,
  getPlantSources: plantId => `/api/plant/${plantId}/order_plans/`,
  getInputOutputReport: (type, plantId, resolution, fromDate, untilDate, groupBy, sourceId) =>
    `/api/plant/${plantId}/report/pandas2/${resolution}/${fromDate}/${untilDate}/?format=json&fields=${groupBy}&source=${type}&sourceId=${sourceId}`,
  getProductionReport: (type, plantId, resolution, dateFrom, dateUntil) =>
    `/api/plant/${plantId}/report/${type}/${resolution}/${dateFrom}/${dateUntil}/?format=json`,
  getDayViewReport: (plantIds, date, sorting) =>
    `/plant/${plantIds}/day_report/${date}/json/?sorting=${sorting}`,
  sendDayViewReport: (plantIds, date, sorting, to) =>
    `/plant/${plantIds}/day_report/${date}/?sorting=${sorting}&to=${to}`,
  graphql: '/graphql',
  note: plantId => `/plant/${plantId}/calendar_note/`,
  quantityRange: plantId => `/plant/${plantId}/order_date_quantity_list/`,
  plantActivity: (plantId, page, pageSize) =>
    `/plant/${plantId}/feed/?page=${page}&page_size=${pageSize}`,
  publicHelpRequest: '/public-help-request',
  createMaterialUrl: plantId => `/api/plant/${plantId}/material/`,
  createMixDesignUrl: plantId => `/api/plant/${plantId}/mix_design/`,
  getInvitationInfo: (invitationKey: string) => `/account/invite/${invitationKey}`,
  getDashboardInfo: (date: DateStringLegacy, plantIds: string) =>
    `/api/dashboard_info/?delivery_on=${date}&plant_ids=${plantIds}`,
  getCustomerPortalsDashboardInfo: (date: DateStringLegacy, plantIds) =>
    `/api/customer_dashboard_info/?delivery_on=${date}&plant_ids=${plantIds}`,

  checkSSODomain: (domain: string) => `/saml_auth/check_domain/?domain=${domain}`,
  loginSSO: (domain: string) => `/saml_auth/login/?domain=${domain}`,

  //WebSocket
  wsConnect: (token: string) => `wss://${getHostName()}/ws/general?token=${token}`,
};

export default apiMap;
