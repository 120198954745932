import { ComponentChild, h } from 'preact';
import cls from 'classnames';

import styles from './inputFieldWrapperStyles.scss';
import capitalize from 'lodash/capitalize';

export enum LabelPosition {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export interface InputFieldWrapperProps {
  label?: ComponentChild | string;
  labelPosition?: LabelPosition;
  labelClassName?: string;
  className?: string;
  htmlFor?: string;
  'data-testid'?: string;
  children: ComponentChild;
}

const InputFieldWrapper = ({
  label,
  labelPosition = LabelPosition.left,
  labelClassName,
  className,
  htmlFor,
  'data-testid': dataTestId = undefined,
  children,
}: InputFieldWrapperProps) => {
  const classes = cls(styles[`inputFieldWrapper${capitalize(labelPosition)}`], className);

  return (
    <div className={classes} data-testid={dataTestId}>
      {label && (
        <label htmlFor={htmlFor} className={cls(styles.fieldLabelWrapper, labelClassName)}>
          {label}
        </label>
      )}
      <div className={cls(styles.inputField, { [styles.fullWidth]: !label })}>{children}</div>
    </div>
  );
};

export default InputFieldWrapper;
