import { useState } from 'preact/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import cls from 'classnames';
import { I18n } from 'react-redux-i18n';

import Button from 'Components/buttons';
import TimePicker from 'Components/fields/TimePicker';
import Checkbox from 'Components/fields/checkbox';
import DatePicker from 'Components/fields/datePicker';
import InputField from 'Components/fields/inputField';
import Tooltip from 'Components/Tooltip';
import InputFieldWrapper, {
  LabelPosition,
} from 'Components/fields/InputFieldWrapper/InputFieldWrapper';
import Textarea from 'Components/fields/Textarea';
import DateQuantityPickerExtraFields from '../DateQuantityPickerExtraFields/DateQuantityPickerExtraFields';

import { convertLegacyToIsoDate } from 'Services/timeService';
import { QuantityPickerDate } from '..';
import { OrderFieldsType, PlantOrderField } from 'Types/plantTypes';
import { Configuration } from 'Types/spaceTypes';
import arrowRight from 'Assets/icons/arr-next.svg';
import arrowDown from 'Assets/icons/arrowDown.svg';
import checkIcon from 'Assets/icons/check-green.svg';

import styles from './dateQuantityPickerRowStyles.scss';

type DateQuantityPickerRowType = {
  date: QuantityPickerDate;
  extraFields: PlantOrderField[];
  selected: boolean;
  calendarOpen?: boolean;
  index: number;
  disabled?: boolean;
  shouldHideDailyOrderFields?: boolean;
  errors?: any[];
  isEntryType?: boolean;
  spaceConfiguration?: Configuration;
  onSelect: (index: number) => void;
};

const DateQuantityPickerRow = ({
  date,
  extraFields,
  selected,
  calendarOpen,
  index,
  disabled,
  shouldHideDailyOrderFields,
  errors,
  isEntryType = false,
  spaceConfiguration = {} as Configuration,
  onSelect,
}: DateQuantityPickerRowType) => {
  const [expanded, setExpanded] = useState(false);
  const methods = useFormContext();
  const shouldShowValue = !isEntryType || spaceConfiguration.value_present;

  const configNames = {
    quantity: {
      label: isEntryType ? spaceConfiguration.value_name : 'quantity',
      fieldName: isEntryType ? 'value' : 'quantity',
    },
    deliveryOn: {
      label: isEntryType ? spaceConfiguration.entry_date_name_single : 'deliveryOn',
      fieldName: isEntryType ? 'date' : 'deliveryOn',
    },
    loadTime: {
      label: isEntryType ? spaceConfiguration.entry_time_name : 'loadTime',
      fieldName: isEntryType ? 'time' : 'loadTime',
    },
  };

  const handleToggleExpandCollapse = () => {
    if (!extraFields.length) return;

    setExpanded(prev => !prev);
  };

  const getTooltipContent = () => {
    const hasValuesInDailyOrderFields = date.extraFields?.some(field => field.value);

    const getTooltipBodyContent = () => {
      if (!extraFields.length) return I18n.t('dateQuantityPicker.noOrderFieldsYetMsg');
      if (!hasValuesInDailyOrderFields) return I18n.t('dateQuantityPicker.clickToAddDetailsMsg');

      if (!!extraFields.length) {
        return (
          <div className={styles.extraFieldsContainer}>
            {date?.extraFields
              .filter(f => f.value)
              .map(dateField => (
                <div className={styles.extraFieldRow}>
                  <span>{dateField.key}</span>
                  <span>
                    {dateField.type === OrderFieldsType.Checkbox ? (
                      <img src={checkIcon} />
                    ) : (
                      dateField.value
                    )}
                  </span>
                </div>
              ))}
          </div>
        );
      }
    };
    return (
      <>
        <div className={styles.tooltipHeader}>{I18n.t('dateQuantityPicker.tooltipTitle')}</div>
        <div className={styles.tooltipBody}>{getTooltipBodyContent()}</div>
      </>
    );
  };

  const renderRowData = () => {
    return (
      <div
        className={cls(
          { [styles.dateRow]: !expanded, [styles.dateRowExpanded]: expanded },
          'grid-row',
        )}
        data-testid="dqp-date-row"
      >
        <div className={cls('grid-col', 'grid-col-md-1', 'phone-visible', styles['col-no-label'])}>
          <div className={cls(styles.th, styles['label-dqp'])}>#</div>
        </div>
        {!shouldHideDailyOrderFields && (
          <div className={cls('grid-col', 'grid-col-md-1', 'ta-center')}>
            <Button
              variant="text"
              icon={expanded ? arrowDown : arrowRight}
              className={styles.expandArrow}
              onClick={handleToggleExpandCollapse}
            />
          </div>
        )}
        <div className={cls('grid-col', 'grid-col-md-1', styles['col-no'], styles.pr0)}>
          <Checkbox
            checked={selected}
            formLabel={<span>{index + 1 + ''}</span>}
            disabled={disabled}
            onChange={() => onSelect(index)}
            other={{ 'data-testid': 'select-date-checkbox' }}
          />
        </div>
        {!isEntryType && (
          <div
            className={cls('grid-col', 'grid-col-md-2', 'phone-visible', styles['col-qty-label'])}
          >
            <div className={cls(styles.th, styles['label-dqp'])}>
              {I18n.t('orderForm.quantity')}
            </div>
          </div>
        )}
        {shouldShowValue && (
          <div className={cls('grid-col', 'grid-col-md-2', styles['col-qty'])}>
            <Controller
              name={`dates.${index}.${configNames.quantity.fieldName}`}
              control={methods.control}
              defaultValue={date.quantity}
              render={({ field }) => {
                return (
                  <InputField
                    type={isEntryType ? 'text' : 'number'}
                    {...field}
                    defaultValue={field.value}
                    other={{ 'data-testid': 'dqp-quantity-input' }}
                    disabled={disabled}
                    hasError={!!errors?.[index]?.[configNames.quantity.fieldName]}
                    errorMessage={errors?.[index]?.[configNames.quantity.fieldName]?.message}
                    onBlur={e => field.onChange(e.target.value || '0')}
                  />
                );
              }}
            />
          </div>
        )}
        <div
          className={cls('grid-col', 'grid-col-md-3', 'phone-visible', styles['col-date-label'])}
        >
          <div className={cls(styles.th, styles['label-dqp'])}>
            {I18n.t('orderForm.deliveryDate')}
          </div>
        </div>
        <div
          className={cls(
            'grid-col',
            { ['grid-col-md-3']: shouldShowValue, ['grid-col-md-4']: !shouldShowValue },
            styles['col-date'],
          )}
        >
          <Controller
            name={`dates.${index}.${configNames.deliveryOn.fieldName}`}
            control={methods.control}
            defaultValue={date[configNames.deliveryOn.fieldName]}
            render={({ field }) => {
              return (
                <DatePicker
                  date={field.value}
                  allowEmptyValue
                  open={index === 0 && calendarOpen} // only first date picker should be open
                  disabled={disabled}
                  hasError={!!errors?.[index]?.[configNames.deliveryOn.fieldName]}
                  errorMessage={errors?.[index]?.[configNames.deliveryOn.fieldName]?.message}
                  onChange={selectedDate => {
                    field.onChange(convertLegacyToIsoDate(selectedDate));
                  }}
                />
              );
            }}
          />
        </div>
        {!isEntryType && (
          <div
            className={cls('grid-col', 'phone-visible', styles['col-time-label'], {
              ['grid-col-md-3']: shouldShowValue,
              ['grid-col-md-4']: !shouldShowValue,
            })}
          >
            <div className={cls(styles.th, styles['label-dqp'])}>{I18n.t('loadTime')}</div>
          </div>
        )}
        <div
          className={cls('grid-col', styles['col-time'], {
            ['grid-col-md-3']: shouldShowValue,
            ['grid-col-md-4']: !shouldShowValue,
          })}
        >
          <Controller
            name={`dates.${index}.${configNames.loadTime.fieldName}`}
            control={methods.control}
            defaultValue={date.loadTime}
            render={({ field }) => {
              return (
                <TimePicker
                  value={field.value}
                  btnLabel={I18n.t('addTime')}
                  show={!!date[configNames.loadTime.fieldName]}
                  disabled={disabled}
                  hasError={!!errors?.[index]?.[configNames.loadTime.fieldName]}
                  errorMessage={errors?.[index]?.[configNames.loadTime.fieldName]?.message}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </div>
        <div className={cls('grid-col', 'grid-col-md-2', 'phone-visible', styles['col-ns-label'])}>
          <div className={cls(styles.th, styles['label-dqp'])}></div>
        </div>
        {!date.loadTime && (
          <div className={cls('grid-col', 'grid-col-md-2', styles['col-ns'])}>
            <Controller
              name={`dates[${index}].nightshift`}
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    checked={value}
                    formLabel={<span>{I18n.t('orderForm.nightShift')}</span>}
                    disabled={disabled}
                    other={{ 'data-testid': 'dqp-nightshift' }}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderControllerField = (dailyField: PlantOrderField, name: string) => {
    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => {
          switch (dailyField.field_type) {
            case OrderFieldsType.Text:
              return <InputField {...field} defaultValue={field.value} disabled={disabled} />;
            case OrderFieldsType.ReadOnly:
              return <InputField {...field} defaultValue={field.value} readOnly />;
            case OrderFieldsType.TextArea:
              return <Textarea {...field} disabled={disabled} />;
            case OrderFieldsType.Checkbox:
              return (
                <Checkbox
                  checked={field.value}
                  formLabel={dailyField.name}
                  disabled={disabled}
                  id={field.name}
                  className="mb-md"
                  margin="none"
                  onChange={field.onChange}
                />
              );
            case OrderFieldsType.Date:
              return (
                <DatePicker
                  date={field.value}
                  selectRange={false}
                  allowEmptyValue={true}
                  showClearButton
                  onClearButtonClick={() => field.onChange('')}
                  onChange={date => field.onChange(convertLegacyToIsoDate(date))}
                  disabled={disabled}
                />
              );
            case OrderFieldsType.Time:
              return (
                <TimePicker
                  value={field.value}
                  show
                  hideOnClear={false}
                  disabled={disabled}
                  onChange={field.onChange}
                />
              );
          }
        }}
      />
    );
  };

  return (
    <div className={cls({ [styles.dateQuantityPickerRowWrapper]: expanded })}>
      {shouldHideDailyOrderFields ? (
        renderRowData()
      ) : (
        <Tooltip
          title={getTooltipContent()}
          delay={1000}
          variant="white"
          shouldRenderTitle={!expanded}
        >
          {renderRowData()}
        </Tooltip>
      )}
      <DateQuantityPickerExtraFields expanded={expanded}>
        {extraFields.map((extraField, fieldIndex) => {
          if (extraField.field_type === OrderFieldsType.Checkbox) {
            return renderControllerField(
              extraField,
              `dates.${index}.extraFields.${fieldIndex}.value`,
            );
          }

          const formLabel =
            extraField.field_type === OrderFieldsType.ReadOnly ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {extraField.name}
                <span className={styles.readOnlyField}>(Read Only)</span>
              </span>
            ) : (
              <span>{extraField.name}</span>
            );
          return (
            <InputFieldWrapper
              label={formLabel}
              labelPosition={LabelPosition.top}
              className="mb-md"
            >
              {renderControllerField(extraField, `dates.${index}.extraFields.${fieldIndex}.value`)}
            </InputFieldWrapper>
          );
        })}
      </DateQuantityPickerExtraFields>
    </div>
  );
};

export default DateQuantityPickerRow;
