import { h } from 'preact';
import ContentLoader from 'react-content-loader';

const MyContentLoader = ContentLoader as any;

const OrderModalLoader = () => (
  <MyContentLoader height="370px" width="100%" preserveAspectRatio="none">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="40"></rect>
    <rect x="0" y="60" rx="4" ry="4" width="49%" height="40"></rect>
    <rect x="50%" y="60" rx="3" ry="3" width="50%" height="40"></rect>
    <rect x="0" y="110" rx="3" ry="3" width="100%" height="160"></rect>
    <rect x="0" y="280" rx="4" ry="4" width="49%" height="40"></rect>
    <rect x="50%" y="280" rx="3" ry="3" width="50%" height="40"></rect>
    <rect x="0" y="330" rx="4" ry="4" width="49%" height="40"></rect>
    <rect x="50%" y="330" rx="3" ry="3" width="50%" height="40"></rect>
  </MyContentLoader>
);

export default OrderModalLoader;
