import {
  OrderRequestActions,
  OrderRequestsActionTypes,
} from 'Redux/actions/actionTypes/orderRequestActionTypes';
import { RequestStatusType } from 'Types/commonTypes';
import { OrderRequestsReducerState } from 'Types/orderRequestTypes';

const initialState: OrderRequestsReducerState = {
  orderRequests: {
    data: [],
    nextPage: '',
    totalCount: 0,
    totalPages: 0,
  },
  producedMaterials: [],
  requestCount: 0,
};

const orderRequestReducer = (
  state = initialState,
  action: OrderRequestActions,
): OrderRequestsReducerState => {
  switch (action.type) {
    case OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_REQUESTED:
      return { ...state, fetchingOrderRequestsStatus: RequestStatusType.InProgress };
    case OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_SUCCEEDED:
      const orderRequests = action.payload;

      return {
        ...state,
        orderRequests: {
          ...state.orderRequests,
          data: orderRequests.items,
          nextPage: orderRequests.nextPage,
          totalCount: orderRequests.totalCount,
          totalPages: orderRequests.totalPages,
        },
        fetchingOrderRequestsStatus: RequestStatusType.Completed,
        fetchingOrderRequestsError: null,
      };
    case OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_FAILED:
      return {
        ...state,
        fetchingOrderRequestsStatus: RequestStatusType.Completed,
        fetchingOrderRequestsError: action.payload,
      };

    case OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_REQUESTED:
      return { ...state, fetchingProducedMaterialsStatus: RequestStatusType.InProgress };
    case OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_SUCCEEDED:
      return {
        ...state,
        producedMaterials: action.payload,
        fetchingProducedMaterialsStatus: RequestStatusType.Completed,
        fetchingProducedMaterialsError: null,
      };
    case OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_FAILED:
      return {
        ...state,
        producedMaterials: [],
        fetchingProducedMaterialsStatus: RequestStatusType.Completed,
        fetchingOrderRequestsError: action.payload,
      };
    case OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_REQUESTED:
      return { ...state, fetchingOrderRequestCountStatus: RequestStatusType.InProgress };
    case OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_SUCCEEDED:
      return {
        ...state,
        requestCount: action.payload,
        fetchingOrderRequestCountStatus: RequestStatusType.Completed,
        fetchingOrderRequestCountError: null,
      };
    case OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_FAILED:
      return {
        ...state,
        requestCount: 0,
        fetchingOrderRequestCountStatus: RequestStatusType.Completed,
        fetchingOrderRequestCountError: action.payload,
      };
    default:
      return state;
  }
};

export default orderRequestReducer;
