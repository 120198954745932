import { useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import DashboardCard from '../DashboardCard';
import Organization from '../Organization';
import Button from 'Components/buttons';
import {
  getDashboardInfo,
  getOrganizationPlantsState,
  OrganizationPlants,
  saveOrganizationStateToLocalStorage,
} from 'Services/dashboardService';
import { RootState } from 'Redux/reducers';
import { useDashboard } from '../useDashboard';

import styles from './plantsStyles.scss';

const Plants = ({ date }) => {
  const plants = useSelector((state: RootState) => state.plants.plants);
  const [organizationCollapseState, setOrganizationCollapseState] = useState(
    getOrganizationPlantsState(plants),
  );

  const { plantData, loading, setRef } = useDashboard({
    date,
    getDashboardInfoService: getDashboardInfo,
  });

  const handleExpandCollapseAll = (isOpen: boolean) => {
    const newState = Object.keys(organizationCollapseState).reduce((acc, organizationId) => {
      const organization = organizationCollapseState[organizationId];
      return { ...acc, [organizationId]: { ...organization, expanded: isOpen } };
    }, {} as OrganizationPlants);
    setOrganizationCollapseState(newState);
    saveOrganizationStateToLocalStorage(newState);
  };

  const handleExpandCollapse = (organizationId: string, isOpen: boolean) => {
    const newState: OrganizationPlants = {
      ...organizationCollapseState,
      [organizationId]: { ...organizationCollapseState[organizationId], expanded: isOpen },
    };
    setOrganizationCollapseState(newState);
    saveOrganizationStateToLocalStorage(newState);
  };

  return (
    <div data-testid="plants-dashboard">
      <div className={styles.buttonsWrapper}>
        <Button
          color="outline"
          label="Expand All"
          className={styles.expandCollapseBtn}
          onClick={() => handleExpandCollapseAll(true)}
        />
        <Button
          color="outline"
          label="Collapse All"
          className={styles.expandCollapseBtn}
          onClick={() => handleExpandCollapseAll(false)}
        />
      </div>
      {Object.keys(organizationCollapseState).map(organizationId => {
        const organization = organizationCollapseState[organizationId];
        const organizationPlants = organization.plants;
        return (
          <Organization
            organizationName={organization.organizationName}
            open={organization.expanded}
            onCollapse={isOpen => handleExpandCollapse(organizationId, isOpen)}
            key={organizationId}
          >
            {organizationPlants.map(plant => {
              return (
                <DashboardCard
                  key={plant.id}
                  entity={plant}
                  plantData={plantData[plant.id]}
                  loading={loading && !plantData[plant.id]}
                  ref={setRef}
                />
              );
            })}
          </Organization>
        );
      })}
      {!plants.length && <p>You do not have access to any Plants</p>}
    </div>
  );
};

export default Plants;
