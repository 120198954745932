import { TranslationKeys } from './en';

export const fr: TranslationKeys = {
  copyright: {
    systemStatus: 'État du système',
  },
  mixPlant: 'Mix Usine',
  aggregatePlant: "Usine d'agrégats",
  pavingSchedule: 'Calendrier de pavage',
  home: 'Maison',
  mesch: 'MeSch Software UG',
  allRights: 'Tous les droits sont réservés. (responsabilité limitée)',
  privacyPolicy: 'Politique de confidentialité',
  termsOfService: "Conditions d'utilisation",
  whatsTheName: "Comment s'appelle le nouvel employé?",
  employeeEmail: "L'adresse e-mail de l'employé:",
  dashboard: 'Tableau de bord',
  paving: 'Pavage',
  plant: 'Usine',
  generalOrderFields: "Champs d'ordre général",
  dailyOrderFields: 'Champs de commande quotidiennes',
  invitation: 'Invitation',
  role: 'Rôle',
  cancel: 'Annuler',
  ok: "D'accord",
  save: 'sauvegarder',
  add: 'Ajouter',
  type: 'Type',
  required: 'Obligatoire',
  visibleToCustomers: 'Visible pour les clients ?',
  export: 'Exporter',
  newField: 'Nouveau champ',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  oldPasswordRequired: "L'ancien mot de passe est requis!",
  newPasswordRequired: 'Le nouveau mot de passe est requis!',
  newPasswordConfirmation: 'Veuillez retaper votre nouveau mot de passe.',
  passwordsDontMatch: 'Les deux champs de mot de passe ne correspondaient pas.',
  passwordMinLength: 'Le mot de passe doit comporter au moins 8 caractères',
  passwordMustBeDifferent: "Le nouveau mot de passe doit être différent de l'ancien mot de passe.",
  passwordChangeDone: 'Votre mot de passe a été changé. Cliquez ici pour pour se déconnecter.',
  nameChangeDone: 'Votre nom a été changé.',
  changePassword: 'Changer le mot de passe',
  password: 'Mot de passe',
  passwordPlaceholder: 'Mot de passe...',
  repeatNewPassword: 'Répété le nouveau mot de passe!',
  repeatPassword: 'Répéter le mot de passe',
  repeatPasswordPlaceholder: 'Répéter le mot de passe...',
  termsAndConditions: 'Commencer mon essai gratuit et accepter les termes et conditions',
  termsAndConditionsRequiredMsg: 'Veuillez accepter les termes et conditions',
  email: 'E-mail',
  emailAddress: 'Adresse e-mail',
  emailPlaceholder: 'E-mail...',
  emailAddressPlaceholder: 'Adresse e-mail',
  subscriberNamePlaceholder: "Nom de l'abonné",
  code: 'Code',
  phone: 'Numéro de téléphone',
  phoneNumber: 'Numéro de téléphone',
  fieldIsRequired: 'Le champ %{fieldName} est obligatoire',
  fieldIsMissing: "%{fieldName} n'existe pas",
  company: 'Compagnie',
  message: 'Message',
  send: 'Envoyer',
  sending: 'Envoi en cours',
  sendInvite: 'Envoyer une invitation',
  sendToMe: 'Envoie moi',
  sendToOrg: 'Envoyer à mon équipe',
  print: 'Imprimer',
  download: 'Télécharger',
  printReport: 'Imprimer le rapport',
  minimumAmountIsRequired: 'Un montant minimum est requis!',
  maximumAmountIsRequired: 'Le montant maximum est requis!',
  currentAmountIsRequired: 'Le montant actuel est requis!',
  nameIsRequired: 'Le nom est requis!',
  selectCountryCode: 'Veuillez sélectionner le code du pays!',
  firstNamePlaceholder: 'Prénom',
  lastNamePlaceholder: 'Nom de famille',
  reports: 'Rapports',
  account: 'Compte',
  referAFriend: 'Référez un ami',
  logOut: 'Se déconnecter',
  organizationMembership: "Membre de l'organisation",
  employeeCount: '%{count} employés',
  members: 'Membres',
  addAnEmployee: 'Ajouter un employé',
  addANewPlant: 'Ajouter une nouvelle usine',
  onlyAddEmployees: 'Vous ne devriez ajouter que les employés de %{name}.',
  onlyAddCurrent: 'Ajouter uniquement les employés actuels',
  inviteNewEmployee: 'Inviter un nouvel employé à %{name}',
  plants: 'Usines',
  schedule: 'Programme',
  edit: 'Éditer',
  create: 'Créer',
  createPlant: 'Créer une usine',
  editPlant: "Modifier l'usine",
  chooseSupplier: 'Choisissez le fournisseur',
  supplier: 'Fournisseur',
  suppliers: 'Fournisseurs',
  materials: 'Matériaux',
  productionMode: 'Mode de fabrication',
  mixDesignLabel: 'Mélange de matériaux',
  activate: 'Activer',
  deactivate: 'Désactiver',
  actions: 'Actions',
  notifications: 'Avis',
  address: 'Adresse',
  name: 'Nom',
  customers: 'Clients',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  saveName: 'Sauvegarder le nom',
  date: 'Date',
  expired: 'Expiré',
  picture: 'Image',
  maxCapacity: 'Capacité maximale',
  plantName: "Nom de l'usine",
  spaceName: 'Space Name',
  plantType: "Type d'usine",
  plantTypePlaceholder: "Sélectionnez le type d'usine",
  search: 'Chercher',
  searchPlaceholder: 'Chercher...',
  nightShift: 'Ronde de nuit',
  nightShiftFrom: 'Ronde de nuit à partir de',
  nightShiftTo: 'Ronde de nuit à',
  inactivePlants: 'Usines inactives',
  activePlants: 'Usines actives',
  tonsPerDay: 'tonnes/jour',
  addPlant: 'Ajouter une usine',
  addInputMaterial: "Ajouter du matériel d'entrée",
  addNewSupplier: 'Ajouter un nouveau fournisseur',
  addSupplier: 'Ajouter un fournisseur',
  editSupplier: 'Modifier le fournisseur',
  deleteSupplier: 'Supprimer le fournisseur',
  deleteSupplierMessage: 'Êtes-vous sûr de vouloir supprimer le fournisseur',
  deleteOrderField: 'Supprimer le champ de commande',
  deleteOrderFieldMessage: 'Voulez-vous vraiment supprimer le champ de commande?',
  yesDelete: 'Oui, supprimer',
  yesDeactivate: 'Oui, désactiver',
  yesActivate: 'Oui, activer',
  yes: 'Oui',
  description: 'Description',
  addEmpoyee: 'Ajouter un employé',
  reactivatePlant: "Réactiver l'usine",
  deactivatePlant: "Désactiver l'usine",
  configureSupplier: 'Configurer le fournisseur',
  configurePlant: "Configurer l'usine",
  billing: 'Facturation',
  changeCard: 'Changer de carte',
  confirm: 'Confirmer',
  aysReactivate: 'Voulez-vous vraiment réactiver %{name}?',
  aysDeactivate: 'Voulez-vous vraiment désactiver %{name}?',
  aysDelete: 'Voulez-vous vraiment supprimer %{name} %{entity}?',
  aysRestore: 'Êtes-vous sûr de vouloir restaurer %{name}?',
  updateEntitySuccessMsg: '%{entity} %{name} a été mis à jour avec succès',
  deleteEntitySuccessMsg: '%{entity} %{name} a été supprimé avec succès',
  toggleNavigation: 'Activer la navigation',
  login: 'Connexion',
  loginSSOBtnLabel: "Connectez-vous avec votre compte d'entreprise",
  signUp: "S'inscrire",
  tryItOut: 'Essaye le!',
  learnMore: 'En savoir plus',
  contact: 'Contact',
  services: 'Services',
  help: 'Aider',
  remove: 'Éliminer',
  deletedPrefix: '[SUPPRIMÉ]',
  inactivePrefix: '[INACTIF]',
  copy: 'Copie',
  verified: 'Vérifié',
  newOrderRequest: 'Nouvelle demande de commande',
  helpButtonText: "BESOIN D'AIDE?",
  supportModalTitle: 'Comment pouvons-nous aider?',
  supportModalInputPlaceholder: 'Écrivez votre question ou votre problème avec le logiciel...',
  supportModalSent: 'Le message a été envoyé avec succès.',
  supportModalError: 'Veuillez nous contacter à support@plantdemand.com',
  loading: 'Chargement en cours...',
  submit: 'Envoyer',
  academy: 'Académie',
  support: 'Appui',
  walkthrough: 'Pas à pas',
  reportBug: 'Signaler un bug',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: 'Mot de passe oublié?',
  staySignedIn: 'Rester connecté',
  logInAgain: 'Connectez-vous à nouveau',
  logInPlantDemand: 'Connectez-vous à PlantDemand',
  passwordRequiredFieldMsg: 'Vous devez spécifier un mot de passe',
  passwordMinLengthErrorMsg: 'Le mot de passe doit comporter au moins %{length} caractères',
  emailRequiredFieldMsg: "L'e-mail est requis",
  newToPD: 'Nouveau sur PlantDemand?',
  newToPDText:
    'Nous sommes heureux de vous voir ici ! Vous pouvez créer un compte et commencer à programmer votre centrale/usine en 4 minutes.',
  externalSupplierNotification:
    "Si vous essayez de donner à un fournisseur externe ou à un client l'accès à vos usines, utilisez plutôt les fonctions de gestion des utilisateurs dans l'onglet de configuration de l'usine.",
  inviteFriend: 'Invite un ami',
  thankYou: 'Merci',
  referAFriendMessage:
    'Nous sommes heureux que vous appréciez PlantDemand et que vous souhaitiez le recommander à vos amis et collègues.',
  thanks: 'Merci',
  reminder: 'Rappel:',
  warningCardTitleOnlyCurrentEmployee: 'Ajouter uniquement les employés actuels',
  warningCardTextOnlyAddEmployee: 'Vous ne devriez ajouter que les employés de',
  warningCardTextUseManagmentFeatures:
    "Si vous essayez de donner à un fournisseur externe ou à un client l'accès à vos usines, utilisez plutôt les fonctions de gestion des utilisateurs dans l'onglet de configuration de l'usine.",
  errorCardText:
    "Il n'y a pas d'usine active dans cette entreprise. Veuillez ajouter une nouvelle usine.",
  pendingInvitations: 'Invitations en attente',
  deleteInvitation: "Supprimer l'invitation?",
  aysDeleteInvitation: "Voulez-vous vraiment supprimer l'invitation de %{user}?",
  inputForecast: "Prévision d'entrée",
  outputForecast: 'Prévision de sortie',
  production: 'Production',
  inventoryForecast: "Prévision d'inventaire",
  dayView: 'Vue quotidienne',
  using: 'Utilisant',
  by: 'Par',
  from: 'De',
  until: "Jusqu'à",
  weekdays: 'Jours de la semaine',
  filters: 'Filtres',
  shortcuts: 'Raccourcis',
  for: 'POUR',
  selectedPlants: '%{count} usines sélectionnées',
  plantsCaps: 'USINES',
  noPlants: 'Aucune usine',
  forAllPlants: 'POUR TOUTES LES USINES',
  forOnePlant: 'POUR UNE USINE',
  thisWeek: 'Cette semaine',
  nextWeek: 'La semaine prochaine',
  nextTwoWeeks: 'Deux semaines',
  threeWeeks: 'Trois semaines',
  thisMonth: 'Ce mois-ci',
  nextMonth: 'Le mois prochain',
  thisQuarter: 'Ce trimestre',
  thisYear: 'Cette année',
  day: 'Jour',
  night: 'Nuit',
  week: 'La semaine',
  month: 'Mois',
  refresh: 'Rafraîchir',
  selectMaterial: 'Sélectionnez le matériau',
  xlsx: 'XLSX',
  youDontHavePlants: "Vous n'avez pas encore d'usines.",
  youCanConfigurePlants: 'Vous pouvez configurer vos usines sur le',
  accountDownCase: 'Compte',
  screen: 'filtrer.',
  youDontHaveOrganisation:
    "Vous n'avez pas encore d'organisation. Si votre entreprise utilise déjà PlantDemand, demandez à votre responsable de vous inviter. Vous pouvez configurer vos usines métiers sur le",
  page: 'page.',
  registerOrganisation: 'Enregistrer une nouvelle organisation',
  askCompanyToConfigureBusiness:
    'Si votre entreprise utilise déjà PlantDemand, demandez à votre responsable de vous inviter. Vous pouvez configurer vos usines métiers sur le',
  availableFields: 'Champs disponibles:',
  updatedMaterialMsg: 'Le matériel a été mis à jour avec succès',
  updatedMaterialFailedMsg: 'Impossible de mettre à jour le matériel',
  deleteMaterialMsg: 'Le matériel a été supprimé avec succès',
  deleteMaterialFailedMsg: 'Impossible de supprimer le matériel',
  addNewSupplierMsg: 'Le fournisseur a été ajouté avec succès',
  groupBy: 'Par groupe:',
  pivotReport: 'Rapport pivot',
  productionReport: 'Rapport de production',
  noReportData: "Il n'y a pas de données pour les dates sélectionnées.",
  materialName: 'Nom du matériau',
  total: 'Total',
  customer: 'Client',
  dates: 'Rendez-vous',
  quantity: 'Quantité',
  loadTime: 'Temps de chargement',
  material: 'Matériel',
  compact: 'Compact',
  classic: 'Classique',
  requestPending: 'Demande en cours',
  obsolete: 'Obsolète',
  list: 'Liste',
  endDailyReport: 'FIN DU RAPPORT QUOTIDIEN POUR',
  addTime: 'Ajouter du temps',
  to: 'À',
  orderBy: 'Commandé par',
  me: 'Moi',
  myOrganization: 'Mon organisation',
  teamNotifications: "Notifications d'équipe",
  dailyReport: 'Rapport journalier',
  plantSettings: 'Paramètres de notification',
  myProfile: 'Mon profil',
  all: 'Tout',
  pleaseSelect: 'Veuillez sélectionner',
  selectAll: 'Tout sélectionner',
  inventory: 'Inventaire',
  desiredRange: 'Gamme souhaitée :',
  minimumInventory: 'Inventaire minimum:',
  maximumInventory: 'Inventaire maximal:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Δ',
  inventoryForecastLegend: 'Δ - Changer ↑ - Production ↓ - Ventes',
  goToPlant: "Aller à l'usine",
  noOrdersOn: 'Aucune commande sur',
  emailSentToMe: "Un e-mail m'a été envoyé",
  emailSentToMyOrganization: "L'e-mail a été envoyé à mon organisation",
  removeNoteConfirmMessage: 'Voulez-vous vraiment supprimer cette note?',
  comment: 'Commentaire',
  updates: 'Mises à jour',
  addComment: 'Ajouter un commentaire',
  commentPlaceholder: 'Tapez votre commentaire',
  dropdownChoosePlaceholder: '- Choisir -',
  showAll: 'Afficher tout',
  showLatest: 'Afficher le plus récent',
  hide: 'Cacher',
  show: 'Spectacle',
  errorTitle: 'Erreur',
  noOptions: 'Aucune option',
  components: 'Composants',
  errorMessage: "Oops! Quelque chose s'est mal passé",
  requestErrorMessage:
    "Une erreur s'est produite. Si vous êtes bloqué, appuyez sur le bouton d'aide.",
  tryNewAppVersionMessage:
    "Nous sommes ravis que vous essayiez la nouvelle version de PlantDemand 🎉 N'hésitez pas à nous envoyer vos commentaires.",
  switchInterfaceMessage: "Passer à l'interface d'origine",
  overProductionTitle: 'Surproduction',
  overProductionMessage: 'Vous dépassez la capacité de %{overProductionCount} pour %{shift}',
  none: 'Aucun',
  showAllOptions: 'Afficher tous les %{count} éléments',
  visibleToCustomersLabel: 'Visible pour les clients?',
  requestMaterial: 'Demander du matériel',
  noTableDataMsg: "Il n'y a pas encore de données",
  reset: 'Réinitialiser',
  dashboardPage: {
    goToPlant: 'Go to plant',
    totalQuantity: 'Total Quantity',
  },
  calendar: {
    addOrderText: 'Ajouter une commande',
    tentative: 'Provisoire',
  },
  checkbox: {
    selectAll: 'Tout sélectionner',
    deselectAll: 'Tout déselectionner',
  },
  calendarWeek: {
    newOrder: 'Nouvelle commande',
    addNote: 'Ajouter une note',
    viewInputMaterial: "Afficher le matériel d'entrée",
    viewDayReport: 'Afficher le rapport quotidien',
    viewEndOfDayReport: 'Afficher le rapport de fin de journée',
  },
  reportsPage: {
    calendarData: 'Données de calendrier',
    groupBy: 'Par groupe',
    pivotBy: 'Faire pivoter par',
    pivotByPlaceholder: 'Pivotez par...',
    presets: 'Préconfigurations...',
    timeFrame: 'Plage de temps',
    sendReportFailedMsg: "Impossible d'envoyer le rapport",
    sendToMyOrg: 'Envoyer à mon organisation',
    sendToMyOrgConfirmMsg:
      'Voulez-vous vraiment envoyer ce rapport à tous les membres de votre organisation?',
    saveInventoryValuesBtn: 'Sauvegarder les valeurs des stocks',
    updateInventoryValuesSuccessMsg: 'Les valeurs des stocks ont été mises à jour',
    updateInventoryValuesErrorMsg: 'Impossible de mettre à jour les valeurs des stocks',
  },
  calendarFilterBar: {
    fields: 'Champs',
    fieldsPlaceholder: 'Champs sélectionnés',
    countFieldsPlaceholder: '%{count} champs',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    view: 'Vue de la commande',
    move: 'Déplacer',
    plants: 'Usines',
    dates: 'Rendez-vous',
    dateRange: 'Plage de dates',
    selectRange: 'Sélectionnez la gamme',
    quantityRange: 'Gamme de quantité',
    min: 'Min',
    max: 'Max',
    goToToday: "Aller à aujourd'hui",
  },
  plantDropdown: {
    plantImageAlt: "Image de l'usine",
  },
  modal: {
    close: 'fermer',
  },
  orderModal: {
    loadOrderDataErrorMsg: 'Impossible de charger les données de la commande',
    createOrderErrorMsg: 'Impossible de créer la commande',
    updateOrderErrorMsg: 'Impossible de mettre à jour la commande',
    deleteOrder: 'Supprimer la commande',
    deleteNote: 'Supprimer la note',
    deleteOrderConfirmationMsg: 'Cette action supprimera %{count}commande(s). Êtes-vous sûr?',
    deleteOrderErrorMsg: 'Impossible de supprimer la commande',
    notifyTeam: "Avertir l'équipe",
    disabledChangePlantTooltipTitle:
      'Veuillez enregistrer cette commande avant de la déplacer vers une autre usine',
    deleteAttachmentText: 'Supprimer la pièce jointe',
    deleteAttachmentQuestion: 'Voulez-vous vraiment supprimer la pièce jointe?',
    deleteAttachmentErrorMsg: 'Impossible de supprimer la pièce jointe',
    downloadAttachmentFailedMsg: 'Impossible de télécharger la pièce jointe',
    deleteLinkText: 'Supprimer le lien',
    deleteLinkQuestion: 'Voulez-vous vraiment supprimer le lien?',
    deleteLinkErrorMsg: 'Impossible de supprimer le lien',
    externalLinks: 'Liens externes',
  },
  orderRequestModal: {
    orderRequest: 'Demande de commande',
    confirmedOrder: 'Commande confirmée',
    urgentRequest: 'Demande urgente',
    rejectRequest: 'Rejeter la demande',
    rejectReason: 'Veuillez fournir une raison pour rejeter la demande.',
    noRejectReason: "Aucun motif de rejet n'a été fourni",
  },
  noteModal: {
    addNoteDate: 'Ajouter une date de note',
    noteDate: 'Notez la date',
    plant_closure: "Fermeture de l'usine",
    space_closure: "Fermeture de l'espace",
    custom: 'Note',
    unknown: 'Inconnue',
    crew: 'Équipage / Temps libre',
    maintenance: 'Entretien',
    alert: 'Alerte',
    inspection: 'Inspection',
    over_capacity: 'Capacité excessive',
    other: 'Autre',
    fieldError: 'Ce champ est requis',
    descriptionLabel: 'La description *',
    descriptionPlaceholder: 'Remarque descriptif...',
    titleLabel: 'Titre',
    titlePlaceholder: 'Client',
    typeLabel: 'Type de note',
    typePlaceholder: "Fermeture de l'usine",
    serverErrorText1:
      "Malheureusement, nous n'avons pas pu enregistrer votre note. Notre serveur a donné l'erreur suivante",
    serverErrorText2: 'Si ce problème persiste, <a>cliquez ici</a> pour contacter PlantDemand',
    notifyTeam: "Avertir l'équipe",
  },
  orderForm: {
    order: 'Ordre',
    addDates: 'Ajouter des dates',
    deliveryDates: 'Dates de livraison',
    quantity: 'Quantité *',
    deliveryDate: 'Date de livraison',
    deliveryTime: 'Heure de livraison',
    nightShift: 'Ronde de nuit',
    create: 'Créer',
    save: 'sauvegarder',
    saving: 'Économie...',
    remove: 'Retirer',
    copy: 'Copie',
    copyOrderMsg:
      'Nous avons créé une copie de la commande n°%{copyOrderId}. Sélectionnez la nouvelle date de livraison et appuyez sur &quot;Enregistrer&quot;',
    moveDates: 'Dates de déménagement',
    movePlant: "Déplacer l'usine",
    changePlant: "Changer d'usine",
    customerVisibleField: 'Ce champ est visible par les clients',
    fieldIsRemoved: 'Ce champ a été supprimé de votre formulaire',
    errorMessage: "Quelque chose s'est mal passé",
    unknownFieldType: 'Type de champ inconnu',
    cancel: 'Annuler',
    reject: 'Reject',
    approve: 'Rejeter',
    dailyOrderFieldsTooltipText:
      'Cliquez sur la flèche pour voir les champs de commande quotidienne',
  },
  dateQuantityPicker: {
    noOrderFieldsYetMsg:
      "Il n'y a pas encore de champs. Accédez à la page Paramètres pour en ajouter un",
    clickToAddDetailsMsg: 'Cliquez ici pour ajouter les détails de la commande quotidienne',
    tooltipTitle: 'Détails quotidiens',
  },
  inputMaterialModal: {
    inputMaterialTitleText: "Matériel d'entrée",
    quantity: 'Quantité',
    supplier: 'Le fournisseur',
    noOrdersMessage:
      "Il n'y a pas de matériel d'entrée car il n'y a pas de commandes prévues à cette date",
    addOrderLink: 'Cliquez pour ajouter une commande.',
    noInputMaterials:
      "<span>Ce %{name} n'a pas de matériel d'entrée.</span> <a>Cliquez ici</a> <span>pour ajouter du matériel d'entrée à %{name}</span>",
    noInputMaterialsForDay: "Il n'y a pas de matériel d'entrée disponible pour cette journée.",
    clickToAddOrder: 'Cliquez ici pour ajouter une nouvelle commande',
    clickToAddMixDesign: 'Cliquez ici pour gérer vos conceptions de mélange.',
  },
  monthNames: {
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Peut',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
  },
  activityList: {
    order: 'Ordre',
    time: 'Temps',
    change: 'Changer',
    user: 'Utilisateur',
    noData: 'Aucun résultat trouvé.',
  },
  ordersList: {
    edit: 'Éditer',
    material: 'Matériel',
    quantity: 'Quantité',
    customer: 'Client',
    deliveryDate: 'Date de livraison',
    createdDate: 'Date de création',
    noData: 'Aucun résultat trouvé.',
    checkAll: '#',
    actions: 'Actions',
  },
  plantHeader: {
    orders: 'Ordres',
    planner: 'Planificateur',
    activity: 'Activité',
    requests: 'Demandes',
    productionSchedule: 'Calendrier de production',
    plantSettings: "Paramètres de l'usine",
    cogIcon: 'Icône de rouage',
    newOrder: 'nouvelle commande',
  },
  accountHeader: {
    account: 'Compte',
    membership: 'Adhésion',
    plants: 'Végétaux',
    customers: 'Client',
    suppliers: 'Fournisseurs',
    billing: 'Facturation',
    notifications: 'Avis',
    myProfile: 'Mon profil',
  },
  moveOrder: {
    selectOrdersHeader: 'Vos commandes sélectionnées',
    selectPlant: "Sélectionnez l'usine",
    selectMaterial: 'Sélectionnez le matériau',
    moveSelectedOrdersBtn: 'Déplacer les commandes sélectionnées',
    copySelectedOrdersBtn: 'Copier les commandes sélectionnées',
    disableBtnMessage: "Veuillez sélectionner l'usine et le matériel cibles",
    disableMoveOrderBtnMessage: 'Veuillez sélectionner au moins un champ',
    keepOriginalOrderLabel: "Conserver la commande d'origine",
    selectMatchingField: 'Supprimer le champ %{fieldName}',
    confirmPlantAndMaterial: "Confirmer l'usine et le matériel",
    moveOrderFrom: 'Déplacer la commande de',
    moveOrderTo: 'Déplacer la commande vers',
    deselectOrderMessage: 'La date de commande a été désélectionnée.',
    undo: 'annuler',
    bubbleMsgHeader: "Déplacez vos champs d'entrée",
    bubbleMsgSubHeader:
      "Faites correspondre les champs de d'entrée pour vous assurer qu'aucune information ne se perd lorsque vous déplacez vos commandes sélectionnées. Sélectionnez au moins un champ de d'entrée pour déplacer cette commande.",
  },
  OrderFormConfigInfoPanel: {
    header:
      "Ici, vous pouvez personnaliser les champs d'entrée de vos formulaires de commande de l'usine:",
    requiredFields: 'Champs obligatoires',
    optionalFields: 'Champs optionnels',
    optionsHeaderExampleText: 'Par exemple, vous pouvez:',
    addTextFieldExampleText: 'Ajouter un champ de texte pour les chargements de camions',
    addTextAreaExampleText: 'Ajouter une zone de texte avec les instructions de ramassage',
    addCheckboxExampleText: 'Ajouter une case à cocher pour afficher les ramassages de nuit',
    visibleToCustomersText:
      'Marquez les champs comme visibles pour le client, afin que les clients puissent afficher et modifier ces champs sur les demandes de commande.',
    fieldOrder: 'Ordre des champs',
    fieldName: 'Nom du champ',
  },
  addInputMaterialPage: {
    materialProperties: 'Propriétés matérielles',
    materialName: 'Nom du matériau',
    supplierProductName: 'Code/nom du produit du fournisseur',
    supplierProductNamePlaceholder: 'Entrez le code/nom du produit du fournisseur',
    inventoryAmount: "Montant de l'inventaire",
    minimum: 'Le minimum',
    maximum: 'Maximum',
    current: 'Courant',
    systemColor: 'Couleur du système',
    materialColor: 'Couleur du matériau',
    isOutputMaterial: 'Matériel de sortie',
    addInputMaterial: "Ajouter du matériel d'entrée",
    materialNamePlaceholder: 'Nom du matériau (ex. 1234 - Nom)',
    deleteMaterial: 'Supprimer le matériel',
    deleteMaterialConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce matériel?',
  },
  addSupplierModal: {
    supplierName: 'Nom du fournisseur',
    supplierAddress: 'Adresse du fournisseur',
  },
  mixDesign: {
    deleteMixDesign: 'Supprimer la conception de mélange',
    deleteMixDesignderConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ceci?',
    updatedMixDesignSucceededMsg: 'La conception de mélange a été mise à jour avec succès',
    updatedMixDesignFailedMsg: 'Impossible de mettre à jour la conception de mélange',
    createdMixDesignSucceededMsg: 'La conception de mélange a été créé avec succès',
    createdMixDesignFailedMsg: 'Impossible de créer une conception de mélange',
    deleteMixDesignSucceededMsg: 'La conception de mélange a été supprimée avec succès',
    deleteMixDesignFailedMsg: 'Impossible de supprimer la conception du mélange',
    addMixDesign: 'Ajouter une conception de mélange',
    addNewMaterial: 'Ajouter du nouveau matériel',
    addInputMaterial: "Ajouter du matériel d'entrée",
    addOutputMaterial: 'Ajouter du matériel de sortie',
    selectOutputMaterial: 'Sélectionnez le matériau de sortie',
    expirationDate: "Date d'expiration",
    specificationBasis: 'Base de spécification',
    addComponent: 'Ajouter un composant',
    percent: 'Pour cent',
    components: 'Composants',
    noComponentsMsg: "Il n'y a pas de composants",
    noMixDesignsMsg: 'Aucune conception de mélange trouvé',
    mixDesignErrorMsg: 'Impossible de charger la conception de mélange',
  },
  materialsPage: {
    noMaterialsMsg: 'Aucun matériel trouvé',
    outputMaterial: 'Matériel de sortie',
  },
  informativePanel: {
    productionMode: 'Mode de fabrication',
    productionModeDescription:
      "Les modes de production produisent des &quot;matières premières&quot;, qui peuvent être commandées directement ou combinées en de nouveaux matériaux à l'aide des &quot;conception de mélange&quot;.",
    rawMaterial: 'Matière première %',
    mixDesign: 'Conception de Mélange',
    mixDesignDescription: 'Combinez des «matières premières» pour créer des «matériaux de sortie».',
  },
  subscribers: {
    subscribers: 'Les abonnés',
    newSubscriber: 'Nouvel abonné',
    addNewSubscriber: 'Ajouter un nouvel abonné',
    infoSection:
      'Vous pouvez ajouter les adresses e-mail des personnes qui recevront les rapports dans le cadre de votre organisation.',
  },
  customersPage: {
    infoSection: 'Exportez cette liste vers une autre usine. Créer un nouveau client',
    viewOrders: 'Afficher les commandes',
    noCustomersMsg: 'Aucun client trouvé',
    targetPlant: 'Usine cible',
    selectTargetPlant: "Sélectionnez l'usine cible",
    exportCustomers: 'Exporter des clients',
    customerName: 'Nom du client',
    customerList: 'Exporter les clients',
    viewAsCustomer: 'Voir comme client',
    addNewCustomer: 'Ajouter un nouveau client',
    company: 'Compagnie',
    customerCompanyName: 'Nom de la société cliente',
    createCustomerPortal: 'Créer un portail client',
    createPortalErrorMsg:
      "Certaines demandes ont échoué. Veuillez réessayer si vous continuez à voir cette erreur, veuillez appuyer sur le bouton d'aide",
    editCustomer: 'Modifier le client',
    deleteCustomer: 'Supprimer le client',
    restoreCustomer: 'Restaurer le client',
    restoreEntitySuccessMsg: 'Le client a été restauré avec succès',
    inactiveCustomerMsg:
      'Ce client est actuellement inactif. Cliquez sur "Restaurer" pour l\'activer à nouveau',
  },
  plantInformation: {
    updatePlantInfoErrorMsg: "Impossible de mettre à jour les informations sur l'usine",
    updatePlantInfoSuccessMsg: "Les informations sur l'usine ont été mises à jour avec succès",
    changePhoto: 'Changer la photo',
    toLabel: 'à',
    exportSectionText1: 'Vos données de PlantDemand vous appartiennent!',
    exportSectionText2:
      "Exportez vos données de commande sous forme de fichier CSV pour effectuer des sauvegardes ou utilisez-les pour créer vos propres rapports Excel. N'oubliez pas de revenir ici pour obtenir la version la plus récente des données.",
    exportSectionText3:
      'Faites un clic droit sur le lien ci-dessous et sélectionnez &quot;Enregistrer la cible sous&quot; ou &quot;Enregistrer le lien sous&quot;;',
    exportCSVFileLabel: 'Exporter le fichier CSV',
    backToPlant: "Retour à l'usine",
    changePlantImgSuccessMsg: "L'image de l'usine a été modifiée avec succès",
    changePlantImgFailedMsg: "Impossible de changer l'image de l'usine",
    exportPlantInfoErrorMsg: "Impossible d'exporter les informations sur l'usine",
  },
  spaceInformation: {
    updateSpaceInfoSuccessMsg: 'Space information has been updated successfully',
    updateSpaceInfoErrorMsg: 'Could not update the space information',
  },
  plantMembers: {
    noMembersFound: 'Aucun membre trouvé',
    editMemberInfo: 'Modifier les informations sur les membres',
    addNewMember: 'Ajouter un nouveau membre',
    name: 'Nom',
    fistNamePlaceholder: 'Prénom du membre',
    lastNamePlaceholder: 'Nom de famille du membre',
    email: 'Adresse e-mail',
    emailPlaceholder: 'Adresse e-mail',
    close: 'fermer',
    remove: 'éliminer',
    save: 'sauvegarder',
    userList: "liste d'utilisateur",
    newEmployeeBtn: 'Nouvel employé',
    employee: 'Employé',
    role: 'Rôle',
    plant: 'Usine',
    plantAccessUserRoles: "Accès à l'usine et rôles d'utilisateur",
    access: 'Accéder',
    noAccessPlaceholder: "Pas d'accès",
    deleteMembershipTitle: "Supprimer l'adhésion",
    deleteMembershipMessage: "Voulez-vous vraiment supprimer l'adhésion?",
    noRoleSelectedMessage: 'Veuillez sélectionner au moins un rôle',
    noPermissionsMessage: "Vous n'êtes pas autorisé à modifier l'accès",
    cannotUpdateYourPermissionsMsg: 'Impossible de mettre à jour vos autorisations',
    cannotDeleteInvitationMessage: "Impossible de supprimer l'invitation",
    updatePermissionsMessage: 'Les autorisations ont été mises à jour avec succès',
    updatePermissionsError: 'Could not update the member permissions',
    removePermissionsError: 'Could not remove the member permissions',
    inviteNewMemberMessage: 'Le membre a été invité avec succès',
    deleteInvitationMessage: "L'invitation a été supprimée avec succès",
    deleteInvitationError: 'Could not delete the invitation',
  },
  plantPermissionsTable: {
    permissionsText: 'Autorisations',
    needCustomPermissionText: "Besoin d'une autorisation personnalisée?",
    contactUs: 'Contactez-nous',
    task: 'Tâche',
    plantManager: "Directeur d'usine",
    dispatch: 'Expédition',
    QA: 'AQ',
    customer: 'Client',
    viewCalendar: 'Afficher le calendrier',
    enterOrders: 'Entrer des commandes',
    viewPlantSettings: "Afficher les paramètres de l'usine",
    changePlantSettings: "Modifier les paramètres de l'usine",
    viewReports: 'Afficher les rapports',
    inviteUsers: 'Inviter des utilisateurs',
    manageOrderPlanner: 'Gérer le planificateur de commandes',
    limited: 'Limité',
  },
  suppliersPage: {
    noSuppliersMsg: 'Aucun fournisseur trouvé',
    cannotCreateSupplier: 'Impossible de créer le fournisseur',
  },
  onBoarding: {
    createCompany: 'Créez votre organisation',
    companyName: "Nom de l'entreprise",
    addFirstPlant: 'Ajoutez votre première usine',
    uploadPlantImage: "Ajouter une photo de l'usine",
    addFirstOutputMaterial: 'Ajoutez votre premier matériel de sortie',
    outputMaterialName: 'Nom du matériau de sortie',
    outputMaterialInfo: "C'est le nom d'un matériel que vous vendez",
    materialSetup: 'Configuration du matériel',
    fileSizeBig:
      "Taille de l'image trop grande. Veuillez redimensionner ou essayer un autre fichier.",
    error: 'Une erreur est survenue',
    isDuplicate: 'Cette valeur a déjà été saisie.',
    toCalendar: 'Enregistrer et Aller au Calendrier',
    toAddMixDesign: 'Enregistrer et Ajouter un autre de Mélange',
  },
  productionModePage: {
    composition: 'Composition',
    hourlyRate: 'Taux horaire',
    defaultHourlyRate: 'Taux horaire par défaut',
    addProductionMode: 'Ajouter un mode de production',
    updatedProductionModeMsg: 'Le mode de production a été mis à jour avec succès',
    cannotUpdateProductionModeMsg: 'Impossible de mettre à jour le mode de production',
    deleteProductionMode: 'Supprimer le mode de production',
    deleteProductionModeConfirmMsg: 'Voulez-vous vraiment supprimer ce mode de production?',
    deleteProductionModeSucceededMsg: 'Le mode de production a été supprimé avec succès',
    deleteProductionModeFailedMsg: 'Impossible de supprimer le mode de production',
    createProductionModeSucceededMsg: 'Le mode de production a été créé avec succès',
    createProductionModeFailedMsg: 'Impossible de créer le mode de production',
  },
  orderRequests: {
    changeOrder: "Change l'ordre",
    reviewRequest: 'Revoir la demande',
    noRequests: "Il n'y a actuellement aucune demande en cours pour cette usine.",
  },
  termsAndConditionsCustomerPortal: 'Créer mon compte gratuit et accepter les',
  termsAndConditionsText: 'Termes et conditions',
  orders: 'ordres',
  nightShiftCaps: 'RONDE DE NUIT',
  minimal: 'Minimale',
  materialCompact: 'Matériau Compact',
  customerRequestNotifications: 'Demandes des clients',
  sendFeedback: 'Envoyer des commentaires',
  customerPortal: 'Portail Clients',
  portal: 'Portail',
  active: 'Actif?',
  configurePortal: 'Vous pouvez configurer le portail client ci-dessous',
  createPortal: 'Créer un portail',
  attachments: 'Attachements',
  customerPortals: 'Portails Clients',
  link: 'Lien',
  movingOrderInProgressMsg: 'Déplacement de %{count} commande(s) en cours...',
  moveOrderSuccessMsg: 'Vous avez déplacé %{count} commande(s) du %{dateFrom} au %{dateTo}',
  moveOrderErrorMsg: 'Impossible de déplacer les commandes',
  orderAttachment: {
    attachFile: 'Attacher un fichier',
  },
  orderFormConfiguration: {
    fieldAddedSuccessfully: 'Le champ a été ajouté avec succès',
    addFieldFailedMsg: "Impossible d'ajouter le champ",
    fieldUpdatedSuccessfully: 'Le champ a été mis à jour avec succès',
    fieldDeletedSuccessfully: 'Le champ a été supprimé avec succès',
    fieldMovedSuccessfully: 'Le champ a été déplacé avec succès',
    noOrderFieldsMsg:
      'Il n\'y a pas encore de champs. Cliquez sur le bouton "Nouveau champ" pour en ajouter un.',
    renameFieldInProgressMsg: "Mise à jour du nom du champ. Attendez s'il vous plaît...",
    renameFieldSuccessMsg: 'Le champ a été renommé avec succès',
    renameFieldFailedMsg: 'Impossible de changer le nom du champ',
  },
  permissionDenied: {
    header: 'Autorisation refusée',
    permissionDeniedMsg: "Vous n'avez pas la permission d'accéder à cette page",
  },
  signUpPage: {
    loginToPlantDemandText: 'Connectez-vous à PlantDemand',
    loginText:
      'Nous sommes heureux de vous voir ici ! Vous pouvez créer un compte et commencer à programmer en 4 minutes.',
    invitationText:
      'Vous avez été invité à PlantDemand pour faciliter la planification des usines. Veuillez terminer la configuration de votre compte pour accéder au calendrier',
    hello: 'Bonjour',
    invitationText1: 'Vous avez été invité à',
    invitationText2: "Veuillez remplir le formulaire d'inscription pour commencer.",
    by: 'par',
    invitationExpiredText:
      'This registration link is expired. Please ask your manager to invite you again or press the "Need Help".',
    newToPlantDemandText: 'Nouveau sur PlantDemand?',
  },
  color: 'Couleur',
  settingsPage: {
    orderFormConfiguration: 'Configuration du formulaire de commande',
    plantInformation: 'Informations sur la plante',
    materialManagement: 'La gestion du matériel',
  },
  customFieldStyles: {
    bold: 'Gras',
    italic: 'Italique',
    underline: 'Souligner',
    strikethrough: 'Barré',
    default: 'Défaut',
    fieldName: 'Nom de domaine',
    dayColor: 'Couleur de quart de jour',
    nightColor: 'Couleur de nuit',
    textFormat: 'Format de texte',
    fieldStyles: 'Styles de champ',
  },
  passwordValidation: {
    email: "S'il vous plaît, mettez une adresse email valide",
    lowerCase: 'Le mot de passe doit contenir des lettres minuscules',
    upperCase: 'Le mot de passe doit contenir une lettre majuscule',
    number: 'Le mot de passe doit contenir un chiffre',
    symbol: 'Le mot de passe doit contenir le symbole',
  },
  passwordRequirements: {
    passwordMustContainMsg: 'Votre mot de passe doit contenir:',
    passwordStrength: 'Fiabilité du mot de passe: ',
    weak: 'Faible',
    strong: 'Fort',
    minLengthMsg: 'Au moins %{length} caractères',
    lowerCaseMsg: 'Au moins une lettre minuscule (a-z)',
    upperCaseMsg: 'Au moins une lettre majuscule (A-Z)',
    numberMsg: 'Au moins un chiffre (0-9)',
    symbolMsg: 'Au moins un symbole (! @ # $ % & + -)',
  },
  signUpComplete: {
    activateAccount: 'Activez votre compte',
    activationMsg1: 'Tu es presque là! Nous avons envoyé un email à',
    activationMsg2:
      "Pour activer votre compte, il vous suffit de cliquer sur le lien fourni dans l'e-mail. Si vous ne le trouvez pas, veuillez vérifier votre dossier spam",
    activationMsg3:
      "Si vous avez besoin d'aide, veuillez nous contacter en cliquant sur le bouton d'aide ci-dessous",
    goToMainPage: 'Aller à la page principale',
  },
  webSocket: {
    reportDataUpdated:
      'Les données du rapport ont été mises à jour. Actualisez la page pour voir les dernières informations',
    orderUpdated:
      'La commande a été mise à jour. Cliquez sur le bouton Actualiser pour recharger les données',
  },
  logoutPage: {
    loggedOut: 'Logged Out',
    thanksForUsingPD: 'Thanks for using PlantDemand',
    signInAgain: 'Sign in again',
    tryAgain: 'Try again',
    backToHome: 'Back to Home page',
    somethingWentWrong: 'Something went wrong',
    logoutError: 'Could not logged you out',
  },
  membership: {
    noPlantsAssigned: 'No plants assigned',
    plantsAndRoles: 'Plants & roles',
  },
};
