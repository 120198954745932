import { DateStringISO, DateStringLegacy, ISOTimeString } from 'Services/timeService';
import { Dictionary, HexColorCode, Optional } from './commonTypes';
import { OrderRequestType } from './orderRequestTypes';
import { Customer, CustomerExtraField, PlantId, PlantOrderField } from './plantTypes';
import { InputOutputReportDataResponse } from './reportTypes';

export type OrderId = string;
export type OrderDateId = string;

export enum formModeType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  COPY = 'COPY',
  READ_ONLY = 'READ_ONLY',
}

export interface OrderState {
  data: OrderDate[];
  totalCount?: number;
  totalPages?: number | null;
  nextPage?: number | null;
  inputMaterials?: InputOutputReportDataResponse;
  fetchingOrdersStatus?: string;
  fetchingOrdersError?: any;
  loadingMoreOrdersStatus?: string;
  loadingMoreOrdersError?: any;
  fetchingInputMaterialsStatus?: string;
  fetchingInputMaterialsErrors?: any;
}

export interface UpdateOrderProps {
  plantId: PlantId;
  orderId: OrderId;
  data: ServerData;
  file: FormData | null;
  links: OrderLink[] | null;
}

export interface ExtraFieldsProps {
  key: string;
  type: string;
  value: string | boolean | null;
  visible_to_customer: boolean | null;
}

export interface UpdateOrderData {
  id: number;
  material: string;
  comment: string;
  customer: string;
  notifyTeam: boolean;
  tentative: boolean;
  dates: OrderDateProps;
}

export interface CreateOrderProps {
  plantId: string;
  data: ServerData;
}

export interface CreateOrderResponse {
  orderId: OrderId;
  deliveryOn: DateStringISO;
}

export interface OrderDateProps {
  id?: string;
  delivery_on: DateStringLegacy;
  load_time: string;
  nightshift: boolean;
  quantity: string;
}

export interface DeleteOrderProps {
  orderId: OrderId;
  plantId: PlantId;
}

export interface GetInputMaterialProps {
  dateFrom: DateStringISO;
  dateUntil: DateStringISO;
  groupBy: string;
  plantId: string;
  resolution: string;
  type: string;
  sourceId: string;
}

export interface OrderExtraField {
  key: string;
  value: string | boolean | null;
  type: string;
  visibleToCustomer: boolean | null;
  deleted?: boolean;
}

export interface BuildSchemaFromOrderFieldsProps {
  plantOrderFields: PlantOrderField[];
  orderExtraFields: OrderExtraField[];
  plantOrderDateFields: PlantOrderField[];
  plantMaterials: PlantMaterial[];
  plantCustomers: Customer[];
  customersLoading?: boolean;
  totalCustomersCount?: number;
  selectedDate?: string;
  formName: string;
  formMode: string;
  plantName: string;
  isCustomerPlant?: boolean;
  disabled?: boolean;
}

export interface PlantMaterial {
  label: string;
  value: string;
}

export interface MapFormDataToOrderProps {
  formData: any;
  orderId?: OrderId;
  orderFields: PlantOrderField[];
  orderDateFields: PlantOrderField[];
  formMode: string;
}

export interface QuantityDate {
  id?: OrderDateId;
  quantity: number;
  deliveryOn: string | null; // date string
  loadTime: ISOTimeString | null;
  nightshift: boolean;
  extraFields: OrderExtraField[];
}

export interface ServerDate {
  id?: string;
  quantity: number;
  delivery_on: string; // legacy date?
  load_time?: string | null;
  nightshift: boolean;
  extra_fields_data: ExtraFieldsProps[];
}

export interface ServerData {
  id?: string;
  customer: string;
  dates: ServerDate;
  material: string;
  tentative: boolean;
  comment?: string;
  notify_team: boolean;
  extra_fields_data: {
    key: string;
    value: string;
    type: string;
    visible_to_customer: boolean;
  }[];
}

export interface OrderField {
  name: string;
  field_type: string;
  visible_to_customer: boolean | null;
  deleted?: boolean;
}

export interface SchemaField {
  [key: string]: {
    [key: string]: any;
  };
}

export interface RequiredFieldsProps {
  plantMaterials: PlantMaterial[];
  plantCustomers: Customer[];
  plantOrderDateFields: PlantOrderField[];
  desiredFutureQuantity?: number;
  customersLoading?: boolean;
  totalCustomersCount?: number;
  selectedDate?: DateStringISO;
  formMode: string;
  isCustomerPlant?: boolean;
  disabled?: boolean;
}

export interface RemoteField {
  [key: string]: {
    title: string;
    type: string;
    remote: boolean;
    disabled?: boolean;
  };
}

export interface MoveOrderParams {
  toPlantId: PlantId;
  toMaterialId: number;
  orderDates: OrderDateId[];
  mapping: string[][];
}

export enum OrderDateSortEnum {
  ORDER_REQUEST_DATES_ASC = 'ORDER_REQUEST_DATES_ASC',
  ORDER_REQUEST_DATES_DESC = 'ORDER_REQUEST_DATES_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  REMOVED_ASC = 'REMOVED_ASC',
  REMOVED_DESC = 'REMOVED_DESC',
  QUANTITY_ASC = 'QUANTITY_ASC',
  QUANTITY_DESC = 'QUANTITY_DESC',
  DELIVERY_ON_ASC = 'DELIVERY_ON_ASC',
  DELIVERY_ON_DESC = 'DELIVERY_ON_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_BY_ASC = 'CREATED_BY_ASC',
  CREATED_BY_DESC = 'CREATED_BY_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  MODIFIED_BY_ASC = 'MODIFIED_BY_ASC',
  MODIFIED_BY_DESC = 'MODIFIED_BY_DESC',
  NIGHTSHIFT_ASC = 'NIGHTSHIFT_ASC',
  NIGHTSHIFT_DESC = 'NIGHTSHIFT_DESC',
  LOAD_TIME_ASC = 'LOAD_TIME_ASC',
  LOAD_TIME_DESC = 'LOAD_TIME_DESC',
  ORDER_ASC = 'ORDER_ASC',
  ORDER_DESC = 'ORDER_DESC',
  PLANT_ASC = 'PLANT_ASC',
  PLANT_DESC = 'PLANT_DESC',
}

export type OrderCustomer = {
  id: string;
  name: string;
  removed: Optional<string>;
  extraFields: CustomerExtraField[];
};

export interface Order {
  id: OrderId;
  tentative: boolean;
  createdAt: string;
  customer: OrderCustomer;
  removed: string | null;
  dates: QuantityDate[];
  extraFields: OrderExtraField[];
  feed: OrderFeed[];
  material: {
    id: string;
    systemColor: HexColorCode;
    name: string;
    removed: string | null;
    active: boolean;
  };
  requests: OrderRequestType[];
}

export type OrderDateOrder = Pick<
  Order,
  'id' | 'tentative' | 'createdAt' | 'customer' | 'extraFields' | 'material'
>;

export interface OrderDate {
  id: OrderId;
  deliveryOn: DateStringISO;
  loadTime: ISOTimeString | null;
  nightshift: boolean;
  quantity: number;
  extraFields: OrderExtraField[];
  order: OrderDateOrder;
}

export interface ListOrdersParams {
  plantId: PlantId;
  dateFrom?: DateStringISO;
  dateUntil?: DateStringISO;
  orderDateIds?: OrderDateId[];
  quantityFrom?: number;
  quantityTo?: number;
  searchString?: string;
  pageSize?: number;
  pageCursor?: number;
  orderBy?: OrderDateSortEnum;
}

export interface ListOrdersResponse {
  items: OrderDate[];
  nextPage: number | null;
  totalCount?: number;
  totalPages: number | null;
}

export interface GetOrderByIdParams {
  plantId: PlantId;
  orderId: OrderId;
}

export interface DeleteOrderAttachmentParams {
  plantId: PlantId;
  orderId: string;
  attachmentId: string;
}
export interface DeleteOrderLinkParams {
  plantId: PlantId;
  orderId: OrderId;
  linkId: string;
}

export interface OrderAttachment {
  file_name: string;
  file_size: string;
  id: string;
  name: string;
  order: OrderId;
}
export interface OrderLink {
  id?: string;
  name: string;
  link: string;
  order?: OrderId;
}

export interface OrderFeed {
  id: string;
  createdAt: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  records: string[];
  comment: string | null;
  messages: { message: string }[];
}

export type CalendarData<T> = Dictionary<{
  dayshift: T[];
  nightshift: T[];
}>;

export type MovableOrdersData = {
  dateFrom: DateStringISO;
  dateTo: DateStringISO;
  materialId: string;
  materialName: string;
  orders: DraggableOrder | DroppableOrder[];
};
export type DraggableOrder = {
  id: OrderId;
  orderId: OrderId;
  customer: string;
  quantity: number;
  loadTime: string | null;
  nightshift: boolean;
  orderExtraFields: OrderExtraField[];
  orderDateExtraFields: OrderExtraField[];
};
export type DroppableOrder = DraggableOrder & { deliveryOn: DateStringISO };
export type DraggableOrdersData = MovableOrdersData & { orders: DraggableOrder[] };
export type DroppableOrdersData = MovableOrdersData & { orders: DroppableOrder[] };

export type MoveOrderPayload = {
  orderId: OrderId;
  customer: string;
  extraFields: OrderExtraField[];
  orderDates: QuantityDate[];
};

export type MoveOrderDatePayload = MovableOrdersData & { orders: MoveOrderPayload[] };

export interface MoveOrCopyOrderDatesProps {
  toPlantId: PlantId;
  toMaterialId: string;
  orderDates: number[];
  customFieldMappings: { fromName: string; toName: string }[];
}
