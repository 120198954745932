import { h, Component, Ref } from 'preact';
import Calendar from 'react-calendar';
import cls from 'classnames';

import SimpleList from 'Components/simpleList/simpleList';
import Button from 'Components/buttons';
import InputField from '../inputField';

import {
  dateObjectToISO,
  getCalendarType,
  getDateFromIsoString,
  getMomentFromDate,
  getMomentFromDateString,
  isSameMonth,
  localizeDate,
  monthNameFromDate,
  getNow,
} from 'Services/dateService';

import { Preset } from 'Types/reportTypes';
import { DateStringISO } from 'Services/timeService';
import calendarArrowPrev from 'Assets/icons/arr-prev.svg';
import calendarArrowNext from 'Assets/icons/arr-next.svg';
import closeIcon from 'Assets/icons/cross.svg';
import circle from 'Assets/icons/circle-o.svg';
import styles from './datePicker.scss';

type BaseDatePickerProps = {
  open?: boolean;
  calendarDatepicker?: boolean;
  name?: string;
  inputFieldId?: string;
  loadingData?: boolean;
  formLabel?: JSX.Element;
  inputRef?: Ref<HTMLInputElement>;
  placeholder?: string;
  label?: string;
  showDoubleView?: boolean;
  datePresetList?: Preset[];
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
  errorMessage?: string;
  selectedPreset?: (selected: Preset) => void;
};

type SingleDateWithValue = {
  selectRange?: false;
  allowEmptyValue?: false;
  date: DateStringISO;
  dateUntil?: never;
  onChange: (date: Date) => void;
  setValue?: (name: string, value: Date) => void;
};

type SingleDateWithEmptyValue = {
  allowEmptyValue: true;
  selectRange?: false;
  date?: DateStringISO;
  dateUntil?: never;
  onChange: (date: Date) => void;
  setValue?: (name: string, value: Date) => void;
};

type DateRangeWithValue = {
  selectRange: true;
  allowEmptyValue?: false;
  date: DateStringISO;
  dateUntil: DateStringISO;
  onChange: (dates: Date[]) => void;
  setValue?: (name: string, value: Date[]) => void;
};

type DateRangeWithEmptyValue = {
  allowEmptyValue: true;
  selectRange: true;
  date?: DateStringISO;
  dateUntil?: DateStringISO;
  onChange: (dates: Date[]) => void;
  setValue?: (name: string, dates: Date[]) => void;
};

type DatePickerWithArrowNavigation = {
  showArrowsNavigation: true;
  onPrevArrowClick: (prevDate: Date) => void;
  onNextArrowClick: (nextDate: Date) => void;
};

type DatePickerWithoutArrowNavigation = {
  showArrowsNavigation?: false;
  onPrevArrowClick?: never;
  onNextArrowClick?: never;
};

type DatePickerWithClearBtn = {
  showClearButton: boolean | true;
  onClearButtonClick: () => void;
};

type DatePickerWithoutClearBtn = {
  showClearButton?: false;
  onClearButtonClick?: never;
};

type DatePickerProps = BaseDatePickerProps &
  (SingleDateWithValue | DateRangeWithValue | SingleDateWithEmptyValue | DateRangeWithEmptyValue) &
  (DatePickerWithoutArrowNavigation | DatePickerWithArrowNavigation) &
  (DatePickerWithClearBtn | DatePickerWithoutClearBtn);

type DatePickerState = {
  date?: Date | Date[];
  dateUntil?: Date;
  open: boolean;
};

class DatePicker extends Component<DatePickerProps, DatePickerState> {
  static defaultProps = {
    allowEmptyValue: false,
    selectRange: false,
    onChange: () => undefined,
    setValue: () => undefined,
  };
  datePickerRef: any;
  state = {
    date: this.getDateState(this.props.date),
    dateUntil: this.getDateState(this.props.dateUntil),
    open: this.props.open || false,
    calendarType: getCalendarType(),
  };

  componentDidUpdate(previousProps: DatePickerProps) {
    const { date, dateUntil, open } = this.props;

    if (previousProps.date !== date) {
      this.setState({
        date: this.getDateState(date),
      });
    }

    if (previousProps.dateUntil !== dateUntil) {
      this.setState({
        dateUntil: this.getDateState(dateUntil),
      });
    }

    if (previousProps.open !== open) {
      this.setState({ open });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  open() {
    if (this.props.disabled) return;

    this.setState({
      open: true,
    });
  }

  getDateState(date?: string): Date | undefined {
    if (date) return getDateFromIsoString(date);

    return !this.props.allowEmptyValue ? getNow() : undefined;
  }

  getLocalizedDate = (date?: Date) => {
    if (!date) {
      return !this.props.allowEmptyValue ? localizeDate(new Date()) : '';
    }

    return localizeDate(date);
  };

  getDateInputValue = () => {
    const { date, dateUntil } = this.state;
    const { selectRange } = this.props;

    const inputDateLocalize = this.getLocalizedDate(date);
    const inputDateUntilLocalize = this.getLocalizedDate(dateUntil);

    if (selectRange && inputDateLocalize && inputDateUntilLocalize) {
      return `${inputDateLocalize} - ${inputDateUntilLocalize}`;
    }

    if (!selectRange && date) {
      return inputDateLocalize;
    }

    return '';
  };

  handleClickOutside = event => {
    if (this.datePickerRef && !this.datePickerRef.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  handleChange = (selectedDate: Date | Date[]) => {
    const { loadingData, name = 'datePicker' } = this.props;

    if (loadingData) return;

    if (!this.props.selectRange && !Array.isArray(selectedDate)) {
      this.setState({
        open: false,
        date: selectedDate,
      });
      this.props.setValue?.(name, selectedDate);
      this.props.onChange(selectedDate);
    } else if (this.props.selectRange && Array.isArray(selectedDate)) {
      this.setState({
        open: false,
        date: selectedDate[0],
        dateUntil: selectedDate[1],
      });

      this.props.setValue?.(name, selectedDate);
      this.props.onChange(selectedDate);
    }
  };

  handleInputDateChange(event: KeyboardEvent) {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;

    const newDate = getMomentFromDateString(value);

    // We check for value.length === 10 because newDate.isValid() returns true if value=2019-03-
    if (value.length === 10 && newDate.isValid()) {
      this.setState({
        date: getDateFromIsoString(value),
      });

      if (event.code === 'Enter' || event.code === 'Escape') {
        if (this.props.selectRange) return;
        this.props.onChange(getDateFromIsoString(value));
        this.setState({
          open: false,
        });
      }
    }
  }

  handleClearButtonClick = () => {
    if (this.props.disabled) return;

    this.setState({ date: undefined, dateUntil: undefined });
    this.props.onClearButtonClick?.();
  };

  formatDate = (date: Date) => {
    const number = date.getDay();
    switch (number) {
      case 1:
        return 'M';
      case 2:
      case 4:
        return 'T';
      case 3:
        return 'W';
      case 5:
        return 'F';
      case 6:
      case 0:
      default:
        return 'S';
    }
  };

  thisMonth() {
    const { loadingData } = this.props;

    if (loadingData || this.props.selectRange) return;

    const newDate = getNow();

    this.setState({
      date: newDate,
    });
    this.props.onChange(newDate);
  }

  prevMonth() {
    const { date = getNow() } = this.state;
    const { loadingData } = this.props;

    if (loadingData || this.props.selectRange) return;

    const newDate = getMomentFromDate(date).subtract(1, 'month').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onChange(newDate);
  }

  prevDay() {
    const { date = getNow() } = this.state;
    const { loadingData } = this.props;

    if (loadingData || this.props.selectRange || !this.props.showArrowsNavigation) return;

    const newDate = getMomentFromDate(date).subtract(1, 'days').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onPrevArrowClick(newDate);
  }

  nextDay() {
    const { date = getNow() } = this.state;
    const { loadingData } = this.props;

    if (loadingData || this.props.selectRange || !this.props.showArrowsNavigation) return;

    const newDate = getMomentFromDate(date).add(1, 'days').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onNextArrowClick(newDate);
  }

  prevWeek() {
    const { date = getNow() } = this.state;
    const { loadingData } = this.props;

    if (loadingData || this.props.selectRange) return;

    const newDate = getMomentFromDate(date).subtract(7, 'days').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onChange(newDate);
  }

  nextWeek() {
    const { loadingData } = this.props;
    const { date = getNow() } = this.state;

    if (loadingData || this.props.selectRange) return;

    const newDate = getMomentFromDate(date).add(7, 'days').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onChange(newDate);
  }

  nextMonth() {
    const { loadingData } = this.props;
    const { date = getNow() } = this.state;

    if (loadingData || this.props.selectRange) return;

    const newDate = getMomentFromDate(date).add(1, 'month').toDate();

    this.setState({
      date: newDate,
    });
    this.props.onChange(newDate);
  }

  getInputDatepicker() {
    const { open, date, dateUntil, calendarType } = this.state;
    const {
      placeholder,
      label,
      inputFieldId,
      showClearButton,
      formLabel,
      inputRef,
      name = 'datePicker',
      showDoubleView,
      showArrowsNavigation,
      selectRange,
      datePresetList,
      selectedPreset,
      disabled,
      hasError,
      errorMessage,
    } = this.props;

    const dateInputValue = this.getDateInputValue();
    return (
      <div
        className={styles.datePickerInputWrapper}
        ref={ref => (this.datePickerRef = ref)}
        data-testid="date-picker-input-wrapper"
      >
        {formLabel}
        <InputField
          formLabel={label}
          placeholder={placeholder}
          value={dateInputValue}
          id={inputFieldId}
          name={name}
          inputClassName={cls(showArrowsNavigation && styles.inputValueCentered)}
          disabled={disabled}
          startAdornment={
            showArrowsNavigation && (
              <Button
                variant="text"
                className={styles.arrows}
                icon={calendarArrowPrev}
                aria-label="start-adornment"
                onClick={this.prevDay.bind(this)}
              />
            )
          }
          endAdornment={
            <>
              {showClearButton && (this.props.date || this.props.dateUntil) && (
                <Button
                  variant="text"
                  icon={closeIcon}
                  className={styles.clearBtn}
                  iconClassName={styles.clearInputValue}
                  aria-label="clear date button"
                  onClick={this.handleClearButtonClick}
                />
              )}
              {showArrowsNavigation && (
                <Button
                  variant="text"
                  className={styles.arrows}
                  icon={calendarArrowNext}
                  aria-label="end-adornment"
                  onClick={this.nextDay.bind(this)}
                />
              )}
            </>
          }
          onFocus={this.open.bind(this)}
          onKeyUp={this.handleInputDateChange.bind(this)}
          onClearButtonClick={this.handleClearButtonClick}
          errorMessage={errorMessage}
          hasError={hasError}
        />
        {inputRef && <input type="hidden" name={name} ref={inputRef} />}

        {open && (
          <div className={styles.filterDatePickerInputOpen} data-testid="date-picker-calendar">
            {datePresetList && (
              <div className={styles.datePresets}>
                <SimpleList listData={datePresetList} onSelect={selectedPreset} />
              </div>
            )}
            <div className={styles.calendar}>
              <Calendar
                showDoubleView={showDoubleView}
                selectRange={selectRange}
                calendarType={calendarType}
                maxDetail="month"
                minDetail="year"
                view="month"
                formatShortWeekday={(locale, date) => this.formatDate(date)}
                onChange={value => this.handleChange(value as Date)}
                value={selectRange ? [date, dateUntil] : date}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  datePickerNavigation = () => {
    const date = this.state.date || getNow();

    return (
      <div className={styles.filterBarNavigation} data-testid="datepicker-navigation">
        <div className={styles.filterBarDate}>
          <h1
            onClick={this.open.bind(this)}
            className={styles.selectedMonth}
            aria-label="selected date"
          >
            {`${monthNameFromDate(dateObjectToISO(date))} ${date.getFullYear()}`}
          </h1>
        </div>
        <div className={styles.navigationBarArrows}>
          <button
            className={styles.btnNavArrow}
            type="button"
            title="Previous"
            onClick={this.prevMonth.bind(this)}
          >
            <img src={calendarArrowPrev} alt="Previous" />
          </button>
          {!isSameMonth(date) && (
            <button
              class={styles.goToCurrentMonth}
              title="This month"
              onClick={this.thisMonth.bind(this)}
            >
              <img src={circle} />
            </button>
          )}
          <button
            className={styles.btnNavArrow}
            type="button"
            title="Next"
            onClick={this.nextMonth.bind(this)}
          >
            <img src={calendarArrowNext} alt="Next" />
          </button>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.calendarDatepicker) {
      return this.getInputDatepicker();
    }

    const { open, calendarType } = this.state;
    const { className } = this.props;

    return (
      <div
        className={cls(styles.datePickerWrapper, className)}
        ref={ref => (this.datePickerRef = ref)}
      >
        {!open ? (
          this.datePickerNavigation()
        ) : (
          <div className={styles.filterDatePickerOpen}>
            {this.datePickerNavigation()}
            <div className={styles.calendarDropdown}>
              <Calendar
                className={styles.filterDatePickerOpen}
                calendarType={calendarType}
                maxDetail="year"
                minDetail="year"
                view="year"
                showNavigation={false}
                formatShortWeekday={(locale, date) => this.formatDate(date)}
                onChange={value => this.handleChange(value as Date)}
                value={this.state.date}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;
