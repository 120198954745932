import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getReloadOrdersParams } from 'Services/orderService';
import {
  createOrderRequest,
  getOrderRequests,
  getProducedMaterials,
} from 'Services/orderRequestService';
import { getPlantOrderRequestCount } from 'Services/orderRequestService';
import {
  CreateOrderRequestActionRequested,
  FetchOrderRequestCountActionRequested,
  LoadOrderRequestActionRequested,
  LoadProducedMaterialsActionRequested,
  OrderRequestsActionTypes,
} from 'Redux/actions/actionTypes/orderRequestActionTypes';
import {
  createOrderRequestFailed,
  createOrderRequestSucceeded,
  fetchOrderRequestsCountFailed,
  fetchOrderRequestsCountSucceeded,
  loadOrderRequests,
  loadOrderRequestsFailed,
  loadOrderRequestsSucceeded,
  loadProducedMaterialsFailed,
  loadProducedMaterialsSucceeded,
} from 'Redux/actions/orderRequestActions';
import { closeModal } from 'Redux/actions/modalActions';
import { CreateOrderResponse, ListOrdersParams } from 'Types/orderTypes';
import { PlantId } from 'Types/plantTypes';
import { ProducedMaterialType, RequestOrderDatesPaginationType } from 'Types/orderRequestTypes';

function* reloadOrderRequests(plantId: PlantId) {
  const calendarFilters = yield select(state => state.calendarFilters);
  const reloadOrdersParams: ListOrdersParams = getReloadOrdersParams(plantId, calendarFilters);
  yield put(loadOrderRequests(reloadOrdersParams));
}

export function* loadOrderRequestsSaga({ payload }: LoadOrderRequestActionRequested) {
  try {
    const response: RequestOrderDatesPaginationType = yield call(getOrderRequests, payload);
    yield put(loadOrderRequestsSucceeded(response));
  } catch (error) {
    yield put(loadOrderRequestsFailed(error));
  }
}

export function* loadProducedMaterialsSaga({ payload }: LoadProducedMaterialsActionRequested) {
  try {
    const response: ProducedMaterialType[] = yield call(getProducedMaterials, payload);
    yield put(loadProducedMaterialsSucceeded(response));
  } catch (error) {
    yield put(loadProducedMaterialsFailed(error));
  }
}

export function* createOrderRequestSaga({ payload }: CreateOrderRequestActionRequested) {
  try {
    const response: CreateOrderResponse = yield call(createOrderRequest, payload);
    yield put(createOrderRequestSucceeded(response));

    yield reloadOrderRequests(payload.plantId);
    yield put(closeModal());
  } catch (error) {
    yield put(createOrderRequestFailed(error));
  }
}

export function* fetchOrderRequestsCountSaga({ payload }: FetchOrderRequestCountActionRequested) {
  try {
    const response = yield call(getPlantOrderRequestCount, payload);
    yield put(fetchOrderRequestsCountSucceeded(response));
  } catch (error) {
    yield put(fetchOrderRequestsCountFailed(error));
  }
}

export default function* watchOrderRequests() {
  yield takeLatest(OrderRequestsActionTypes.LOAD_ORDER_REQUESTS_REQUESTED, loadOrderRequestsSaga);
  yield takeLatest(OrderRequestsActionTypes.CREATE_ORDER_REQUEST_REQUESTED, createOrderRequestSaga);
  yield takeLatest(
    OrderRequestsActionTypes.FETCH_ORDER_REQUEST_COUNT_REQUESTED,
    fetchOrderRequestsCountSaga,
  );
  yield takeLatest(
    OrderRequestsActionTypes.LOAD_PRODUCED_MATERIALS_REQUESTED,
    loadProducedMaterialsSaga,
  );
}
