export type TranslationKeys = typeof en;

export const en = {
  copyright: {
    systemStatus: 'System Status',
  },
  mixPlant: 'Mix Plant',
  aggregatePlant: 'Aggregate Plant',
  pavingSchedule: 'Paving Schedule',
  customer: 'Customer',
  home: 'Home',
  mesch: 'MeSch Software UG',
  allRights: '(Haftungsbeschränkt). All Rights Reserved.',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Service',
  whatsTheName: "What's the new employee's name?",
  employeeEmail: "The employee's e-mail address:",
  dashboard: 'Dashboard',
  paving: 'Paving',
  plant: 'Plant',
  generalOrderFields: 'General order fields',
  dailyOrderFields: 'Daily order fields',
  invitation: 'Invitation',
  role: 'Role',
  cancel: 'Cancel',
  ok: 'Ok',
  save: 'Save',
  add: 'Add',
  type: 'Type',
  required: 'Required',
  visibleToCustomers: 'Visible to customers?',
  export: 'Export',
  newField: 'New field',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm New Password',
  oldPasswordRequired: 'The old password is required!',
  newPasswordRequired: 'The new password is required!',
  newPasswordConfirmation: 'Please type again your new password.',
  passwordsDontMatch: "The two password fields didn't match.",
  passwordMinLength: 'Password must have at least 8 characters',
  passwordMustBeDifferent: 'The new password needs to be different from the old password.',
  passwordChangeDone: 'Your password was changed. Click here to log out.',
  nameChangeDone: 'Your name was changed.',
  changePassword: 'Change password',
  password: 'Password',
  passwordPlaceholder: 'Password...',
  repeatNewPassword: 'Repeat new password!',
  repeatPassword: 'Repeat Password',
  repeatPasswordPlaceholder: 'Repeat Password...',
  termsAndConditions: 'Start my Free Trial and accept the ',
  termsAndConditionsCustomerPortal: 'Create my free acount and accept the ',
  termsAndConditionsText: 'Terms and Conditions',
  termsAndConditionsRequiredMsg: 'Please accept the Terms and Conditions',
  email: 'Email',
  emailAddress: 'Email Address',
  emailPlaceholder: 'Email...',
  emailAddressPlaceholder: 'Email Address',
  subscriberNamePlaceholder: 'Subscriber Name',
  code: 'Code',
  phone: 'Phone',
  phoneNumber: 'Phone number',
  fieldIsRequired: '%{fieldName} field is required',
  fieldIsMissing: '%{fieldName} does not exist',
  company: 'Company',
  message: 'Message',
  send: 'Send',
  sending: 'Sending',
  sendInvite: 'Send invite',
  sendToMe: 'Send To Me',
  sendToOrg: 'Send To My Team',
  print: 'Print',
  download: 'Download',
  printReport: 'Print Report',
  minimumAmountIsRequired: 'Minimum amount is required!',
  maximumAmountIsRequired: 'Maximum amount is required!',
  currentAmountIsRequired: 'Current amount is required!',
  nameIsRequired: 'Name is required!',
  selectCountryCode: 'Please select country code!',
  firstNamePlaceholder: 'First name',
  lastNamePlaceholder: 'Last name',
  reports: 'Reports',
  account: 'Account',
  referAFriend: 'Refer a friend',
  logOut: 'Log-out',
  organizationMembership: 'Organization Membership',
  employeeCount: '%{count} employees',
  members: 'Members',
  addAnEmployee: 'Add an Employee',
  addANewPlant: 'Add a new plant',
  onlyAddEmployees: 'You should only be adding employees of %{name}.',
  onlyAddCurrent: 'Only add current employees',
  inviteNewEmployee: 'Invite a new employee to %{name}',
  plants: 'Plants',
  schedule: 'Schedule',
  edit: 'Edit',
  create: 'Create',
  createPlant: 'Create plant',
  editPlant: 'Edit plant',
  chooseSupplier: 'Choose supplier',
  supplier: 'Supplier',
  suppliers: 'Suppliers',
  materials: 'Materials',
  productionMode: 'Production Mode',
  mixDesignLabel: 'Mix Design',
  orders: 'Orders',
  activate: 'Activate',
  deactivate: 'Deactivate',
  actions: 'Actions',
  notifications: 'Notifications',
  address: 'Address',
  name: 'Name',
  customers: 'Customers',
  firstName: 'First Name',
  lastName: 'Last Name',
  saveName: 'Save Name',
  date: 'Date',
  expired: 'Expired',
  picture: 'Picture',
  maxCapacity: 'Max Capacity',
  plantName: 'Plant Name',
  spaceName: 'Space Name',
  plantType: 'Plant Type',
  plantTypePlaceholder: 'Select Plant type',
  search: 'Search',
  searchPlaceholder: 'Search...',
  nightShift: 'Night Shift',
  nightShiftCaps: 'NIGHT SHIFT',
  nightShiftFrom: 'Night Shift From',
  nightShiftTo: 'Night Shift To',
  inactivePlants: 'Inactive Plants',
  activePlants: 'Active Plants',
  tonsPerDay: 'tons/day',
  addPlant: 'Add Plant',
  addInputMaterial: 'Add input material',
  addNewSupplier: 'Add new supplier',
  addSupplier: 'Add Supplier',
  editSupplier: 'Edit Supplier',
  deleteSupplier: 'Delete Supplier',
  deleteSupplierMessage: 'Are you sure you want to delete the supplier ',
  deleteOrderField: 'Delete order form field',
  deleteOrderFieldMessage: 'Are you sure you want to delete the order field?',
  yesDelete: 'Yes, Delete',
  yesDeactivate: 'Yes, Deactivate',
  yesActivate: 'Yes, Activate',
  yes: 'Yes',
  description: 'Description',
  addEmpoyee: 'Add Employee',
  reactivatePlant: 'Reactivate Plant',
  deactivatePlant: 'Deactivate Plant',
  configureSupplier: 'Configure supplier',
  configurePlant: 'Configure Plant',
  billing: 'Billing',
  changeCard: 'Change Card',
  confirm: 'Confirm',
  aysReactivate: 'Are you sure you want to reactivate %{name}?',
  aysDeactivate: 'Are you sure you want to deactivate %{name}?',
  aysDelete: 'Are you sure you want to delete %{name} %{entity}?',
  aysRestore: 'Are you sure you want to restore %{name}?',
  updateEntitySuccessMsg: '%{entity} %{name} has been updated successfully',
  deleteEntitySuccessMsg: '%{entity} %{name} has been deleted successfully',
  toggleNavigation: 'Toggle navigation',
  login: 'Log-in',
  loginSSOBtnLabel: 'Sign in with your company account',
  signUp: 'Sign up',
  tryItOut: 'Try it out!',
  learnMore: 'Learn More',
  contact: 'Contact',
  services: 'Services',
  help: 'Help',
  remove: 'Remove',
  deletedPrefix: '[DELETED]',
  inactivePrefix: '[INACTIVE]',
  copy: 'Copy',
  verified: 'Verified',
  newOrderRequest: 'New Order Request',
  helpButtonText: 'NEED HELP?',
  supportModalTitle: 'How can we help?',
  supportModalInputPlaceholder: 'Write out your question or problem with the software...',
  supportModalSent: 'The message was sent successfully.',
  supportModalError: 'Please contact us at support@plantdemand.com',
  loading: 'Loading...',
  submit: 'Submit',
  academy: 'Academy',
  support: 'Support',
  walkthrough: 'Walkthrough',
  reportBug: 'Report a Bug',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: 'Forgot password?',
  staySignedIn: 'Stay Signed In',
  logInAgain: 'Log in again',
  logInPlantDemand: 'Log-in to PlantDemand',
  passwordRequiredFieldMsg: 'You must specify a password',
  passwordMinLengthErrorMsg: 'Password must have at least %{length} characters',
  emailRequiredFieldMsg: 'Email is required',
  newToPD: 'New to PlantDemand?',
  newToPDText:
    'We are happy to see you here! You can create an account and start scheduling in 4 minutes.',
  externalSupplierNotification:
    'If you are trying to give an external supplier or customer access to your plants, use the user management features on the plant configuration tab instead.',
  inviteFriend: 'Invite a friend',
  thankYou: 'Thank You ',
  referAFriendMessage:
    "We're glad you're enjoying PlantDemand and want to refer it to your friends and colleagues.",
  thanks: 'Thanks',
  reminder: 'Reminder:',
  warningCardTitleOnlyCurrentEmployee: 'Only add current employees',
  warningCardTextOnlyAddEmployee: 'You should only be adding employees of ',
  warningCardTextUseManagmentFeatures:
    'If you are trying to give an external supplier or customer access to your plants, use the user management features on the plant configuration tab instead.',
  errorCardText: "There are no active plant's in this business. Please add a new plant.",
  pendingInvitations: 'Pending invitations',
  deleteInvitation: 'Delete invitation?',
  aysDeleteInvitation: 'Are you sure you want to delete the invitation for %{user}?',
  inputForecast: 'Input Forecast',
  outputForecast: 'Output Forecast',
  production: 'Production',
  inventoryForecast: 'Inventory Forecast',
  dayView: 'Day View',
  using: 'Using',
  by: 'By',
  from: 'From',
  until: 'Until',
  weekdays: 'Weekdays',
  filters: 'Filters',
  shortcuts: 'Shortcuts',
  for: 'FOR',
  selectedPlants: '%{count} plants selected',
  plantsCaps: 'PLANTS',
  noPlants: 'No plants',
  forAllPlants: 'FOR ALL PLANTS',
  forOnePlant: 'FOR ONE PLANT',
  thisWeek: 'This Week',
  nextWeek: 'Next Week',
  nextTwoWeeks: 'Two Weeks',
  threeWeeks: 'Three Weeks',
  thisMonth: 'This Month',
  nextMonth: 'Next Month',
  thisQuarter: 'This Quarter',
  thisYear: 'This Year',
  day: 'Day',
  night: 'Night',
  week: 'Week',
  month: 'Month',
  refresh: 'Refresh',
  selectMaterial: 'Select Material',
  xlsx: 'XLSX',
  youDontHavePlants: "You don't have any plants yet.",
  youCanConfigurePlants: 'You can configure your plants on the ',
  accountDownCase: ' account',
  screen: ' screen.',
  youDontHaveOrganisation:
    "You don't have an organization yet. If your company is already using PlantDemand ask your manager to invite you. You can configure your businesses plants on the ",
  page: ' page.',
  registerOrganisation: 'Register a new organization',
  askCompanyToConfigureBusiness:
    'If your company is already using PlantDemand ask your manager to invite you. You can configure your businesses plants on the ',
  availableFields: 'Avaliable fields:',
  updatedMaterialMsg: 'The material was updated successfully',
  updatedMaterialFailedMsg: 'Cannot update material',
  deleteMaterialMsg: 'The material was deleted successfully',
  deleteMaterialFailedMsg: 'Cannot delete material',
  addNewSupplierMsg: 'The supplier was added successfully',
  groupBy: 'Group by:',
  pivotReport: 'Pivot Report',
  productionReport: 'Production Report',
  noReportData: 'There is no data for the selected dates.',
  materialName: 'Material Name',
  total: 'Total',
  dates: 'Dates',
  quantity: 'Quantity',
  loadTime: 'Load Time',
  material: 'Material',
  compact: 'Compact',
  classic: 'Classic',
  minimal: 'Minimal',
  materialCompact: 'Material Compact',
  requestPending: 'Request pending',
  obsolete: 'Obsolete',
  list: 'List',
  endDailyReport: 'END THE DAILY REPORT FOR',
  addTime: 'Add Time',
  to: 'To',
  orderBy: 'Order By',
  me: 'Me',
  myOrganization: 'My Organization',
  teamNotifications: 'Team Notifications',
  customerRequestNotifications: 'Customer Requests',
  dailyReport: 'Daily Report',
  plantSettings: 'Notifications Settings',
  myProfile: 'My Profile',
  all: 'All',
  pleaseSelect: 'Please Select',
  selectAll: 'Select All',
  inventory: 'Inventory',
  desiredRange: 'Desired range:',
  minimumInventory: 'Minimum inventory:',
  maximumInventory: 'Maximum inventory:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Δ',
  inventoryForecastLegend: 'Δ - Change ↑ - Production ↓ - Sales',
  goToPlant: 'Go to plant',
  noOrdersOn: 'No Orders on',
  emailSentToMe: 'Email has been sent to Me',
  emailSentToMyOrganization: 'Email has been sent to my Organization',
  removeNoteConfirmMessage: 'Are you sure you want to remove this note?',
  comment: 'Comment',
  updates: 'Updates',
  addComment: 'Add Comment',
  commentPlaceholder: 'Type your comment',
  dropdownChoosePlaceholder: '- Choose -',
  showAll: 'Show all',
  showLatest: 'Show latest',
  hide: 'Hide',
  show: 'Show',
  errorTitle: 'Error',
  noOptions: 'No options',
  components: 'Components',
  errorMessage: 'Oops! Something went wrong',
  requestErrorMessage: 'An error occured. If you are stuck please press the help button. ',
  tryNewAppVersionMessage:
    'We’re excited you’re trying the new version of PlantDemand 🎉 Please feel free to send us your feedback. ',
  sendFeedback: 'Send Feedback',
  switchInterfaceMessage: 'Switch to original interface',
  overProductionTitle: 'Over-Production',
  overProductionMessage: 'You are over capacity by %{overProductionCount} for %{shift}',
  none: 'None',
  showAllOptions: 'Show all %{count} items',
  visibleToCustomersLabel: 'Visible to customers?',
  customerPortal: 'Customer Portal',
  createPortal: 'Create Portal',
  requestMaterial: 'Request material',
  attachments: 'Attachments',
  customerPortals: 'Customer Portals',
  portal: 'Portal',
  active: 'Active?',
  configurePortal: 'You can configure the customer portal below',
  link: 'Link',
  movingOrderInProgressMsg: 'Moving %{count} order(s) in progress...',
  moveOrderSuccessMsg: 'You have moved %{count} order(s) from %{dateFrom} to %{dateTo}',
  moveOrderErrorMsg: 'Cound not move the orders',
  noTableDataMsg: 'There is no data yet',
  reset: 'Reset',
  dashboardPage: {
    goToPlant: 'Go to plant',
    totalQuantity: 'Total Quantity',
  },
  calendar: {
    addOrderText: 'Add Order',
    tentative: 'Tentative',
  },
  checkbox: {
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
  },
  calendarWeek: {
    newOrder: 'New Order',
    addNote: 'Add Note',
    viewInputMaterial: 'View Input Material',
    viewDayReport: 'View Day Report',
    viewEndOfDayReport: 'View End of Day Report',
  },
  reportsPage: {
    calendarData: 'Calendar Data',
    groupBy: 'Group by',
    pivotBy: 'Pivot by',
    pivotByPlaceholder: 'Pivot by...',
    presets: 'Presets...',
    timeFrame: 'Time Frame',
    sendReportFailedMsg: 'Cannot send the report',
    sendToMyOrg: 'Send to my organization',
    sendToMyOrgConfirmMsg:
      'Are you sure you want to send this report to all members of your organization?',
    saveInventoryValuesBtn: 'Save inventory values',
    updateInventoryValuesSuccessMsg: 'Inventory values have been updated',
    updateInventoryValuesErrorMsg: 'Could not update inventory values',
  },
  calendarFilterBar: {
    fields: 'Fields',
    fieldsPlaceholder: 'Selected Fields',
    countFieldsPlaceholder: '%{count} Fields',
    startDate: 'Start Date',
    endDate: 'End Date',
    view: 'Order View',
    move: 'Move',
    plants: 'Plants',
    dates: 'Dates',
    dateRange: 'Date Range',
    selectRange: 'Select Range',
    quantityRange: 'Quantity Range',
    min: 'Min',
    max: 'Max',
    goToToday: 'Go to today',
  },
  plantDropdown: {
    plantImageAlt: 'Plant image',
  },
  modal: {
    close: 'Close',
  },
  orderModal: {
    loadOrderDataErrorMsg: 'Could not load the order data',
    createOrderErrorMsg: 'Could not create the order',
    updateOrderErrorMsg: 'Could not update the order',
    deleteOrder: 'Delete order',
    deleteNote: 'Delete note',
    deleteOrderConfirmationMsg: 'This action will delete %{count} order(s). Are you sure?',
    deleteOrderErrorMsg: 'Could not delete the order',
    notifyTeam: 'Notify Team',
    disabledChangePlantTooltipTitle: 'Please save this order before moving it to another plant',
    deleteAttachmentText: 'Delete attachment',
    deleteAttachmentQuestion: 'Are you sure you want to delete the attachment?',
    deleteAttachmentErrorMsg: 'Could not delete the attachment',
    downloadAttachmentFailedMsg: 'Could not download the attachment',
    deleteLinkText: 'Delete link',
    deleteLinkQuestion: 'Are you sure you want to delete the link?',
    deleteLinkErrorMsg: 'Could not delete the link',
    externalLinks: 'External links',
  },
  orderAttachment: {
    attachFile: 'Attach file',
  },
  orderRequestModal: {
    orderRequest: 'Order request',
    confirmedOrder: 'Confirmed order',
    urgentRequest: 'Urgent request',
    rejectRequest: 'Reject Request',
    rejectReason: 'Please provide a reason to reject request.',
    noRejectReason: 'No reject reason has been provided.',
  },
  noteModal: {
    addNoteDate: 'Add Note Date',
    noteDate: 'Note date',
    plant_closure: 'Plant Closure',
    space_closure: 'Space Closure',
    custom: 'Note',
    unknown: 'Unknown',
    crew: 'Crew / Time-off',
    maintenance: 'Maintenance',
    alert: 'Alert',
    inspection: 'Inspection',
    over_capacity: 'Over-Capacity',
    other: 'Other',
    fieldError: 'This field is required',
    descriptionLabel: 'Description *',
    descriptionPlaceholder: 'Note description ...',
    titleLabel: 'Title',
    titlePlaceholder: 'Customer',
    typeLabel: 'Note type',
    typePlaceholder: 'Plant Closure',
    serverErrorText1:
      'Unfortunately we were not able to save your note. Our server gave the following error',
    serverErrorText2:
      'If this problem keeps happening <a>click here</a> to reach to reach out to PlantDemand',
    notifyTeam: 'Notify Team',
  },
  orderForm: {
    order: 'Order',
    addDates: 'Add Dates',
    deliveryDates: 'Delivery Dates',
    quantity: 'Quantity *',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    nightShift: 'Night Shift',
    create: 'Create',
    save: 'Save',
    saving: 'Saving...',
    remove: 'Remove',
    copy: 'Copy',
    copyOrderMsg:
      'We created a copy of order #%{copyOrderId}. Select the new delivery date and press "Save"',
    moveDates: 'Move Dates',
    movePlant: 'Move Plant',
    changePlant: 'Change Plant',
    customerVisibleField: 'This field is visible by the customers',
    fieldIsRemoved: 'This field was removed from your form',
    errorMessage: 'Something went wrong',
    unknownFieldType: 'Unknown field type',
    cancel: 'Cancel',
    reject: 'Reject',
    approve: 'Approve',
    dailyOrderFieldsTooltipText: 'Click the arrow to see Daily order fields',
  },
  dateQuantityPicker: {
    noOrderFieldsYetMsg: 'There are no fields yet. Go to Settings page to add one',
    clickToAddDetailsMsg: 'Click here to add daily order details',
    tooltipTitle: 'Daily details',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Input Material',
    quantity: 'Quantity',
    supplier: 'Supplier',
    noOrdersMessage:
      'There are no input materials because there are no orders scheduled on this date',
    addOrderLink: 'Click to add an order.',
    noInputMaterials:
      '<span>This %{name} has no input materials. </span><a>Click here </a><span>to add input material to %{name}</span>',
    noInputMaterialsForDay: 'There are no input materials available for this day.',
    clickToAddOrder: 'Click here to add a new order',
    clickToAddMixDesign: 'Click here to manager your mix designs.',
  },
  monthNames: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  activityList: {
    order: 'Order',
    time: 'Time',
    change: 'Change',
    user: 'User',
    noData: 'No results found.',
  },
  ordersList: {
    edit: 'Edit',
    material: 'Material',
    quantity: 'Quantity',
    customer: 'Customer',
    deliveryDate: 'Delivery Date',
    createdDate: 'Created Date',
    noData: 'No results found.',
    checkAll: '#',
    actions: 'Actions',
  },
  plantHeader: {
    orders: 'Orders',
    planner: 'Planner',
    activity: 'Activity',
    requests: 'Requests',
    productionSchedule: 'Production schedule',
    plantSettings: 'Plant Settings',
    cogIcon: 'Cog Icon',
    newOrder: 'New order',
  },
  accountHeader: {
    account: 'Account',
    membership: 'Membership',
    plants: 'Plants',
    customers: 'Customers',
    suppliers: 'Suppliers',
    billing: 'Billing',
    notifications: 'Notifications',
    myProfile: 'My Profile',
  },
  moveOrder: {
    selectOrdersHeader: 'Your selected orders',
    selectPlant: 'Select Plant',
    selectMaterial: 'Select Material',
    moveSelectedOrdersBtn: 'Move selected orders',
    copySelectedOrdersBtn: 'Copy selected orders',
    disableBtnMessage: 'Please select target Plant and Material',
    disableMoveOrderBtnMessage: 'Please select at least one field',
    keepOriginalOrderLabel: 'Keep original order',
    selectMatchingField: 'Remove %{fieldName} field',
    confirmPlantAndMaterial: 'Confirm Plant & Material',
    moveOrderFrom: 'Move order from',
    moveOrderTo: 'Move order to',
    deselectOrderMessage: 'The order date has been deselected.',
    undo: 'Undo',
    bubbleMsgHeader: 'Move your input fields',
    bubbleMsgSubHeader:
      'Match the input fields to ensure no information gets lost when you move your selected orders. Select at least one input field to move this order.',
  },
  orderFormConfiguration: {
    fieldAddedSuccessfully: 'The field was added successfully',
    addFieldFailedMsg: 'Cannot add the field',
    fieldUpdatedSuccessfully: 'The field was updated successfully',
    fieldDeletedSuccessfully: 'The field was deleted successfully',
    fieldMovedSuccessfully: 'The field was moved successfully',
    noOrderFieldsMsg: 'There are no fields yet. Click the "New field" button to add one',
    renameFieldInProgressMsg: 'Field renaming in progress. Please wait...',
    renameFieldSuccessMsg: 'The field has been renamed successfully',
    renameFieldFailedMsg: 'Could not rename the field',
  },
  OrderFormConfigInfoPanel: {
    header: 'Here, you can customize the fields on your plants order forms: ',
    requiredFields: 'Required Fields',
    optionalFields: 'Optional Fields',
    optionsHeaderExampleText: 'For example you can:',
    addTextFieldExampleText: 'Add an text field for truck loads',
    addTextAreaExampleText: 'Add an text area with pickup instructions',
    addCheckboxExampleText: 'Add an checkbox to show nightshift pickups',
    visibleToCustomersText:
      'Mark fields as visible to customer, so that customers can view and edit these fields on order requests.',
    fieldOrder: 'Field order',
    fieldName: 'Field name',
  },
  addInputMaterialPage: {
    materialProperties: 'Material properties',
    materialName: 'Material name',
    supplierProductName: 'Supplier product code/name',
    supplierProductNamePlaceholder: 'Enter supplier product code/name',
    inventoryAmount: 'Inventory amount',
    minimum: 'Minimum',
    maximum: 'Maximum',
    current: 'Current',
    systemColor: 'System color',
    materialColor: 'Material color',
    isOutputMaterial: 'is output material',
    addInputMaterial: 'Add input material',
    materialNamePlaceholder: 'Material name (ex. 1234 - Name)',
    deleteMaterial: 'Delete material',
    deleteMaterialConfirmationMsg: 'Are you sure you want to delete this material?',
  },
  addSupplierModal: {
    supplierName: 'Supplier name',
    supplierAddress: 'Supplier Address',
  },
  mixDesign: {
    deleteMixDesign: 'Delete mix design',
    deleteMixDesignderConfirmationMsg: 'Are you sure you want to delete this?',
    updatedMixDesignSucceededMsg: 'The mix design has been updated successfully',
    updatedMixDesignFailedMsg: 'Cannot update mix design',
    createdMixDesignSucceededMsg: 'The mix design has been created successfully',
    createdMixDesignFailedMsg: 'Cannot create mix design',
    deleteMixDesignSucceededMsg: 'The mix design has been deleted successfully',
    deleteMixDesignFailedMsg: 'Cannot delete mix design',
    addMixDesign: 'Add Mix Design',
    addNewMaterial: 'Add new material',
    addInputMaterial: 'Add input material',
    addOutputMaterial: 'Add output material',
    selectOutputMaterial: 'Select Output Material',
    expirationDate: 'Expiration date',
    specificationBasis: 'Specification basis',
    addComponent: 'Add component',
    percent: 'Percent',
    components: 'Components',
    noComponentsMsg: 'There are no components',
    noMixDesignsMsg: 'No mix designs found',
    mixDesignErrorMsg: 'Cannot load the mix design',
  },
  materialsPage: {
    noMaterialsMsg: 'No materials found',
    outputMaterial: 'Output Material',
  },
  informativePanel: {
    productionMode: 'Production Mode',
    productionModeDescription:
      'Production modes produce "raw materials", which can be ordered directly or combined into new materials using "mix designs".',
    rawMaterial: 'Raw Material %',
    mixDesign: 'Mix Design',
    mixDesignDescription: 'Combine "raw materials" to create "output materials".',
  },
  subscribers: {
    subscribers: 'Subscribers',
    newSubscriber: 'New Subscriber',
    addNewSubscriber: 'Add new subscriber',
    infoSection:
      'You can add email addresses of people that will receive reports as part of your organization.',
  },
  customersPage: {
    infoSection: 'Export this list to another plant. Create new customer',
    viewOrders: 'View Orders',
    viewAsCustomer: 'View as customer',
    noCustomersMsg: 'No customers found',
    targetPlant: 'Target Plant',
    selectTargetPlant: 'Select target plant',
    exportCustomers: 'Export Customers',
    customerName: 'Customer Name',
    customerList: 'Customers list',
    addNewCustomer: 'Add new customer',
    company: 'Company',
    customerCompanyName: 'Customer Company Name',
    createCustomerPortal: 'Create Customer Portal',
    createPortalErrorMsg:
      'Some requests failed. Please try again if you continue to see this error please press the help button',
    editCustomer: 'Edit Customer',
    deleteCustomer: 'Delete customer',
    restoreCustomer: 'Restore customer',
    restoreEntitySuccessMsg: 'Customer %{name} has been restored successfully',
    inactiveCustomerMsg:
      "This customer is currently inactive. Click 'Restore' to activate them again",
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'Plant information has been updated successfully',
    updatePlantInfoErrorMsg: 'Could not update the plant information',
    changePhoto: 'Change Photo',
    toLabel: 'to',
    exportSectionText1: 'Your PlantDemand data belongs to you!',
    exportSectionText2:
      'Export your order data as a CSV file to make backups or use it to build your own excel reports. Remember to check back here to get the most up-to-date version of the data.',
    exportSectionText3:
      'Right click on the link below and select "Save Target as" or "Save Link as";',
    exportCSVFileLabel: 'Export CSV File',
    backToPlant: 'Back to plant',
    changePlantImgSuccessMsg: 'Plant image has been changed successfully',
    changePlantImgFailedMsg: 'Could not change the plant image',
    exportPlantInfoErrorMsg: 'Could not export the plant information',
  },
  spaceInformation: {
    updateSpaceInfoSuccessMsg: 'Space information has been updated successfully',
    updateSpaceInfoErrorMsg: 'Could not update the space information',
  },
  plantMembers: {
    editMemberInfo: 'Edit member information',
    addNewMember: 'Add new member',
    name: 'Name',
    fistNamePlaceholder: 'Member first name',
    lastNamePlaceholder: 'Member last name',
    email: 'Email address',
    emailPlaceholder: 'Email address',
    close: 'Close',
    remove: 'Remove',
    save: 'Save',
    userList: 'User List',
    newEmployeeBtn: 'New employee',
    employee: 'Employee',
    role: 'Role',
    plant: 'Plant',
    plantAccessUserRoles: 'Plant access & User Roles',
    access: 'Access',
    noMembersFound: 'No members found',
    noAccessPlaceholder: 'No access',
    deleteMembershipTitle: 'Delete membership',
    deleteMembershipMessage: 'Are you sure you want to delete the membership?',
    noRoleSelectedMessage: 'Please select at least one role',
    noPermissionsMessage: 'You do not have permissions to modify the access',
    cannotUpdateYourPermissionsMsg: 'Cannot update your permissions',
    cannotDeleteInvitationMessage: 'Cannot delete invitation',
    updatePermissionsMessage: 'Permissions have been updated successfully',
    updatePermissionsError: 'Could not update the member permissions',
    removePermissionsError: 'Could not remove the member permissions',
    inviteNewMemberMessage: 'The member has been invited successfully',
    deleteInvitationMessage: 'The invitation has been deleted successfully',
    deleteInvitationError: 'Could not delete the invitation',
  },
  plantPermissionsTable: {
    permissionsText: 'Permissions',
    needCustomPermissionText: 'Need a custom permission? ',
    contactUs: 'Contact us',
    task: 'Task',
    plantManager: 'Plant Manager',
    dispatch: 'Dispatch',
    QA: 'QA',
    customer: 'Customer',
    viewCalendar: 'View Calendar',
    enterOrders: 'Enter Orders',
    viewPlantSettings: 'View Plant Settings',
    changePlantSettings: 'Change Plant Settings',
    viewReports: 'View Reports',
    inviteUsers: 'Invite Users',
    manageOrderPlanner: 'Manage Order Planner',
    limited: 'Limited',
  },
  suppliersPage: {
    noSuppliersMsg: 'No suppliers found',
    cannotCreateSupplier: 'Could not create the supplier',
  },
  onBoarding: {
    createCompany: 'Create your organization',
    companyName: 'Company Name',
    addFirstPlant: 'Add your first plant',
    uploadPlantImage: 'Add Plant Photo',
    addFirstOutputMaterial: 'Add your first output material',
    outputMaterialName: 'Output material name',
    outputMaterialInfo: 'This is the name of a material you sell',
    materialSetup: 'Material Setup',
    fileSizeBig: 'Image size too big. Please resize or try another file.',
    error: 'An error has occurred',
    isDuplicate: 'This value has already been entered.',
    toCalendar: 'Save & Go To Calendar',
    toAddMixDesign: 'Save & Add Another Mix',
  },
  productionModePage: {
    composition: 'Composition',
    hourlyRate: 'Hourly Rate',
    defaultHourlyRate: 'Default hourly rate',
    addProductionMode: 'Add Production Mode',
    updatedProductionModeMsg: 'The production mode was updated successfully',
    cannotUpdateProductionModeMsg: 'Cannot update production mode',
    deleteProductionMode: 'Delete production mode',
    deleteProductionModeConfirmMsg: 'Are you sure you want to remove this production mode?',
    deleteProductionModeSucceededMsg: 'The production mode was deleted successfully',
    deleteProductionModeFailedMsg: 'Cannot delete production mode',
    createProductionModeSucceededMsg: 'The production mode has been created successfully',
    createProductionModeFailedMsg: 'Cannot create production mode',
  },
  orderRequests: {
    changeOrder: 'Change Order',
    reviewRequest: 'Review Request',
    noRequests: 'There are currently no pending requests for this plant.',
  },
  permissionDenied: {
    header: 'Permission Denied',
    permissionDeniedMsg: 'You do not have permission to access this page',
  },
  signUpPage: {
    loginToPlantDemandText: 'Log-in to PlantDemand',
    loginText:
      'We are happy to see you here! You can create an account and start scheduling in 4 minutes.',
    invitationText:
      'You have been invited to PlantDemand to facilitate plant scheduling. Please complete your account setup to access the schedule',
    hello: 'Hello',
    invitationText1: 'You have been invited to',
    invitationText2: 'Please fill the registration form to get started.',
    by: 'by',
    invitationExpiredText:
      'This registration link is expired. Please ask your manager to invite you again or press the Need Help? button.',
    newToPlantDemandText: 'New to PlantDemand?',
  },
  color: 'Color',
  settingsPage: {
    orderFormConfiguration: 'Order form configuration',
    plantInformation: 'Plant information',
    materialManagement: 'Material management',
  },
  passwordValidation: {
    email: 'Please enter a valid Email Address',
    lowerCase: 'Password should contain lowercase letters',
    upperCase: 'Password should contain uppercase letter',
    number: 'Password should contain number',
    symbol: 'Password should contain symbol',
  },
  passwordRequirements: {
    passwordMustContainMsg: 'Your password must contain:',
    passwordStrength: 'Password Strength: ',
    weak: 'Weak',
    strong: 'Strong',
    minLengthMsg: 'At least %{length} characters',
    lowerCaseMsg: 'At least one lowercase letter(a-z)',
    upperCaseMsg: 'At least one uppercase letter(A-Z)',
    numberMsg: 'At least one number(0-9)',
    symbolMsg: 'At least one symbol(! @ # $ % & + -)',
  },
  signUpComplete: {
    activateAccount: 'Activate your account',
    activationMsg1: 'You are almost there! We have sent an email to',
    activationMsg2:
      "To activate your account, simply click on the link provided in the email. In case you can't find it, please double-check your spam folder",
    activationMsg3:
      'If you need any assistance, please contact us by clicking on the help button below',
    goToMainPage: 'Go to Main page',
  },
  webSocket: {
    reportDataUpdated:
      'Report data has been updated. Refresh the page to see the latest information',
    orderUpdated: 'The order has been updated. Click the Refresh button to reload the data',
  },
  customFieldStyles: {
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strikethrough: 'Strikethrough',
    default: 'Default',
    fieldName: 'Field Name',
    dayColor: 'Day Color',
    nightColor: 'Night Color',
    textFormat: 'Text Format',
    fieldStyles: 'Field Styles',
  },
  logoutPage: {
    loggedOut: 'Logged Out',
    thanksForUsingPD: 'Thanks for using PlantDemand',
    signInAgain: 'Sign in again',
    tryAgain: 'Try again',
    backToHome: 'Back to Home page',
    somethingWentWrong: 'Something went wrong',
    logoutError: 'Could not logged you out',
  },
  membership: {
    noPlantsAssigned: 'No plants assigned',
    plantsAndRoles: 'Plants & roles',
  },
};
