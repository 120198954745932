export const ru = {
  copyright: {
    systemStatus: 'Статус системы',
  },
  mixPlant: 'Микс завод',
  aggregatePlant: 'Агрегатный завод',
  pavingSchedule: 'График укладки брусчатки',
  home: 'Домой',
  mesch: 'MeSch Software UG',
  allRights: '(Haftungsbeschränkt). Все права защищены.',
  privacyPolicy: 'Конфиденциальность',
  termsOfService: 'Условия обслуживания',
  whatsTheName: 'Как зовут нового сотрудника?',
  employeeEmail: 'Электронный адрес сотрудника:',
  dashboard: 'Приборная панель',
  paving: 'Мощение',
  plant: 'Завод',
  role: 'Роль',
  cancel: 'Отменить',
  ok: 'Ок',
  save: 'сохранить',
  add: 'Добавить',
  type: 'Тип',
  required: 'Требуется',
  visibleToCustomers: 'Видно клиентам?',
  export: 'Экспорт',
  newField: 'Новое поле',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  confirmNewPassword: 'Подтвердить новый пароль',
  oldPasswordRequired: 'Нужен старый пароль!',
  newPasswordRequired: 'Необходимо ввести новый пароль!',
  newPasswordConfirmation: 'Введите еще раз новый пароль.',
  passwordsDontMatch: 'Два поля пароля не совпадают.',
  passwordMinLength: 'Пароль должен содержать не менее 8 символов',
  passwordMustBeDifferent: 'Новый пароль должен отличаться от старого.',
  passwordChangeDone: 'Ваш пароль изменен. Нажмите здесь, чтобы выйти.',
  nameChangeDone: 'Ваше имя изменено.',
  changePassword: 'Изменить пароль',
  password: 'Пароль',
  passwordPlaceholder: 'Пароль...',
  repeatNewPassword: 'Повторите новый пароль!',
  repeatPassword: 'Повторите пароль',
  repeatPasswordPlaceholder: 'Повторить пароль...',
  termsAndConditions: 'Начните бесплатную пробную версию и примите Правила и условия',
  termsAndConditionsRequiredMsg: 'Пожалуйста, примите Общие положения и условия',
  email: 'Электронная почта',
  emailAddress: 'Адрес электронной почты',
  emailPlaceholder: 'Электронная почта...',
  emailAddressPlaceholder: 'Адрес электронной почты',
  subscriberNamePlaceholder: 'Имя абонента',
  code: 'Код',
  phone: 'Телефон',
  phoneNumber: 'Телефонный номер',
  fieldIsRequired: 'Поле %{fieldName} обязательно',
  fieldIsMissing: '%{fieldName} не существует',
  company: 'Компания',
  message: 'Сообщение',
  send: 'Отправить',
  sending: 'Отправка',
  sendInvite: 'Отправить приглашение',
  sendToMe: 'Пришли мне',
  sendToOrg: 'Отправить моей команде',
  print: 'Распечатать',
  download: 'Загрузить',
  printReport: 'Распечатать отчет',
  minimumAmountIsRequired: 'Нужна минимальная сумма!',
  maximumAmountIsRequired: 'Нужна максимальная сумма!',
  currentAmountIsRequired: 'Необходимая текущая сумма!',
  nameIsRequired: 'Имя обязательно!',
  selectCountryCode: 'Выберите код страны!',
  firstNamePlaceholder: 'Имя',
  lastNamePlaceholder: 'Фамилия',
  reports: 'Отчеты',
  account: 'Учетная запись',
  referAFriend: 'Предложите другу',
  logOut: 'Выйти',
  organizationMembership: 'Членство в организации',
  employeeCount: '%{count} сотрудников',
  members: 'Члены',
  addAnEmployee: 'Разместите сотрудника',
  addANewPlant: 'Добавьте новый завод',
  onlyAddEmployees: 'Вы должны добавлять только сотрудников %{name}.',
  onlyAddCurrent: 'Разместите только текущих сотрудников',
  inviteNewEmployee: 'Пригласите нового сотрудника в %{name}',
  plants: 'Заводы',
  schedule: 'Расписание',
  edit: 'Редактировать',
  create: 'Создать',
  createPlant: 'Создать завод',
  editPlant: 'Редактировать завод',
  chooseSupplier: 'Выберите поставщика',
  supplier: 'Поставщик',
  suppliers: 'Поставщики',
  materials: 'Материалы',
  productionMode: 'Режим производства',
  mixDesignLabel: 'Смешанный дизайн',
  activate: 'Активировать',
  deactivate: 'Дезактивировать',
  actions: 'Действия',
  notifications: 'Уведомления',
  address: 'Адрес',
  name: 'Имя',
  firstName: 'Имя',
  lastName: 'Фамилия',
  saveName: 'Сохранить имя',
  date: 'Дата',
  expired: 'Срок действия истек',
  picture: 'Картина',
  maxCapacity: 'Максимальная емкость',
  plantName: 'Название завода',
  plantType: 'Тип завода',
  plantTypePlaceholder: 'Выберите тип завода',
  search: 'Поиск',
  searchPlaceholder: 'Поиск...',
  nightShift: 'Ночная смена',
  nightShiftCaps: 'НОЧНОЕ ИЗМЕНЕНИЕ',
  nightShiftFrom: 'Ночная смена от',
  nightShiftTo: 'Ночная смена К',
  inactivePlants: 'Неактивные заводы',
  activePlants: 'Активные заводы',
  addPlant: 'Добавить завод',
  addInputMaterial: 'Добавить входной материал',
  addNewSupplier: 'Добавить нового поставщика',
  addSupplier: 'Добавить поставщика',
  editSupplier: 'Редактировать поставщика',
  deleteSupplier: 'Удалить поставщика',
  deleteSupplierMessage: 'Вы уверены, что хотите удалить поставщика?',
  deleteOrderField: 'Удалить поле формы заказа',
  deleteOrderFieldMessage: 'Вы уверены, что хотите удалить поле заказа?',
  yesDelete: 'Да, удалить',
  yesDeactivate: 'Да, деактивировать',
  yesActivate: 'Да, активировать',
  yes: 'Да',
  description: 'Описание',
  addEmpoyee: 'Добавить сотрудника',
  reactivatePlant: 'Повторно активировать завод',
  deactivatePlant: 'Деактивировать завод',
  configureSupplier: 'Настроить поставщика',
  configurePlant: 'Настройка установки',
  billing: 'Выставление счетов',
  changeCard: 'Изменить карту',
  confirm: 'Подтвердить',
  aysReactivate: 'Вы уверены, что хотите повторно активировать %{name}?',
  aysDeactivate: 'Вы уверены, что хотите деактивировать %{name}?',
  toggleNavigation: 'Включить навигацию',
  login: 'Вход',
  signUp: 'Зарегистрироваться',
  tryItOut: 'Попытайтесь!',
  learnMore: 'Учите больше',
  contact: 'Контакты',
  services: 'Услуги',
  help: 'Справка',
  remove: 'Удалить',
  deletedPrefix: '[УДАЛЕНО]',
  copy: 'Копия',
  verified: 'Проверено',
  newOrderRequest: 'Новый запрос на заказ',
  helpButtonText: 'НУЖНА ПОМОЩЬ?',
  supportModalTitle: 'Как мы можем помочь?',
  supportModalInputPlaceholder: 'Задайте вопрос или проблему с программным обеспечением...',
  supportModalSent: 'Сообщение успешно отправлено.',
  supportModalError: 'Пожалуйста, свяжитесь с нами по адресу support@plantdemand.com',
  loading: 'Загрузка...',
  submit: 'Отправить',
  academy: 'Академия',
  support: 'Поддержка',
  walkthrough: 'Прохождение',
  reportBug: 'Сообщить об ошибке',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  forgotPassword: 'Забыли пароль?',
  staySignedIn: 'Запомнить меня',
  logInAgain: 'Войдите снова',
  logInPlantDemand: 'Войдите в PlantDemand',
  passwordRequiredFieldMsg: 'Необходимо указать пароль',
  passwordMinLengthErrorMsg: 'Пароль должен содержать не менее 8 символов',
  emailRequiredFieldMsg: 'Необходимо указать адрес электронной почты',
  newToPD: 'Впервые пользуетесь PlantDemand?',
  newToPDText:
    'Мы рады вас здесь видеть! Вы можете создать аккаунт и начать планировать за 4 минуты.',
  externalSupplierNotification:
    'Если вы пытаетесь предоставить внешнему поставщику или клиенту доступ к настройкам, используйте функции управления пользователями на вкладке конфигурации установки.',
  inviteFriend: 'Пригласите друга',
  thankYou: 'Спасибо тебе',
  referAFriendMessage:
    'Мы рады, что вам нравится PlantDemand и хотим поделиться им с друзьями и коллегами.',
  thanks: 'Спасибо',
  reminder: 'Напоминание:',
  warningCardTitleOnlyCurrentEmployee: 'Разместите только текущих сотрудников',
  warningCardTextOnlyAddEmployee: 'Вы должны добавлять только сотрудников',
  warningCardTextUseManagmentFeatures:
    'Если вы пытаетесь предоставить внешнему поставщику или клиенту доступ к настройкам, используйте функции управления пользователями на вкладке конфигурации установки.',
  errorCardText: 'В этом бизнесе нет работающих заводов. Пожалуйста, добавьте новый завод.',
  pendingInvitations: 'Приглашение в ожидании',
  deleteInvitation: 'Удалить приглашение?',
  aysDeleteInvitation: 'Вы уверены, что хотите удалить приглашение для %{user}?',
  inputForecast: 'Входной прогноз',
  outputForecast: 'Прогноз выхода',
  production: 'производство',
  inventoryForecast: 'Прогноз запасов',
  dayView: 'Просмотр дня',
  using: 'Использование',
  by: 'за',
  from: 'от',
  until: 'Пока',
  weekdays: 'Будни',
  filters: 'Фильтры',
  shortcuts: 'Ярлыки',
  for: 'ЗА',
  selectedPlants: 'Выбрано %{count} заводов',
  plantsCaps: 'ВОДИ',
  noPlants: 'Никаких Заводов',
  forAllPlants: 'ДЛЯ ВСЕХ ЗАВОДОВ',
  forOnePlant: 'НА ОДИН ЗАВОД',
  thisWeek: 'На этой неделе',
  nextWeek: 'На следующей неделе',
  nextTwoWeeks: 'Две недели',
  threeWeeks: 'Три недели',
  thisMonth: 'В этом месяце',
  nextMonth: 'В следующем месяце',
  thisQuarter: 'Этот квартал',
  thisYear: 'В этом году',
  day: 'День',
  night: 'Ночь',
  week: 'Неделя',
  month: 'Луна',
  refresh: 'Обновить',
  selectMaterial: 'Выберите материал',
  xlsx: 'XLSX',
  youDontHavePlants: 'У вас нет заводов.',
  youCanConfigurePlants: 'Вы можете настроить свои заводы на',
  accountDownCase: 'счет',
  screen: 'экран.',
  youDontHaveOrganisation:
    'У вас нет еще организации. Если ваша компания уже использует PlantDemand, попросите менеджера пригласить вас. Вы можете настроить заводы вашего бизнеса на',
  page: 'страница.',
  registerOrganisation: 'Зарегистрируйте новую организацию',
  askCompanyToConfigureBusiness:
    'Если ваша компания уже использует PlantDemand, попросите менеджера пригласить вас. Вы можете настроить заводы вашего бизнеса на',
  availableFields: 'Доступные поля:',
  updatedMaterialMsg: 'Материал успешно обновлен',
  updatedMaterialFailedMsg: 'Невозможно обновить материал',
  deleteMaterialMsg: 'Материал успешно удалён',
  deleteMaterialFailedMsg: 'Невозможно удалить материал',
  addNewSupplierMsg: 'Поставщика успешно добавлено',
  groupBy: 'Группировать по:',
  pivotReport: 'Сводный отчет',
  productionReport: 'Отчет о производстве',
  noReportData: 'Нет данных для выбранных дат.',
  materialName: 'Название материала',
  total: 'Всего',
  customer: 'Заказчик',
  dates: 'Дать',
  quantity: 'Количество',
  loadTime: 'Время загрузки',
  material: 'Материал',
  compact: 'Компактный',
  classic: 'Классический',
  requestPending: 'Запрос ожидает рассмотрения',
  list: 'Список',
  endDailyReport: 'ЗАВЕРШИТЬ ЕЖЕДНЕВНЫЙ ОТЧЕТ ЗА',
  addTime: 'Добавить время',
  to: 'До',
  orderBy: 'Сортировать по',
  me: 'Я',
  myOrganization: 'Моя организация',
  teamNotifications: 'Уведомление команды',
  dailyReport: 'Ежедневный отчет',
  plantSettings: 'Настройка уведомлений',
  myProfile: 'Мой профиль',
  all: 'Все',
  pleaseSelect: 'Выберите пожалуйста',
  selectAll: 'Выбрать все',
  inventory: 'Инвентарь',
  desiredRange: 'Желательный диапазон:',
  minimumInventory: 'Минимальный запас:',
  maximumInventory: 'Максимальный запас:',
  productionEntriesSign: '↑',
  orderDatesSign: '↓',
  deltaSign: 'Д',
  inventoryForecastLegend: 'Δ - Изменение ↑ - Производство ↓ - Сбыт',
  goToPlant: 'Идите заводить',
  noOrdersOn: 'Без заказов',
  emailSentToMe: 'Мне отправлено письмо',
  emailSentToMyOrganization: 'Электронная почта отправлена Моей организации',
  removeNoteConfirmMessage: 'Вы уверены, что хотите удалить эту заметку?',
  comment: 'Комментарий',
  updates: 'Обновление',
  addComment: 'Добавить комментарий',
  commentPlaceholder: 'Введите свой комментарий',
  dropdownChoosePlaceholder: '- Выберите -',
  showAll: 'Показать все',
  showLatest: 'Показать последние',
  hide: 'Скрыть',
  show: 'Показать',
  errorTitle: 'Ошибка',
  noOptions: 'Без вариантов',
  components: 'компоненты',
  errorMessage: 'Ой! Что-то пошло не так',
  requestErrorMessage: 'Произошла ошибка. Если вы застряли, нажмите кнопку справки.',
  tryNewAppVersionMessage:
    'Мы рады, что вы пробуете новую версию PlantDemand 🎉 Пожалуйста, пришлите нам свой отзыв.',
  switchInterfaceMessage: 'Перейти к оригинальному интерфейсу',
  overProductionTitle: 'Перепроизводство',
  overProductionMessage: 'Вы превысили мощность на %{overProductionCount}',
  none: 'Ни одного',
  showAllOptions: 'Показать все %{count} элементов',
  visibleToCustomersLabel: 'Видно клиентам?',
  requestMaterial: 'Запрос на материал',
  calendar: {
    addOrderText: 'Добавить заказ',
    tentative: 'Ориентировочный',
  },
  checkbox: {
    selectAll: 'Выбрать все',
    deselectAll: 'Снять выбор из всех',
  },
  calendarWeek: {
    newOrder: 'Новый заказ',
    addNote: 'Добавить комментарий',
    viewInputMaterial: 'Просмотреть входной материал',
    viewDayReport: 'Просмотреть отчет за день',
    viewEndOfDayReport: 'Просмотреть отчет на конец дня',
  },
  reportsPage: {
    calendarData: 'Календарные данные',
    groupBy: 'Группировать по',
    pivotBy: 'Pivot by',
    pivotByPlaceholder: 'Pivot by...',
    presets: 'Предварительные настройки...',
    timeFrame: 'Период времени',
    sendReportFailedMsg: 'Невозможно отправить отчет',
  },
  calendarFilterBar: {
    fields: 'Поля',
    fieldsPlaceholder: 'Выбранные поля',
    countFieldsPlaceholder: '%{count} полей',
    startDate: 'Дата начала',
    endDate: 'Дата окончания',
    view: 'Просмотреть заказ',
    move: 'Двигаться',
    plants: 'Заводы',
    dates: 'Дать',
    dateRange: 'Промежуток времени',
    selectRange: 'Выберите диапазон',
    quantityRange: 'Диапазон количества',
    min: 'Мин',
    max: 'Макс',
    goToToday: 'Перейти к сегодняшнему дню',
  },
  plantDropdown: {
    plantImageAlt: 'Изображение завода',
  },
  modal: {
    close: 'Закрыть',
  },
  orderModal: {
    deleteOrder: 'Удалить заказ',
    deleteOrderConfirmationMsg: 'Это действие удалит %{count} заказ(ов). Ты уверен?',
    notifyTeam: 'Сообщить команду',
    disabledChangePlantTooltipTitle:
      'Пожалуйста, сохраните этот заказ перед тем, как переместить его на другой завод',
  },
  orderRequestModal: {
    orderRequest: 'Запрос на заказ',
    confirmedOrder: 'Подтвержденный заказ',
    urgentRequest: 'Срочный запрос',
  },
  noteModal: {
    addNoteDate: 'Добавить дату примечания',
    noteDate: 'Примечание даты',
    plant_closure: 'Закрытие завода',
    custom: 'Примечание',
    unknown: 'Неизвестный',
    crew: 'Экипаж / Перерыв',
    maintenance: 'Техническое обслуживание',
    alert: 'Предупреждение',
    inspection: 'Обзор',
    other: 'Другой',
    fieldError: 'Это поле обязательно',
    descriptionLabel: 'описание*',
    descriptionPlaceholder: 'Описание примечания...',
    titleLabel: 'Название',
    titlePlaceholder: 'Заказчик',
    typeLabel: 'Тип примечания',
    typePlaceholder: 'Закрытие завода',
    serverErrorText1:
      'К сожалению, нам не удалось сохранить вашу заметку. Наш сервер выдал такую ошибку',
    serverErrorText2:
      'Если эта проблема не исчезнет, <a>нажмите здесь</a> , чтобы связаться с PlantDemand',
    notifyTeam: 'Сообщить команду',
  },
  orderForm: {
    order: 'порядок',
    addDates: 'Добавьте даты',
    deliveryDates: 'Сроки доставки',
    quantity: 'Количество *',
    deliveryDate: 'Дата доставки',
    deliveryTime: 'Время доставки',
    nightShift: 'Ночная смена',
    create: 'создать',
    save: 'сохранить',
    saving: 'Сохранение...',
    remove: 'Удалить',
    copy: 'Копия',
    copyOrderMsg:
      'Мы создали копию заказа №%{copyOrderId}. Выберите дату доставки и нажмите «Сохранить»',
    moveDates: 'Переместить даты',
    movePlant: 'Переместить завод',
    changePlant: 'Сменить завод',
    customerVisibleField: 'Это поле видно клиентам',
    fieldIsRemoved: 'Это поле удалено из вашей формы',
    errorMessage: 'Что-то пошло не так',
    unknownFieldType: 'Неизвестный тип поля',
  },
  inputMaterialModal: {
    inputMaterialTitleText: 'Входной материал',
    quantity: 'Количество',
    supplier: 'Поставщик',
    noOrdersMessage: 'Нет исходных материалов, поскольку на дату нет запланированных заказов',
    addOrderLink: 'Нажмите для добавления заказа.',
    noInputMaterials:
      '<span>У этого %{name} нет входных материалов.</span> <a>Нажмите здесь</a> <span>для добавления входного материала в %{name}</span>',
    noInputMaterialsForDay: 'Входных материалов в этот день нет.',
    clickToAddOrder: 'Нажмите здесь, чтобы добавить новый заказ.',
    clickToAddMixDesign: 'Щелкните здесь, чтобы управлять своими дизайнами смесей.',
  },
  monthNames: {
    January: 'января',
    February: 'Февраль',
    March: 'март',
    April: 'апрель',
    May: 'Может',
    June: 'июнь',
    July: 'июль',
    August: 'август',
    September: 'Сентябрь',
    October: 'октябрь',
    November: 'Ноябрь',
    December: 'Декабрь',
  },
  activityList: {
    order: 'порядок',
    time: 'время',
    change: 'Смена',
    user: 'Пользователь',
    noData: 'Ничего не найдено.',
  },
  ordersList: {
    edit: 'Редактировать',
    material: 'материал',
    quantity: 'Количество',
    customer: 'Заказчик',
    deliveryDate: 'Дата доставки',
    createdDate: 'Дата создания',
    noData: 'Ничего не найдено.',
    checkAll: '#',
    actions: 'Действия',
  },
  plantHeader: {
    orders: 'Заказ',
    planner: 'Планировщик',
    activity: 'Деятельность',
    requests: 'Запросы',
    productionSchedule: 'График производства',
    plantSettings: 'Настройка завода',
    cogIcon: 'Cog значок',
    newOrder: 'Новый заказ',
  },
  accountHeader: {
    account: 'Учетная запись',
    membership: 'Членство',
    plants: 'Заводы',
    suppliers: 'Поставщики',
    billing: 'Выставление счетов',
    notifications: 'Уведомления',
    myProfile: 'Мой профиль',
  },
  moveOrder: {
    selectOrdersHeader: 'Выбранные вами заказы',
    selectPlant: 'Выберите &quot;Завод&quot;.',
    selectMaterial: 'Выберите материал',
    moveSelectedOrdersBtn: 'Переместить выбранные заказы',
    copySelectedOrdersBtn: 'Копировать выбранные заказы',
    disableBtnMessage: 'Пожалуйста, выберите целевой завод и материал',
    disableMoveOrderBtnMessage: 'Выберите по крайней мере одно поле',
    keepOriginalOrderLabel: 'Сохраняйте оригинальный порядок',
    selectMatchingField: 'Удалить поле %{fieldName}',
    confirmPlantAndMaterial: 'Подтвердить завод и материал',
    moveOrderFrom: 'Переместить заказ с',
    moveOrderTo: 'Переместить заказ в',
    deselectOrderMessage: 'Дата заказа отменена.',
    undo: 'Отменить',
    bubbleMsgHeader: 'Переместите поля ввода',
    bubbleMsgSubHeader:
      'Совместите поля ввода, чтобы ни одна информация не была потеряна при перемещении выбранных заказов. Выберите, по крайней мере, одно поле ввода, чтобы переместить этот порядок.',
  },
  OrderFormConfigInfoPanel: {
    header: 'Здесь можно настроить поля в формах заказа заводов:',
    requiredFields: 'обязательные поля',
    optionalFields: 'Необязательные поля',
    optionsHeaderExampleText: 'Например, вы можете:',
    addTextFieldExampleText: 'Добавьте текстовое поле для грузовиков',
    addTextAreaExampleText: 'Добавьте текстовую область с инструкциями по получению',
    addCheckboxExampleText: 'Добавьте флажок, чтобы показать ночные смены',
    visibleToCustomersText:
      'Отметьте поля как видимые для клиента, чтобы клиенты могли просматривать и редактировать эти поля при запросах на заказ.',
    fieldOrder: 'Полевой порядок',
    fieldName: 'Название поля',
  },
  addInputMaterialPage: {
    materialProperties: 'Свойства материала',
    materialName: 'Название материала',
    supplierProductName: 'Код/наименование товара поставщика',
    supplierProductNamePlaceholder: 'Введите код/наименование продукта поставщика Имя',
    inventoryAmount: 'Сумма запасов',
    minimum: 'Минимум',
    maximum: 'Максимум',
    current: 'текущий',
    systemColor: 'Системный цвет',
    materialColor: 'Цвет материала',
    isOutputMaterial: 'исходный материал',
    addInputMaterial: 'Добавьте исходный материал',
    materialNamePlaceholder: 'Название материала (например, 1234 – Имя)',
    deleteMaterial: 'Удалить материал',
    deleteMaterialConfirmationMsg: 'Вы уверены, что хотите удалить этот материал?',
  },
  addSupplierModal: {
    supplierName: 'Имя поставщика',
    supplierAddress: 'Адрес поставщика',
  },
  mixDesign: {
    deleteMixDesign: 'Удалить дизайн смеси',
    deleteMixDesignderConfirmationMsg: 'Вы уверены, что хотите это удалить?',
    updatedMixDesignSucceededMsg: 'Дизайн смеси успешно обновлен',
    updatedMixDesignFailedMsg: 'Невозможно обновить дизайн смеси',
    createdMixDesignSucceededMsg: 'Дизайн смеси создан успешно',
    createdMixDesignFailedMsg: 'Невозможно создать смешанный дизайн',
    deleteMixDesignSucceededMsg: 'Дизайн смеси успешно удалён',
    deleteMixDesignFailedMsg: 'Невозможно удалить смешанный дизайн',
    addMixDesign: 'Добавить Mix Design',
    addNewMaterial: 'Добавьте новый материал',
    addInputMaterial: 'Добавьте исходный материал',
    addOutputMaterial: 'Добавить исходный материал',
    selectOutputMaterial: 'Выберите исходный материал',
    expirationDate: 'Срок годности',
    specificationBasis: 'Основа спецификации',
    addComponent: 'Добавить компонент',
    percent: 'Процент',
    components: 'компоненты',
    noComponentsMsg: 'Компонентов нет',
    noMixDesignsMsg: 'Смешанные дизайны не найдены',
    mixDesignErrorMsg: 'Не удается загрузить дизайн смеси',
  },
  materialsPage: {
    noMaterialsMsg: 'Материалы не найдены',
    outputMaterial: 'Исходный материал',
  },
  informativePanel: {
    productionMode: 'Режим производства',
    productionModeDescription:
      'Производственные режимы производят «сырье», которое можно заказать непосредственно или объединить в новые материалы с помощью смешанных конструкций.',
    rawMaterial: 'Сырой материал %',
    mixDesign: 'Смешанный дизайн',
    mixDesignDescription: 'Комбинируйте «сырье» для создания «исходных материалов».',
  },
  subscribers: {
    subscribers: 'Подписчики',
    newSubscriber: 'Новый абонент',
    addNewSubscriber: 'Добавить нового абонента',
    infoSection:
      'Вы можете добавить адреса электронной почты для людей, которые будут получать отчеты от вашей организации.',
  },
  customers: {
    infoSection: 'Экспортируйте этот список на другой завод. Создайте нового клиента',
    viewOrders: 'Просмотреть заказ',
    noCustomersMsg: 'Клиенты не найдены',
    targetPlant: 'Целевой завод',
    selectTargetPlant: 'Выберите целевой завод',
    exportCustomers: 'Экспорт клиентов',
    customerName: 'Имя клиента',
    customerList: 'Список клиентов',
  },
  plantInformation: {
    updatePlantInfoSuccessMsg: 'Информация о заводе успешно обновлена',
    changePhoto: 'Изменить фотографию',
    toLabel: 'к',
    exportSectionText1: 'Ваши данные PlantDemand принадлежат вам!',
    exportSectionText2:
      'Экспортируйте данные заказа как файл CSV для создания резервных копий или используйте их для создания собственных отчетов Excel. Не забудьте проверить здесь, чтобы получить последнюю версию данных.',
    exportSectionText3:
      'Щелкните правой кнопкой мыши ссылку ниже и выберите «Сохранить цель как» или «Сохранить ссылку как»;',
    exportCSVFileLabel: 'Экспорт файла CSV',
    backToPlant: 'Обратно к заводу',
  },
  plantMembers: {
    editMemberInfo: 'Редактировать информацию об участнике',
    addNewMember: 'Добавить нового участника',
    name: 'Имя',
    namePlaceholder: 'Имя члена',
    email: 'Адрес электронной почты',
    emailPlaceholder: 'Адрес электронной почты',
    close: 'Закрыть',
    remove: 'Удалить',
    save: 'сохранить',
    userList: 'Список пользователей',
    newEmployeeBtn: 'Новый работник',
    employee: 'Сотрудник',
    role: 'роль',
    plant: 'Завод',
    plantAccessUserRoles: 'Доступ к заводу и роли пользователей',
    access: 'Доступ',
    noAccessPlaceholder: 'Нет доступа',
    deleteMembershipTitle: 'Удалить членство',
    deleteMembershipMessage: 'Вы уверены, что хотите удалить членство?',
    noRoleSelectedMessage: 'Выберите по крайней мере одну роль',
    noPermissionsMessage: 'У вас нет прав на изменение доступа',
    cannotUpdateYourPermissionsMsg: 'Невозможно обновить ваши разрешения',
    cannotDeleteInvitationMessage: 'Не удалось удалить приглашение',
    updatePermissionsMessage: 'Разрешения успешно обновлены',
    inviteNewMemberMessage: 'Участник успешно приглашен',
    deleteInvitationMessage: 'Приглашение успешно удалено',
  },
  plantPermissionsTable: {
    permissionsText: 'Разрешения',
    needCustomPermissionText: 'Нужно специальное разрешение?',
    contactUs: 'Свяжитесь с нами',
    task: 'Задача',
    plantManager: 'Директор завода',
    dispatch: 'Отправка',
    QA: 'QA',
    customer: 'Заказчик',
    viewCalendar: 'Просмотреть календарь',
    enterOrders: 'Введите заказ',
    viewPlantSettings: 'Просмотр параметров завода',
    changePlantSettings: 'Изменить настройки завода',
    viewReports: 'Просмотреть отчеты',
    inviteUsers: 'Пригласите пользователей',
    manageOrderPlanner: 'Управляйте планировщиком заказов',
    limited: 'Ограниченный',
  },
  suppliersPage: {
    noSuppliersMsg: 'Поставщики не найдены',
    cannotCreateSupplier: 'Не удалось создать поставщика',
  },
  onBoarding: {
    createCompany: 'Создайте свою организацию',
    companyName: 'Название компании',
    addFirstPlant: 'Добавьте свой первый завод',
    uploadPlantImage: 'Добавить фото завода',
    addFirstOutputMaterial: 'Разместите свой первый исходный материал',
    outputMaterialName: 'Название исходного материала',
    outputMaterialInfo: 'Это название материала, который вы продаете',
    materialSetup: 'Настройка материала',
    fileSizeBig: 'Большой размер изображения. Измените размер или попробуйте другой файл.',
    error: 'Произошла ошибка',
  },
  productionModePage: {
    composition: 'Композиция',
    hourlyRate: 'Повременная ставка',
    defaultHourlyRate: 'Почасовая ставка по умолчанию',
    addProductionMode: 'Добавьте режим производства',
    updatedProductionModeMsg: 'Режим производства успешно обновлен',
    cannotUpdateProductionModeMsg: 'Невозможно обновить рабочий режим',
    deleteProductionMode: 'Удалить режим производства',
    deleteProductionModeConfirmMsg: 'Вы уверены, что хотите удалить этот режим производства?',
    deleteProductionModeSucceededMsg: 'Производственный режим успешно удалён',
    deleteProductionModeFailedMsg: 'Не удается удалить рабочий режим',
    createProductionModeSucceededMsg: 'Производственный режим успешно создан',
    createProductionModeFailedMsg: 'Невозможно создать рабочий режим',
  },
  orderRequests: {
    changeOrder: 'Изменить порядок',
    noRequests: 'На данный момент нет запросов на этот завод.',
  },
};
