import { ComponentChild, h } from 'preact';
import { Ref, useCallback, useEffect, useState } from 'preact/hooks';
import RcTimePicker from 'rc-time-picker';
import cls from 'classnames';
import { Message } from 'react-hook-form';

import Button from 'Components/buttons';

import {
  getLocalMomentFromUTCMoment,
  getMomentFromISOTime,
  roundUpTimeToNumber,
  toDefaultTimeFormat,
} from 'Services/timeService';

import styles from './timePicker.scss';
import 'rc-time-picker/assets/index.css';

export interface TimePickerProps {
  value?: string;
  name?: string;
  formLabel?: ComponentChild;
  className?: string;
  btnLabel?: string;
  show: boolean;
  inputRef?: Ref<HTMLInputElement>;
  hideOnClear?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: Message;
  onChange: (time: string) => void;
  setValue?: (name: string, value: string) => void;
}

const TimePicker = ({
  value,
  name = '',
  formLabel,
  className,
  btnLabel,
  show,
  inputRef,
  hideOnClear = true,
  disabled,
  hasError,
  errorMessage,
  onChange,
  setValue,
}: TimePickerProps) => {
  const getTime = useCallback(() => {
    const momentValue = value ? getMomentFromISOTime(value) : undefined;
    return momentValue ? getLocalMomentFromUTCMoment(momentValue) : undefined;
  }, [value]);

  const [time, setTime] = useState<moment.Moment | undefined>(getTime);
  const [showTimePicker, setShowTimePicker] = useState(show);
  const MINUTE_STEP = 5;

  useEffect(() => {
    setTime(getTime());
  }, [getTime]);

  const toggleTimePicker = () => {
    if (!showTimePicker) {
      const momentNow = roundUpTimeToNumber(MINUTE_STEP);

      setTime(momentNow);
      setValue?.(name, toDefaultTimeFormat(momentNow));
      onChange?.(toDefaultTimeFormat(momentNow));
    }

    setShowTimePicker(prevState => !prevState);
  };

  const handleChange = value => {
    if (!value && hideOnClear) setShowTimePicker(false);

    const selectedTime = value ? toDefaultTimeFormat(value) : value;

    setTime(value);
    setValue?.(name, selectedTime);
    onChange?.(selectedTime);
  };

  return (
    <div data-testid="time-picker-wrapper" className={cls(className, { disabled: disabled })}>
      {!showTimePicker && (
        <Button
          variant="text"
          label={btnLabel}
          className={styles.showTimePickerBtn}
          disabled={disabled}
          onClick={toggleTimePicker}
        />
      )}
      {formLabel}

      {showTimePicker && (
        <div data-testid="time-picker">
          <RcTimePicker
            className={cls(styles.customClass, { [styles.hasError]: hasError })}
            popupClassName={styles.popup}
            showSecond={false}
            onChange={handleChange}
            hideDisabledOptions
            minuteStep={MINUTE_STEP}
            value={time}
            use12Hours
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            getPopupContainer={triggerNode => triggerNode.parentNode} // https://github.com/react-component/time-picker/issues/64
          />
          {inputRef && <input type="hidden" name={name} ref={inputRef} />}

          {hasError && (
            <div className={styles.errorStyle} data-testid="input-error-message">
              {errorMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimePicker;
