import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SetLastActiveSpaceAction,
  SetSpacesPermissionsAction,
  setSpacesPermissionsFailed,
  setSpacesPermissionsSucceeded,
  SpaceActionTypes,
} from 'Redux/actions/spaceActions';

import { setActiveSpace } from 'Services/entryService';

export function* saveLastActiveSpace({ payload }: SetLastActiveSpaceAction) {
  yield call(setActiveSpace, payload);
}

export function* fetchSpacesPermissions({ payload }: SetSpacesPermissionsAction) {
  try {
    yield put(setSpacesPermissionsSucceeded(payload));
  } catch (error) {
    yield put(setSpacesPermissionsFailed(error));
  }
}

export default function* watchSpaces() {
  yield takeLatest(SpaceActionTypes.SET_LAST_ACTIVE_SPACE, saveLastActiveSpace);
  yield takeLatest(SpaceActionTypes.SET_SPACE_PERMISSIONS_REQUESTED, fetchSpacesPermissions);
}
