import { h } from 'preact';
import Textarea from 'Components/fields/Textarea';
import Button from 'Components/buttons';
import descriptionIcon from '../../assets/icons/description.svg';

import styles from './addComment.scss';

export interface AddCommentProps {
  className?: string;
  btnLabel: string;
  name: string;
  placeholder: string;
  showInput: boolean;
  disabled?: boolean;
  onClick: () => void;
  onChange: (e) => void;
}

const AddComment = ({
  className,
  btnLabel,
  name,
  placeholder,
  showInput,
  disabled,
  onClick,
  onChange,
}: AddCommentProps) => {
  const handleClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <div className={[styles.commentSection, className].join(' ')}>
      {!showInput && (
        <Button
          variant="text"
          label={btnLabel}
          icon={descriptionIcon}
          className={styles.showCommentBtn}
          disabled={disabled}
          onClick={handleClick}
        />
      )}
      {showInput && <Textarea value="" placeholder={placeholder} name={name} onChange={onChange} />}
    </div>
  );
};

export default AddComment;
