import { call, takeLatest } from 'redux-saga/effects';
import { storeCalendarFilters } from '../../services/calendarFilterService';
import {
  SetCalendarFiltersActionTypes,
  SetCalendarFiltersAction,
} from '../actions/calendarFilterActions';

export function* saveCalendarFilters({ payload }: SetCalendarFiltersAction) {
  yield call(storeCalendarFilters, payload);
}

export default function* watchCalendarFilters() {
  yield takeLatest(SetCalendarFiltersActionTypes.SET_CALENDAR_FILTERS, saveCalendarFilters);
}
