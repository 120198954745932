export const MENU_OPEN = '@menu/MENU_OPEN';
export const MENU_CLOSE = '@menu/MENU_CLOSE';

export const openMenu = () => ({
  type: MENU_OPEN,
});

export const closeMenu = () => ({
  type: MENU_CLOSE,
});
