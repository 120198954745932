import { RootState } from 'Redux/reducers';
import { Roles } from 'Services/plantSettingsService';
import { EntityAccess } from 'Types/plantSettingsTypes';
import { Space, SpacePermissions } from 'Types/spaceTypes';

export const getCurrentSpaceSelector = (state: RootState, spaceId: string): Space | undefined => {
  const spaces = state.spaces.spaces;
  return spaces.find(space => space.id == spaceId);
};

export const viewSpaceConfigurationPermissions = (state: RootState): SpacePermissions[] => {
  return state.spaces.spacesPermissions.filter(
    (pp: SpacePermissions) => pp.permissions.view_space_configuration,
  );
};

export const editUsersSpacePermissions = (state: RootState): EntityAccess[] => {
  return state.spaces.spacesPermissions
    .filter((pp: SpacePermissions) => pp.permissions.edit_users)
    .filter((fp: SpacePermissions) => fp.role !== Roles.CustomerContact)
    .map((pp: SpacePermissions) => ({
      entityId: pp.space_id,
      entityName: pp.space_name,
      canEditUsers: true,
    }));
};
