import { AxiosError } from 'axios';
import { UnknownError } from 'Errors/UnknownError';
import { isAxiosError } from 'Errors/utils';

type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
};

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new UnknownError(JSON.stringify(maybeError), maybeError);
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new UnknownError(String(maybeError), maybeError);
  }
};

// the purpose of that method is to extract the error message(s) sent from the server
const toAxiosErrorMessage = (error: AxiosError) => {
  const errorsArray: string[] = [];
  if (error?.response?.data !== null && typeof error?.response?.data === 'object') {
    const { data } = error.response;
    Object.keys(data).forEach(key => {
      const errors = data[key];

      if (typeof errors === 'string') {
        errorsArray.push(errors);
      } else if (Array.isArray(errors)) {
        errors.forEach(error => {
          if (typeof error === 'string') {
            errorsArray.push(error);
          } else if (typeof error === 'object') {
            Object.keys(error).forEach(errorKey => {
              errorsArray.push(error[errorKey]);
            });
          }
        });
      }
    });
    return errorsArray.join('\n');
  }

  if (typeof error?.response?.data === 'string') {
    return error?.response?.data;
  }

  return error.message;
};

export const getErrorMessage = (error: unknown) => {
  // we could have different kind of errors here
  if (isAxiosError(error)) return toAxiosErrorMessage(error as AxiosError);

  return toErrorWithMessage(error).message;
};
