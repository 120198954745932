import { createContext } from 'preact';
import { useSelector } from 'react-redux';
import { RootState } from 'Redux/reducers';
import { getCurrentSpaceSelector } from 'Redux/selectors/spacesSelector';
import { Configuration } from 'Types/spaceTypes';

type SpaceScheduleContext = {
  isEntryType: boolean;
  spaceConfiguration: Configuration;
};
export const SpaceScheduleContext = createContext<SpaceScheduleContext>({
  spaceConfiguration: {} as Configuration,
  isEntryType: false,
});

export const SpaceScheduleProvider = ({ spaceId, children }) => {
  const currentSpace = useSelector((state: RootState) => getCurrentSpaceSelector(state, spaceId));
  const spaceConfiguration = currentSpace?.configuration || ({} as Configuration);
  const contextValue: SpaceScheduleContext = { spaceConfiguration, isEntryType: true };

  return (
    <SpaceScheduleContext.Provider value={contextValue}>{children}</SpaceScheduleContext.Provider>
  );
};
