import axios from '../plantDemandAxios';
import apiMap from '../utils/apiMap/apiMap';
import { Supplier } from '../types/supplierTypes';
import { AddSupplierFormType } from 'Components/AddSupplierModal';

export const getSuppliers = callback => {
  axios.get(apiMap.allSuppliers).then(({ data }) => {
    callback(data);
  });
};

export const getSupplier = async (id: string): Promise<Supplier> => {
  const response = await axios.get(apiMap.supplierById(id));
  return response.data;
};

export const getAllSuppliers = async (): Promise<Supplier[]> => {
  const response = await axios.get(apiMap.allSuppliers);
  return response.data as Supplier[];
};

export const addNewSupplier = async (data: AddSupplierFormType): Promise<Supplier> => {
  const response = await axios.post(apiMap.newSupplier, data);
  return response.data as Supplier;
};

export const editSupplier = data => {
  return axios.put(apiMap.supplierById(data.id), data);
};

export const deleteSupplier = id => {
  return axios.delete(apiMap.supplierById(id));
};
